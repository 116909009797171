<template>
    <div class="internet-bill container">
        <router-link :to="{ name: 'InternetPayment' }" class="breadcrumbs">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
            >
                <path
                    fill="currentColor"
                    d="M7.828 11H20v2H7.828l5.364 5.364l-1.414 1.414L4 12l7.778-7.778l1.414 1.414z"
                />
            </svg>
            {{ $t('internetPaymentInfo') }}
        </router-link>

        <div class="mt-4">
            <div class="head">{{ $t('billDetail') }}</div>
            <div class="bill">
                <div class="bill-info" v-if="InternetBill">
                    <div>
                        <div>{{ $t('customerCode') }}:</div>
                        <div class="value">
                            {{ InternetBill.customer_code }}
                        </div>
                    </div>
                    <template v-if="InternetBill.bill_value">
                        <div v-if="InternetBill.bill_value">
                            <div>{{ $t('cost') }}:</div>
                            <div class="value">
                                {{
                                    TruncateToDecimals2(InternetBill.bill_value)
                                }}
                                VNĐ
                            </div>
                        </div>
                        <div v-if="InternetBill.fullName">
                            <div>{{ $t('name') }}</div>
                            <div class="value">
                                {{ InternetBill.fullName }}
                            </div>
                        </div>
                        <div v-if="InternetBill.ky">
                            <div>{{ $t('period') }}</div>
                            <div class="value">
                                {{ InternetBill.ky }}
                            </div>
                        </div>
                    </template>
                    <div v-else class="base-loader smaller my-2"></div>
                </div>
                <div v-else class="base-loader"></div>
            </div>
            <div class="botans">
                <button
                    class="botan confirm"
                    :disabled="InternetBill && !InternetBill.bill_value"
                    @click="$bvModal.show('internet-confirm')"
                >
                    <div
                        v-if="InternetBill && !InternetBill.bill_value"
                        class="base-loader smallest inline"
                    ></div>
                    {{ $t('payment') }}
                </button>
                <button
                    class="botan cancel"
                    @click="cancelConfirm(InternetBill)"
                >
                    {{ $t('cancel') }}
                </button>
            </div>
            <div v-if="InternetBill && !InternetBill.bill_value" class="notice">
                {{ $t('pleaseWaitWhileBilling') }}
            </div>
        </div>
        <b-modal
            id="internet-confirm"
            hide-header
            hide-footer
            dialog-class="supa-modal"
            centered
        >
            <div class="mi-head">{{ $t('confirmPayment') }}?</div>
            <div class="mi-confirm">{{ $t('doYouContinuePayment') }}?</div>
            <div class="botans">
                <button class="botan confirm" @click="postPayment">
                    {{ $t('confirm') }}
                </button>
                <button
                    class="botan"
                    @click="$bvModal.hide('internet-confirm')"
                >
                    {{ $t('cancel') }}
                </button>
            </div>
            <button
                class="close black"
                @click="$bvModal.hide('internet-confirm')"
                type="button"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    buyVoucherviewBox="0 0 24 24"
                >
                    <path
                        fill="currentColor"
                        d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                    />
                </svg>
            </button>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: 'InternetDetail',
    data() {
        return {
            InternetBill: null,
        };
    },
    created() {
        this.reqBill();
    },
    methods: {
        async reqBill() {
            const myParam = this.$route.params.id;
            if (myParam) {
                const res = await this.$store.dispatch(
                    'utilities/getBillDetail',
                    myParam,
                );
                if (res.status) {
                    this.InternetBill = res.data;
                    if (this.InternetBill.status === -1) {
                        this.$toastr.e(
                            this.$t('codeTransactionFailed', {
                                code: this.InternetBill.customer_code,
                            }),
                        );
                        this.$router.push({
                            name: 'InternetPayment',
                        });
                    } else if (
                        !this.InternetBill?.bill_value &&
                        this.InternetBill.status === 0
                    ) {
                        setTimeout(() => {
                            this.reqBill();
                        }, 10000);
                    }
                } else {
                    this.$router.push({
                        name: 'InternetPayment',
                    });
                }
            }
        },
        cancelConfirm(item) {
            this.$store.commit('utilities/SET_CANCELING_BILL', item);
            this.$bvModal.show('cancel-bill');
        },
        async postPayment() {
            this.$store
                .dispatch('utilities/postPayment', {
                    transactionId: this.InternetBill.transactionId,
                })
                .then((res) => {
                    if (res?.status) {
                        this.$bvModal.hide('internet-confirm');
                        this.$router.push({
                            name: 'InternetPayment',
                        });
                    }
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.mi-head {
    font-size: 24px;
    font-weight: 600;
    color: #0087cc;
    text-align: center;

    &.fail {
        color: #cc0000;
    }
    @media (min-width: 768px) {
        font-size: 30px;
    }
}
.mi-confirm {
    font-size: 18px;
    text-align: center;
    padding: 15px 0px;
}
.internet-bill {
    padding-top: 30px;
    padding-bottom: 30px;
}
.head {
    text-align: center;
    max-width: 700px;
    margin: auto;
    color: #1478b0;
    font-weight: 600;
    font-size: 1.5em;
    padding-bottom: 15px;
    @media (min-width: 992px) {
        font-size: 2em;
    }
}
.bill {
    max-width: 700px;
    margin: auto;
    padding: 15px;
    border-radius: 12px;
    box-shadow: 1px 1px 4px -1px #1478b0;
}
.breadcrumbs {
    font-size: 1.25em;
    color: #1478b0;
    font-weight: 600;
    transition: 0.2s;
    &:hover {
        color: #22578a;
    }
    @media (min-width: 992px) {
        font-size: 1.75em;
    }
}
.bill-info {
    > div:not(.base-loader) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        font-size: 18px;
        padding: 4px 0px;
        &:last-child {
            &::after {
                display: none;
            }
        }
        .value {
            font-weight: 700;
            color: #0087cc;
        }
    }
}
.botans {
    max-width: 700px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 15px 0px;
    .botan {
        border: 1px solid #0087cc;
        color: #0087cc;
        border-radius: 100vw;
        padding: 5px 15px;
        transition: 0.2s;
        font-size: 1.25em;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        &:disabled {
            opacity: 0.7;
        }
        &.confirm {
            background-color: #0087cc;
            border-color: #0087cc;
            color: #fff;
            &:hover {
                background-color: #1478b0;
            }
            &:active {
                background-color: #22578a;
            }
        }
        &.cancel {
            background-color: #ef4444;
            border-color: #ef4444;

            color: #fff;

            &:hover {
                background-color: #dc2626;
            }
            &:active {
                background-color: #b91c1c;
            }
        }
    }
}
.notice {
    color: #f97316;
    font-size: 1.25em;
    font-weight: 600;
    max-width: 700px;
    margin: auto;
    padding: 15px;
    text-align: center;
}
</style>
