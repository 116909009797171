<template>
    <div class="my-container" id="gotitwrap">
        <div>
            <Transition :name="transitionName" mode="out-in">
                <router-view> </router-view>
            </Transition>
        </div>
    </div>
</template>
<script>
export default {
    name: 'GotItParent',
    data() {
        return {
            transitionName: 'slid',
        };
    },

    watch: {
        $route(to, from) {
            const toDepth = to.path.split('/').length;
            const fromDepth = from.path.split('/').length;
            this.transitionName = toDepth < fromDepth ? 'slid-back' : 'slid';
        },
    },
};
</script>
<style lang="scss" scoped>
.my-container {
    padding-bottom: 60px;
    background-image: url('~@/assets/images/mockup/pattern.png');
    min-height: 80vh;
    margin-bottom: -100px;
}
</style>
<style lang="scss">
#gotitwrap {
    .slid-enter {
        transform: translateX(100%);
        opacity: 0;
    }
    .slid-leave-to {
        transform: translateX(-100%);
        opacity: 0;
    }
    .slid-enter-active,
    .slid-leave-active {
        transition: 0.2s;
    }

    .slid-back-enter {
        opacity: 0;
        transform: translateX(-100%);
    }
    .slid-back-leave-to {
        opacity: 0;
        transform: translateX(100%);
    }
    .slid-back-enter-active,
    .slid-back-leave-active {
        transition: 0.2s;
    }
}
</style>
