<template>
    <div class="authen-container">
        <div class="left">
            <router-view></router-view>
        </div>
        <div class="right">
            <h3>Welcome to XIN App: Journeys To The Well-Being!</h3>
            <p>
                With XIN, you can explore new destinations, share your
                experiences, find new friends, and earn profits from every
                journey. We are committed to providing you with a safe,
                transparent, and exciting platform to discuss, connect, and
                explore the world. Join XIN now to experience a meaningful and
                thrilling travel adventure. Thank you for being a part with us!
            </p>
            <img src="../../assets/images/background/dashboard.png" alt="" />
        </div>
        <NotificationModal />
    </div>
</template>

<script>
import NotificationModal from '@/components/shared/NotificationModal.vue';

export default {
    components: {NotificationModal}
};
</script>

<style lang="scss" scoped>
.authen-container {
    display: flex;
    min-height: 100vh;

    .left {
        width: 50%;
    }

    .right {
        width: 50%;
        padding: 40px;
        background: linear-gradient(#3b5a6b, #0e5c75);
        position: relative;
        z-index: 1;
        background: #055F8D;

        h3 {

            font-size: 30px;
            font-weight: 600;
            color: #fff;
            max-width: 560px;
            margin: auto;
        }

        p {
            margin: 3rem auto 2rem;

            font-size: 16px;
            font-weight: 400;
            color: #c6c6c6;
            max-width: 560px;
        }

        img {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 70%;
            z-index: -1;
        }
    }

    @media (max-width: 1025px) {
        flex-direction: column;
        overflow-y: hidden;
        .left {
            width: 100%;
            height: 100vh;
        }

        .right {
            display: none;
        }
    }

    @media (max-width: 990px) {
        .right {
            padding: 7.6rem 3rem 0 3rem;
        }
    }
}
</style>
