const axios = require('./axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        rateHistories: [
            {
                date: '2021-01-01',
                rate: 1.0,
            },
            {
                date: '2021-01-02',
                rate: 1.1,
            },
            {
                date: '2021-01-03',
                rate: 1.2,
            },
        ],
    }),

    getters: {
        rateHistories: (state) => state.rateHistories, // Correct getter
    },

    actions: {
        async getRateHistory({ commit }, query = {}) {
            const queryString = new URLSearchParams(query).toString();

            const url = queryString
                ? `/rate-history?${queryString}`
                : '/rate-history';
            return axios.get(url).then((res) => {
                if (res) {
                    commit('SET_RATE_HISTORIES', res.data);
                }
                return res;
            });
        },
    },

    mutations: {
        SET_RATE_HISTORIES(state, rates) {
            state.rateHistories = rates;
        },
    },
};
