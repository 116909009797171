<template>
    <b-modal
        v-model="visible"
        dialog-class="my-notify"
        hide-footer
        title="Notification"
    >
        <VueSlickCarousel v-bind="settings" v-if="Banners.length > 0">
            <img
                v-for="(item, index) in Banners"
                :key="index"
                :src="item.image"
            />
        </VueSlickCarousel>
    </b-modal>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import { mapGetters } from 'vuex';

export default {
    name: 'NotificationBanner',
    components: {
        VueSlickCarousel,
    },
    data() {
        return {
            visible: false,
            settings: {
                dots: false,
                dotsClass: 'slick-dots custom-dot-class',
                edgeFriction: 0.35,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 4000,
                adaptiveHeight: true,
            },
        };
    },
    computed: {
        ...mapGetters({
            Banners: 'core/Banners',
        }),
    },
    methods: {},
    mounted() {
        this.$store.dispatch('core/req_getBanners').then((res) => {
            if (res && res.data.results && res.data.results.length > 0) {
                this.visible = true;
            }
        });
    },
};
</script>

<style lang="scss">
// .modal-dialog.my-notify {
//     .modal-content {
//         .modal-body {
//             padding: 0;
//         }
//     }
// }
</style>
