var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"table-container"},[_c('h3',[_vm._v(_vm._s(_vm.$t('myEsim')))]),(_vm.EsimHistory && _vm.EsimHistory.results.length > 0)?_c('div',[_c('b-table',{staticClass:"nft-table",attrs:{"responsive":"","striped":"","hover":"","items":_vm.EsimHistory.results,"fields":_vm.fields},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('h4',{staticClass:"text-center my-3"},[_vm._v(" "+_vm._s(_vm.$t('noData'))+" ")])]},proxy:true},{key:"cell(createdAt)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(new Date(value).toLocaleString())+" ")]}},{key:"cell(capacity)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" Mb ")]}},{key:"cell(type)",fn:function(ref){
var value = ref.value;
return [(value === 'local')?_c('div',[_vm._v(_vm._s(_vm.$t('local')))]):(value === 'regional')?_c('div',[_vm._v(" "+_vm._s(_vm.$t('regional'))+" ")]):(value === 'global')?_c('div',[_vm._v(" "+_vm._s(_vm.$t('global'))+" ")]):_vm._e()]}},{key:"cell(qrCodeURL)",fn:function(ref){
var value = ref.value;
return [_c('b-button',{staticClass:"btn-info",on:{"click":function($event){return _vm.openQR(value)}}},[_vm._v(" "+_vm._s(_vm.$t('activeEsim'))+" ")])]}},{key:"cell(expired_at)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(new Date(value).toLocaleString())+" ")]}},{key:"cell(status)",fn:function(ref){
var value = ref.value;
return [(value === 'ACTIVE')?_c('div',[_c('strong',{staticClass:"text-active"},[_vm._v("Active")])]):(value === 'NOT_ACTIVE')?_c('div',[_c('strong',{staticClass:"text-warning"},[_vm._v("Pending")])]):_vm._e()]}},{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}}],null,false,3686499797)})],1):_c('div',[_c('b-table',{staticClass:"nft-table",attrs:{"responsive":"","striped":"","hover":"","show-empty":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"no-data"},[_vm._v(_vm._s(_vm.$t('noData')))])]},proxy:true}])})],1),(_vm.EsimHistory)?_c('Paginate',{attrs:{"totalPages":_vm.EsimHistory.totalPages},on:{"current":_vm.onChangePage}}):_vm._e()],1),_c('b-modal',{attrs:{"id":"default-nft","modal-class":"default-nft","centered":"","hide-header":"","hide-footer":""}},[_c('h3',[_vm._v("Default X-NFT")]),_c('div',{staticClass:"content"},[_c('div',[_vm._v("Are you sure set default this number ?")]),_c('div',[_vm._v(" By set default to this number, your Esim will bind to this number when you first get it. ")])]),_c('div',{staticClass:"buttons"},[_c('button',{on:{"click":function($event){return _vm.onSetDefaultNFT(_vm.selectedId)}}},[_vm._v(" "+_vm._s(_vm.$t('confirm'))+" ")]),_c('button',{on:{"click":function($event){return _vm.$bvModal.hide('default-nft')}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])]),_c('button',{staticClass:"close",attrs:{"type":"button"},on:{"click":function($event){return _vm.$bvModal.hide('default-nft')}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"1em","height":"1em","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":"currentColor","d":"M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"}})])])]),_c('b-modal',{attrs:{"id":"active-esim","hide-header":"","hide-footer":"","centered":""}},[_c('img',{attrs:{"src":_vm.selectedQR,"alt":""}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }