const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        error: '',
        success: '',
        info: '',
        maintainers: 0,
        wallet: {
            fee: 0,
        },
        banners: [],
        availableLangs: [
            {
                value: 'en',
                text: 'English',
            },
            {
                value: 'vi',
                text: 'Tiếng Việt',
            },
            {
                value: 'ja',
                text: '日本語',
            },
            {
                value: 'ko',
                text: '한국어',
            },
            {
                value: 'ar',
                text: 'العربية',
            },
            {
                value: 'zh',
                text: '中文',
            },
        ],
    }),

    getters: {
        Error: (state) => state.error,
        Success: (state) => state.success,
        WalletConfig: (state) => state.wallet,
        Banners: (state) => state.banners,
        AvailableLangs: (state) => state.availableLangs
    },

    actions: {
        reqGetWalletConfig({ commit }) {
            axios.get('/balance/config').then((res) => {
                if (res && res.data) {
                    commit('SET_WALLET_CONFIG', res.data);
                }
            });
        },
        async req_getBanners({ commit }) {
            return axios.get('/system/get-banner').then((res) => {
                if (res && res.data.results && res.data.results.length > 0) {
                    commit('SET_BANNERS', res.data.results);
                }
                return res;
            });
        },
    },
    mutations: {
        SUCCESS_MESSAGE(state, message) {
            state.success = message;
        },
        ERROR_MESSAGE(state, message) {
            state.error = message;
        },
        INFO_MESSAGE(state, message) {
            state.info = message;
        },
        ERROR_500(state, message) {
            state.status = message;
        },
        SET_WALLET_CONFIG(state, data) {
            state.wallet.fee = data.fee;
        },
        SET_BANNERS(state, data) {
            state.banners = data;
        },
    },
};
