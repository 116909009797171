<template>
    <div>
        <div class="search">
            <input
                v-model="searchValue"
                type="text"
                :placeholder="$t('searchAvailableCountries')"
                @change="searchCountries"
            />
        </div>
        <div v-if="!EsimLocal">
            <div class="loading">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill="none"
                        stroke="currentColor"
                        stroke-dasharray="16"
                        stroke-dashoffset="16"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 3c4.97 0 9 4.03 9 9"
                    >
                        <animate
                            fill="freeze"
                            attributeName="stroke-dashoffset"
                            dur="0.2s"
                            values="16;0"
                        />
                        <animateTransform
                            attributeName="transform"
                            dur="1.5s"
                            repeatCount="indefinite"
                            type="rotate"
                            values="0 12 12;360 12 12"
                        />
                    </path>
                </svg>
                <p>Loading...</p>
            </div>
        </div>
        <div
            class="no-data"
            v-else-if="searchValue && EsimLocal.data.results.length == 0"
        >
            {{ $t('noData') }}
        </div>
        <div
            class="no-data"
            v-else-if="!searchValue && EsimLocal.data.results.length == 0"
        >
            {{ $t('noData') }}
        </div>
        <div class="content" v-else>
            <div
                v-for="item in EsimLocal.data.results"
                :key="item.slug"
                @click="goPayment(item.country_code)"
            >
                <img width="60" height="45" :src="item.image" alt="" />
                <p>
                    {{ item.title }}
                </p>
            </div>
        </div>
        <div class="paginate">
            <b-pagination
                v-if="EsimLocal"
                v-model="page"
                :per-page="EsimLocal.data.page"
                :total-rows="EsimLocal.data.totalPages * EsimLocal.data.page"
                first-number
                :disabled="pending"
            ></b-pagination>
        </div>
    </div>
</template>

<script>
import Paginate from '@/components/shared/Paginate.vue';

export default {
    components: {
        Paginate,
    },
    data() {
        return {
            searchValue: '',
            currentRoute: this.$route.path,
            EsimLocal: null,
            selectedCountry: null,
            page: 1,
            limit: 32,
            currentPage: 1,
            pending: false,
        };
    },

    methods: {
        goPayment(input) {
            this.selectedCountry = input;
            this.$router.push({
                name: 'LocalPayment',
                query: { country_code: input },
            });
            // this.$store.dispatch('nft/getEsimLocal', {
            //     filter: {
            //         type: 'local',
            //         country: input,
            //     },
            // });
        },
        onChangePage(payload, input) {
            this.page = payload;
            this.$store.dispatch('nft/getEsimLocal', {
                filter: {
                    type: 'local',
                    country: input,
                },
                page: this.page,
                limit: this.limit,
            });
        },
        reqList() {
            if (this.pending) {
                return;
            }
            this.pending = true;

            this.$store
                .dispatch('nft/getEsimLocal', {
                    filter: {
                        type: 'local',
                    },
                    page: this.page,
                    limit: this.limit,
                })
                .then((res) => {
                    if (res) {
                        this.EsimLocal = res;
                    }
                })
                .finally(() => {
                    this.pending = false;
                });
        },

        searchCountries() {
            if (this.searchValue) {
                this.page = 1;
                this.$store
                    .dispatch('nft/getEsimLocal', {
                        filter: {
                            type: 'local',
                        },
                        page: this.page,
                        limit: this.limit,
                        keySearch: this.searchValue,
                    })
                    .then((res) => {
                        if (res) {
                            this.EsimLocal = res;
                        }
                    });
            } else {
                this.$store
                    .dispatch('nft/getEsimLocal', {
                        filter: {
                            type: 'local',
                        },
                        page: this.page,
                        limit: this.limit,
                    })
                    .then((res) => {
                        if (res) {
                            this.EsimLocal = res;
                        }
                    });
            }
        },
    },

    mounted() {
        this.reqList();
    },
    watch: {
        page() {
            this.reqList();
        },
    },
};
</script>

<style lang="scss" scoped>
.content {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    row-gap: 2rem;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.15) 2.95px 2.95px 4.6px;
    padding: 1rem;

    > div {
        display: flex;
        gap: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
            width: 60px;
            border-radius: 4px;
        }
        > p {
            font-size: 14px;
            font-weight: 600;
            text-align: center;
        }
    }

    @media (max-width: 1200px) {
        grid-template-columns: repeat(6, 1fr);
    }

    @media (max-width: 991px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 575px) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.search {
    margin-right: auto;
    margin-top: 1rem;
    max-width: 400px;

    input {
        border: 2px solid #0087cc;
        outline: none;
        border-radius: 22px;
        padding: 5px 15px 5px 35px;
        width: 100%;
        background-image: url('../../../../assets/images/icons/search.svg');
        background-repeat: no-repeat;
        background-position: 10px 10px;
        transition: 0.2s;

        &:focus {
            box-shadow: #3593c3 0px 0px 15px;
        }
    }
}

.no-data {
    color: #0087cc;
    font-weight: 600;
    font-size: 40px;
    text-align: center;
}

.loading {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    svg {
        color: #0087cc;
        font-size: 80px;
    }

    p {
        color: #0087cc;
        font-size: 20px;
    }
}

.paginate {
    margin-top: 1rem;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #0087cc;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>
