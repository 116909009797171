var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('stakingHistory')))]),_c('div',{staticClass:"base-table"},[_c('b-table',{attrs:{"thead-class":"custom-header","striped":"","hover":"","items":function () { return _vm.StakeHistory && _vm.StakeHistory.results
                        ? _vm.StakeHistory.results
                        : []; },"show-empty":"","fields":_vm.fields,"responsive":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('h4',{staticClass:"text-center my-3"},[_vm._v(" "+_vm._s(_vm.$t('noData'))+" ")])]},proxy:true},{key:"head()",fn:function(ref){
                        var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}},{key:"cell(dayEnd)",fn:function(ref){
                        var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getDateTime3(value))+" ")]}},{key:"cell(dayStart)",fn:function(ref){
                        var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getDateTime3(value))+" ")]}},{key:"cell(amount)",fn:function(ref){
                        var value = ref.value;
return [_vm._v(" "+_vm._s(Number(value).toLocaleString('en-US', { signDisplay: 'never', }))+" ")]}}])})],1),(_vm.StakeHistory)?_c('b-pagination',{attrs:{"total-rows":_vm.StakeHistory.totalResults,"per-page":_vm.params.limit},model:{value:(_vm.params.page),callback:function ($$v) {_vm.$set(_vm.params, "page", $$v)},expression:"params.page"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }