<template>
    <div>
        <b-modal
            id="choose-nft-free"
            modal-class="nft"
            centered
            hide-header
            hide-footer
            :no-close-on-backdrop="true"
            :no-close-on-esc="true"

        >
            <h3>{{ $t('pleaseSelectFreeNFT') }}</h3>
            <div class="note">
                {{ $t('requireChooseFreeNFT') }}
            </div>
            <div class="nft-select">
                <div v-if="FreeNFT && FreeNFT.length > 0">
                    <label
                        v-for="(item, index) in FreeNFT"
                        :key="index"
                        class="nft-option"
                    >
                        <div>{{ item.number }}</div>
                        <input
                            type="radio"
                            :value="item.number"
                            @change="getNFTId(item.id)"
                            v-model="selectedNum"
                        />

                        <span class="custom-check"></span>
                    </label>
                </div>
                <div v-else class="empty-list">
                    {{ $t('noData') }}
                </div>
            </div>
            <div class="buttons">
                <button @click="buyNFTFree(selectedId)">
                    {{ $t('confirm') }}
                </button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            selectedNum: '',
            selectedId: null,
        };
    },

    computed: {
        ...mapGetters({
            FreeNFT: 'nft/FreeNFT',
        }),
    },

    methods: {
        getNFTId(input) {
            this.selectedId = input;
        },

        buyNFTFree(id) {
            if (!this.selectedId) {
                this.$toastr.e('Please select number first', 'Warning !!!');
            } else {
                this.$store
                    .dispatch('nft/buyNFTFree', { nftId: id })
                    .then(() => {
                        this.$bvModal.hide('choose-nft-free');
                    });
            }
        },
    },

    mounted() {
        this.$store.dispatch('nft/getFreeNFT').then((res) => {
            if (res && res.length > 0) {
                this.$bvModal.show('choose-nft-free');
            }
        });
    },
};
</script>

<style lang="scss" scoped>
.nft {
    h3 {
        color: #0087cc;
        text-align: center;
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 1.5rem;
    }

    .note {
        font-size: 18px;
        font-weight: 600;
        color: #db2d16;
        text-align: center;
        margin-bottom: 1rem;
    }
    .nft-select {
        // height: 350px;
        overflow-y: auto;
        color: #0087cc;
        margin-bottom: 1.5rem;
        position: relative;
        .nft-option {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 6px;
            border-bottom: 1px solid #c1c1c1;
            font-weight: 500;
            font-size: 16px;
            cursor: pointer;
            transition: 0.2s;

            &:hover {
                background-color: #1249841c;
            }

            &:last-child {
                border: none;
            }

            > input[type='radio'] {
                position: absolute;
                width: 0;
                height: 0;
                opacity: 0;
            }

            .custom-check {
                display: inline-block;
                width: 24px;
                height: 24px;
                border-radius: 6px;
                border: 1px solid #afafaf;
                transition: 0.2s;
                position: relative;
                color: transparent;

                &::before {
                    content: '';
                    position: absolute;
                    border: 2px solid currentColor;
                    border-radius: 2px;
                    width: 70%;
                    height: 40%;
                    border-color: transparent transparent currentColor
                        currentColor;
                    transform: translate(-50%, -50%) rotate(-45deg);
                    top: 40%;
                    left: 50%;
                }
            }

            > input[type='radio']:checked + .custom-check {
                background-color: #124984;
                color: #fff;
            }
        }

        .loading {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            gap: 15px;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            svg {
                color: #0087cc;
                font-size: 100px;
            }

            p {
                color: #0087cc;
                font-size: 20px;
            }
        }
        .empty-list {
            text-align: center;
            padding-top: 32px;
            font-size: 20px;
        }
    }

    .buttons {
        display: flex;
        justify-content: center;
        gap: 15px;

        button {
            max-width: 400px;
            width: 100%;
            color: white;
            border-radius: 72px;
            padding: 4px 10px;
            font-size: 18px;
            text-transform: uppercase;
            font-weight: 600;
            text-align: center;
            transition: 0.2s;
            outline: none;
            background-image: linear-gradient(
                to right,
                #075d9b,
                #1775b8,
                #91c4e9,
                #d2e9fa
            );
            background-position: center;
            background-size: 200%;

            &:hover {
                background-position: 0%;
            }
        }
    }

    .tip {
        text-align: center;
        font-weight: 600;
        color: #0087cc;
        margin: 10px 0;
    }

    .refresh {
        gap: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0;

        > div {
            display: flex;
            align-items: center;
            gap: 5px;
            color: #0087cc;
            font-weight: 600;
            cursor: pointer;
        }
    }

    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        outline: none;
    }

    @media (max-width: 1500px) {
        .nft-select {
            height: 250px;
        }
    }

    @media (max-width: 414px) {
        h3 {
            font-size: 20px;
        }

        .search {
            input {
                font-size: 14px;
                padding: 5px 10px;
            }
        }

        .buttons {
            button {
                font-size: 14px;
            }
        }
    }
}
</style>

<style lang="scss">
.nft {
    .modal-dialog {
        max-width: 700px;
    }
    .modal-content {
        border-radius: 22px;
        .modal-body {
            padding: 3rem;
            border-radius: 19px;
            background-image: url('~@/assets/images/mockup/pattern.png');

            @media (max-width: 414px) {
                padding: 1rem;

                h3 {
                    margin-top: 1rem;
                }
            }
        }
    }
}
</style>
