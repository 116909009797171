<template>
    <b-modal id="mana-center" hide-footer hide-header dialog-class="supa-modal" centered>
        <div>
            <div class="head">{{ $t('manaCenter') }}</div>
            <div>
                <!-- <button class="botan nice-gradient" @click="redirectModal('mana-topup')">
                    <div>
                        <div class="nice-mana small">
                            <img src="@/assets/images/icons/mana.svg" width="20" height="20" class="mana right" />
                            <img src="@/assets/images/icons/mana.svg" width="20" height="20" class="mana left" />
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"
                                class="plus">
                                <path fill="currentColor"
                                    d="M18 10h-4V6a2 2 0 0 0-4 0l.071 4H6a2 2 0 0 0 0 4l4.071-.071L10 18a2 2 0 0 0 4 0v-4.071L18 14a2 2 0 0 0 0-4" />
                            </svg>
                        </div>
                        <span> Top up Mana </span>
                    </div>
                </button> -->
                <button class="botan nice-gradient" @click="redirectModal('mana-limit-confirm')">
                    <div>
                        <div class="nice-mana small">
                            <img src="@/assets/images/icons/mana.svg" width="20" height="20" class="mana" />
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"
                                class="expand" transform="rotate(-90)">
                                <path fill="currentColor"
                                    d="m20.08 11.42l-4.04-5.65c-.34-.48-.89-.77-1.48-.77c-1.49 0-2.35 1.68-1.49 2.89L16 12l-2.93 4.11c-.87 1.21 0 2.89 1.49 2.89c.59 0 1.15-.29 1.49-.77l4.04-5.65c.24-.35.24-.81-.01-1.16" />
                                <path fill="currentColor"
                                    d="M13.08 11.42L9.05 5.77C8.7 5.29 8.15 5 7.56 5C6.07 5 5.2 6.68 6.07 7.89L9 12l-2.93 4.11C5.2 17.32 6.07 19 7.56 19c.59 0 1.15-.29 1.49-.77l4.04-5.65c.24-.35.24-.81-.01-1.16" />
                            </svg>
                            <div class="bar"></div>
                        </div>
                        <span> {{ $t('expandManaCapacity') }} </span>
                    </div>
                </button>
                <button class="botan nice-gradient" @click="redirectModal('mana-transfer')">
                    <div>
                        <div class="nice-mana small transfer">
                            <img width="20" height="20" src="@/assets/images/icons/mana.svg" class="mana" />
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                class="arrow">
                                <path fill="currentColor"
                                    d="M13.293 7.293a.999.999 0 0 0 0 1.414L15.586 11H8a1 1 0 0 0 0 2h7.586l-2.293 2.293a.999.999 0 1 0 1.414 1.414L19.414 12l-4.707-4.707a.999.999 0 0 0-1.414 0" />
                            </svg>
                        </div>
                        <span> {{ $t('transferMana') }} </span>
                    </div>
                </button>
            </div>
        </div>
        <button class="close" @click="$bvModal.hide('mana-center')" type="button" style="color: #0087cc">
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                <path fill="currentColor"
                    d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z" />
            </svg>
        </button>
    </b-modal>
</template>

<script>
export default {
    name: 'ManaCenter',
    methods: {
        redirectModal(input) {
            this.$bvModal.show(input);
            this.$bvModal.hide('mana-center');
        },

        expandManaCap() {
            this.$bvModal.open('mana-limit-confirm')
        }
    },
};
</script>
<style lang="scss" scoped>
.head {
    font-size: 30px;
    font-weight: 600;
    color: #0087cc;
    text-align: center;
    margin-top: 0.5rem;
}

.botan {
    border-radius: 6px;
    gap: 10px;
    width: 100%;
    color: #f9fafb;
    font-size: 20px;
    font-weight: 700;
    margin: 10px 0px;
    padding: 4px 10px;

    >div {
        max-width: 280px;
        display: flex;
        align-items: center;
        margin: auto;
        gap: 10px;

        >span {
            flex-grow: 1;
            text-align: center;
        }
    }
}
</style>
