<template>
    <div>
        <div v-if="walletAddress.length < 1" class="connect-wallet">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.07em"
                height="1em"
                viewBox="0 0 256 240"
            >
                <path
                    fill="#e17726"
                    d="M250.066 0L140.219 81.279l20.427-47.9z"
                />
                <path
                    fill="#e27625"
                    d="m6.191.096l89.181 33.289l19.396 48.528zM205.86 172.858l48.551.924l-16.968 57.642l-59.243-16.311zm-155.721 0l27.557 42.255l-59.143 16.312l-16.865-57.643z"
                />
                <path
                    fill="#e27625"
                    d="m112.131 69.552l1.984 64.083l-59.371-2.701l16.888-25.478l.214-.245zm31.123-.715l40.9 36.376l.212.244l16.888 25.478l-59.358 2.7zM79.435 173.044l32.418 25.259l-37.658 18.181zm97.136-.004l5.131 43.445l-37.553-18.184z"
                />
                <path
                    fill="#d5bfb2"
                    d="m144.978 195.922l38.107 18.452l-35.447 16.846l.368-11.134zm-33.967.008l-2.909 23.974l.239 11.303l-35.53-16.833z"
                />
                <path
                    fill="#233447"
                    d="m100.007 141.999l9.958 20.928l-33.903-9.932zm55.985.002l24.058 10.994l-34.014 9.929z"
                />
                <path
                    fill="#cc6228"
                    d="m82.026 172.83l-5.48 45.04l-29.373-44.055zm91.95.001l34.854.984l-29.483 44.057zm28.136-44.444l-25.365 25.851l-19.557-8.937l-9.363 19.684l-6.138-33.849zm-148.237 0l60.435 2.749l-6.139 33.849l-9.365-19.681l-19.453 8.935z"
                />
                <path
                    fill="#e27525"
                    d="m52.166 123.082l28.698 29.121l.994 28.749zm151.697-.052l-29.746 57.973l1.12-28.8zm-90.956 1.826l1.155 7.27l2.854 18.111l-1.835 55.625l-8.675-44.685l-.003-.462zm30.171-.101l6.521 35.96l-.003.462l-8.697 44.797l-.344-11.205l-1.357-44.862z"
                />
                <path
                    fill="#f5841f"
                    d="m177.788 151.046l-.971 24.978l-30.274 23.587l-6.12-4.324l6.86-35.335zm-99.471 0l30.399 8.906l6.86 35.335l-6.12 4.324l-30.275-23.589z"
                />
                <path
                    fill="#c0ac9d"
                    d="m67.018 208.858l38.732 18.352l-.164-7.837l3.241-2.845h38.334l3.358 2.835l-.248 7.831l38.487-18.29l-18.728 15.476l-22.645 15.553h-38.869l-22.63-15.617z"
                />
                <path
                    fill="#161616"
                    d="m142.204 193.479l5.476 3.869l3.209 25.604l-4.644-3.921h-36.476l-4.556 4l3.104-25.681l5.478-3.871z"
                />
                <path
                    fill="#763e1a"
                    d="M242.814 2.25L256 41.807l-8.235 39.997l5.864 4.523l-7.935 6.054l5.964 4.606l-7.897 7.191l4.848 3.511l-12.866 15.026l-52.77-15.365l-.457-.245l-38.027-32.078zm-229.628 0l98.326 72.777l-38.028 32.078l-.457.245l-52.77 15.365l-12.866-15.026l4.844-3.508l-7.892-7.194l5.952-4.601l-8.054-6.071l6.085-4.526L0 41.809z"
                />
                <path
                    fill="#f5841f"
                    d="m180.392 103.99l55.913 16.279l18.165 55.986h-47.924l-33.02.416l24.014-46.808zm-104.784 0l-17.151 25.873l24.017 46.808l-33.005-.416H1.631l18.063-55.985zm87.776-70.878l-15.639 42.239l-3.319 57.06l-1.27 17.885l-.101 45.688h-30.111l-.098-45.602l-1.274-17.986l-3.32-57.045l-15.637-42.239z"
                />
            </svg>
            <button @click="onConnectMetaMask">
                {{ $t('connectWithWallet') }}
            </button>
        </div>
        <div class="wallet-form" v-else>
            <h3>{{ $t('updateWallet') }}</h3>
            <!-- Current password -->
            <form @submit.prevent="updateWallet">
                <div class="address">
                    <strong>{{ $t('address') }}</strong
                    >:
                    <div v-if="walletAddress.length > 1">
                        <b-form-select v-model="selected" class="mb-3">
                            <template #first>
                                <b-form-select-option :value="null" disabled
                                    >--
                                    {{
                                        $t('pleaseSelectAddress')
                                    }}--</b-form-select-option
                                >
                            </template>
                            <b-form-select-option
                                v-for="address in walletAddress"
                                :key="address"
                                :value="address"
                            >
                                {{ address }}
                            </b-form-select-option>
                        </b-form-select>
                    </div>
                    <span v-else>
                        {{ this.walletAddress[0] }}
                    </span>
                </div>
                <div class="input-pw">
                    <input
                        :type="showCurrentPass ? 'text' : 'password'"
                        :placeholder="$t('password')"
                        id="password"
                        v-model="currentPassword"
                        autocomplete="current-password"
                    />

                    <img
                        v-if="!showCurrentPass"
                        @click="toggleShowCurrent"
                        class="eye-open"
                        src="@/assets/images/icons/eye.svg"
                        alt=""
                    />
                    <img
                        v-else
                        @click="toggleShowCurrent"
                        class="eye-close"
                        src="@/assets/images/icons/eye-close.svg"
                        alt=""
                    />
                </div>
                <div class="update-btn">
                    <button type="submit">{{ $t('update') }}</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            address: '0xc6ed69214CE2884d6C4D620CB19F6321c4190865',
            isChose: '',
            unsubscribe: null,
            walletAddress: [],
            selected: null,
            currentPassword: null,
            showCurrentPass: false,
        };
    },
    computed: {
        ...mapGetters({
            UserInfo: 'info/UserInfo',
            WalletConfig: 'core/WalletConfig',
            Address: 'info/Address',
            isNetWork: 'contract/isNetWork',
        }),
        ChainID() {
            if (this.isNetWork === 'testnet') {
                return 97;
            }
            return 56;
        },
        netWorkConfig() {
            const testNet = {
                chainId: '0x61',
                chainName: 'Smart Chain - Testnet',
                nativeCurrency: {
                    name: 'Binance Coin',
                    symbol: 'BNB',
                    decimals: 18,
                },
                rpcUrls: [
                    'https://data-seed-prebsc-2-s2.binance.org:8545/',
                    'https://data-seed-prebsc-1-s1.binance.org:8545/',
                    'https://data-seed-prebsc-5-s5.binance.org:8545/',
                    'https://data-seed-prebsc-3-s3.binance.org:8545/',
                    'https://data-seed-prebsc-4-s4.binance.org:8545/',
                ],
                blockExplorerUrls: ['https://testnet.bscscan.com'],
            };
            const mainNet = {
                chainId: '0x38',
                chainName: 'Smart Chain',
                nativeCurrency: {
                    name: 'Binance Coin',
                    symbol: 'BNB',
                    decimals: 18,
                },
                rpcUrls: [
                    'https://bsc-dataseed.binance.org/',
                    'https://bsc-dataseed1.defibit.io/',
                    'https://bsc-dataseed1.ninicoin.io/',
                ],
                blockExplorerUrls: ['https://bscscan.com'],
            };
            if (this.isNetWork === 'testnet') {
                return testNet;
            }
            return mainNet;
        },
        AddressShow() {
            const { address } = this.UserInfo;
            // if (!address) return null;
            // const stringShort = `${address.slice(0, 7)}...${address.slice(
            //     address.length - 10,
            //     address.length,
            // )}`;
            return address;
        },
    },

    methods: {
        async onConnectMetaMask() {
            // let address = [];
            try {
                this.walletAddress = await window.ethereum.request({
                    method: 'eth_requestAccounts',
                });
            } catch (error) {
                this.outLoad();
                if (
                    error.message ===
                    'Already processing eth_requestAccounts. Please wait.'
                ) {
                    this.$toastr.e('Please Connect Metamask To Join', 'Oops!');
                    return false;
                }
                this.$toastr.e(error.message, 'Oops!');
                return false;
            }
            if (window.ethereum.chainId !== this.ChainID) {
                const params = this.netWorkConfig;
                window.ethereum
                    .request({
                        method: 'wallet_addEthereumChain',
                        params: [params],
                    })
                    .then(() => console.log('add network success'))
                    .catch(() => {
                        this.showNotification = true;
                        this.message = {
                            content:
                                'Please switch To The  Binance Smartchain Network!',
                            failed: true,
                            title: 'Oops...',
                        };
                        window.ethereum.request({
                            method: 'wallet_switchEthereumChain',
                            params: [{ chainId: this.ChainID }],
                        });
                    });
            }
            if (this.walletAddress.length < 0) {
                return false;
            }
            this.$store.commit('info/SET_ADDRESS', {
                address: this.walletAddress[0],
            });
            this.$store.dispatch('auth/updateWallet', {
                address: this.walletAddress[0],
            });
            console.log('Address: ', this.walletAddress);

            // this.$bvModal.show('update-wallet-modal');

            return true;
        },
        async updateWallet() {
            const txData = await window.ethereum.request({
                method: 'personal_sign',
                params: [this.Address, this.$store.state.auth.sign],
            });
            console.log(this.$store.state.auth.sign);
            this.$store
                .dispatch('auth/updateWallet', {
                    address: this.Address,
                    sign: txData,
                    password: this.currentPassword,
                })
                .then((res) => {
                    this.$bvModal.hide('update-wallet-modal');
                    this.$store.dispatch('info/req_getInfo');

                    if (res) {
                        this.$toastr.s(
                            'Successfully update address',
                            'Success',
                        );
                    }
                });
        },
        toggleShowCurrent() {
            this.showCurrentPass = !this.showCurrentPass;
        },
    },

    mounted() {
        console.log('Address length: ', this.walletAddress.length);
    },
};
</script>

<style lang="scss" scoped>
.connect-wallet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 1.5rem;
    svg {
        font-size: 100px;
    }
    button {
        background: linear-gradient(180deg, #00d1ff 0%, #0087c8 100%);
        border-radius: 72px;
        color: white;
        font-size: 16px;
        padding: 0.5rem 2rem;
    }
}
</style>

<style lang="scss">
.wallet-form {
    position: relative;

    .modal-body {
        border-radius: 22px;
    }

    h3 {
        font-size: 30px;
        font-weight: 600;
        color: #0087cc;
        text-align: center;
        margin-top: 1.5rem;
    }

    input {
        width: 100%;
        padding: 0 1rem;
        border: 1px solid #e4e4e4;
        border-radius: 8px;
        height: 40px;
        color: #000;
    }

    input:focus-visible {
        outline: none;
    }

    input::placeholder {
        color: #a6a6a6;
        font-size: 18px;
        font-style: italic;
        font-weight: 400;
        text-align: left;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .update-btn {
        display: flex;
        justify-content: center;

        button {
            background: linear-gradient(180deg, #00d1ff 0%, #0087c8 100%);
            border-radius: 72px;
            color: white;
            font-size: 16px;
            padding: 0.5rem 2rem;
        }
    }

    .input-pw {
        position: relative;
        margin: 10px 0;
        img {
            position: absolute;
            top: 50%;
            right: 5px;
            transform: translate(-50%, -50%);
            cursor: pointer;
        }
    }

    .address {
        overflow-x: auto;
    }
}
</style>
