<template>
    <div class="brands-list">
        <h1>{{ $t('partners') }}</h1>
        <div class="brands-container">
            <div class="show-more">
                <div class="result">
                    {{ $t('result') }} <strong>({{ brands.length }})</strong>
                </div>
                <button @click="showMoreFunc">
                    {{ showAll ? $t('collapse') : $t('showMore') }}
                </button>
            </div>
            <div class="loading" v-if="this.slicedBrands.length === 0">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill="none"
                        stroke="currentColor"
                        stroke-dasharray="16"
                        stroke-dashoffset="16"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 3c4.97 0 9 4.03 9 9"
                    >
                        <animate
                            fill="freeze"
                            attributeName="stroke-dashoffset"
                            dur="0.2s"
                            values="16;0"
                        />
                        <animateTransform
                            attributeName="transform"
                            dur="1.5s"
                            repeatCount="indefinite"
                            type="rotate"
                            values="0 12 12;360 12 12"
                        />
                    </path>
                </svg>
            </div>
            <div class="brands" v-else>
                <div v-for="brand in slicedBrands" :key="brand.brandId">
                    <a
                        :href="slugGotIt(brand.slug, brand.brandId)"
                        target="_blank"
                    >
                        <img :src="brand.brandLogo" alt="" />
                        <!-- {{ brand.categoryID }} -->
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            brands: [],
            slicedBrands: [],
            error: null,
            firstRender: 50,
            showAll: false,
            category: [
                {
                    id: '0',
                    value: 'Tất cả',
                },
                {
                    id: '2',
                    value: 'Cafe & bánh',
                },
                {
                    id: '1',
                    value: 'Nhà Hàng',
                },
                {
                    id: '3',
                    value: 'Mỹ phẩm',
                },
                {
                    id: '21',
                    value: 'Spa & Làm đẹp',
                },
                {
                    id: '7',
                    value: 'Siêu thị & Mua sắm',
                },
                {
                    id: '9',
                    value: 'Tiện ích & Giải trí',
                },
                {
                    id: '11',
                    value: 'Thời trang & Phụ kiện',
                },
                {
                    id: '12',
                    value: 'Du lịch & Di chuyển',
                },
                {
                    id: '13',
                    value: 'Mẹ & bé',
                },
                {
                    id: '17',
                    value: 'Chăm sóc sức khỏe',
                },
                {
                    id: '18',
                    value: 'Giáo dục & Ngoại ngữ',
                },
                {
                    id: '19',
                    value: 'Hoa, Trái cây & Thực phẩm sạch',
                },
                {
                    id: '20',
                    value: 'Nhà cửa, Đời sống & Thú cưng',
                },
                {
                    id: '16',
                    value: 'Thẻ game',
                },
                {
                    id: '999',
                    value: 'Nạp Tiền Điện Thoại',
                },
            ],
        };
    },
    beforeMount() {
        this.fetchBrands();
    },
    methods: {
        async fetchBrands() {
            try {
                const response = await fetch(
                    'https://alabapay.pigaming.co/api/get-brands',
                );
                if (!response.ok) {
                    throw new Error(
                        `An error occurred: ${response.statusText}`,
                    );
                }
                const data = await response.json();
                this.brands = data.data.flat();
                this.slicedBrands = this.brands.slice(0, this.firstRender);
                console.log('brands:', data);
            } catch (error) {
                this.error = error.message;
            }
        },

        showMoreFunc() {
            if (this.showAll) {
                this.slicedBrands = this.brands.slice(0, this.firstRender);
            } else {
                this.slicedBrands = this.brands;
            }
            this.showAll = !this.showAll;
        },
        slugGotIt(slug, id) {
            return `https://www.gotit.vn/brands/${slug}.${id}`;
        },
    },
};
</script>

<style lang="scss" scoped>
.brands-list {
    margin-top: 3rem;
}
.brands {
    margin-top: 1rem;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, 80px);
    justify-content: center;
    img {
        object-fit: contain;
        width: 100%;
    }
}

.show-more {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .result {
        font-size: 18px;
        color: #0087cc;
    }
    button {
        color: #fff;
        font-weight: 600;
        background: #0087cc;
        padding: 8px 16px;
        border-radius: 8px;

        &:hover {
            text-decoration: underline;
        }
    }
}

.loading {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    svg {
        color: #0087cc;
        font-size: 100px;
    }
}
</style>
