<template>
    <div>
        <b-modal id="mana-topup" hide-footer hide-header dialog-class="supa-modal" centered>
            <div>
                <h3 class="head">Mana topup</h3>
                <div class="content">
                    <b-form-select v-model="selected" class="mb-3">
                        <template #first>
                            <b-form-select-option :value="null" disabled>-- Please select an
                                option--</b-form-select-option>
                        </template>
                        <b-form-select-option v-for="price in filteredManaPrice" :key="price[0]" :value="price">
                            {{ price[0] }} Mana - {{ price[1] }} USDT
                        </b-form-select-option>
                    </b-form-select>
                    <div class="button">
                        <button @click="topupPop">
                            Buy Mana
                        </button>
                    </div>
                </div>
            </div>
            <button class="close" @click="$bvModal.hide('mana-topup')" type="button" style="color: #0087cc">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor"
                        d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z" />
                </svg>
            </button>
            <button class="breadcrumb" @click="goBack">
                <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                    <path fill="currentColor"
                        d="M17 11H9.414l2.293-2.293a.999.999 0 1 0-1.414-1.414L5.586 12l4.707 4.707a.997.997 0 0 0 1.414 0a.999.999 0 0 0 0-1.414L9.414 13H17a1 1 0 0 0 0-2" />
                </svg>
                Mana Center
            </button>
            <b-modal id="mana-topup-confirm" dialog-class="mana-topup-confirm" centered hide-footer hide-header>
                <svg @click="$bvModal.hide('mana-topup-confirm')" xmlns="http://www.w3.org/2000/svg" width="1em"
                    height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor"
                        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z" />
                </svg>
                <h2>Topup Mana</h2>
                <h3>Are you sure you want to buy mana ?</h3>
                <p>
                    Currently your mana is
                    <strong>{{ UserInfo.mana }} / {{ UserInfo.limitMana }}</strong>
                    , Do you want to buy
                    <strong v-if="this.selected">{{
                        this.selected[0] }}
                    </strong> mana for
                    <strong>
                        <span v-if="this.selected">{{ this.selected[1] }}</span>
                        USDT
                    </strong>
                    ?
                </p>
                <p>

                </p>
                <div>
                    <span @click="buyMana">Confirm</span>
                    <span @click="$bvModal.hide('mana-topup-confirm')">Cancel</span>
                </div>
            </b-modal>
        </b-modal>
    </div>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ManaTopup',
    data() {
        return {
            selected: null,
            filteredSelected: null
        }
    },
    computed: {
        ...mapGetters({
            ManaPrice: 'info/ManaPrice',
            UserInfo: 'info/UserInfo',

        }),

        filteredManaPrice() {
            if (this.ManaPrice) {
                return this.filteredSelected = Object.entries(this.ManaPrice)
            }
        }
    },
    methods: {
        goBack() {
            this.$bvModal.show('mana-center');
            this.$bvModal.hide('mana-topup');
        },
        topupPop() {
            if (this.selected === null) {
                this.$toastr.e('Please select amount mana you want!!', 'Oops!');
            } else {
                this.$bvModal.show('mana-topup-confirm')
            }
        },
        buyMana() {
            this.$store.dispatch('info/buyManaPackage', { mana: this.selected[0] }).then(() => {
                this.$bvModal.hide('mana-topup-confirm')
                this.$bvModal.hide('mana-topup')
                this.$store.dispatch('info/req_getInfo');
            })
            console.log('mana price: ', this.filteredSelected)
        }
    },

    mounted() {
        this.$store.dispatch('info/manaPrice')
    }
};
</script>

<style lang="scss" scoped>
.head {
    font-size: 30px;
    font-weight: 600;
    color: #0087cc;
    text-align: center;
    margin-top: 1rem;
}

.button {
    display: flex;
    justify-content: center;

    button {
        max-width: 200px;
        width: 100%;
        padding: 4px 10px;
        border-radius: 72px;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 600;
        background-image: linear-gradient(to right, #075d9b, #1775b8, #91c4e9, #d2e9fa);
        background-position: center;
        background-size: 200%;
        color: #fff;
        transition: 0.3s;
        outline: none;

        &:hover {
            background-position: 0%;
        }
    }
}
</style>

<style lang="scss">
.mana-topup-confirm {
    @media (min-width: 320px) {
        .modal-content {
            position: relative;
            width: 100%;
            margin: 0 auto;

            .modal-body {
                border-radius: 12px;

                h2 {
                    color: #0087CC;
                    text-align: center;
                    font-size: 30px;
                    margin-bottom: 15px;
                }

                h3 {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                }

                p {
                    font-size: 16px;
                    font-weight: 500;
                    text-align: center;
                }

                svg {
                    position: absolute;
                    font-size: 30px;
                    top: 10px;
                    right: 10px;
                    cursor: pointer;
                }

                >div {
                    display: flex;
                    align-content: center;
                    justify-content: space-around;
                    gap: 15px;
                    margin-top: 1rem;

                    span {
                        cursor: pointer;
                        max-width: 200px;
                        width: 100%;
                        color: white;
                        border-radius: 72px;
                        padding: 4px 10px;
                        font-size: 18px;
                        text-transform: uppercase;
                        font-weight: 600;
                        text-align: center;
                        transition: 0.2s;

                        &:nth-child(1) {
                            background-image: linear-gradient(to right, #075d9b, #1775b8, #91c4e9, #d2e9fa);
                            background-position: center;
                            background-size: 200%;

                            &:hover {
                                background-position: 0%;

                            }
                        }

                        &:nth-child(2) {
                            color: #0087CC;
                            border: 1px solid #0087CC;
                        }
                    }
                }
            }
        }
    }
}
</style>
