<template>
    <div class="">
        <div class="title">{{ $t('stakingProfit') }}</div>
        <div class="base-table">
            <b-table
                thead-class="custom-header"
                striped
                hover
                :items="
                    () =>
                        StakeProfit && StakeProfit.results
                            ? StakeProfit.results
                            : []
                "
                show-empty
                :fields="fields"
                responsive
            >
                <template #empty>
                    <h4 class="text-center my-3">
                        {{ $t('noData') }}
                    </h4>
                </template>
                <template #head()="{ label }">
                    {{ $t(label) }}
                </template>
                <template #cell(createdAt)="{ value }">
                    {{ getDateTime3(value) }}
                </template>
            </b-table>
        </div>
        <b-pagination
            v-if="StakeProfit"
            v-model="params.page"
            :total-rows="StakeProfit.totalResults"
            :per-page="params.limit"
        >
        </b-pagination>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import Paginate from '@/components/shared/Paginate.vue';

export default {
    name: 'StakingHistory',
    data() {
        return {
            params: {
                page: 1,
                limit: 10,
            },
            fields: [
                {
                    class: 'text-center',
                    label: 'packageName',
                    key: 'packageName',
                },
                {
                    class: 'text-center',
                    label: 'amount',
                    key: 'amount',
                },
                {
                    class: 'text-center',
                    label: 'profit',
                    key: 'profit',
                },
                {
                    class: 'text-center',
                    label: 'period',
                    key: 'period',
                },
                {
                    class: 'text-center',
                    label: 'status',
                    key: 'status',
                },
                {
                    class: 'text-center',
                    label: 'createdAt',
                    key: 'createdAt',
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            StakeProfit: 'stake/StakeProfit',
        }),
    },
    methods: {
        requestHistory() {
            this.$store.dispatch('stake/getStakeProfit', this.params);
        },
    },
    watch: {
        'params.page': {
            // eslint-disable-next-line object-shorthand
            handler: function (newVal) {
                console.log('NEWVAL', newVal);
                this.requestHistory();
            },
        },
    },
    mounted() {
        this.requestHistory();
        this.unsubscribe = this.$store.subscribe((mutation) => {
            switch (mutation.type) {
                case 'stake/STAKED':
                    this.requestHistory();
                    break;
                default:
                    break;
            }
        });
    },
    beforeDestroy() {
        this.unsubscribe();
    },
};
</script>

<style lang="scss" scoped>
.base-table {
    border-radius: 22px;
}
.title {
    font-weight: 600;
    font-size: 28px;
    color: #0087cc;
}
</style>
