<template>
    <div class="container py-2">
        <StakingPackages></StakingPackages>
        <StakingHistory></StakingHistory>
        <StakingProfit></StakingProfit>
    </div>
</template>

<script>
import StakingPackages from '@/components/Staking/StakingPackages.vue';
import StakingHistory from '@/components/Staking/StakingHistory.vue';
import StakingProfit from '@/components/Staking/StakingProfit.vue';

export default {
    name: 'StakingView',
    components: {
        StakingPackages,
        StakingHistory,
        StakingProfit
    },
};
</script>
