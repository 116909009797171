<template>
    <div v-if="stateObject">
        <template v-if="stateObject === 'fetching'">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
            >
                <circle cx="12" cy="2" r="0" fill="currentColor">
                    <animate
                        attributeName="r"
                        begin="0"
                        calcMode="spline"
                        dur="1s"
                        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                        repeatCount="indefinite"
                        values="0;2;0;0"
                    />
                </circle>
                <circle
                    cx="12"
                    cy="2"
                    r="0"
                    fill="currentColor"
                    transform="rotate(45 12 12)"
                >
                    <animate
                        attributeName="r"
                        begin="0.125s"
                        calcMode="spline"
                        dur="1s"
                        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                        repeatCount="indefinite"
                        values="0;2;0;0"
                    />
                </circle>
                <circle
                    cx="12"
                    cy="2"
                    r="0"
                    fill="currentColor"
                    transform="rotate(90 12 12)"
                >
                    <animate
                        attributeName="r"
                        begin="0.25s"
                        calcMode="spline"
                        dur="1s"
                        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                        repeatCount="indefinite"
                        values="0;2;0;0"
                    />
                </circle>
                <circle
                    cx="12"
                    cy="2"
                    r="0"
                    fill="currentColor"
                    transform="rotate(135 12 12)"
                >
                    <animate
                        attributeName="r"
                        begin="0.375s"
                        calcMode="spline"
                        dur="1s"
                        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                        repeatCount="indefinite"
                        values="0;2;0;0"
                    />
                </circle>
                <circle
                    cx="12"
                    cy="2"
                    r="0"
                    fill="currentColor"
                    transform="rotate(180 12 12)"
                >
                    <animate
                        attributeName="r"
                        begin="0.5s"
                        calcMode="spline"
                        dur="1s"
                        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                        repeatCount="indefinite"
                        values="0;2;0;0"
                    />
                </circle>
                <circle
                    cx="12"
                    cy="2"
                    r="0"
                    fill="currentColor"
                    transform="rotate(225 12 12)"
                >
                    <animate
                        attributeName="r"
                        begin="0.625s"
                        calcMode="spline"
                        dur="1s"
                        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                        repeatCount="indefinite"
                        values="0;2;0;0"
                    />
                </circle>
                <circle
                    cx="12"
                    cy="2"
                    r="0"
                    fill="currentColor"
                    transform="rotate(270 12 12)"
                >
                    <animate
                        attributeName="r"
                        begin="0.75s"
                        calcMode="spline"
                        dur="1s"
                        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                        repeatCount="indefinite"
                        values="0;2;0;0"
                    />
                </circle>
                <circle
                    cx="12"
                    cy="2"
                    r="0"
                    fill="currentColor"
                    transform="rotate(315 12 12)"
                >
                    <animate
                        attributeName="r"
                        begin="0.875s"
                        calcMode="spline"
                        dur="1s"
                        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                        repeatCount="indefinite"
                        values="0;2;0;0"
                    />
                </circle>
            </svg>
        </template>
        <template v-else>
            <div
                v-if="typeof stateObject === 'object' && stateObject.used_brand"
                class="mobile-data"
            >
                <div>{{ $t('usedFor') }}:</div>
                <div>
                    {{ stateObject.used_brand }}
                </div>
            </div>
            <div
                v-if="typeof stateObject === 'object' && stateObject.used_time"
                class="mobile-data"
            >
                <div>{{ $t('usedDate') }}:</div>
                <div>
                    {{ getDateTime3(stateObject.used_time) }}
                </div>
            </div>
            <div
                v-if="typeof stateObject === 'object' && typeof stateObject.used_store === 'string'"
                class="mobile-data"
            >
                <div>{{ $t('usedAt') }}:</div>
                <div>
                    {{ stateObject.used_store }}
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'GotItStatus',
    props: {
        stateObject: {
            type: [String, Object],
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.used {
    color: #4c0519;
}
.active {
    color: #dcfce7;
}
.semibold {
    font-weight: 600;
}
</style>
