var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"back"},[_c('router-link',{staticClass:"goback",attrs:{"to":{ name: 'GotIt' }}},[_vm._v(" "+_vm._s(_vm.$t('goBack'))+" ")]),_c('a',{staticClass:"howtouse",attrs:{"href":"https://www.gotit.vn/how-to-use","target":"_blank"}},[_vm._v(_vm._s(_vm.$t('howToUse')))])],1),(_vm.GotItHistory)?_c('div',{staticClass:"d-none d-xl-block gotit-history"},[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.ComputedHistory,"fields":_vm.fields,"show-empty":"","responsive":"","thead-class":"customer-header"},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}},{key:"cell(price)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.TruncateToDecimals2(value))+" VNĐ ")]}},{key:"cell(transactionRefId)",fn:function(ref){
var item = ref.item;
return [(item && item.statusObject)?[_c('GotItStatus',{attrs:{"stateObject":item.statusObject}})]:_vm._e()]}},{key:"cell(voucherLink)",fn:function(row){return [_c('a',{staticClass:"botan",attrs:{"href":row.value,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('useGiftcard'))+" "),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"1em","height":"1em","viewBox":"0 0 24 24"}},[_c('g',{attrs:{"fill":"none"}},[_c('path',{attrs:{"d":"M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"}}),_c('path',{attrs:{"fill":"currentColor","d":"m14.707 5.636l5.657 5.657a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 0 1-1.414-1.414l3.95-3.95H4a1 1 0 1 1 0-2h13.243l-3.95-3.95a1 1 0 1 1 1.414-1.414"}})])])])]}},{key:"cell(voucherImageLink)",fn:function(ref){
var value = ref.value;
return [_c('a',{staticClass:"botan",attrs:{"href":value,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('giftcardImage'))+" "),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"1em","height":"1em","viewBox":"0 0 24 24"}},[_c('g',{attrs:{"fill":"none"}},[_c('path',{attrs:{"d":"M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"}}),_c('path',{attrs:{"fill":"currentColor","d":"m14.707 5.636l5.657 5.657a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 0 1-1.414-1.414l3.95-3.95H4a1 1 0 1 1 0-2h13.243l-3.95-3.95a1 1 0 1 1 1.414-1.414"}})])])])]}},{key:"cell(#)",fn:function(row){return [_c('b-form-checkbox',{on:{"change":row.toggleDetails},model:{value:(row.detailsShowing),callback:function ($$v) {_vm.$set(row, "detailsShowing", $$v)},expression:"row.detailsShowing"}},[_vm._v(" "+_vm._s(_vm.$t('showDetails'))+" ")])]}},{key:"row-details",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"details"},[(item.mana || item.xin)?_c('div',{staticClass:"mobile-data"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('totalSoM', { something: _vm.$t('cost') }))+": ")]),_c('div',[(item.xin)?_c('span',[_vm._v(" "+_vm._s(_vm.TruncateToDecimals2(item.xin))+" "),_c('img',{attrs:{"src":require("@/assets/images/logo/xin.png"),"width":"18px"}})]):_vm._e()])]):_vm._e(),(item.voucherCode)?_c('div',{staticClass:"voucher-code"},[_c('div',{staticClass:"flex"},[_c('div',[_vm._v(_vm._s(_vm.$t('giftcardCode'))+":")]),_c('div',[_vm._v(" "+_vm._s(item.voucherCode)+" ")])]),_c('div',{staticClass:"barcode-wrapper"},[_c('barcode',{attrs:{"value":item.voucherCode,"width":4}},[_vm._v(" Failed to render barcode ")])],1)]):_vm._e(),(item.createdAt)?_c('div',{staticClass:"mobile-data"},[_c('div',[_vm._v(_vm._s(_vm.$t('boughtDate'))+":")]),_c('div',[_vm._v(" "+_vm._s(_vm.getDateTime3(item.createdAt))+" ")])]):_vm._e(),(item.expiryDate)?_c('div',{staticClass:"mobile-data"},[_c('div',[_vm._v(_vm._s(_vm.$t('expiryDate'))+":")]),_c('div',[_vm._v(" "+_vm._s(_vm.getDateTime3(item.expiryDate))+" ")])]):_vm._e(),_c('GotItDetail',{attrs:{"stateObject":item.statusObject}})],1)]}}],null,false,1275523721)})],1):_vm._e(),(_vm.GotItHistory)?_c('div',{staticClass:"d-xl-none gotit-history"},[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.ComputedHistory,"fields":_vm.mobileFields,"show-empty":"","responsive":"","thead-class":"customer-header"},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}},{key:"cell(price)",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.TruncateToDecimals2(value))+" VNĐ")]),_c('div',{staticClass:"serial"},[_vm._v(" "+_vm._s(item.voucherSerial)+" ")])]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.TruncateToDecimals2(value))+" VNĐ")]),_c('div',{staticClass:"serial"},[_vm._v(" "+_vm._s(item.voucherSerial)+" ")])]}},{key:"cell(voucherLink)",fn:function(row){return [_c('a',{staticClass:"botan",attrs:{"href":row.value,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('useGiftcard'))+" "),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"1em","height":"1em","viewBox":"0 0 24 24"}},[_c('g',{attrs:{"fill":"none"}},[_c('path',{attrs:{"d":"M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"}}),_c('path',{attrs:{"fill":"currentColor","d":"m14.707 5.636l5.657 5.657a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 0 1-1.414-1.414l3.95-3.95H4a1 1 0 1 1 0-2h13.243l-3.95-3.95a1 1 0 1 1 1.414-1.414"}})])])])]}},{key:"cell(#)",fn:function(row){return [_c('b-form-checkbox',{on:{"change":row.toggleDetails},model:{value:(row.detailsShowing),callback:function ($$v) {_vm.$set(row, "detailsShowing", $$v)},expression:"row.detailsShowing"}}),_c('GotItStatus',{attrs:{"stateObject":row.item.statusObject}})]}},{key:"row-details",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"details"},[(item.orderName)?_c('div',{staticClass:"mobile-data"},[_c('div',[_vm._v(_vm._s(_vm.$t('name'))+":")]),_c('div',[_vm._v(" "+_vm._s(item.orderName)+" ")])]):_vm._e(),(item.mana || item.xin)?_c('div',{staticClass:"mobile-data"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('totalSoM', { something: _vm.$t('cost') }))+": ")]),_c('div',[(item.mana)?_c('span',[_vm._v(" "+_vm._s(_vm.TruncateToDecimals2(item.mana))+" "+_vm._s(_vm.$t('mana'))+" ")]):_vm._e(),(item.mana && item.xin)?_c('span',[_vm._v(" + ")]):_vm._e(),(item.xin)?_c('span',[_vm._v(" "+_vm._s(_vm.TruncateToDecimals2(item.xin))+" XIN ")]):_vm._e()])]):_vm._e(),(item.voucherCode)?_c('div',{staticClass:"voucher-code"},[_c('div',{staticClass:"flex"},[_c('div',[_vm._v(_vm._s(_vm.$t('giftcardCode'))+":")]),_c('div',[_vm._v(" "+_vm._s(item.voucherCode)+" ")])]),_c('div',{staticClass:"barcode-wrapper"},[_c('barcode',{attrs:{"value":item.voucherCode,"width":4}},[_vm._v(" Failed to render barcode ")])],1)]):_vm._e(),(item.createdAt)?_c('div',{staticClass:"mobile-data"},[_c('div',[_vm._v(_vm._s(_vm.$t('boughtDate'))+":")]),_c('div',[_vm._v(" "+_vm._s(_vm.getDateTime3(item.createdAt))+" ")])]):_vm._e(),(item.expiryDate)?_c('div',{staticClass:"mobile-data"},[_c('div',[_vm._v(_vm._s(_vm.$t('expiryDate'))+":")]),_c('div',[_vm._v(" "+_vm._s(_vm.getDateTime3(item.expiryDate))+" ")])]):_vm._e(),_c('GotItDetail',{attrs:{"stateObject":item.statusObject}})],1)]}},{key:"cell(transactionRefId)",fn:function(ref){
var item = ref.item;
return [(item && item.statusObject)?[_c('GotItStatus',{attrs:{"stateObject":item.statusObject}})]:_vm._e()]}}],null,false,4046951544)})],1):_vm._e(),(_vm.GotItHistory)?_c('b-pagination',{attrs:{"total-rows":_vm.GotItHistory.totalResults,"per-page":_vm.limit},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }