<template>
    <div class="chart_swap mb-4">
        <div class="content">
            <div class="select">
                <div class="dropdown-container">
                    <select
                        v-model="selectedPeriod"
                        @change="fetchRateHistory"
                        class="dropdown"
                    >
                        <option value="1d">{{ $t('day') }}</option>
                        <option value="1w">{{ $t('week') }}</option>
                        <option value="1m">{{ $t('month') }}</option>
                    </select>
                </div>
                <div class="dropdown-container">
                    <select
                        v-model="selectedXinCoin"
                        @change="fetchRateHistory"
                        class="dropdown"
                    >
                        <option value="1">USDT - XIN</option>
                        <option value="2">XIN - USDT</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="chart">
            <apexchart
                class="apexChart"
                width="100%"
                height="100%"
                type="area"
                :options="FinalizedOptions"
                :series="TheSeries"
            ></apexchart>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ApexChart from 'vue-apexcharts';

export default {
    name: 'RateChartSwap',
    components: {
        apexchart: ApexChart,
    },
    data() {
        return {
            selectedPeriod: '1m',
            selectedXinCoin: '2',
            series: [
                {
                    name: 'Swap',
                    data: [],
                },
            ],
            chartOptions: {
                chart: {
                    type: 'area',
                    stacked: false,
                    height: 350,
                    zoom: {
                        type: 'x',
                        enabled: true,
                        autoScaleYaxis: true,
                    },
                    toolbar: {
                        autoSelected: 'zoom',
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                markers: {
                    size: 0,
                },
                title: {
                    text: 'Price XIN',
                    align: 'left',
                },
                subtitle: {
                    text: '',
                    align: 'left',
                    offsetX: 25,
                    offsetY: 40.5,
                    style: {
                        fontSize: '12px',
                        fontWeight: '700',
                    },
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 1,
                        inverseColors: false,
                        opacityFrom: 0.5,
                        opacityTo: 0,
                        stops: [0, 90, 100],
                    },
                },
                yaxis: {
                    decimalsInFloat: 6,
                    title: {
                        text: 'Price',
                    },
                    // labels: {
                    //     align: 'left',
                    //     style: {
                    //         colors: '#000000aa',
                    //     },
                    //     offsetX: 10,
                    //     offsetY: -4,
                    // },

                    // floating: true,
                },
                xaxis: {
                    type: 'datetime',
                },
                tooltip: {
                    shared: false,
                    y: {
                        formatter: (val) => {
                            return val.toFixed(6);
                        },
                    },
                },
                stroke: {
                    curve: 'straight',
                },
            },
            seriesRate2: {
                name: '',
                data: [],
            },
            seriesRate3: {
                name: '',
                data: [],
            },
        };
    },
    computed: {
        ...mapGetters({
            rateHistories: 'rate/rateHistories',
        }),
        subtitleText() {
            return this.selectedXinCoin === '1' ? 'XIN' : 'USDT';
        },
        currentRate() {
            if (this.seriesRate2.data && this.seriesRate2.data.length > 0) {
                return (
                    this.seriesRate2.data[this.seriesRate2.data.length - 1]
                        ?.y || 0
                );
            }
            return 0;
        },
        packageRate() {
            if (this.seriesRate3.data && this.seriesRate3.data.length > 0) {
                return (
                    this.seriesRate3.data[this.seriesRate3.data.length - 1]
                        ?.y || 0
                );
            }
            return 0;
        },
        TheSeries() {
            return [this.seriesRate2, this.seriesRate3];
        },
        SeriesName() {
            return this.selectedXinCoin === '1' ? 'USDT/XIN' : 'XIN/USDT';
        },
        FinalizedOptions() {
            return {
                ...this.chartOptions,
                subtitle: {
                    ...this.chartOptions.subtitle,
                    text: this.subtitleText,
                },
                legend: {
                    formatter: (label, opts) => {
                        return [
                            label,
                            '(',
                            opts.w.globals.series[opts.seriesIndex][0] ?? 0,
                            ')',
                        ];
                    },
                    itemMargin: {
                        vertical: 5,
                    },
                },
            };
        },
    },
    methods: {
        ...mapActions('rate', ['getRateHistory']),
        fetchRateHistory() {
            const params2 = {
                rateType: 'swap',
                rateId: Number(this.selectedXinCoin),
                period: this.selectedPeriod,
            };
            const params3 = {
                rateType: 'package',
                period: this.selectedPeriod,
            };
            this.getRateHistory(params2)
                .then((res) => {
                    if (res.status) {
                        const { data } = res;
                        data.sort((a, b) => {
                            return new Date(b.date) - new Date(a.date);
                        });
                        this.seriesRate2 = {
                            name: 'Swap',
                            data: data.map((item) => ({
                                x: new Date(item.date).getTime(),
                                y: item.rate,
                            })),
                        };
                    }
                })
                .catch((error) => {
                    console.error('Error fetching rate history:', error);
                });
            this.getRateHistory(params3)
                .then((res) => {
                    if (res.status) {
                        const { data } = res;
                        data.sort((a, b) => {
                            return new Date(b.date) - new Date(a.date);
                        });
                        this.seriesRate3 = {
                            name: 'Package',
                            data: data.map((item) => ({
                                x: new Date(item.date).getTime(),
                                y: item.rate,
                            })),
                        };
                    }
                })
                .catch((error) => {
                    console.error('Error fetching rate history:', error);
                });
        },
        updateChartData() {
            const seriesName =
                this.selectedXinCoin === '1' ? 'USDT/xinCoin' : 'xinCoin/USDT';

            const newChartOptions = {
                ...this.chartOptions.subtitle,
                subtitle: {
                    ...this.chartOptions.subtitle,
                    text: this.subtitleText,
                },
            };
            this.chartOptions = newChartOptions;

            if (this.rateHistories && this.rateHistories.length > 0) {
                this.series = [
                    {
                        name: seriesName,
                        data: this.rateHistories.map((item) => ({
                            x: new Date(item.date).getTime(),
                            y: item.rate,
                        })),
                    },
                ];
            } else {
                this.series = [
                    {
                        name: seriesName,
                        data: [],
                    },
                ];
            }
        },
    },
    mounted() {
        this.fetchRateHistory();
    },
};
</script>

<style lang="scss" scoped>
h2 {
    text-align: left;
    margin-bottom: 20px;
    margin: 0;
    text-align: left;
    margin-bottom: 20px;
    margin: 0;
}

.content {
    display: flex;
    justify-content: end;
    align-items: center;
}

.select {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.dropdown-container {
    position: relative;
    position: relative;
}

.dropdown {
    padding: 7px 12px;
    font-size: 14px;
    border: rgb(255, 255, 255);
    border-radius: 8px;
    background: rgb(255, 255, 255);
    cursor: pointer;
    outline: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    width: 100%;
}

.dropdown:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
}

.dropdown option {
    font-size: 16px;
    font-size: 16px;
}

.chart {
    position: relative;
    height: 450px;
    margin-top: 18px;
    padding: 15px 20px;
    border-radius: 20px;
    background: rgb(255, 255, 255);
    filter: drop-shadow(0 0px 1px #494848);
    @media (min-width: 576px) {
        aspect-ratio: 16/10;
        height: auto;
    }
    @media (min-width: 992px) {
        height: 300px;
        width: 100%;
    }
    p {
        position: absolute;
        z-index: 2;
        top: 36px;
        left: 121px;
        font-size: 14px;
        font-weight: 700;
    }

    .apexChart {
        width: 100%;
        position: relative;
    }
}
</style>

