<template>
    <div>
        <div class="buyxnode-container">
            <div class="content">
                <div
                    class="wrap"
                    :class="{
                        super: node.nodeId === 8,
                        promotion1: node.nodeId === 12,
                        promotion2: node.nodeId === 11,
                    }"
                    v-for="(node, key) in ComputedCorpos"
                    :key="key"
                >
                    <div>
                        <div class="title">
                            <div class="node">
                                <div class="logo-wrap">
                                    <div class="logo-wrap-2">
                                        <div class="logo-wrap-3"></div>
                                    </div>
                                </div>
                                <div class="logo">
                                    <img :src="node.photoUrl" alt="Node" />
                                </div>
                            </div>
                            <div class="tito">
                                <h1 v-if="promotionAru(node.nodeId)">
                                    {{
                                        (
                                            node.performance +
                                            promotionAru(node.nodeId).upHz
                                        ).toLocaleString('en-US')
                                    }}<span> Hz/h</span>
                                </h1>
                                <h1 v-else>
                                    {{ node.performance.toLocaleString('en-US')
                                    }}<span> Hz/h</span>
                                </h1>
                                <div class="blur">
                                    <div class="package">
                                        <p>
                                            {{ node.name }} -
                                            {{
                                                TruncateToDecimals2(
                                                    node.priceUSDT,
                                                )
                                            }}
                                            USDT
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="details">
                            <div class="node-mobile">
                                <div class="node">
                                    <div class="logo-wrap">
                                        <div class="logo-wrap-2">
                                            <div class="logo-wrap-3"></div>
                                        </div>
                                    </div>
                                    <div class="logo">
                                        <img :src="node.photoUrl" alt="Node" />
                                    </div>
                                </div>
                            </div>
                            <div class="detail-content">
                                <h4 class="tit">
                                    {{
                                        $t(
                                            'getEnterpriseXNodeExclusiveBenefits',
                                        )
                                    }}
                                </h4>
                                <ul>
                                    <li
                                        v-for="(item, index) in contentFilter(
                                            node.nodeId,
                                        )"
                                        :key="index"
                                    >
                                        <svg
                                            width="19"
                                            height="19"
                                            viewBox="0 0 19 19"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle
                                                cx="9.5"
                                                cy="9.5"
                                                r="9"
                                                stroke="currentColor"
                                            />
                                            <path
                                                d="M5.20312 9.72549L8.36979 12.8922L14.7031 6.10645"
                                                stroke="currentColor"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                        {{ $t(item.key) }}:
                                        <span>
                                            {{
                                                $t(
                                                    item.value,
                                                    item.valueInterpolator,
                                                )
                                            }}.
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <button class="botan" @click="setNode(node)">
                                {{ $t('buy') }}
                            </button>
                        </div>
                        <div class="particle-container">
                            <div
                                v-for="i in 300"
                                :key="i"
                                class="particle"
                            ></div>
                        </div>
                        <div
                            v-if="promotionAru(node.nodeId)"
                            class="promotion-tag2"
                        >
                            <div>{{ $t('promotion') }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="MerchantList && MerchantList.length > 0"
                class="d-lg-block table-responsive corpo"
            >
                <div class="head">Your Merchant accounts</div>
                <b-table
                    striped
                    hover
                    thead-class="customer-header"
                    :fields="fields"
                    :items="MerchantList"
                >
                    <template #cell(merchant)="{ item }">
                        <div>
                            {{ item.package_name }} |
                            {{ item.price.toLocaleString('en-US') }} USDT
                        </div>
                    </template>
                    <template #cell(percent_up)="{ item }">
                        {{ item.percent_up * 100 }} %
                    </template>
                    <template #cell(cooldown_day)="{ item }">
                        {{ item.cooldown_day }}
                    </template>
                    <template #cell(username)="{ item }">
                        {{ item.username }}
                        <b-button
                            class="bootstrap-btn"
                            size="sm"
                            v-clipboard:copy="item.username"
                            v-clipboard:success="onSuccess"
                            v-clipboard:error="onError"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                viewBox="0 0 256 256"
                            >
                                <path
                                    fill="currentColor"
                                    d="M216 28H88a12 12 0 0 0-12 12v36H40a12 12 0 0 0-12 12v128a12 12 0 0 0 12 12h128a12 12 0 0 0 12-12v-36h36a12 12 0 0 0 12-12V40a12 12 0 0 0-12-12m-60 176H52V100h104Zm48-48h-24V88a12 12 0 0 0-12-12h-68V52h104Z"
                                />
                            </svg>
                        </b-button>
                    </template>
                    <template #cell(password)="{ item }">
                        *******
                        <!-- Click event thì copy real password cho nó -->
                        <b-button
                            class="bootstrap-btn"
                            size="sm"
                            v-clipboard:copy="item.real_pass"
                            v-clipboard:success="onSuccess"
                            v-clipboard:error="onError"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                viewBox="0 0 256 256"
                            >
                                <path
                                    fill="currentColor"
                                    d="M216 28H88a12 12 0 0 0-12 12v36H40a12 12 0 0 0-12 12v128a12 12 0 0 0 12 12h128a12 12 0 0 0 12-12v-36h36a12 12 0 0 0 12-12V40a12 12 0 0 0-12-12m-60 176H52V100h104Zm48-48h-24V88a12 12 0 0 0-12-12h-68V52h104Z"
                                />
                            </svg>
                        </b-button>
                    </template>
                    <template #cell(link)>
                        <!-- đá qua trang quét QR -->
                        <b-button
                            size="sm"
                            class="mr-2 bg-success"
                            @click="gotoManage"
                        >
                            Manage
                        </b-button>
                    </template>
                </b-table>
            </div>
            <b-modal
                id="buy-node"
                hide-header
                hide-footer
                dialog-class="supa-modal"
                centered
                @hidden="resetChoice"
            >
                <svg
                    @click="closeBuyNode"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M32.5775 29.9235C32.9297 30.2757 33.1276 30.7534 33.1276 31.2516C33.1276 31.7497 32.9297 32.2275 32.5775 32.5797C32.2252 32.932 31.7475 33.1298 31.2493 33.1298C30.7512 33.1298 30.2735 32.932 29.9212 32.5797L20.0009 22.6563L10.0775 32.5766C9.72523 32.9288 9.24749 33.1267 8.74935 33.1267C8.2512 33.1267 7.77346 32.9288 7.42122 32.5766C7.06898 32.2244 6.87109 31.7466 6.87109 31.2485C6.87109 30.7503 7.06898 30.2726 7.42122 29.9203L17.3447 20L7.42435 10.0766C7.07211 9.72435 6.87422 9.24661 6.87422 8.74847C6.87422 8.25032 7.07211 7.77258 7.42435 7.42034C7.77659 7.0681 8.25433 6.87021 8.75247 6.87021C9.25061 6.87021 9.72836 7.0681 10.0806 7.42034L20.0009 17.3438L29.9243 7.41878C30.2766 7.06654 30.7543 6.86865 31.2525 6.86865C31.7506 6.86865 32.2284 7.06654 32.5806 7.41878C32.9328 7.77102 33.1307 8.24876 33.1307 8.7469C33.1307 9.24505 32.9328 9.72279 32.5806 10.075L22.6572 20L32.5775 29.9235Z"
                        fill="#0087CC"
                    />
                </svg>
                <div>
                    <h2>{{ $t('payment') }}</h2>
                    <!-- <h3>Package</h3> -->
                    <div class="partial-wrapper">
                        <div class="partial-item">
                            <div class="left">
                                <img :src="chosenNode.photoUrl" alt="" />
                            </div>
                            <div class="right">
                                <h1>
                                    {{ chosenNode.performance }}
                                    <span>Hz/h</span> -
                                    {{ chosenNode.quantityUseMonth }}
                                    {{
                                        chosenNode.quantityUseMonth > 1
                                            ? 'Months'
                                            : 'Month'
                                    }}
                                </h1>
                                <p v-if="chosenNode.style">
                                    {{ chosenNode.name }}
                                </p>
                                <div
                                    class="list"
                                    v-if="
                                        chosenNode.content &&
                                        chosenNode.content.length > 0
                                    "
                                >
                                    <div
                                        v-for="(
                                            point, index
                                        ) in chosenNode.content"
                                        :key="index"
                                    >
                                        <svg
                                            v-if="point.status"
                                            width="15"
                                            height="15"
                                            viewBox="0 0 15 15"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle
                                                cx="7.5"
                                                cy="7.5"
                                                r="7"
                                                stroke="white"
                                            />
                                            <path
                                                d="M4.10742 7.67855L6.60742 10.1786L11.6074 4.82141"
                                                stroke="white"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                        <svg
                                            v-else
                                            width="15"
                                            height="15"
                                            viewBox="0 0 15 15"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle
                                                cx="7.5"
                                                cy="7.5"
                                                r="7"
                                                stroke="white"
                                            />
                                        </svg>
                                        <p>{{ point.des }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="particle-container">
                                <div
                                    v-for="i in 300"
                                    :key="i"
                                    class="particle"
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div class="confirmation" :style="chosenNode.style">
                        <div class="use-voucher" v-if="chosenNode.nodeId < 4">
                            <div>
                                <svg
                                    width="60"
                                    height="60"
                                    viewBox="0 0 60 60"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M10 10C8.67392 10 7.40215 10.5268 6.46447 11.4645C5.52678 12.4021 5 13.6739 5 15V25C6.32608 25 7.59785 25.5268 8.53553 26.4645C9.47322 27.4021 10 28.6739 10 30C10 31.3261 9.47322 32.5979 8.53553 33.5355C7.59785 34.4732 6.32608 35 5 35V45C5 46.3261 5.52678 47.5979 6.46447 48.5355C7.40215 49.4732 8.67392 50 10 50H50C51.3261 50 52.5979 49.4732 53.5355 48.5355C54.4732 47.5979 55 46.3261 55 45V35C53.6739 35 52.4021 34.4732 51.4645 33.5355C50.5268 32.5979 50 31.3261 50 30C50 28.6739 50.5268 27.4021 51.4645 26.4645C52.4021 25.5268 53.6739 25 55 25V15C55 13.6739 54.4732 12.4021 53.5355 11.4645C52.5979 10.5268 51.3261 10 50 10H10ZM38.75 17.5L42.5 21.25L21.25 42.5L17.5 38.75L38.75 17.5ZM22.025 17.6C24.475 17.6 26.45 19.575 26.45 22.025C26.45 23.1986 25.9838 24.3241 25.1539 25.1539C24.3241 25.9838 23.1986 26.45 22.025 26.45C19.575 26.45 17.6 24.475 17.6 22.025C17.6 20.8514 18.0662 19.7259 18.8961 18.8961C19.7259 18.0662 20.8514 17.6 22.025 17.6ZM37.975 33.55C40.425 33.55 42.4 35.525 42.4 37.975C42.4 39.1486 41.9338 40.2741 41.104 41.104C40.2741 41.9338 39.1486 42.4 37.975 42.4C35.525 42.4 33.55 40.425 33.55 37.975C33.55 36.8014 34.0162 35.6759 34.8461 34.8461C35.6759 34.0162 36.8014 33.55 37.975 33.55Z"
                                        fill="#0087CC"
                                    />
                                </svg>
                                <p>XNode Voucher</p>
                            </div>
                            <div>
                                <button @click="openVoucherModal">
                                    See More
                                    <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M4.375 3.51094L5.2582 2.625L9.625 7L5.2582 11.375L4.375 10.4891L7.85586 7L4.375 3.51094Z"
                                            fill="white"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div class="detail">
                            <h3>{{ $t('detail') }}</h3>
                            <div
                                v-if="
                                    selectedVoucher &&
                                    selectedVoucher.voucherSetting
                                "
                                class="evoucher"
                            >
                                <span>E-Voucher: </span>
                                <span>{{
                                    selectedVoucher.voucherSetting.name
                                }}</span>
                            </div>
                            <p class="total">
                                {{ $t('total') }}
                                <span
                                    v-if="
                                        selectedVoucher &&
                                        selectedVoucher.voucherSetting &&
                                        this.currency === 'XIN'
                                    "
                                >
                                    {{ chosenNode.name }} -
                                    {{
                                        roundUpFormat(
                                            chosenNode.priceXin -
                                                chosenNode.priceXin *
                                                    selectedVoucher
                                                        .voucherSetting.amount,
                                        )
                                    }}
                                    {{ this.currency }}
                                </span>
                                <span v-else-if="this.currency === 'XIN'">
                                    {{ chosenNode.name }} -
                                    {{ roundUpFormat(chosenNode.priceXin) }}
                                    {{ this.currency }}
                                </span>
                                <span v-else class="price">
                                    {{ chosenNode.name }} -
                                    {{ roundUpFormat(chosenNode.priceUSDT) }}
                                    {{ this.currency }}
                                </span>
                            </p>
                        </div>
                        <div class="botans">
                            <button
                                class="confirm"
                                :disabled="pending"
                                @click="openBuyNode"
                            >
                                {{ $t('continuepayment') }}
                            </button>
                        </div>
                    </div>
                </div>
            </b-modal>
            <b-modal
                id="congratulations"
                hide-header
                hide-footer
                centered
                dialog-class="supa-modal"
                @hidden="
                    () => {
                        boughtNode = {};
                    }
                "
            >
                <div>
                    <div class="mi-head">{{ $t('buyXNodeSuccess') }}!</div>
                    <div class="mi-sub">
                        {{ $t('buyNodeSuccess', [boughtNode.performance]) }}
                    </div>
                    <div class="botans">
                        <button
                            class="cancel"
                            @click="
                                $router.push({ name: 'XnodeInventory' }),
                                    $bvModal.hide('congratulations')
                            "
                        >
                            {{ $t('goToMyXNode') }}
                        </button>
                        <button
                            class="confirm"
                            @click="$bvModal.hide('congratulations')"
                        >
                            {{ $t('buyMore') }}
                        </button>
                    </div>
                    <button
                        class="close black"
                        @click="$bvModal.hide('congratulations')"
                        type="button"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                            />
                        </svg>
                    </button>
                </div>
            </b-modal>
            <b-modal
                id="ohno"
                hide-header
                hide-footer
                centered
                dialog-class="supa-modal"
            >
                <div>
                    <div class="mi-head fail">{{ $t('uhOh') }}!</div>
                    <div class="mi-sub">{{ $t('failedToBuyXNode') }}</div>
                    <div class="botans fail">
                        <button class="confirm" @click="$bvModal.hide('ohno')">
                            {{ $t('buyMore') }}
                        </button>
                    </div>
                    <button
                        class="close black"
                        @click="$bvModal.hide('ohno')"
                        type="button"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                            />
                        </svg>
                    </button>
                </div>
            </b-modal>
            <b-modal id="buynode-confirm" centered hide-footer hide-header>
                <svg
                    @click="$bvModal.hide('buynode-confirm')"
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill="currentColor"
                        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"
                    />
                </svg>
                <h2>{{ $t('buynode') }}</h2>
                <h3>{{ $t('areyousure') }} {{ chosenNode.name }}?</h3>
                <div class="payment">
                    <p>{{ $t('selectcurrency') }}:</p>
                    <div class="unit">
                        <div
                            :class="{
                                active: currency === 'XIN',
                                inactive: currency !== 'XIN',
                            }"
                            @click="setCurrency('XIN')"
                        >
                            XIN
                        </div>
                        <div
                            :class="{
                                active: currency === 'USDT',
                                inactive: currency !== 'USDT',
                            }"
                            @click="setCurrency('USDT')"
                        >
                            USDT
                        </div>
                        <div
                            :class="{
                                active: currency === '50-50',
                                inactive: currency !== '50-50',
                            }"
                            @click="setCurrency('50-50')"
                        >
                            XIN - USDT
                        </div>
                    </div>
                </div>
                <div v-if="this.currency === '50-50'" class="note">
                    {{ $t('notepay') }}
                </div>
                <p class="content">
                    {{ $t('proceedtobuy') }}
                    <strong>{{ chosenNode.name }}</strong>
                    <strong>
                        <span
                            v-if="
                                selectedVoucher &&
                                selectedVoucher.voucherSetting &&
                                this.currency === 'XIN'
                            "
                        >
                            {{ $t('for') }}
                            {{
                                roundUpFormat(
                                    chosenNode.priceXin -
                                        chosenNode.priceXin *
                                            selectedVoucher.voucherSetting
                                                .amount,
                                )
                            }}
                            {{ this.currency }}
                        </span>
                        <span v-else-if="this.currency === 'XIN'">
                            {{ $t('for') }}
                            {{ roundUpFormat(chosenNode.priceXin) }}
                            {{ this.currency }}
                        </span>
                        <span v-else-if="this.currency === '50-50'">
                            {{ $t('for') }}

                            <span
                                >{{
                                    roundUpFormat(chosenNode.priceXin / 2)
                                }}
                                XIN</span
                            >
                            {{ $t('and') }}

                            <span
                                >{{
                                    roundUpFormat(chosenNode.priceUSDT / 2)
                                }}
                                USDT</span
                            >
                        </span>
                        <span v-else class="price">
                            {{ $t('for') }}

                            {{ roundUpFormat(chosenNode.priceUSDT) }}
                            {{ this.currency }}
                        </span>
                    </strong>
                    ?
                </p>
                <div class="buttons">
                    <span @click="buyNode">{{ $t('confirm') }}</span>
                    <span @click="$bvModal.hide('buynode-confirm')">{{
                        $t('cancel')
                    }}</span>
                </div>
            </b-modal>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
// import Paginate from '../../components/shared/Paginate.vue';

export default {
    name: 'BuyXnode',
    // components: { Paginate },
    data() {
        return {
            chosenNode: {},
            boughtNode: {},
            pending: false,
            voucherId: null,
            particles: [],
            containerWidth: 0,
            containerHeight: 0,
            selectedVoucher: null,
            promotion: null,
            currency: 'USDT',
            selectedPromotion: null,
            page: 1,
            email: 'ntdchi99+14@gmail.com',
            items: [
                {
                    merchant_id: 19,
                    package_name: '50000 USDT Node Mining Package',
                    percent_up: '40%',
                    cooldown_day: '1 Day',
                    username: 'FHCXRqL1u9',
                    password: '******',
                    link: 'Manage',
                },
            ],
            fields: [
                {
                    key: 'merchant',
                    label: 'Merchant',
                    sortable: true,
                },
                {
                    key: 'percent_up',
                    label: 'Member bonus',
                    sortable: true,
                },
                {
                    key: 'cooldown_day',
                    label: 'Cooldown day',
                    sortable: true,
                },
                {
                    key: 'username',
                    label: 'Username',
                    sortable: true,
                },
                {
                    key: 'password',
                    label: 'Password',
                    sortable: true,
                },
                {
                    key: 'link',
                    label: 'Link',
                },
            ],
            packageContent: {
                12: [
                    {
                        key: 'memberBonus',
                        value: 'percentMiningPowerBoostForCheckins',
                        valueInterpolator: {
                            percent: '15%',
                        },
                    },
                    {
                        key: 'businessBonus',
                        value: 'percentBonusPerCheckin',
                        valueInterpolator: {
                            percent: '0.1%',
                        },
                    },
                    {
                        key: 'memberLimit',
                        value: 'dailyMemberCheckinLimit',
                        valueInterpolator: {
                            number: '200',
                        },
                    },
                    {
                        key: 'utilityPayment',
                        value: 'percentDirectCashbackAndCommission',
                        valueInterpolator: {
                            cashback: '10%',
                            commission: '1%'
                        },
                    },
                    {
                        key: 'gotitPayment',
                        value: 'percentDirectCashbackAndCommission',
                        valueInterpolator: {
                            cashback: '10%',
                            commission: '1%'
                        },
                    },
                ],
                11: [
                    {
                        key: 'memberBonus',
                        value: 'percentMiningPowerBoostForCheckins',
                        valueInterpolator: {
                            percent: '10%',
                        },
                    },
                    {
                        key: 'businessBonus',
                        value: 'percentBonusPerCheckin',
                        valueInterpolator: {
                            percent: '0.1%',
                        },
                    },
                    {
                        key: 'memberLimit',
                        value: 'dailyMemberCheckinLimit',
                        valueInterpolator: {
                            number: '100',
                        },
                    },
                    {
                        key: 'utilityPayment',
                        value: 'percentDirectCashbackAndCommission',
                        valueInterpolator: {
                            cashback: '10%',
                            commission: '1%'
                        },
                    },
                    {
                        key: 'gotitPayment',
                        value: 'percentDirectCashbackAndCommission',
                        valueInterpolator: {
                            cashback: '10%',
                            commission: '1%'
                        },
                    },
                ],
                0: [
                    {
                        key: 'memberBonus',
                        value: 'percentMiningPowerBoostForCheckins',
                        valueInterpolator: {
                            percent: '20%',
                        },
                    },
                    {
                        key: 'businessBonus',
                        value: 'percentBonusPerCheckin',
                        valueInterpolator: {
                            percent: '0.1%',
                        },
                    },
                    {
                        key: 'memberLimit',
                        value: 'dailyMemberCheckinLimit',
                        valueInterpolator: {
                            number: '300',
                        },
                    },
                    {
                        key: 'utilityPayment',
                        value: 'percentDirectCashbackAndCommission',
                        valueInterpolator: {
                            cashback: '10%',
                            commission: '1%'
                        },
                    },
                    {
                        key: 'gotitPayment',
                        value: 'percentDirectCashbackAndCommission',
                        valueInterpolator: {
                            cashback: '10%',
                            commission: '1%'
                        },
                    },
                ],
            },
        };
    },
    computed: {
        ...mapGetters({
            NodeList: 'mining/NodeList',
            Rates: 'wallet/Rates',
            CorpoNodes: 'mining/CorpoNodes',
            MerchantList: 'merchant/MerchantList',
            NodePromo: 'promotion/NodePromo',
        }),
        ComputedCorpos() {
            return this.CorpoNodes.toReversed();
        },
    },
    methods: {
        setNode(node) {
            this.chosenNode = node;
            this.$bvModal.show('buy-node');
        },
        resetChoice() {
            this.chosenNode = {};
            this.selectedVoucher = null;
            this.voucherId = null;
            this.currency = 'USDT';
        },
        buyNode() {
            if (this.pending) {
                return;
            }
            this.pending = true;
            if (this.voucherId) {
                if (this.currency === '50-50') {
                    this.$store
                        .dispatch('mining/buyNodeXinUsdt', {
                            nodeId: this.chosenNode.nodeId,
                            voucher: this.voucherId,
                            promotion: this.promotion,
                        })
                        .then((res) => {
                            if (res) {
                                this.boughtNode = { ...this.chosenNode };
                                this.$bvModal.hide('buy-node');
                                this.$bvModal.hide('buynode-confirm');
                                // this.$bvModal.show('congratulations');
                                this.$store.dispatch('info/get_aboutXnode');
                                this.$store.dispatch('info/req_getInfo');
                            } else {
                                this.$bvModal.hide('buy-node');
                                this.$bvModal.show('ohno');
                            }
                            this.$store.dispatch(
                                'voucher/userVoucher',
                                this.page,
                            );
                        })
                        .finally(() => {
                            this.pending = false;
                        });
                } else {
                    this.$store
                        .dispatch('mining/post_buyNode', {
                            nodeId: this.chosenNode.nodeId,
                            voucher: this.voucherId,
                            currency:
                                this.currency === 'XIN' ? 'xinCoin' : 'USDT',
                            promotion: this.promotionAru(this.chosenNode.nodeId)
                                ?._id,
                        })
                        .then((res) => {
                            if (res) {
                                this.boughtNode = { ...this.chosenNode };
                                this.$bvModal.hide('buy-node');
                                this.$bvModal.hide('buynode-confirm');
                                this.$bvModal.show('congratulations');
                                this.$store.dispatch('info/get_aboutXnode');
                                this.$store.dispatch('info/req_getInfo');
                            } else {
                                this.$bvModal.hide('buy-node');
                                this.$bvModal.hide('buynode-confirm');
                                this.$bvModal.show('ohno');
                            }
                            this.$store.dispatch(
                                'voucher/userVoucher',
                                this.page,
                            );
                        })
                        .finally(() => {
                            this.pending = false;
                        });
                }
            } else if (this.currency === '50-50') {
                this.$store
                    .dispatch('mining/buyNodeXinUsdt', {
                        nodeId: this.chosenNode.nodeId,
                        promotion: this.promotionAru(this.chosenNode.nodeId)
                            ?._id,
                    })
                    .then((res) => {
                        if (res) {
                            this.boughtNode = { ...this.chosenNode };
                            this.$bvModal.hide('buy-node');
                            this.$bvModal.hide('buynode-confirm');
                            // this.$bvModal.show('congratulations');
                            this.$store.dispatch('info/get_aboutXnode');
                            this.$store.dispatch('info/req_getInfo');
                        } else {
                            this.$bvModal.hide('buy-node');
                            this.$bvModal.show('ohno');
                        }
                        this.$store.dispatch('voucher/userVoucher', this.page);
                    });
            } else {
                this.$store
                    .dispatch('mining/post_buyNode', {
                        nodeId: this.chosenNode.nodeId,
                        currency: this.currency === 'XIN' ? 'xinCoin' : 'USDT',
                        promotion: this.promotionAru(this.chosenNode.nodeId)
                            ?._id,
                    })
                    .then((res) => {
                        if (res) {
                            this.boughtNode = { ...this.chosenNode };
                            this.$bvModal.hide('buy-node');
                            this.$bvModal.hide('buynode-confirm');
                            this.$bvModal.show('congratulations');
                            this.$store.dispatch('info/get_aboutXnode');
                            this.$store.dispatch('info/req_getInfo');
                            this.$store.dispatch('merchant/merchantList');
                        } else {
                            this.$bvModal.hide('buy-node');
                            this.$bvModal.hide('buynode-confirm');
                            this.$bvModal.show('ohno');
                        }
                    })
                    .finally(() => {
                        this.pending = false;
                    });
            }
        },
        openBuyNode() {
            this.$bvModal.show('buynode-confirm');
            // this.$bvModal.hide('buy-node')
        },
        openVoucherModal() {
            this.$bvModal.show('voucher-list');
        },

        useVoucher() {
            this.voucherId = this.selectedVoucher._id;
            this.$bvModal.hide('voucher-list');
        },
        closeBuyNode() {
            this.$bvModal.hide('buy-node');
            this.selectedVoucher = null;
            this.voucherId = null;
        },
        onChangePage(payload) {
            this.page = payload;
            // this.$store.dispatch('voucher/filteredUserVoucher', {page: this.page , page: 'DISCOUNT_BUY_NODE'})
            this.$store.dispatch('voucher/userVoucher', { page: this.page });
        },
        setCurrency(input) {
            this.currency = input;
        },
        gotoManage() {
            window.open(process.env.VUE_APP_MERCHANT_REDIRECT, '_blank');
        },
        onSuccess() {
            this.$toastr.s('Copy Success', 'Successfully');
        },
        onError() {
            this.$toastr.e(
                'Copy Link Referral Fail,Please Try Again',
                'Failed',
            );
        },
        promotionAru(nodeId) {
            if (this.NodePromo?.results) {
                return this.NodePromo.results.find(
                    (el) => el.nodeId === nodeId,
                );
            }
            return undefined;
        },

        contentFilter(input) {
            const data = this.packageContent[input];
            if (data) {
                return data;
            }
            return this.packageContent[0];
        },
    },

    mounted() {
        this.$store.dispatch('voucher/filteredUserVoucher', {
            page: this.page,
            type: 'DISCOUNT_BUY_NODE',
        });
        this.$store.dispatch('voucher/userVoucher', { page: this.page });
        this.$store.dispatch('mining/businessNode');
        this.$store.dispatch('promotion/getPromotion', {
            type: 'node',
            page: 1,
            limit: 10,
        });
    },
};
</script>

<style scoped lang="scss">
.buyxnode-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 6rem;
    margin-top: 3rem;
    padding: 15px;

    p {
        margin: 0;
    }

    .content {
        width: 100%;
        padding: 0px 15px;
        --name-color: #fff;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        z-index: 1;
        .wrap {
            flex: 0 0 100%;
            padding: 0.5rem;
            position: relative;
            z-index: 1;

            --prime-color: #fff;
            --accent-color: #50caff;
            --prime-gradient: linear-gradient(#54b0df, #cbfdff, #1b77ba);
            --accent-gradient: linear-gradient(
                rgba(80, 202, 255, 1),
                rgba(255, 255, 255, 1),
                rgba(80, 202, 255, 1)
            );
            --prime-shadow: ;
            @media (min-width: 1400px) {
                flex: 0 0 50%;
            }
            > div {
                border-radius: 22px;
                position: relative;
                background-image: url('../../assets/images/background/corpo-bg.png'),
                    conic-gradient(from 90deg at 50% 0%, #111, 50%, #222, #111);
                background-repeat: no-repeat;
                background-size: contain;
            }

            &.super {
                --accent-color: #fff778;
                --prime-gradient: linear-gradient(
                    to right,
                    #fff778,
                    #fffeea,
                    #fff778
                );
                --accent-gradient: conic-gradient(
                    from 0.5turn at 50% 110%,
                    white,
                    #fff778
                );
            }

            &.promotion1 {
                --accent-color: #ec78ff;
                --prime-gradient: linear-gradient(
                    to right,
                    #ec78ff,
                    #9875fb,
                    #78f7ff
                );
                --accent-gradient: conic-gradient(
                    from 0.5turn at 50% 110%,
                    #ec78ff,
                    #9875fb,
                    #78f7ff
                );
            }

            &.promotion2 {
                --accent-color: #fe8c2e;
                --prime-gradient: linear-gradient(to right, #fe8c2e, #edc9b4);
                --accent-gradient: conic-gradient(
                    from 0.5turn at 50% 110%,
                    #fe8c2e,
                    #edc9b4
                );
            }

            &:nth-child(1) {
                > div {
                    z-index: 1;
                }

                .particle-container {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    bottom: 0;
                    z-index: -2;
                    overflow: hidden;

                    .particle {
                        border-radius: 50%;
                    }

                    @for $i from 1 through 150 {
                        @keyframes particle-animation-#{$i} {
                            0% {
                                transform: translate3d(
                                    (random(150) * 100%),
                                    (random(100) * 100%),
                                    (random(100) * 1px)
                                );
                            }

                            100% {
                                transform: translate3d(
                                    (random(150) * 100%),
                                    (random(100) * -100%),
                                    (random(100) * 1px)
                                );
                            }
                        }

                        .particle:nth-child(#{$i}) {
                            animation: particle-animation-#{$i}
                                30s
                                linear
                                infinite;
                            $size: random(5) + 2 + px;
                            opacity: calc(random(100) / 100);
                            height: $size;
                            width: $size;
                            animation-delay: -$i * 0.02s;
                            transform: translate3d(
                                (random(100) * 50%),
                                (random(100) * 50%),
                                (random(100) * 1px)
                            );
                            background: #ec78ff;
                        }
                    }
                }
            }

            &:nth-child(2) {
                > div {
                    z-index: 1;
                }

                .particle-container {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    bottom: 0;
                    z-index: -2;
                    overflow: hidden;

                    .particle {
                        border-radius: 50%;
                    }

                    @for $i from 1 through 150 {
                        @keyframes particle-animation-#{$i} {
                            0% {
                                transform: translate3d(
                                    (random(150) * 100%),
                                    (random(100) * 100%),
                                    (random(100) * 1px)
                                );
                            }

                            100% {
                                transform: translate3d(
                                    (random(150) * 100%),
                                    (random(100) * -100%),
                                    (random(100) * 1px)
                                );
                            }
                        }

                        .particle:nth-child(#{$i}) {
                            animation: particle-animation-#{$i}
                                30s
                                linear
                                infinite;
                            $size: random(5) + 2 + px;
                            opacity: calc(random(100) / 100);
                            height: $size;
                            width: $size;
                            animation-delay: -$i * 0.02s;
                            transform: translate3d(
                                (random(100) * 50%),
                                (random(100) * 50%),
                                (random(100) * 1px)
                            );
                            background: #fe8c2e;
                        }
                    }
                }
            }
        }

        .title {
            border-top-right-radius: 22px;
            border-top-left-radius: 22px;
            background: var(--prime-gradient);
            padding: 0.5rem;
            max-width: 100%;
            min-height: 132px;
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: 15px;

            h1 {
                font-size: 40px;
                font-weight: 600;
                margin: 0;
                text-align: center;
                color: #000;
                padding-bottom: 1rem;
                @media (min-width: 1920px) {
                    font-size: 50px;
                }
                span {
                    font-size: 20px;
                    @media (min-width: 1920px) {
                        font-size: 28px;
                    }
                }
            }

            .node {
                position: absolute;
                left: 60px;
                top: 15px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                filter: drop-shadow(0px 0px 12px rgb(27, 32, 38));
                .logo-wrap {
                    width: 170px;
                    aspect-ratio: 20/17;
                    margin: 0 auto;
                    padding: 0.7rem;
                    background: linear-gradient(
                        rgba(21, 21, 21, 1),
                        rgba(67, 67, 67, 1)
                    );
                    clip-path: polygon(
                        25% 0,
                        75% 0%,
                        100% 50%,
                        75% 100%,
                        25% 100%,
                        0% 50%
                    );
                    border-radius: 8px;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
                        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                    @media (min-width: 1920px) {
                        width: 200px;
                    }
                    .logo-wrap-2 {
                        width: 100%;
                        height: 100%;
                        padding: 0.5rem;
                        background: var(--accent-gradient);
                        clip-path: polygon(
                            25% 0,
                            75% 0%,
                            100% 50%,
                            75% 100%,
                            25% 100%,
                            0% 50%
                        );
                        .logo-wrap-3 {
                            height: 100%;
                            background: linear-gradient(
                                rgba(21, 21, 21, 1),
                                rgba(67, 67, 67, 1)
                            );
                            clip-path: polygon(
                                25% 0,
                                75% 0%,
                                100% 50%,
                                75% 100%,
                                25% 100%,
                                0% 50%
                            );
                            position: relative;
                            z-index: 6;
                        }
                    }
                }

                .logo {
                    position: absolute;
                    inset: 0;
                    > img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }

            .tito {
                position: absolute;
                right: 45px;
                top: 15px;
            }

            @media (max-width: 575px) {
                padding: 1rem;

                .tito {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        .blur {
            filter: drop-shadow(0px 0px 12px var(--accent-color));
        }

        .package {
            background: #313131;
            display: inline-block;
            padding: 15px 35px;
            border-radius: 11px;
            // box-shadow: rgba(0, 0, 0, 0.45) 1px 1px 40px 0px inset;
            clip-path: polygon(
                10% 0,
                90% 0,
                100% 50%,
                90% 100%,
                10% 100%,
                0 50%
            );
            max-width: 350px;
            line-height: 1;
            p {
                font-size: 18px;
                font-weight: 500;
                text-align: center;
                color: var(--accent-color);
                margin: 0;
                @media (min-width: 1920px) {
                    font-size: 24px;
                }
            }
        }

        .details {
            color: #f8fafc;
            padding: 3rem 1rem 1rem 1rem;

            .node-mobile {
                display: none;

                .node {
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    filter: drop-shadow(0px 0px 12px rgb(27, 32, 38));
                    .logo-wrap {
                        width: 200px;
                        aspect-ratio: 20/17;
                        margin: 0 auto;
                        padding: 0.7rem;
                        background: linear-gradient(
                            rgba(21, 21, 21, 1),
                            rgba(67, 67, 67, 1)
                        );
                        clip-path: polygon(
                            25% 0,
                            75% 0%,
                            100% 50%,
                            75% 100%,
                            25% 100%,
                            0% 50%
                        );
                        border-radius: 8px;
                        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
                            rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                        .logo-wrap-2 {
                            width: 100%;
                            height: 100%;
                            padding: 0.5rem;
                            background: linear-gradient(
                                rgba(80, 202, 255, 1),
                                rgba(255, 255, 255, 1),
                                rgba(80, 202, 255, 1)
                            );
                            clip-path: polygon(
                                25% 0,
                                75% 0%,
                                100% 50%,
                                75% 100%,
                                25% 100%,
                                0% 50%
                            );
                            .logo-wrap-3 {
                                height: 100%;
                                background: linear-gradient(
                                    rgba(21, 21, 21, 1),
                                    rgba(67, 67, 67, 1)
                                );
                                clip-path: polygon(
                                    25% 0,
                                    75% 0%,
                                    100% 50%,
                                    75% 100%,
                                    25% 100%,
                                    0% 50%
                                );
                                position: relative;
                                z-index: 6;
                            }
                        }
                    }

                    .logo {
                        position: absolute;
                        inset: 0;
                        > img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }

            .detail-content {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                padding-right: 3rem;

                h4 {
                    font-size: 20px;
                    @media (min-width: 1600px) {
                        font-size: 24px;
                    }
                }

                ul {
                    list-style: none;
                    color: #fff;
                    font-weight: 700;
                    padding-left: 20px;
                    li {
                        padding-bottom: 7px;
                        svg {
                            color: var(--accent-color);
                        }
                    }
                    span {
                        color: #cacaca;
                        font-weight: 500;
                    }
                }
            }

            .botan {
                border-radius: 60px;
                background: var(--prime-gradient);
                font-weight: 600;
                width: 62%;
                display: block;
                // max-width: 300px;
                padding: 10px 15px;
                // margin: 0 auto;
                margin-left: auto;
                margin-right: 3rem;
            }
        }

        @media (min-width: 576px) {
            .title {
                padding: 1rem;
            }

            .node {
                > img {
                    width: 160px;
                    height: 160px;
                }
            }

            .details {
                ul {
                    padding-left: 40px;
                }
            }
        }
    }

    @media (max-width: 1025px) {
        gap: 5rem;

        .content {
            .title {
                .node {
                    display: none;
                }

                .tito {
                    position: unset;
                    margin-bottom: -60px;
                }
            }
            .details {
                .node-mobile {
                    display: block;
                }

                .detail-content {
                    padding-right: unset;
                    align-items: center;
                    margin-top: 1rem;

                    .tit {
                        text-align: center;
                    }

                    ul {
                        padding: 0;
                        li {
                            padding-bottom: 10px;
                        }
                    }
                }

                .botan {
                    max-width: 350px;
                    width: 100%;
                    margin: 0 auto;
                }
            }
        }
    }

    @media (max-width: 990px) {
        gap: 2rem;
    }
}

.partial-wrapper {
    margin: -1em -1em 0px;
    border-radius: 22px 22px 0px 0px;
    // background-image: linear-gradient(#9e9e9e, #656565 10% 90%, #989898);
    padding: 1em;

    .partial-item {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background-color: #161616;
        border-radius: 6px;
        padding: 10px;
        background-image: url('../../assets/images/background/corpo-bg.png');
        background-repeat: no-repeat;
        background-size: contain;
        .left {
            img {
                max-width: 204px;
                width: 100%;
            }
        }

        .title {
            border-top-right-radius: 22px;
            border-top-left-radius: 22px;
            padding-top: 2rem;
            padding-bottom: 2rem;
            max-width: 100%;
            min-height: 152px;

            h1 {
                font-size: 60px;
                font-weight: 600;
                color: #ffffff;
                text-align: center;

                span {
                    font-size: 40px;
                }
            }
        }

        .package {
            background: #6a7a87;
            width: 90%;
            margin: 0 auto;
            padding: 1rem;
            border-radius: 11px;
            margin-top: -1em;

            p {
                font-size: 18px;
                font-weight: 500;
                text-align: center;
                color: var(--name-color);
                margin: 0;
                @media (min-width: 1920px) {
                    font-size: 24px;
                }
            }
        }

        .right {
            h1 {
                font-size: 25px;
                font-weight: 600;
                color: #fff;
                margin-top: 1rem;
            }

            > p {
                background: #313131;
                border-radius: 6px;
                display: inline-block;
                color: #fff;
                padding: 5px 25px;
            }

            > div {
                div {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    p {
                        margin-bottom: 0;
                        color: #fff;
                    }
                }
            }
        }

        @media (max-width: 575px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px;
        }
    }

    .partial-item:nth-child(5) {
        background: red;
    }
}

.confirmation {
    --name-color: #000;
    padding-top: 15px;

    .use-voucher {
        background: #d9d9d9;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 6px;

        div:nth-child(1) {
            display: flex;
            align-items: center;
            gap: 10px;

            p {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 0;
            }
        }

        div:nth-child(2) {
            background: linear-gradient(to right, #1775b8, #91c4e9);
            border-radius: 6px;

            button {
                font-size: 16px;
                font-weight: 700;
                color: #fff;
                padding: 5px 10px;
                outline: none;
            }
        }

        @media (max-width: 418px) {
            div:nth-child(2) {
                button {
                    svg {
                        display: none;
                    }
                }
            }
        }
    }

    .detail {
        margin-top: 15px;

        h3,
        p {
            font-size: 20px;
        }

        p {
            display: flex;
            justify-content: space-between;
            font-weight: 600;
        }

        .evoucher {
            display: flex;
            justify-content: space-between;
        }
    }
}

.botans {
    display: flex;
    justify-content: center;
    gap: 10px;

    > button {
        background: linear-gradient(to right, #1775b8, #91c4e9);
        max-width: 465px;
        width: 90%;
        margin: 0 auto;
        border-radius: 100vw;
        padding: 10px 15px;
        font-size: 18px;
        font-size: 18px;
        font-weight: 600;
        color: #fff;
    }

    &.fail {
        grid-template-columns: 1fr;
    }
}

.mi-head {
    font-size: 30px;
    font-weight: 600;
    color: #0087cc;
    text-align: center;

    &.fail {
        color: #cc0000;
    }
}

.mi-sub {
    text-align: center;
    font-weight: 600;
    padding: 20px 0px;
}

.banner {
    position: relative;
    width: fit-content;
    margin: 15px auto 15px;

    > img {
        max-width: 100%;
        border-radius: 12px;
    }

    > button {
        position: absolute;
        bottom: 25px;
        left: 50%;
        transform: translateX(-50%);
        width: fit-content;
        white-space: nowrap;
        color: white;
        font-size: 24px;
        font-weight: 600;
        border-radius: 12px;
        padding: 2px 15px;
        box-shadow: 0px 0px 5p 0px #0087c8;
        outline: none;

        @media (min-width: 768px) {
            font-size: 12px;
            bottom: 2px;
        }

        @media (min-width: 1200px) {
            font-size: 16px;
        }

        @media (min-width: 1400px) {
            font-size: 24px;
        }
    }
}

.table-responsive {
    .head {
        font-size: 24px;
        font-weight: 600;
        color: #fff;
    }
}

.note {
    color: #0087cc;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 10px;
    @media (max-width: 376px) {
        font-size: 16px;
    }
}

.promotion-tag2 {
    background-color: #ff2525;
    position: absolute;
    top: 4px;
    right: -0.8em;
    // transform: translate(-50%, -50%) rotate(-30deg);
    line-height: 1.6;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    padding: 0px 8px;

    filter: drop-shadow(1px 1px 2px #000000aa);
    &::before {
        content: '';
        position: absolute;
        border: 0.8em solid #ff2525;
        border-left-color: transparent;
        right: 100%;
        top: 0;
        bottom: 0;
    }
    &::after {
        content: '';
        position: absolute;
        border: 0.4em solid transparent;
        border-left-color: #ce1f1f;
        border-top-color: #ce1f1f;
        right: 0;
        top: 100%;
        z-index: -1;
    }
}
</style>

<style lang="scss">
#buy-node {
    .modal-dialog {
        max-width: 782px;
        width: 90%;
        margin: 0 auto;
        position: relative;

        .modal-body {
            > svg {
                position: absolute;
                top: 10px;
                right: 10px;
                cursor: pointer;
            }
        }
    }

    h2 {
        font-size: 30px;
        font-weight: 600;
        color: #0087cc;
        text-align: center;
    }

    h3 {
        font-size: 18px;
        font-weight: 600;
        text-align: left;
        color: #0087cc;
    }

    @media (max-width: 575px) {
        .modal-dialog {
            width: 95%;
        }

        .use-voucher {
            p {
                font-size: 16px;
            }
        }

        .detail {
            h3 {
                font-size: 16px;
            }

            p {
                font-size: 16px;
                gap: 15px;
            }
        }

        .botans {
            button {
                width: 100%;
                font-size: 13px;
            }
        }
    }
}

#buynode-confirm {
    @media (min-width: 320px) {
        .modal-content {
            position: relative;
            width: 100%;
            margin: 0 auto;

            .modal-body {
                border-radius: 12px;

                h2 {
                    color: #0087cc;
                    text-align: center;
                    font-size: 30px;
                    margin-bottom: 15px;
                }

                h3 {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                }

                p {
                    font-size: 18px;
                    font-weight: 500;
                    text-align: center;
                }

                svg {
                    position: absolute;
                    font-size: 30px;
                    top: 10px;
                    right: 10px;
                    cursor: pointer;
                }

                .payment {
                    display: flex;
                    align-items: center;
                    margin: 15px 0;
                    justify-content: center;
                    gap: 5px;
                    p {
                        font-size: 20px;
                        font-weight: 600;
                        color: #0087cc;
                        margin-bottom: 0;
                    }

                    .unit {
                        display: flex;
                        transition: 0.2s;

                        > div {
                            padding: 5px 20px;
                            color: #fff;
                            cursor: pointer;
                            font-weight: 700;
                            white-space: nowrap;

                            &:nth-child(1) {
                                border-top-left-radius: 6px;
                                border-bottom-left-radius: 6px;
                            }

                            // &:nth-child(2) {
                            //     border-top-right-radius: 6px;
                            //     border-bottom-right-radius: 6px;
                            // }

                            &:nth-child(3) {
                                border-top-right-radius: 6px;
                                border-bottom-right-radius: 6px;
                            }
                        }
                    }

                    .unit > div.inactive {
                        background: #ccc;
                        color: #000;
                        opacity: 0.3;
                    }

                    .unit > div.active {
                        background: #1775b8;
                        color: #fff;
                    }

                    @media (max-width: 991px) {
                        .unit {
                            > div {
                                font-size: 18px;
                            }
                        }
                    }

                    @media (max-width: 418px) {
                        flex-direction: column;

                        .unit {
                            margin-bottom: 15px;
                        }
                    }
                }
                > .buttons {
                    display: flex;
                    align-content: center;
                    justify-content: space-around;
                    gap: 15px;
                    margin-top: 1rem;

                    span {
                        cursor: pointer;
                        max-width: 200px;
                        width: 100%;
                        color: white;
                        border-radius: 72px;
                        padding: 4px 10px;
                        font-size: 18px;
                        text-transform: uppercase;
                        font-weight: 600;
                        text-align: center;
                        transition: 0.2s;

                        &:nth-child(1) {
                            background-image: linear-gradient(
                                to right,
                                #075d9b,
                                #1775b8,
                                #91c4e9,
                                #d2e9fa
                            );
                            background-position: center;
                            background-size: 200%;

                            &:hover {
                                background-position: 0%;
                            }
                        }

                        &:nth-child(2) {
                            color: #0087cc;
                            border: 1px solid #0087cc;
                        }
                    }
                }
            }
        }
    }
}

::-webkit-scrollbar {
    width: 6px;
    display: block;
    padding-right: 5px;
}

::-webkit-scrollbar-track {
    border-radius: 12px;
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #0087cc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #0087c8;
}

.bootstrap-btn {
    background: transparent;
    border: none;

    &:hover {
        background: transparent;
    }

    svg {
        font-size: 20px;
    }
}

.table-responsive.corpo {
    background: #1478b0;
    border-radius: 22px;
    margin: 15px;
    padding: 1rem;

    .customer-header {
        background-color: transparent;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #fff;
    }

    .table th,
    .table td {
        border: none;
    }

    table {
        thead {
            tr {
                th {
                    white-space: nowrap;
                }
            }
        }

        tbody {
            tr {
                height: 70px;
                border-radius: 12px;
                background: transparent;

                &:nth-child(even) {
                    background: #e1e1e175;
                }

                td {
                    background: none;
                    height: 100%;
                    padding: 0.75rem;
                    vertical-align: middle;
                    color: #fff;
                    font-weight: 500;

                    .mobile-data {
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        line-height: 1;
                        padding: 5px 0px;

                        > div:last-child {
                            color: #fff;
                        }
                    }

                    > span {
                        height: 70px;
                        display: flex;
                        padding: 0 0.75rem;
                        align-items: center;
                        margin-bottom: 0;
                        justify-content: center;
                        backdrop-filter: blur(15.899999618530273px);
                    }
                }
            }
        }
    }
}
</style>
