const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        voucherList: null,
        gotItVoucherList: null,
        userVoucherList: [],
        filterdVoucherList: [],
        gotItHistory: null,
        gotItManaXinList: null,
        myVoucherList: null,
        voucherShopList: null,
    }),

    getters: {
        VoucherList: (state) => state.voucherList,
        UserVoucherList: (state) => state.userVoucherList,
        FilteredUserVoucherList: (state) => state.filterdVoucherList,
        GotitVoucherList: (state) => state.gotItVoucherList,
        GotItHistory: (state) => state.gotItHistory,
        GotItManaXinList: (state) => state.gotItManaXinList,
        MyVoucherList: (state) => state.myVoucherList,
        VoucherShopList: (state) => state.voucherShopList,
    },

    actions: {
        async voucherList({ commit }) {
            return axios.get('/voucher/voucher-settings').then((res) => {
                if (res && res.data) {
                    commit('SET_VOUCHER_LIST', res.data.results);
                    console.log(res.data.results);
                }
            });
        },

        async userVoucher({ commit }, input) {
            return axios
                .get('/voucher/user', {
                    params: { page: input.page },
                })
                .then((res) => {
                    if (res && res.data) {
                        commit('SET_USER_VOUCHER_LIST', res.data);
                        console.log(res.data);
                    }
                });
        },

        async filteredUserVoucher({ commit }, input) {
            return axios
                .get('/voucher/user', {
                    params: { page: input.page, type: input.type },
                })
                .then((res) => {
                    if (res && res.data) {
                        commit('SET_FILTERED_USER_VOUCHER_LIST', res.data);
                        console.log('Filtered User voucher list: ', res.data);
                    }
                });
        },

        async buyVoucher(context, input) {
            return axios.post('/voucher/buy', input).then((res) => {});
        },

        async useVoucherXin(context, params) {
            return axios.get(`/voucher/change-xin/${params}`).then((res) => {
                if (res && res.data) {
                    console.log(res.data);
                }
            });
        },

        async voucherType() {
            return axios.get('/voucher/type').then((res) => {
                if (res && res.data) {
                    console.log(res.data);
                }
            });
        },
        async gotItVoucherList({ commit }) {
            return axios.get(`voucher/gotit/list/${'XIN'}`).then((res) => {
                if (res && res.data) {
                    commit('SET_GOTIT_VOUCHER_LIST', res.data);
                }
                return res;
            });
        },
        async gotItManaXinList({ commit }) {
            return axios
                .get(`/voucher/gotit/list/${'XIN_MANA'}`)
                .then((res) => {
                    if (res.status) {
                        commit('SET_GOTIT_XIN_MANA_LIST', res.data);
                    }
                    return res;
                });
        },
        async buyVoucherGotIt(context, input) {
            return axios.post('voucher/gotit/buy', input).then((res) => {
                return res;
            });
        },
        async gotItVoucherHistory({ commit }, input) {
            return axios
                .get('voucher/gotit/history', { params: input })
                .then((res) => {
                    if (res.status) {
                        commit('SET_GOTIT_HISTORY', res.data);
                    }
                    return res;
                });
        },
        async gotItDetail(context, id) {
            return axios.get(
                `https://alabapay.pigaming.co/api/get-details/${id}`,
            );
        },
        async onCreateVoucher(context, input) {
            return axios.post('/voucher/create-voucher', input).then((res) => {
                return res;
            });
        },
        async myVoucherList({ commit }, input) {
            return axios
                .get('voucher/shop/my-voucher', { params: input })
                .then((res) => {
                    console.log('My voucher list: ', res);
                    if (res.status) {
                        commit('SET_MY_VOUCHER_LIST', res.data);
                    }
                    return res;
                });
        },
        async updateVoucher(context, input) {
            return axios
                .post('voucher/shop/update-voucher', input)
                .then((res) => {
                    console.log('Update voucher: ', res);
                    return res;
                });
        },
        async voucherShop({ commit }, input) {
            return await axios
                .get('/voucher/shop/list', { params: input })
                .then((res) => {
                    console.log('Voucher shop: ', res);
                    if (res.status) {
                        commit('SET_VOUCHER_SHOP', res.data);
                    }
                    return res;
                });
        },
        async shopBuyVoucher(context, params) {
            return axios.post(`/voucher/buy-on-shop/${params}`).then((res) => {
                if (res && res.data) {
                    console.log('Buy voucher shop: ', res.data);
                }
            });
        },
    },

    mutations: {
        SET_VOUCHER_LIST(state, data) {
            // console.log('Voucher list: ', data.results);
            state.voucherList = data;
        },

        SET_GOTIT_VOUCHER_LIST(state, data) {
            // console.log('Voucher list: ', data.results);
            state.gotItVoucherList = data;
        },

        SET_USER_VOUCHER_LIST(state, data) {
            // console.log('Voucher list: ', data.results);
            state.userVoucherList = data;
        },
        SET_FILTERED_USER_VOUCHER_LIST(state, data) {
            state.filterdVoucherList = data;
        },
        SET_GOTIT_HISTORY(state, data) {
            state.gotItHistory = data;
        },
        SET_GOTIT_XIN_MANA_LIST(state, data) {
            state.gotItManaXinList = data;
        },
        SET_MY_VOUCHER_LIST(state, data) {
            state.myVoucherList = data;
        },
        SET_VOUCHER_SHOP(state, data) {
            state.voucherShopList = data;
        },
    },
};
