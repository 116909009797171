<template>
    <div class="">
        <div class="title">{{ $t('stakingHistory') }}</div>
        <div class="base-table">
            <b-table
                thead-class="custom-header"
                striped
                hover
                :items="() =>
                        StakeHistory && StakeHistory.results
                            ? StakeHistory.results
                            : []"
                show-empty
                :fields="fields"
                responsive
            >
            <template #empty>
                        <h4 class="text-center my-3">
                            {{ $t('noData') }}
                        </h4>
                    </template>
                <template #head()="{ label }">
                    {{ $t(label) }}
                </template>
                <template #cell(dayEnd)="{ value }">
                    {{ getDateTime3(value) }}
                </template>
                <template #cell(dayStart)="{ value }">
                    {{ getDateTime3(value) }}
                </template>
                <template #cell(amount)="{ value }">
                    {{
                        Number(value).toLocaleString('en-US', {
                            signDisplay: 'never',
                        })
                    }}
                </template>
            </b-table>
        </div>
        <b-pagination
            v-if="StakeHistory"
            v-model="params.page"
            :total-rows="StakeHistory.totalResults"
            :per-page="params.limit"
        >
        </b-pagination>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import Paginate from '@/components/shared/Paginate.vue';

export default {
    name: 'StakingHistory',
    data() {
        return {
            params: {
                page: 1,
                limit: 2,
            },
            fields: [
                {
                    class: 'text-center',
                    label: 'packageName',
                    key: 'packageName',
                },
                {
                    class: 'text-center',
                    label: 'amount',
                    key: 'amount',
                },
                {
                    class: 'text-center',
                    label: 'currentPeriod',
                    key: 'currentPeriod',
                },
                {
                    class: 'text-center',
                    label: 'totalProfit',
                    key: 'totalProfit',
                },
                {
                    class: 'text-center',
                    label: 'status',
                    key: 'claimStatus',
                },
                {
                    class: 'text-center',
                    label: 'dayStart',
                    key: 'dayStart',
                },
                {
                    class: 'text-center',
                    label: 'dayEnd',
                    key: 'dayEnd',
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            StakeHistory: 'stake/StakeHistory',
        }),
    },
    methods: {
        requestHistory() {
            this.$store.dispatch('stake/getStakeHistory', this.params);
        },
    },
    watch: {
        'params.page': {
            // eslint-disable-next-line object-shorthand
            handler: function (newVal) {
                console.log('NEWVAL', newVal);
                this.requestHistory();
            },
        },
    },
    mounted() {
        this.requestHistory();
        this.unsubscribe = this.$store.subscribe((mutation) => {
            switch (mutation.type) {
                case 'stake/STAKED':
                    this.requestHistory();
                    break;
                default:
                    break;
            }
        });
    },
    beforeDestroy() {
        this.unsubscribe();
    },
};
</script>

<style lang="scss" scoped>
.base-table {
    border-radius: 22px;
}
.title {
    font-weight: 600;
    font-size: 28px;
    color: #0087cc;
}
</style>
