<template>
    <div class="paginate">
        <button class="paginate prev" :disabled="!(currentPage > 1)" @click="currentPage--">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                    stroke-width="1.5" d="m14 7l-5 5l5 5" />
            </svg>
        </button>
        <button class="paginate" v-for="pageIndex in range(0, totalPages).slice(
            totalPages - currentPage < 4 && totalPages - currentPage > 5
                ? totalPages - 4
                : currentPage > 1
                    ? currentPage - 1
                    : 1,
            currentPage <= 1 ? currentPage + 5 : currentPage + 4,
        )" :key="`current-page-${pageIndex}`" :class="{ active: pageIndex == currentPage }"
            @click="currentPage = pageIndex">
            {{ pageIndex }}
        </button>
        <button class="paginate next" :disabled="!(currentPage < totalPages)" @click="currentPage++">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                    stroke-width="1.5" d="m10 17l5-5l-5-5" />
            </svg>
        </button>
    </div>
</template>

<script>
export default {
    props: {
        totalPages: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            currentPage: 1,
        };
    },
    watch: {
        currentPage: {
            handler(newVal) {
                this.onChangePage(newVal);
            },
        },
    },
    methods: {
        range(start, end) {
            return Array(end - start + 1)
                .fill()
                .map((_, idx) => start + idx);
        },
        onChangePage(page) {
            this.$emit('current', page);
        },
    },
    mounted() {
        this.onChangePage(this.currentPage);
    },
};
</script>

<style lang="scss" scoped>
.paginate {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;

    .paginate {
        background: none;
        font-weight: 500;
        font-size: 14px;
        color: #b0aeae;
        border: none;
        height: 35px;
        min-width: 35px;
        box-shadow: none;
        border: none;
        border: 0.5px solid rgba(53, 63, 72, 0);
        margin: 0 4px;
        margin: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:focus {
            outline: none;
            border: 0;
        }

        &.next {
            background: none;
            color: #000;
        }

        &.prev {
            background: none;
            color: #000;
        }

        &.active {
            justify-content: center;
            align-items: center;
            border-radius: 2px;
            color: #00c2ff;
            position: relative;
            background: linear-gradient(to bottom, #18d4e2, #0087c8);

            &::before {
                content: '';
                z-index: -1;
                position: absolute;
                top: 1px;
                right: 1px;
                bottom: 1px;
                left: 1px;
                background: linear-gradient(0deg,
                        rgba(0, 0, 0, 0.2),
                        rgba(0, 0, 0, 0.2)),
                    #353f48;
            }
        }
    }
}
</style>
