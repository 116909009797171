<template>
    <div>
        <div v-if="!EsimRegional">
            <div class="loading">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill="none"
                        stroke="currentColor"
                        stroke-dasharray="16"
                        stroke-dashoffset="16"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 3c4.97 0 9 4.03 9 9"
                    >
                        <animate
                            fill="freeze"
                            attributeName="stroke-dashoffset"
                            dur="0.2s"
                            values="16;0"
                        />
                        <animateTransform
                            attributeName="transform"
                            dur="1.5s"
                            repeatCount="indefinite"
                            type="rotate"
                            values="0 12 12;360 12 12"
                        />
                    </path>
                </svg>
                <p>Loading...</p>
            </div>
        </div>
        <div class="content" v-else>
            <div
                v-for="(item, index) in EsimRegional.data.results"
                :key="index"
                @click="goPayment(item.slug)"
            >
                <img :src="item.image" alt="" />
                <p>
                    {{ item.title }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            EsimRegional: null,
        };
    },

    methods: {
        goPayment(input) {
            this.$router.push({
                name: 'RegionalPayment',
                query: { regional_code: input },
            });
            this.$store.dispatch('nft/getEsimRegional', {
                filter: {
                    type: 'regional',
                    regional: input,
                },
            });
        },
    },

    mounted() {
        this.$store
            .dispatch('nft/getEsimRegional', {
                filter: {
                    type: 'regional',
                },
            })
            .then((res) => {
                if (res) {
                    this.EsimRegional = res;
                }
            });
    },
};
</script>

<style lang="scss" scoped>
.content {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 15px;
    > div {
        padding: 1rem;
        display: flex;
        gap: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 20px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        img {
            width: 90px;
        }
        > p {
            font-size: 16px;
            font-weight: 600;
        }
    }

    @media (max-width: 1200px) {
        grid-template-columns: repeat(6, 1fr);
    }

    @media (max-width: 991px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 575px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 414px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.loading {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    svg {
        color: #0087cc;
        font-size: 80px;
    }

    p {
        color: #0087cc;
        font-size: 20px;
    }
}
</style>
