var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"paginate"},[_c('button',{staticClass:"paginate prev",attrs:{"disabled":!(_vm.currentPage > 1)},on:{"click":function($event){_vm.currentPage--}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"1.5","d":"m14 7l-5 5l5 5"}})])]),_vm._l((_vm.range(0, _vm.totalPages).slice(
        _vm.totalPages - _vm.currentPage < 4 && _vm.totalPages - _vm.currentPage > 5
            ? _vm.totalPages - 4
            : _vm.currentPage > 1
                ? _vm.currentPage - 1
                : 1,
        _vm.currentPage <= 1 ? _vm.currentPage + 5 : _vm.currentPage + 4
    )),function(pageIndex){return _c('button',{key:("current-page-" + pageIndex),staticClass:"paginate",class:{ active: pageIndex == _vm.currentPage },on:{"click":function($event){_vm.currentPage = pageIndex}}},[_vm._v(" "+_vm._s(pageIndex)+" ")])}),_c('button',{staticClass:"paginate next",attrs:{"disabled":!(_vm.currentPage < _vm.totalPages)},on:{"click":function($event){_vm.currentPage++}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"1.5","d":"m10 17l5-5l-5-5"}})])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }