<template>
    <b-modal
        id="update-phone"
        hide-footer
        hide-header
        dialog-class="supa-modal"
        centered
    >
        <div>
            <div class="head">{{ $t('updatePhone') }}</div>
            <div class="notice">
                <div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24c1.12.37 2.33.57 3.57.57c.55 0 1 .45 1 1V20c0 .55-.45 1-1 1c-9.39 0-17-7.61-17-17c0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1c0 1.25.2 2.45.57 3.57c.11.35.03.74-.25 1.02z"
                        />
                    </svg>
                    {{ $t('updatePhoneGetTheBest') }}
                </div>
            </div>
            <form class="myForm" @submit.prevent="updatePhone">
                <label>
                    {{ $t('phone') }}
                    <div class="input-box">
                        <input v-model="phone" />
                    </div>
                </label>
                <div class="text-center">
                    <button type="submit" class="botan nice-gradient">
                        {{ $t('update') }}
                    </button>
                </div>
            </form>
        </div>
        <!-- Close button x -->
        <button
            class="close"
            @click="$bvModal.hide('update-phone')"
            type="button"
            style="color: #0087cc"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
            >
                <path
                    fill="currentColor"
                    d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                />
            </svg>
        </button>
    </b-modal>
</template>

<script>
export default {
    name: 'UpdatePhone',
    data() {
        return {
            phone: '',
        };
    },
    methods: {
        updatePhone() {
            this.$store
                .dispatch('auth/updatePhone', {
                    phone: this.phone,
                })
                .then((res) => {
                    if (res) {
                        this.$store.dispatch('info/req_getInfo');
                        this.$bvModal.hide('update-phone');
                    }
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.myForm {
    padding-top: 15px;
    .botan {
        color: white;
        border-radius: 100vw;
        font-weight: 600;
        padding: 10px 15px;
        box-shadow: 1px 1px 6px -2px #000;
    }
    label {
        display: block;
        font-weight: 600;
        .input-box {
            > input {
                box-shadow: 0px 0px 6px 2px #0000001c;
                border-radius: 6px;
                border: 1px solid #3f3f46;
                background-color: #d4d4d8;
                display: block;
                width: 100%;
                color: #000;
                padding: 4px 10px;
                margin: 5px 0px;
            }
        }
    }
}
.head {
    font-size: 30px;
    font-weight: 600;
    color: #0087cc;
    text-align: center;
    margin-top: 10px;
}
.notice {
    padding-top: 30px;
    > div {
        color: #0087cc;
        padding-left: 15px;
    }
}
</style>
