<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    data() {
        return {
            currentRoute: this.$route.path,
        };
    },

    mounted() {
        console.log('Current route path:', this.currentRoute);
    },
};
</script>

<style lang="scss" scoped></style>
