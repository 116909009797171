<template>
    <div class="master-table mt-4">
        <div v-if="!MasterNodeList">loading</div>
        <b-table
            v-else
            hover
            :items="MasterNodeList.data.results"
            :fields="fieldsMasterNode"
            :striped="false"
            :bordered="true"
            responsive
        >
            <template v-slot:cell(showDetail)="row">
                <button
                    @click="() => getMasterNode(row.item.userId)"
                    class="btn-primary"
                >
                    {{ $t('showDetails') }}
                </button>
            </template>
            <template #head()="data">
                {{ $t(data.label) }}
            </template>
        </b-table>
        <b-modal
            size="xl"
            dialog-class="modal-open-master-list"
            id="modal-open-master-list"
            centered
            hide-footer
            hide-header
            :striped="false"
        >
            <div class="master-table">
                <div v-if="!MasterNodeList">loading</div>
                <b-table
                    v-else
                    hover
                    :items="MasterNodeList.data.results"
                    :fields="fieldsMasterNode"
                    :striped="false"
                    :bordered="true"
                    responsive
                >
                    <template v-slot:cell(showDetail)="row">
                        <button
                            @click="() => getMasterNode(row.item.userId)"
                            class="btn-primary"
                        >
                            {{ $t('showDetails') }}
                        </button>
                    </template>
                    <template #head()="data">
                        {{ $t(data.label) }}
                    </template>
                </b-table>
            </div>
            <button
                class="btn-close"
                @click="$bvModal.hide('modal-open-master-list')"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5em"
                    height="1.5em"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill="#1c71d8"
                        d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                    />
                </svg>
            </button>
        </b-modal>
        <b-modal
            size="lg"
            dialog-class="modal-open-master-detail"
            id="modal-open-master-detail"
            centered
            hide-footer
            hide-header
        >
            <div v-if="!MasterNode">Loading</div>
            <div v-else class="master">
                <div class="master__header">
                    <h3>{{ $t('masterXNodeDetail') }}</h3>
                </div>

                <div class="master__body">
                    <div class="master__item">
                        <p class="master__item--heading">{{ $t('userID') }}:</p>
                        <p>{{ MasterNode.data.userId }}</p>
                    </div>

                    <div>
                        <p class="master__item--heading">{{ $t('email') }}:</p>
                        <p>{{ MasterNode.data.email }}</p>
                    </div>

                    <div>
                        <p class="master__item--heading">
                            {{ $t('phoneNumber') }}:
                        </p>
                        <p>{{ MasterNode.data.phone }}</p>
                    </div>

                    <div>
                        <p class="master__item--heading">
                            {{ $t('address') }}:
                        </p>
                        <p>{{ MasterNode.data.address }}</p>
                    </div>

                    <div>
                        <p class="master__item--heading">
                            {{ $t('description') }}:
                        </p>
                        <p>{{ MasterNode.data.description }}</p>
                    </div>

                    <div>
                        <p class="master__item--heading">
                            {{ $t('createdDate') }}:
                        </p>
                        <p>{{ MasterNode.data.createdAt }}</p>
                    </div>
                </div>

                <div class="master__footer">
                    <button
                        class="btn-primary"
                        @click="() => updateMember(MasterNode.data.userId)"
                    >
                        {{ $t('updateMasterXNode') }}
                    </button>
                </div>
            </div>

            <button
                class="btn-close"
                @click="$bvModal.hide('modal-open-master-detail')"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5em"
                    height="1.5em"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill="#1c71d8"
                        d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                    />
                </svg>
            </button>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
    data() {
        return {
            masternodeId: null,
            fieldsMasterNode: [
                {
                    key: 'userId',
                    label: 'userId',
                },
                {
                    key: 'email',
                    label: 'email',
                },
                {
                    key: 'showDetail',
                    label: 'more',
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            UserInfo: 'info/UserInfo',
            Master: 'info/Master',
            MasterNodeList: 'masternode/MasterNodeList',
            MasterNode: 'masternode/MasterNode',
        }),
        NoMasterXNode() {
            return !this.UserInfo.masterId && !this.Master;
        },
    },

    methods: {
        ...mapMutations(['onLoad', 'outLoad']),

        getAllMasterNode() {
            this.$bvModal.show('modal-open-master-list');
            this.$store.dispatch('masternode/getMasterNodeList').then((res) => {
                if (res && res.status) {
                    this.listMasterNode = res.data.results;
                }
            });
        },
        getMasterNode(userId) {
            this.$bvModal.show('modal-open-master-detail');
            this.$store
                .dispatch('masternode/getMasterNodeById', userId)
                .then((res) => {
                    if (res && res.status) {
                        this.listMasterNode = res.data.results;
                    }
                });
        },
        updateMember(userId) {
            this.$store
                .dispatch('masternode/updateMemberMasterNode', {
                    masterId: userId,
                })
                .then((res) => {
                    this.$store.dispatch('info/req_getInfo');
                    this.$bvModal.hide('modal-open-master-detail');
                    this.$bvModal.hide('modal-open-master-list');
                });
        },
        updateMasterNode() {
            this.$store
                .dispatch('masternode/updateMasterNode', {
                    masterId: this.masternodeId,
                })
                .then((res) => {
                    if (res && res.status) {
                        this.$store.dispatch('info/req_getInfo');
                        this.$bvModal.hide('modal-update-masternode');
                    }
                });
        },
    },

    mounted() {
        this.$store.dispatch('masternode/getMasterNodeList');
    },
};
</script>

<style scoped lang="scss">
.btn-primary {
    padding: 8px 16px;
    font-weight: 500;
    font-size: 16px;
    border-radius: 8px;
    background: linear-gradient(to right, #1775b8, #91c4e9);
}

@media (max-width: 993px) {
    .btn-primary {
        padding: 4px 8px;
    }
}
</style>

<style lang="scss">
.modal-open-master-list {
    .modal-content {
        .modal-body {
            display: flex;
            border-radius: 22px;
            flex-direction: column;
            padding: 44px 32px;
            position: relative;

            .master-table {
                table {
                    margin-bottom: 0;

                    thead {
                        tr {
                            th {
                            }
                        }
                    }

                    tbody {
                        tr {
                            td {
                            }
                        }
                    }
                }
            }

            @media (max-width: 575px) {
                .master-table {
                    overflow-x: hidden;
                }
            }

            .btn-close {
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(-8%, 8%);
            }
        }
    }

    .close {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}

.modal-open-master-detail {
    .modal-content {
        .modal-body {
            display: flex;
            border-radius: 22px;
            flex-direction: column;
            padding: 24px 32px;

            .btn-close {
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(-8%, 8%);
            }

            .master {
                display: flex;
                flex-direction: column;
                gap: 32px;
                padding: 0 8px;

                &__header {
                    text-align: center;

                    h3 {
                        text-transform: uppercase;
                        font-size: 28px;
                        font-weight: 700;
                        color: #0087cc;
                    }
                }

                &__body {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 8px;
                    padding: 16px 32px;
                    border-radius: 8px;
                }

                &__footer {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 8px 0;
                }

                &__item {
                    &--heading {
                        font-weight: 600;
                        font-size: 18px;
                        color: #0087cc;
                    }
                }
            }

            p {
                margin-bottom: 0;
            }
        }
    }

    .close {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}
</style>
