<script>
import {
    cancelOrderBuy,
    cancelOrderSell,
    historyOpen,
    historyClose,
    historyLast,
} from '@/store/modules/exchange';

import Paginate from '@/components/shared/Paginate.vue';

export default {
    components: {
        Paginate,
    },
    name: 'HistoryExchange',
    props: {
        isMobile: {
            type: Boolean,
            default: false,
        },
        coin: {
            type: Object,
            default: null,
        },
        listCurrency: {
            type: Array,
            default: null,
        },
        canceled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            OpenHistory: [],
            fieldOpens: [
                {
                    key: 'currency',
                    sortable: false,
                    label: 'Currencies',
                    class: 'text-center',
                },
                {
                    key: 'amount',
                    sortable: false,
                    label: 'Quantity',
                    class: 'text-center',
                },
                {
                    key: 'price',
                    sortable: false,
                    label: 'Price',
                    class: 'text-center',
                },
                {
                    key: 'sold',
                    sortable: false,
                    label: 'Sold',
                    class: 'text-center',
                },
                {
                    key: 'rest',
                    sortable: false,
                    label: 'Rest',
                    class: 'text-center',
                },
                {
                    key: 'type',
                    sortable: false,
                    label: 'Type',
                    class: 'text-center',
                },
                {
                    key: 'action',
                    sortable: false,
                    label: 'Action',
                    class: 'text-center',
                },
                {
                    key: 'createdAt',
                    sortable: false,
                    label: 'Time',
                    class: 'text-center',
                },
            ],
            fieldMB: [
                {
                    key: 'currency',
                    sortable: false,
                    label: 'Currencies',
                    class: 'text-center',
                },
                {
                    key: 'amount',
                    sortable: false,
                    label: 'Quantity',
                    class: 'text-center',
                },
                {
                    key: 'price',
                    sortable: false,
                    label: 'Price',
                    class: 'text-center',
                },
                {
                    key: '#',
                    label: 'Action',
                    class: 'text-center',
                },
            ],
            fieldMB2: [
                {
                    key: 'currency',
                    sortable: false,
                    label: 'Currencies',
                    class: 'text-center',
                },
                {
                    key: 'amountMatch',
                    sortable: false,
                    label: 'Quantity',
                    class: 'text-center',
                },
                {
                    key: 'priceMatch',
                    sortable: false,
                    label: 'Price',
                    class: 'text-center',
                },
                {
                    key: '#',
                    label: 'Action',
                    class: 'text-center',
                },
            ],
            rawCloseHistory: [],
            rawLastHistory: [],
            tableActive: 'isOpen',
            open: {
                limit: 10,
                page: 1,
                total: 1,
            },
            close: {
                limit: 10,
                page: 1,
                total: 1,
            },
            itemPick: {},
        };
    },
    computed: {
        CloseHistory() {
            if (this.tableActive === 'isClose') return this.rawCloseHistory;
            if (this.tableActive === 'isLast') return this.rawLastHistory;
            return [];
        },
        fieldClose() {
            const arr = [
                {
                    key: 'currency',
                    sortable: false,
                    label: 'Currencies',
                    class: 'text-center',
                },
                {
                    key: 'amountMatch',
                    sortable: false,
                    label: 'Quantity',
                    class: 'text-center',
                },
                {
                    key: 'priceOrder',
                    sortable: false,
                    label: 'Price Order',
                    class: 'text-center',
                },
                {
                    key: 'priceMatch',
                    sortable: false,
                    label: 'Price Match',
                    class: 'text-center',
                },
                {
                    key: 'fee',
                    sortable: false,
                    label: 'Fee',
                    class: 'text-center',
                },
                {
                    key: '#',
                    sortable: false,
                    label: 'Total USDT',
                    class: 'text-center',
                },
                {
                    key: 'type',
                    sortable: false,
                    label: 'Type',
                    class: 'text-center',
                },
                {
                    key: 'status',
                    sortable: false,
                    label: 'Status',
                    class: 'text-center',
                },
                {
                    key: 'time',
                    sortable: false,
                    label: 'Time',
                    class: 'text-center',
                },
            ];
            if (this.tableActive === 'isLast') {
                return arr.filter((item) => item.key !== 'priceOrder');
            }
            return arr;
        },
    },
    methods: {
        async getOpenHistory() {
            const data = await historyOpen({
                coin: this.coin.id,
                query: this.open,
            });
            if (data) {
                const { results, totalPages } = data;
                this.open = {
                    limit: this.open.limit,
                    page: this.open.page,
                    total: totalPages || 1,
                };
                this.OpenHistory = results || null;
            }
        },
        async getCloseHistory() {
            const data = await historyClose({
                coin: this.coin.id,
                query: this.close,
            });
            if (data) {
                const { results, totalPages } = data;
                this.close = {
                    limit: this.close.limit,
                    page: this.close.page,
                    total: totalPages || 1,
                };
                this.rawCloseHistory = results || [];
            }
        },
        async getLastHistory() {
            const data = await historyLast({
                coin: this.coin.id,
                query: {
                    limit: 20,
                },
            });
            if (data) {
                const { results, totalPages } = data;
                const newData = [];
                results.forEach((history) => {
                    const {
                        amountMatch,
                        createdAt,
                        fee,
                        pair,
                        priceMatch,
                        type,
                    } = history;
                    newData.push({
                        currency: pair,
                        amountMatch,
                        status: 1,
                        priceMatch,
                        time: createdAt,
                        fee,
                        type: type ?? 'Sell',
                        priceOrder: priceMatch,
                    });
                });
                this.close = {
                    limit: this.close.limit,
                    page: this.close.page,
                    total: totalPages || 1,
                };
                this.rawLastHistory = newData || [];
            }
        },
        async onCancel() {
            const { type, _id } = this.itemPick;
            console.log(type, _id);
            if (!type || !_id) {
                this.$toastr.e('Cancel Failed', 'Somethings went wrong!');
                return;
            }
            let data;
            if (type === 'buy') {
                data = await cancelOrderBuy(_id);
            } else {
                data = await cancelOrderSell(_id);
            }
            console.log(data);
            this.$bvModal.hide('confirm-cancel-order');
            this.getOpenHistory();
            this.getCloseHistory();
            this.$emit('canceled', true);
        },
        onShowCancel(item) {
            this.itemPick = item;
            this.$bvModal.show('confirm-cancel-order');
        },
        onOpenChange(payload) {
            this.open.page = payload;
            this.getOpenHistory();
        },
        onCloseChange(payload) {
            this.close.page = payload;
            this.getCloseHistory();
        },
        coinActive(id) {
            const items = this.listCurrency.find((item) => item.id === id);
            if (items) return items.item;
            return 'Processing';
        },
    },
    mounted() {
        this.getOpenHistory();
        this.getCloseHistory();
        this.getLastHistory();
    },
};
</script>
<template>
    <div class="mt-4 mb-2">
        <div class="history-tr p-0">
            <div class="history-tr-header">
                <b-button
                    class="history-tr-header-item"
                    variant="none"
                    :class="{ active: tableActive == 'isOpen' }"
                    @click="tableActive = 'isOpen'"
                >
                    Open History
                </b-button>

                <b-button
                    class="history-tr-header-item"
                    variant="none"
                    :class="{ active: tableActive == 'isClose' }"
                    @click="tableActive = 'isClose'"
                >
                    Close History
                </b-button>
                <b-button
                    class="history-tr-header-item w-auto px-3"
                    variant="none"
                    :class="{ active: tableActive == 'isLast' }"
                    @click="tableActive = 'isLast'"
                >
                    Global Transactions
                </b-button>
            </div>
            <div class="history-tr-body">
                <div
                    class="open-ht table-responsive2"
                    v-if="tableActive == 'isOpen'"
                >
                    <b-table
                        v-if="!isMobile"
                        striped
                        hover
                        :items="OpenHistory"
                        :fields="fieldOpens"
                        show-empty
                        thead-class="customer-header"
                    >
                        <template #cell(currency)="data">
                            {{ coinActive(data.item.currency) }}
                        </template>
                        <template #cell(amount)="data">
                            {{
                                data.item.amount >= 1
                                    ? TruncateToDecimals2(
                                          data.item.amount || 0,
                                          '',
                                          3,
                                      )
                                    : toPlainString(data.item.amount || 0)
                            }}
                        </template>
                        <template #cell(price)="data">
                            {{
                                data.item.price >= 1
                                    ? TruncateToDecimals2(
                                          data.item.price || 0,
                                          '',
                                          3,
                                      )
                                    : toPlainString(data.item.price || 0)
                            }}
                        </template>
                        <template #cell(type)="data">
                            <span
                                class="badge px-2 py-1"
                                :class="
                                    data.item.type === 'buy'
                                        ? 'badge-success'
                                        : 'badge-danger'
                                "
                                style="text-transform: capitalize"
                            >
                                {{ data.item.type }}
                            </span>
                        </template>
                        <template #cell(sold)="data">
                            {{
                                data.item.sold >= 1
                                    ? TruncateToDecimals2(
                                          data.item.sold || 0,
                                          '',
                                          3,
                                      )
                                    : toPlainString(data.item.sold || 0)
                            }}
                        </template>
                        <template #cell(rest)="data">
                            {{
                                data.item.rest >= 1
                                    ? TruncateToDecimals2(
                                          data.item.rest || 0,
                                          '',
                                          3,
                                      )
                                    : toPlainString(data.item.rest || 0)
                            }}
                        </template>
                        <template #cell(action)="data">
                            <b-button
                                variant="danger"
                                size="sm"
                                class="py-1 px-2 cancel-ordered"
                                @click="onShowCancel(data.item)"
                            >
                                Cancel {{ data.item.type }}
                            </b-button>
                        </template>
                        <template #cell(createdAt)="data">
                            {{ getDateTime3(data.item.createdAt) }}
                        </template>
                    </b-table>
                    <b-table
                        v-else
                        striped
                        hover
                        :items="OpenHistory"
                        :fields="fieldMB"
                        show-empty
                        thead-class="customer-header"
                    >
                        <template #empty>
                            <h4 class="text-center my-3">No Data</h4>
                        </template>
                        <template #cell(currency)="data">
                            {{ coinActive(data.item.currency) }}
                        </template>
                        <template #cell(amount)="data">
                            {{
                                data.item.amount >= 1
                                    ? TruncateToDecimals2(
                                          data.item.amount || 0,
                                          '',
                                          3,
                                      )
                                    : toPlainString(data.item.amount || 0)
                            }}
                        </template>
                        <template #cell(price)="data">
                            {{
                                data.item.price >= 1
                                    ? TruncateToDecimals2(
                                          data.item.price || 0,
                                          '',
                                          3,
                                      )
                                    : toPlainString(data.item.price || 0)
                            }}
                        </template>
                        <template #cell(#)="row">
                            <b-button
                                size="sm"
                                variant="info"
                                @click="row.toggleDetails"
                                class="px-2 py-0"
                                style="font-size: 12px"
                            >
                                {{ row.detailsShowing ? 'Hide' : 'Show' }}
                            </b-button>
                        </template>
                        <template #row-details="row">
                            <div>
                                <div
                                    class="group-item d-flex justify-content-between align-items-center"
                                    v-for="field in fieldOpens"
                                    :key="field.key"
                                >
                                    <div class="left">{{ field.label }}:</div>
                                    <div
                                        class="right"
                                        v-if="field.key == 'action'"
                                    >
                                        <b-button
                                            variant="danger"
                                            size="sm"
                                            class="py-1 px-2 cancel-ordered"
                                            @click="onShowCancel(row.item)"
                                        >
                                            Cancel Order
                                        </b-button>
                                    </div>
                                    <div
                                        class="right"
                                        v-else-if="field.key == 'currency'"
                                    >
                                        {{ coin.item }}
                                    </div>
                                    <div
                                        class="right"
                                        v-else-if="field.key == 'type'"
                                    >
                                        <span
                                            class="badge"
                                            :class="
                                                row.item.type === 'buy'
                                                    ? 'badge-success'
                                                    : 'badge-danger'
                                            "
                                            style="text-transform: capitalize"
                                        >
                                            {{ row.item.type }}
                                        </span>
                                    </div>
                                    <div
                                        class="right"
                                        v-else-if="field.key == 'createdAt'"
                                    >
                                        {{ getDateTime3(row.item[field.key]) }}
                                    </div>
                                    <div class="right" v-else>
                                        {{
                                            row.item[field.key] >= 1
                                                ? TruncateToDecimals2(
                                                      row.item[field.key] || 0,
                                                      '',
                                                      3,
                                                  )
                                                : toPlainString(
                                                      row.item[field.key] || 0,
                                                  )
                                        }}
                                    </div>
                                </div>
                            </div>
                        </template>
                    </b-table>
                </div>

                <div
                    class="close-ht table-responsive2"
                    v-if="
                        tableActive == 'isClose' ||
                        tableActive == 'isLast' ||
                        tableActive == 'isLast'
                    "
                >
                    <b-table
                        v-if="!isMobile"
                        striped
                        hover
                        :items="CloseHistory"
                        :fields="fieldClose"
                        show-empty
                        thead-class="customer-header"
                    >
                        <template #empty>
                            <h4 class="text-center my-3">No Data</h4>
                        </template>
                        <template #cell(currency)="data">
                            {{ coinActive(data.item.currency) }}
                        </template>
                        <template #cell(amountMatch)="data">
                            {{
                                TruncateToDecimals2(
                                    data.item.amountMatch,
                                    '',
                                    5,
                                )
                            }}
                        </template>
                        <template #cell(priceMatch)="data">
                            {{
                                0 >= data.item.priceMatch
                                    ? TruncateToDecimals2(
                                          data.item.priceMatch,

                                          '',
                                          5,
                                      )
                                    : toPlainString(data.item.priceMatch)
                            }}
                        </template>
                        <template #cell(fee)="data">
                            {{ TruncateToDecimals2(data.item.fee || 0, '', 5) }}
                        </template>
                        <template #cell(#)="data">
                            {{
                                TruncateToDecimals2(
                                    data.item.priceMatch *
                                        data.item.amountMatch -
                                        data.item.fee,
                                    '',
                                    5,
                                )
                            }}
                        </template>
                        <template #cell(type)="data">
                            <span
                                class="badge px-2 py-1"
                                :class="
                                    data.item.type === 'buy'
                                        ? 'badge-success'
                                        : 'badge-danger'
                                "
                                style="text-transform: capitalize"
                            >
                                {{ data.item.type }}
                            </span>
                        </template>
                        <template #cell(status)="data">
                            <span
                                class="badge"
                                :class="
                                    data.item.status === 1
                                        ? 'badge-success'
                                        : data.item.status === 0
                                        ? 'badge-warning'
                                        : 'badge-danger'
                                "
                            >
                                {{
                                    data.item.status === 1
                                        ? 'Success'
                                        : data.item.status === 0
                                        ? 'Order'
                                        : 'Canceled'
                                }}
                            </span>
                        </template>
                        <template #cell(time)="data">
                            {{ getDateTime3(data.item.time) }}
                        </template>
                    </b-table>
                    <b-table
                        v-else
                        striped
                        hover
                        :items="CloseHistory"
                        :fields="fieldMB2"
                        show-empty
                        thead-class="customer-header"
                    >
                        <template #empty>
                            <h4 class="text-center my-3">No Data</h4>
                        </template>
                        <template #cell(currency)="data">
                            {{ coinActive(data.item.currency) }}
                        </template>
                        <template #cell(amountMatch)="data">
                            {{
                                TruncateToDecimals2(
                                    data.item.amountMatch,
                                    '',
                                    5,
                                )
                            }}
                        </template>
                        <template #cell(priceMatch)="data">
                            {{
                                0 >= data.item.priceMatch
                                    ? TruncateToDecimals2(
                                          data.item.priceMatch,
                                          '',
                                          5,
                                      )
                                    : toPlainString(data.item.priceMatch)
                            }}
                        </template>
                        <template #cell(#)="row">
                            <b-button
                                size="sm"
                                variant="info"
                                @click="row.toggleDetails"
                                class="px-2 py-0"
                            >
                                {{ row.detailsShowing ? 'Hide' : 'Show' }}
                            </b-button>
                        </template>
                        <template #row-details="row">
                            <div>
                                <div
                                    class="group-item d-flex justify-content-between align-items-center"
                                    v-for="field in fieldClose"
                                    :key="field.key"
                                >
                                    <div class="left">{{ field.label }}:</div>
                                    <div
                                        class="right"
                                        v-if="field.key == 'status'"
                                    >
                                        <span
                                            class="badge"
                                            :class="
                                                row.item.status === 1
                                                    ? 'badge-success'
                                                    : row.item.status === 0
                                                    ? 'badge-warning'
                                                    : 'badge-danger'
                                            "
                                        >
                                            {{
                                                row.item.status === 1
                                                    ? 'Success'
                                                    : row.item.status === 0
                                                    ? 'Order'
                                                    : 'Canceled'
                                            }}
                                        </span>
                                    </div>
                                    <div
                                        class="right"
                                        v-else-if="field.key == 'type'"
                                    >
                                        <span
                                            class="badge"
                                            :class="
                                                row.item.type === 'buy'
                                                    ? 'badge-success'
                                                    : 'badge-danger'
                                            "
                                            style="text-transform: capitalize"
                                        >
                                            {{ row.item.type }}
                                        </span>
                                    </div>
                                    <div
                                        class="right"
                                        v-else-if="field.key == 'currency'"
                                    >
                                        {{ coin.item }}
                                    </div>
                                    <div
                                        class="right"
                                        v-else-if="field.key == 'time'"
                                    >
                                        {{ getDateTime3(row.item[field.key]) }}
                                    </div>
                                    <div
                                        class="right"
                                        v-else-if="field.key == '#'"
                                    >
                                        {{
                                            TruncateToDecimals2(
                                                row.item.priceMatch *
                                                    row.item.amountMatch -
                                                    row.item.fee,
                                                '',
                                                5,
                                            )
                                        }}
                                    </div>
                                    <div class="right" v-else>
                                        {{
                                            row.item[field.key] >= 1
                                                ? TruncateToDecimals2(
                                                      row.item[field.key] || 0,
                                                      '',
                                                      3,
                                                  )
                                                : toPlainString(
                                                      row.item[field.key] || 0,
                                                  )
                                        }}
                                    </div>
                                </div>
                            </div>
                        </template>
                    </b-table>
                </div>
                <div class="table-paginate" v-if="tableActive == 'isOpen'">
                    <Paginate
                        @current="onOpenChange"
                        :totalPages="open.total"
                    />
                </div>
                <div class="table-paginate" v-if="tableActive == 'isClose'">
                    <Paginate
                        @current="onCloseChange"
                        :totalPages="close.total"
                    />
                </div>
            </div>
        </div>
        <b-modal
            id="confirm-cancel-order"
            title="Cancel Order"
            hide-footer
            centered
        >
            <div>
                <p class="mb-0 text-confirm-1">
                    Do you want cancel order {{ itemPick.type }} ?
                </p>
                <p class="mb-0 text-confirm-2">
                    Please click "confirm" when you cancel
                    {{
                        itemPick.type === 'sell'
                            ? 'the order sell'
                            : 'your order'
                    }}
                    or close the popup click "close".
                </p>
            </div>
            <div
                class="d-flex mt-5 flex-wrap justify-content-around align-items-center"
            >
                <button class="mx-auto my-2 confirm botan" @click="onCancel()">
                    Confirm
                </button>
                <button
                    class="mx-auto my-2 botan"
                    @click="$bvModal.hide('confirm-cancel-order')"
                >
                    Close
                </button>
            </div>
        </b-modal>
    </div>
</template>

<style lang="scss">
.text-confirm-1 {
    font-size: 1.4em;
    color: #fff;

    text-align: center;
    margin-top: 10px;
}
.text-confirm-2 {
    font-size: 1.2em;
    color: darkorange;

    text-align: center;
    margin-top: 10px;
}
.history-tr {
    min-height: 100px;
    background-color: #0f172a;
    border-radius: 22px;
    width: 100%;
    .table-responsive2 {
        @media (max-width: 767px) {
            max-height: 450px;
            min-height: 200px;
            overflow-x: hidden;
            overflow-y: auto;
            width: auto;
            padding: 0 15px !important;
        }
    }
    .history-tr-header {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        margin-bottom: 10px;
        position: relative;
        &::after {
            content: '';
            flex-grow: 1;
            border-bottom: 1px solid #ffffff75;
        }
        &-item {
            color: #fff;
            height: 40px;
            display: flex;
            font-weight: 600;
            box-shadow: none;
            border-radius: 0;
            margin: 0;
            border: 0;
            border-bottom: 1px solid #ffffff71;
            max-width: 250px;
            width: auto;
            text-align: center;
            justify-content: center;
            font-size: 15px;
            padding: 10px 15px;
            letter-spacing: 1px;

            align-items: center;
            &.active {
                color: #00ffff;
                background: rgba(255, 255, 255, 0.1);
                border: 1px solid rgba(255, 255, 255, 0.521);
                border-radius: 5px 5px 0 0;
                border-bottom: 0;
            }
            @media (max-width: 480px) {
                font-size: 12px;
            }
        }
    }
    .cancel-ordered {
        font-size: 11px;
    }
    table {
        color: #fff;
        th,
        td {
            min-width: unset !important;
        }
        tr {
            th {
                background-color: rgba(255, 255, 255, 0.08) !important;
                border: 1px solid rgba(255, 255, 255, 0.521);
                border-left: 0;
                border-right: 0;
            }
        }
    }
    .group-item {
        border-bottom: 1px solid rgba(255, 255, 255, 0.19);
        padding: 5px;
    }
    .table-hover tbody tr:hover {
        color: #0ff;
        background-color: rgba(255, 255, 255, 0.08) !important;
    }
    .table-paginate {
        margin: 15px auto;
        .paginate {
            justify-content: center;
        }
    }
}
@media (max-width: 575px) {
    .history-tr {
        .history-tr-body {
            .table-responsive2 {
                thead {
                    tr {
                        th {
                            font-size: 12px;
                            padding: 20px 5px;
                        }
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss" scoped>
.botan {
    border-radius: 100vw;
    padding: 5px 20px;
    border: 2px solid #1478b0;
    color: #1478b0;
    &.confirm {
        background-color: #1478b0;
        color: #fff;
        border: none;
    }
}
</style>
