<template>
  <main class="node-view">
    <div class="home">
      <div class="home__container">
        <div class="home__content">
          <div class="home__content--header">
            <h2 class="heading__highlight">{{ $t('benefitOf') }}</h2>
            <h1 class="heading__primary">master XNode</h1>
          </div>
          <div class="home__content--paragraphs">
            <p class="text__primary">
              {{ $t('masterHomeText01') }}
            </p>

            <p class="text__primary">
              {{ $t('masterHomeText02') }}
            </p>
          </div>

          <div>
            <b-button id="show-btn" @click="$bvModal.show('bv-modal-form')"
              class="btn__primary btn__primary--icon shadow">
              <img src="../../assets/images/icons/arrows-join.svg" />
              <p class="text__primary">{{ $t('joinNow') }}</p>
            </b-button>

            <b-modal id="bv-modal-form" size="lg" centered hide-footer hide-header class="custom-modal">
              <div class="close-container">
                <button @click="$bvModal.hide('bv-modal-form')">
                  <img src="../../assets/images/icons/close.svg" alt="Decoration" />
                </button>
              </div>
              <div class="custom-modal__body">
                <div class="home__content--heading">
                  <h1 class="heading__primary--form text-white">
                    {{ $t('joinWithUs') }}
                  </h1>
                </div>

                <form ref="form" @submit.stop.prevent="handleSubmit" class="custom-form">
                  <b-form-group :label="$t('email')" label-for="email-input" invalid-feedback="Email is required"
                    :state="emailState">
                    <b-form-input id="email-input" v-model="email" :state="emailState"
                      :placeholder="$t('inputYourEmail')" required></b-form-input>
                  </b-form-group>

                  <b-form-group :label="$t('phoneNumber')" label-for="phone-input"
                    invalid-feedback="Phone number is required" :state="phoneState">
                    <b-form-input id="phone-input" v-model="phone" :state="phoneState"
                      :placeholder="$t('inputYourPhone')" required></b-form-input>
                  </b-form-group>

                  <b-form-group :label="$t('address')" label-for="address-input" invalid-feedback="Address is required"
                    :state="addressState">
                    <b-form-input id="address-input" v-model="address" :state="addressState"
                      :placeholder="$t('inputYourAddress')" required></b-form-input>
                  </b-form-group>

                  <b-form-group :label="$t('note')" label-for="note-input" invalid-feedback="Note is required"
                    :state="noteState">
                    <b-form-textarea id="note-input" v-model="note" :state="noteState"
                      :placeholder="$t('leaveSomethingYouWantToNote')" rows="4" max-rows="6"></b-form-textarea>
                  </b-form-group>

                  <div class="custom-form__btn-container">
                    <b-button type="submit" class="btn__primary mt-20 w-75">{{ $t('submit') }}</b-button>
                  </div>
                </form>
              </div>

              <div class="decoration__form--cube11">
                <img src="../../assets/images/background/cube-background-11.png" alt="Decoration" />
              </div>

              <div class="decoration__form--cube12">
                <img src="../../assets/images/background/cube-background-12.png" alt="Decoration" />
              </div>

              <div class="decoration__form--cube13">
                <img src="../../assets/images/background/cube-background-13.png" alt="Decoration" />
              </div>

              <div class="decoration__form--cube14">
                <img src="../../assets/images/background/cube-background-14.png" alt="Decoration" />
              </div>
            </b-modal>
          </div>

          <div class="decoration__home--cube07">
            <img src="../../assets/images/background/cube-background-7.png" alt="Decoration" />
          </div>

          <div class="decoration__home--cube08">
            <img src="../../assets/images/background/cube-background-8.png" alt="Decoration" />
          </div>

          <div class="decoration__home--cube09">
            <img src="../../assets/images/background/cube-background-9.png" alt="Decoration" />
          </div>
        </div>
        <div class="home__images">
          <img src="../../assets/images/background/master-node-benefit.png" alt="Master Node"
            class="home__images--item" />
        </div>
      </div>

      <div class="decoration__home--left">
        <img src="../../assets/images/background/ellipse-287.svg" alt="Decoration" />
      </div>

      <div class="decoration__home--top">
        <img src="../../assets/images/background/ellipse-286.svg" alt="Decoration" />
      </div>

      <div class="decoration__home--right">
        <img src="../../assets/images/background/ellipse-288.svg" alt="Decoration" />
      </div>

      <b-modal id="bv-modal-success" size="lg" hide-footer hide-header centered>
        <div class="modal-success">
          <img src="../../assets/images/icons/success.svg" alt="Decoration" />

          <h2>Successfull!</h2>

          <p>You have sent your registration information</p>
        </div>
      </b-modal>
    </div>

    <div class="benefit">
      <div class="benefit__container">
        <div class="benefit__header">
          <h1 class="heading__primary heading__decoration">
            {{ $t('benefitsOfMasterXNode') }}
          </h1>
        </div>

        <div class="benefit__body">
          <div class="benefit__content" v-if="!isSmallDesktop">
            <div data-aos="fade-up" data-aos-duration="4000">
              <img src="../../assets/images/background/xin-rocket.png" class="benefit__image" />
            </div>

            <div class="benefit__tree--right-01">
              <div class="benefit__tree--right-02">
                <div class="benefit__tree--right-03">
                  <div class="benefit__phase--right">
                    <div class="benefit__phase" data-aos="fade-left" data-aos-offset="300"
                      data-aos-easing="ease-in-sine">
                      <p>
                        {{ $t('benefitOfContent05') }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="benefit__tree--right-04">
                  <div class="benefit__phase--right">
                    <div class="benefit__phase " data-aos="fade-left" data-aos-offset="300"
                      data-aos-easing="ease-in-sine">
                      <p>
                        {{ $t('benefitOfContent06') }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="benefit__tree--right-05">
                  <div class="benefit__phase--right">
                    <div class="benefit__phase " data-aos="fade-left" data-aos-offset="300"
                      data-aos-easing="ease-in-sine">
                      <p>
                        {{ $t('benefitOfContent07') }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="benefit__phase--end-right">
                  <div class="benefit__phase " data-aos="fade-left" data-aos-offset="300"
                    data-aos-easing="ease-in-sine">
                    <p>
                      {{ $t('benefitOfContent08') }}
                    </p>
                  </div>
                </div>
              </div>

            </div>

            <div class="benefit__tree--left-01">
              <div class="benefit__tree--left-02">
                <div class="benefit__tree--left-03">
                  <div class="benefit__phase--left">
                    <div class="benefit__phase" data-aos="fade-right" data-aos-offset="300"
                      data-aos-easing="ease-in-sine">
                      <p>
                        {{ $t('benefitOfContent01') }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="benefit__tree--left-04">
                  <div class="benefit__phase--left">
                    <div class="benefit__phase" data-aos="fade-right" data-aos-offset="300"
                      data-aos-easing="ease-in-sine">
                      <p>
                        {{ $t('benefitOfContent02') }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="benefit__tree--left-05">
                  <div class="benefit__phase--left">
                    <div class="benefit__phase" data-aos="fade-right" data-aos-offset="300"
                      data-aos-easing="ease-in-sine">
                      <p>
                        {{ $t('benefitOfContent03') }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="benefit__phase--end-left">
                  <div class="benefit__phase" data-aos="fade-right" data-aos-offset="300"
                    data-aos-easing="ease-in-sine">
                    <p>
                      {{ $t('benefitOfContent04') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="benefit__content--vertical" v-if="isSmallDesktop">
            <div data-aos="fade-up">
              <img src="../../assets/images/background/xin-rocket.png" />
            </div>


            <div class="benefit__phase--even">
              <div class="benefit__phase" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"
                data-aos-duration="1000">
                <p>
                  Exclusive rights to distribute mining nodes by region.
                </p>
              </div>
            </div>

            <div class="benefit__phase--odd">
              <div class="benefit__phase " data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine"
                data-aos-duration="1000">
                <p>
                  Enjoy 70% of the revenue from the system's mining fees for users in that Master Node system.
                </p>
              </div>
            </div>

            <div class="benefit__phase--even">
              <div class="benefit__phase " data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"
                data-aos-duration="1000">
                <p>
                  Offer a 10% commission directly on the mining node package value.
                </p>
              </div>
            </div>

            <div class="benefit__phase--odd">
              <div class="benefit__phase " data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine"
                data-aos-duration="1000">
                <p>
                  Reach 300,000 USDT in revenue in 3 months and receive a 100,000 USDT refund.
                </p>
              </div>
            </div>

            <div class="benefit__phase--even">
              <div class="benefit__phase " data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"
                data-aos-duration="1000">
                <p>
                  Receive facilities with brand identity (HUB coffee) worth 30,000 USDT.
                </p>
              </div>
            </div>

            <div class="benefit__phase--odd">
              <div class="benefit__phase " data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine"
                data-aos-duration="1000">
                <p>
                  Design human resource models and consult locations.
                </p>
              </div>
            </div>

            <div class="benefit__phase--even">
              <div class="benefit__phase " data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"
                data-aos-duration="1000">
                <p>
                  Support marketing on Social Channels and according to system plans.
                </p>
              </div>
            </div>

            <div class="benefit__phase--odd">
              <div class="benefit__phase " data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine"
                data-aos-duration="1000">
                <p>
                  Supports 6 mining nodes worth 5,000 USDT.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="condition">
      <div class="condition__container">
        <div class="condition__header">
          <h1 class="heading__primary">
            {{ $t('conditionForJoin') }}
          </h1>
        </div>

        <div class="condition__body">
          <div class="condition__content">
            <div class="accordion" role="tablist" data-aos="zoom-in-right" data-aos-easing="ease-in-out"
              data-aos-delay="300" data-aos-offset="0" data-aos-duration="2000">
              <b-card no-body class="mb-3">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.accordion-1 :class="{
                    'btn-secondary__active':
                      isCondition01,
                  }">
                    <div class="btn__container">
                      <p :class="{
                        'text-active':
                          isCondition01,
                      }">
                        {{ $t('conditionHeading01') }}
                      </p>

                      <img v-if="!isCondition01" src="../../assets/images/icons/arrow-condition-right.svg"
                        alt="Decoration" />

                      <img v-else src="../../assets/images/icons/arrow-condition-down.svg" alt="Decoration" />
                    </div>
                  </b-button>
                </b-card-header>
                <b-collapse id="accordion-1" v-model="isCondition01" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <!-- <b-card-text>{{ isCondition01 }} {{ isCondition02 }} {{ isCondition03 }}</b-card-text> -->
                    <b-card-text>
                      {{ $t('conditionContent01') }}
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-3">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.accordion-2 :class="{
                    'btn-secondary__active':
                      isCondition02,
                  }">
                    <div class="btn__container">
                      <p :class="{
                        'text-active':
                          isCondition02,
                      }">
                        {{ $t('conditionHeading02') }}
                      </p>

                      <img v-if="!isCondition02" src="../../assets/images/icons/arrow-condition-right.svg"
                        alt="Decoration" />

                      <img v-else src="../../assets/images/icons/arrow-condition-down.svg" alt="Decoration" />
                    </div>
                  </b-button>
                </b-card-header>
                <b-collapse id="accordion-2" v-model="isCondition02" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <!-- <b-card-text>{{ isCondition01 }} {{ isCondition02 }} {{ isCondition03 }}</b-card-text> -->
                    <b-card-text>
                      {{ $t('conditionContent02') }}
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-3">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.accordion-3 :class="{
                    'btn-secondary__active':
                      isCondition03,
                  }">
                    <div class="btn__container">
                      <p :class="{
                        'text-active':
                          isCondition03,
                      }">
                        {{ $t('conditionHeading03') }}
                      </p>

                      <img v-if="!isCondition03" src="../../assets/images/icons/arrow-condition-right.svg"
                        alt="Decoration" />

                      <img v-else src="../../assets/images/icons/arrow-condition-down.svg" alt="Decoration" />
                    </div>
                  </b-button>
                </b-card-header>
                <b-collapse id="accordion-3" v-model="isCondition03" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <!-- <b-card-text>{{ isCondition01 }} {{ isCondition02 }} {{ isCondition03 }}</b-card-text> -->
                    <b-card-text>
                      {{ $t('conditionContent03') }}
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>

          <div class="condition__images" data-aos="zoom-in-left" data-aos-easing="ease-in-out" data-aos-delay="300"
            data-aos-offset="0" data-aos-duration="2000">
            <img v-if="isCondition01" src="../../assets/images/background/condition1.png" alt="Condition1" />

            <img v-if="isCondition02" src="../../assets/images/background/condition2.png" alt="Condition2" />

            <img v-if="isCondition03" src="../../assets/images/background/condition3.png" alt="Condition3" />
          </div>
        </div>
      </div>

      <div class="decoration__condition--ellipse289">
        <img src="../../assets/images/background/ellipse-289.svg" alt="Decoration" />
      </div>
    </div>

    <div class="roadmap">
      <div class="roadmap__container">
        <div class="roadmap__header">
          <h1 class="heading__primary heading__decoration">
            {{ $t('ourRoadmap') }}
          </h1>
        </div>

        <div class="roadmap__content">
          <div class="roadmap__map">
            <div class="map__line" v-if="!isSmallScreen">
              <div class="map__dot map__dot--01">
                <div class="map__arrow map__arrow--down">
                  <div class="map__phase--01">
                    <div class="map__phase" data-aos="zoom-in-up" data-aos-duration="2000">
                      <div class="particle" v-for="(particle, index) in particles" :key="index"
                        :style="particle.style" />
                      <div class="map__phase--container map__phase--container-down">
                        <h2>
                          {{ $t('roadmapPrimaryHeading01') }}
                        </h2>
                        <h3>
                          {{ $t('roadmapSecondaryHeading01') }}
                        </h3>
                        <ul class="map__phase--list">
                          <li>
                            {{ $t('phase01Content01') }}
                          </li>
                          <li>
                            {{ $t('phase01Content02') }}
                          </li>
                          <li>
                            {{ $t('phase01Content03') }}
                          </li>
                          <li>
                            {{ $t('phase01Content04') }}
                          </li>
                          <li>
                            {{ $t('phase01Content05') }}
                          </li>
                          <li>
                            {{ $t('phase01Content06') }}
                          </li>
                        </ul>
                      </div>

                      <button class="btn__primary map__phase--btn map__phase--btn-top">
                        {{ $t('phase1') }}
                      </button>

                      <div class="particle-container">
                        <div v-for="i in 25" :key="i" class="particle"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="map__dot map__dot--02">
                <div class="map__arrow map__arrow--up">
                  <div class="map__phase--02">
                    <div class="map__phase" data-aos="zoom-in" data-aos-duration="2000">
                      <div class="particle" v-for="(particle, index) in particles" :key="index"
                        :style="particle.style" />
                      <div class="map__phase--container map__phase--container-up">
                        <h2>
                          {{ $t('roadmapPrimaryHeading01') }}
                        </h2>
                        <h3>
                          {{ $t('roadmapSecondaryHeading02') }}
                        </h3>
                        <ul class="map__phase--list">
                          <li>
                            {{ $t('phase02Content01') }}
                          </li>
                          <li>
                            {{ $t('phase02Content02') }}
                          </li>
                          <li>
                            {{ $t('phase02Content03') }}
                            <ul class="map__phase--list">
                              <li>
                                {{ $t('phase02Content04') }}
                              </li>
                              <li>
                                {{ $t('phase02Content05') }}
                              </li>
                            </ul>
                          </li>
                          <li>
                            {{ $t('phase02Content06') }}
                          </li>
                        </ul>
                      </div>

                      <button class="btn__primary map__phase--btn map__phase--btn-bottom">
                        {{ $t('phase2') }}
                      </button>

                      <div class="particle-container">
                        <div v-for="i in 25" :key="i" class="particle"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="map__dot map__dot--03">
                <div class="map__arrow map__arrow--down">
                  <div class="map__phase--03">
                    <div class="map__phase" data-aos="zoom-in-up" data-aos-duration="2000">
                      <div class="particle" v-for="(particle, index) in particles" :key="index"
                        :style="particle.style" />
                      <div class="map__phase--container map__phase--container-down">
                        <h2>
                          {{ $t('roadmapPrimaryHeading01') }}
                        </h2>
                        <h3>
                          {{ $t('roadmapSecondaryHeading03') }}
                        </h3>
                        <ul class="map__phase--list">
                          <li>
                            {{ $t('phase03Content01') }}
                          </li>
                          <li>
                            {{ $t('phase03Content02') }}
                          </li>
                          <li>
                            {{ $t('phase03Content03') }}
                          </li>
                          <li>
                            {{ $t('phase03Content04') }}
                          </li>
                          <li>
                            {{ $t('phase03Content05') }}
                          </li>
                          <li>
                            {{ $t('phase03Content06') }}
                          </li>
                        </ul>
                      </div>

                      <button class="btn__primary map__phase--btn map__phase--btn-top">
                        {{ $t('phase3') }}
                      </button>

                      <div class="particle-container">
                        <div v-for="i in 25" :key="i" class="particle"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="map__dot map__dot--04">
                <div class="map__arrow map__arrow--up">
                  <div class="map__phase--04">
                    <div class="map__phase" data-aos="zoom-in" data-aos-duration="2000">
                      <div class="particle" v-for="(particle, index) in particles" :key="index"
                        :style="particle.style" />
                      <div class="map__phase--container map__phase--container-up">
                        <h2>
                          {{ $t('roadmapPrimaryHeading01')}}
                        </h2>
                        <h3>
                          {{ $t('roadmapSecondaryHeading04') }}
                        </h3>
                        <ul class="map__phase--list">
                          <li>
                            {{ $t('phase04Content01') }}
                          </li>
                          <li>
                            {{ $t('phase04Content02') }}
                          </li>
                          <li>
                            {{ $t('phase04Content03') }}
                            <ul class="map__phase--list">
                              <li>
                                {{ $t('phase04Content04') }}
                              </li>
                              <li>
                                {{ $t('phase04Content05') }}
                              </li>
                              <li>
                                {{ $t('phase04Content06') }}
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>

                      <button class="btn__primary map__phase--btn map__phase--btn-bottom">
                        {{ $t('phase4') }}
                      </button>

                      <div class="particle-container">
                        <div v-for="i in 25" :key="i" class="particle"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="map-vertical" v-if="isSmallScreen">
              <div class="map-vertical__line">
                <div class="map-vertical__dot map-vertical__dot--01">
                  <div class="map-vertical__arrow map-vertical__arrow--down">
                    <div class="map-vertical__phase--01">
                      <div class="map-vertical__phase" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine" data-aos-duration="1000">

                        <div class="particle" v-for="(particle, index) in particles" :key="index"
                          :style="particle.style" />
                        <div class="map-vertical__phase--container map-vertical__phase--container-down">
                          <h2>
                            {{ $t('roadmapPrimaryHeading01') }}
                          </h2>
                          <h3>
                            {{ $t('roadmapSecondaryHeading01') }}
                          </h3>
                          <ul class="map__phase--list">
                            <li>
                              {{ $t('phase01Content01') }}
                            </li>
                            <li>
                              {{ $t('phase01Content02') }}
                            </li>
                            <li>
                              {{ $t('phase01Content03') }}
                            </li>
                            <li>
                              {{ $t('phase01Content04') }}
                            </li>
                            <li>
                              {{ $t('phase01Content05') }}
                            </li>
                            <li>
                              {{ $t('phase01Content06') }}
                            </li>
                          </ul>
                        </div>
                        <button class="btn__primary map__phase--btn map__phase--btn-top">
                          {{ $t('phase1') }}
                        </button>

                        <div class="particle-container">
                          <div v-for="i in 25" :key="i" class="particle"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="map-vertical__dot map-vertical__dot--02">

                  <div class="particle" v-for="(particle, index) in particles" :key="index" :style="particle.style" />
                  <div class="map-vertical__arrow map-vertical__arrow--down">
                    <div class="map-vertical__phase--02">
                      <div class="map-vertical__phase" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine" data-aos-duration="1000">
                        <div class="map-vertical__phase--container map-vertical__phase--container-down">
                          <h2>
                            {{ $t('roadmapPrimaryHeading02') }}
                          </h2>
                          <h3>
                            {{ $t('roadmapSecondaryHeading02') }}
                          </h3>
                          <ul class="map__phase--list">
                            <li>
                              {{ $t('phase02Content01') }}
                            </li>
                            <li>
                              {{ $t('phase02Content02') }}
                            </li>
                            <li>
                              {{ $t('phase02Content03') }}
                              <ul class="map__phase--list">
                                <li>
                                  {{ $t('phase02Content04') }}
                                </li>
                                <li>
                                  {{ $t('phase02Content05') }}
                                </li>
                              </ul>
                            </li>
                            <li>
                              {{ $t('phase02Content06') }}
                            </li>
                          </ul>
                        </div>
                        <button class="btn__primary map__phase--btn map__phase--btn-top">
                          {{ $t('phase2') }}
                        </button>

                        <div class="particle-container">
                          <div v-for="i in 25" :key="i" class="particle"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="map-vertical__dot map-vertical__dot--03">

                  <div class="particle" v-for="(particle, index) in particles" :key="index" :style="particle.style" />
                  <div class="map-vertical__arrow map-vertical__arrow--down">
                    <div class="map-vertical__phase--03">
                      <div class="map-vertical__phase" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine" data-aos-duration="1000">
                        <div class="map-vertical__phase--container map-vertical__phase--container-down">
                          <h2>
                            {{ $t('roadmapPrimaryHeading03') }}
                          </h2>
                          <h3>
                            {{ $t('roadmapSecondaryHeading03') }}
                          </h3>
                          <ul class="map__phase--list">
                            <li>
                              {{ $t('phase03Content01') }}
                            </li>
                            <li>
                              {{ $t('phase03Content02') }}
                            </li>
                            <li>
                              {{ $t('phase03Content03') }}
                            </li>
                            <li>
                              {{ $t('phase03Content04') }}
                            </li>
                            <li>
                              {{ $t('phase03Content05') }}
                            </li>
                            <li>
                              {{ $t('phase03Content06') }}
                            </li>
                          </ul>
                        </div>
                        <button class="btn__primary map__phase--btn map__phase--btn-top">
                          {{ $t('phase3') }}
                        </button>

                        <div class="particle-container">
                          <div v-for="i in 25" :key="i" class="particle"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="map-vertical__dot map-vertical__dot--04">
                  <div class="particle" v-for="(particle, index) in particles" :key="index" :style="particle.style" />
                  <div class="map-vertical__arrow map-vertical__arrow--down">
                    <div class="map-vertical__phase--04">
                      <div class="map-vertical__phase" data-aos="fade-left" data-aos-offset="300"
                        data-aos-easing="ease-in-sine" data-aos-duration="1000">
                        <div class="map-vertical__phase--container map-vertical__phase--container-down">
                          <h2>
                            {{ $t('roadmapPrimaryHeading04') }}
                          </h2>
                          <h3>
                            {{ $t('roadmapSecondaryHeading04') }}
                          </h3>
                          <ul class="map__phase--list">
                            <li>
                              {{ $t('phase04Content01') }}
                            </li>
                            <li>
                              {{ $t('phase04Content02') }}
                            </li>
                            <li>
                              {{ $t('phase04Content03') }}
                              <ul class="map__phase--list">
                                <li>
                                  {{ $t('phase04Content04') }}
                                </li>
                                <li>
                                  {{ $t('phase04Content05') }}
                                </li>
                                <li>
                                  {{ $t('phase04Content06') }}
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                        <button class="btn__primary map__phase--btn map__phase--btn-top">
                          {{ $t('phase4') }}
                        </button>

                        <div class="particle-container">
                          <div v-for="i in 25" :key="i" class="particle"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="decoration__roadmap--ellipse290">
        <img src="../../assets/images/background/ellipse-290.png" alt="Decoration" />
      </div>
    </div>

    <div class="periodic">
      <div class="periodic__container">
        <div class="periodic__header">
          <h1 class="heading__primary heading__decoration">
            {{ $t('periodicActivities') }}
          </h1>
        </div>

        <div class="periodic__body">
          <div class="periodic__item" @mouseover="isPeriodic01Hover = true" @mouseleave="isPeriodic01Hover = false"
            data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="1000">
            <div class="periodic__item--image">
              <img src="../../assets/images/background/element1-new.png"
                class="periodic__item--image-1" />

              <!-- <img v-show="isPeriodic01Hover" src="../../assets/images/background/element1_active.png"
                class="periodic__item--image-1-active" /> -->

              <img v-if="!isPeriodic01Hover" src="../../assets/images/background/ellipse-273.png"
                class="decoration__periodic--ellipse273" />

              <img v-if="!isPeriodic01Hover" src="../../assets/images/background/ellipse-272.png"
                class="decoration__periodic--ellipse272" />

              <img v-if="!isPeriodic01Hover" src="../../assets/images/background/ellipse-271.png"
                class="decoration__periodic--ellipse271" />

              <img v-if="isPeriodic01Hover" src="../../assets/images/background/ellipse-273-active.png"
                class="decoration__periodic--active-273" />

              <img v-if="isPeriodic01Hover" src="../../assets/images/background/ellipse-272-active.png"
                class="decoration__periodic--active-272" />

              <img v-if="isPeriodic01Hover" src="../../assets/images/background/ellipse-271-active.png"
                class="decoration__periodic--active-271" />

              <img v-if="isPeriodic01Hover" src="../../assets/images/background/ellipse-274-active.png"
                class="decoration__periodic--active-274" />
            </div>

            <div class="periodic__item--content">
              <h2 :class="{
                'periodic__item--content-active':
                  isPeriodic01Hover,
              }">
                {{ $t('periodicHeading01') }}
              </h2>
              <p>
                {{ $t('periodicContent01') }}
              </p>
            </div>
          </div>

          <div class="periodic__item" @mouseover="isPeriodic02Hover = true" @mouseleave="isPeriodic02Hover = false"
            data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="1000">
            <div class="periodic__item--image">
              <img src="../../assets/images/background/element2-new.png"
                class="periodic__item--image-2" />

              <!-- <img v-show="isPeriodic02Hover" src="../../assets/images/background/element2_active.png"
                class="periodic__item--image-2-active" /> -->

              <img v-show="!isPeriodic02Hover" src="../../assets/images/background/ellipse-273.png"
                class="decoration__periodic--ellipse273" />

              <img v-show="!isPeriodic02Hover" src="../../assets/images/background/ellipse-272.png"
                class="decoration__periodic--ellipse272" />

              <img v-show="!isPeriodic02Hover" src="../../assets/images/background/ellipse-271.png"
                class="decoration__periodic--ellipse271" />

              <!-- <img v-show="isPeriodic02Hover" src="../../assets/images/background/ellipse-273-active.png"
                class="decoration__periodic--active-273" /> -->

              <img v-show="isPeriodic02Hover" src="../../assets/images/background/ellipse-272-active.png"
                class="decoration__periodic--active-272" />

              <img v-show="isPeriodic02Hover" src="../../assets/images/background/ellipse-271-active.png"
                class="decoration__periodic--active-271" />

              <img v-show="isPeriodic02Hover" src="../../assets/images/background/ellipse-274-active.png"
                class="decoration__periodic--active-274" />
            </div>

            <div class="periodic__item--content">
              <h2 :class="{
                'periodic__item--content-active':
                  isPeriodic02Hover,
              }">
                {{ $t('periodicHeading02') }}
              </h2>
              <p>
                {{ $t('periodicContent02') }}
              </p>
            </div>
          </div>

          <div class="periodic__item" @mouseover="isPeriodic03Hover = true" @mouseleave="isPeriodic03Hover = false"
            data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="1000">
            <div class="periodic__item--image">
              <img src="../../assets/images/background/element3-new.png"
                class="periodic__item--image-3" />

              <!-- <img v-show="isPeriodic03Hover" src="../../assets/images/background/element3_active.png"
                class="periodic__item--image-3-active" /> -->

              <img v-show="!isPeriodic03Hover" src="../../assets/images/background/ellipse-273.png"
                class="decoration__periodic--ellipse273" />

              <img v-show="!isPeriodic03Hover" src="../../assets/images/background/ellipse-272.png"
                class="decoration__periodic--ellipse272" />

              <img v-show="!isPeriodic03Hover" src="../../assets/images/background/ellipse-271.png"
                class="decoration__periodic--ellipse271" />

              <!-- <img v-show="isPeriodic03Hover" src="../../assets/images/background/ellipse-273-active.png"
                class="decoration__periodic--active-273" /> -->

              <img v-show="isPeriodic03Hover" src="../../assets/images/background/ellipse-272-active.png"
                class="decoration__periodic--active-272" />

              <img v-show="isPeriodic03Hover" src="../../assets/images/background/ellipse-271-active.png"
                class="decoration__periodic--active-271" />

              <img v-show="isPeriodic03Hover" src="../../assets/images/background/ellipse-274-active.png"
                class="decoration__periodic--active-274" />
            </div>

            <div class="periodic__item--content">
              <h2 :class="{
                'periodic__item--content-active':
                  isPeriodic03Hover,
              }">
                {{ $t('periodicHeading03') }}
              </h2>
              <p>
                {{ $t('periodicContent03') }}
              </p>
            </div>
          </div>

          <div class="periodic__item" @mouseover="isPeriodic04Hover = true" @mouseleave="isPeriodic04Hover = false"
            data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="1000">
            <div class="periodic__item--image">
              <img src="../../assets/images/background/element4-new.png"
                class="periodic__item--image-4" />

              <!-- <img v-show="isPeriodic04Hover" src="../../assets/images/background/element4_active.png"
                class="periodic__item--image-4-active" /> -->

              <img v-show="!isPeriodic04Hover" src="../../assets/images/background/ellipse-273.png"
                class="decoration__periodic--ellipse273" />

              <img v-show="!isPeriodic04Hover" src="../../assets/images/background/ellipse-272.png"
                class="decoration__periodic--ellipse272" />

              <img v-show="!isPeriodic04Hover" src="../../assets/images/background/ellipse-271.png"
                class="decoration__periodic--ellipse271" />

              <!-- <img v-show="isPeriodic04Hover" src="../../assets/images/background/ellipse-273-active.png"
                class="decoration__periodic--active-273" /> -->

              <img v-show="isPeriodic04Hover" src="../../assets/images/background/ellipse-272-active.png"
                class="decoration__periodic--active-272" />

              <img v-show="isPeriodic04Hover" src="../../assets/images/background/ellipse-271-active.png"
                class="decoration__periodic--active-271" />

              <img v-show="isPeriodic04Hover" src="../../assets/images/background/ellipse-274-active.png"
                class="decoration__periodic--active-274" />
            </div>

            <div class="periodic__item--content">
              <h2 :class="{
                'periodic__item--content-active':
                  isPeriodic04Hover,
              }">
                {{ $t('periodicHeading04') }}
              </h2>
              <p>
                {{ $t('periodicContent04') }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="decoration__periodic--cube10">
        <img src="../../assets/images/background/cube-background-10.png" alt="cube" />
      </div>
    </div>

    <div class="footer">
      <div class="footer__container">
        <div class="footer__content">
          <div class="footer__heading">
            <img src="../../assets/images/logo/beeg.png" />
            <h1 class="footer__text--primary">
              {{ $t('join') }}
              <span class="footer__text--highlight">Master XNode</span>
              {{ $t('now') }}
            </h1>
          </div>
          <div class="footer__join-now">
            <b-button id="show-btn" @click="$bvModal.show('bv-modal-form')"
              class="btn__primary btn__primary--icon shadow">
              <img src="../../assets/images/icons/arrows-join.svg" />
              <p class="text__primary">{{ $t('joinNow') }}</p>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'MasterNodes',
  data() {
    return {
      modalShow: false,
      modalShowSuccess: false,
      name: '',
      nameState: null,
      address: '',
      addressState: null,
      phone: '',
      phoneState: null,
      email: '',
      emailState: null,
      note: '',
      noteState: null,

      text1: 'To become an owner of the Master XNode, you need to invest an amount of 100,000 USDT. This initial investment allows you not only to gain exclusive rights to business and distribution of XIN products and services in the region but also ensures stable income from mining, trading, and sales activities.',
      text2: 'Master XNode owners participate in training courses provided by the company to enhance their understanding of products, services, and to stay updated on new business strategies. Regular monitoring by Master XNode ensures continuous and stable operation in transactions.',
      text3: "Investors must adhere to all legal regulations and business ethics during the operation of the Master XNode. Master XNode owners are encouraged to actively participate in the company's community activities and events to promote bonding and mutual support among members.",
      isCondition01: true,
      isCondition02: false,
      isCondition03: false,

      isSmallScreen: false,
      isSmallDesktop: false,

      isPeriodic01Hover: false,
      isPeriodic02Hover: false,
      isPeriodic03Hover: false,
      isPeriodic04Hover: false,
      particles: [],
    };
  },

  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenSize);
  },

  methods: {
    checkScreenSize() {
      this.isSmallScreen = window.matchMedia(
        '(max-width: 1024px)',
      ).matches;

      this.isSmallDesktop = window.matchMedia(
        '(max-width: 1280px)',
      ).matches;
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.name = '';
      this.nameState = null;
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      if (this.$refs.form.checkValidity()) {
        this.$store
          .dispatch('mining/register_user', {
            email: this.email,
            phone: this.phone,
            address: this.address,
            description: this.note,
          })
          .then((res) => {
            if (res && res.status) {
              this.$bvModal.hide('bv-modal-form');
              this.$bvModal.show('bv-modal-success');
              this.$router.push({ name: 'MasterXNode' })
            }
          })
          .catch(() => { });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.node-view {
  margin-bottom: -100px;
}

.heading__primary {
  font-size: 60px;
  font-weight: 900;
  color: #0087cc;
  text-transform: uppercase;
}

.heading__decoration::after {
  content: '';
  display: block;
  width: 50%;
  height: 3px;
  background-color: #0087cc;
  margin: 2px auto;
}

.heading__highlight {
  font-size: 35px;
  font-weight: 900;
  color: #0087cc;
  text-transform: uppercase;
}

.text__primary {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px;
}

.text-white {
  color: white;
}

.shadow {
  box-shadow: 0px 4px 10px #8fc9e9;
}

.mt-20 {
  margin-top: 20px;
}

.w-75 {
  widows: 75%;
}

.btn__primary {
  background: linear-gradient(to bottom, #0effff, #124de3);
  padding: 16px 56px;
  color: white;
  font-weight: 500;
  font-size: 18px;
  border-radius: 72px;

  &--icon {
    display: flex;
    gap: 8px;
    align-items: center;
  }
}

.close-container {
  position: absolute;
  top: 20px;
  right: 20px;
}

.particle-container {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  z-index: 999;
  overflow: hidden;

  .particle {
    border-radius: 50%;
    z-index: -999;
  }

  @for $i from 1 through 150 {
    @keyframes particle-animation-#{$i} {
      0% {}

      100% {
        transform: translate3d(((random(70) - 35) * 100%),
            (random(100) * -50%),
            (random(100) * 1px));
      }
    }

    .particle:nth-child(#{$i}) {
      animation: particle-animation-#{$i} 5s linear infinite;
      $size: random(5) + 2 + px;
      opacity: 0.5;
      height: $size;
      width: $size;
      animation-delay: -$i * 0.02s;
      position: absolute;
      top: 100%;
      left: random(100) * 1%;
      background: linear-gradient(#25E0F9, #25E0F9);
    }
  }
}


.home {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;

  &__container {
    max-width: 1280px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 64px;
  }

  &__content {
    align-content: center;

    display: grid;
    gap: 32px 0;

    z-index: 10;

    position: relative;

    &--header {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &--paragraphs {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &--heading {
      display: flex;
      justify-content: center;
      margin: 32px 0;
    }
  }

  &__images {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
}

.benefit {
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;

  &__container {
    max-width: 1280px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 64px;
    z-index: 10;
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__body {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &--vertical {
      display: flex;
      flex-direction: column;
      gap: 48px;
      align-items: center;
    }

    img {
      z-index: 10;
    }
  }

  .gradient-border {}

  &__phase {
    --bWidth: 3px;
    /* border width*/
    --bRadius: 92px;
    /* the radius */
    --gradient: linear-gradient(to bottom, #0ec5ff 1.08%, #3964fb 83%);
    --realPadding: 15px;
    padding: 12px 24px;
    position: relative;
    z-index: 0;
    background-color: #5593b5;
    border: none;
    outline: none;
    border-radius: var(--bRadius);
    isolation: isolate;
    transition: 0.4s;
    width: 300px;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      inset: 0;
      padding: var(--bWidth);
      border-radius: inherit;
      background: var(--gradient);
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      inset: 0;
      border-radius: inherit;
      transition: 0.4s;
      background: var(--gradient);
      opacity: 0;
    }

    &.withBG {
      &::after {
        opacity: 1;
      }

      &.withBlur {
        &::before {
          -webkit-mask: none;
          mask: none;
        }

        &::after {
          filter: blur(21px);
        }

        &:disabled {
          &::after {
            filter: none;
          }
        }
      }
    }

    &:hover {
      &::after {
        opacity: 1;
      }
    }

    &:disabled {
      filter: grayscale(80%);
      cursor: not-allowed !important;
    }

    p {
      color: white;
      font-size: 13px;
      font-weight: 600;
      margin-bottom: 0;
      text-align: center;
    }

    &--right {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(14%, -40%)
    }

    &--left {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-14%, -40%)
    }

    &--end {
      &-right {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translate(14%, 46%)
      }

      &-left {
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translate(-14%, 46%)
      }
    }

    &--even {
      transform: translate(-10%, 0);
    }

    &--odd {
      transform: translate(10%, 0);
    }
  }

  &__tree {
    &--right {
      &-01 {
        position: absolute;
        bottom: 5%;
        right: -35%;
        background-color: #6ADAE1;
        height: 3px;
        width: 200px;
        z-index: 9;
      }

      &-02 {
        position: absolute;
        bottom: 0;
        right: 35%;
        background-color: #6ADAE1;
        height: 304px;
        width: 3px;
        z-index: 9;
      }

      &-03 {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #6ADAE1;
        height: 3px;
        width: 72px;
        z-index: 9;
      }

      &-04 {
        position: absolute;
        top: 33%;
        left: 0;
        background-color: #6ADAE1;
        height: 3px;
        width: 72px;
        z-index: 9;
      }

      &-05 {
        position: absolute;
        top: 66%;
        left: 0;
        background-color: #6ADAE1;
        height: 3px;
        width: 72px;
        z-index: 9;
      }
    }

    &--left {
      &-01 {
        position: absolute;
        bottom: 5%;
        left: -32%;
        background-color: #6ADAE1;
        height: 3px;
        width: 200px;
        z-index: 9;
      }

      &-02 {
        position: absolute;
        bottom: 0;
        left: 35%;
        background-color: #6ADAE1;
        height: 304px;
        width: 3px;
        z-index: 9;
      }

      &-03 {
        position: absolute;
        top: 0;
        right: 0;
        background-color: #6ADAE1;
        height: 3px;
        width: 72px;
        z-index: 9;
      }

      &-04 {
        position: absolute;
        top: 33%;
        right: 0;
        background-color: #6ADAE1;
        height: 3px;
        width: 72px;
        z-index: 9;
      }

      &-05 {
        position: absolute;
        top: 66%;
        right: 0;
        background-color: #6ADAE1;
        height: 3px;
        width: 72px;
        z-index: 9;
      }
    }
  }
}

.condition {
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;
  margin-top: 132px;

  &__container {
    max-width: 1280px;
    width: 100%;
    display: grid;
    gap: 72px 0;
    z-index: 10;
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__body {
    display: grid;
    grid-template-columns: 2fr minmax(374px, 1fr);
    gap: 0 32px;
  }

  &__images {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 374px;
      max-height: 300px;
    }
  }
}

.roadmap {
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;
  margin-top: 92px;

  &__container {
    max-width: 1280px;
    width: 100%;
    z-index: 10;

    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__map {
    min-height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.map {
  &__line {
    background-color: #858585;
    width: 94%;
    height: 3px;
    border-radius: 4px;
    position: relative;
    margin: 0 auto;
  }

  &__dot {
    background: linear-gradient(to bottom, #0effff, #124de3);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid #858585;

    &--01 {
      position: absolute;
      top: 0;
      left: 10%;
      transform: translate(0, -50%);
    }

    &--02 {
      position: absolute;
      top: 0;
      left: 37%;
      transform: translate(0, -50%);
    }

    &--03 {
      position: absolute;
      top: 0;
      right: 37%;
      transform: translate(0, -50%);
    }

    &--04 {
      position: absolute;
      top: 0;
      right: 10%;
      transform: translate(0, -50%);
    }
  }

  &__arrow {
    background-color: #858585;

    &--down {
      position: absolute;
      bottom: 0%;
      left: 50%;
      width: 4px;
      height: 48px;
      transform: translate(-50%, 100%);

      &::after {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        background-size: cover;
        background-position: center;
        background-image: url('../../assets/images/icons/roadmap-down.svg');
        transform: translate(-44%, 250%);
      }
    }

    &--up {
      position: absolute;
      top: 0;
      left: 50%;
      width: 4px;
      height: 48px;
      transform: translate(-50%, -100%);

      &::after {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        background-size: cover;
        background-position: center;
        background-image: url('../../assets/images/icons/roadmap-up.svg');
        transform: translate(-40%, -100%);
      }
    }
  }

  &__phase {
    background-color: #0c6496b2;
    border-radius: 8px;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to bottom, #0effff, #124de3) 1;
    max-width: 312px;
    width: 312px;
    height: 280px;
    position: relative;

    &--list {
      // list-style-type: none;
      font-size: 12px;
      padding-inline-start: 1.5em;

      li {
        font-weight: 400;
        font-size: 12px;
        color: white;
      }

      // & li::before {
      //   content: "+";
      //   display: inline-block;
      //   width: 1em;
      //   margin-left: -1em;
      // }
    }

    &--container {
      padding: 24px;
      border-radius: 8px;

      &-up {}

      &-down {
        margin-top: 32px;
      }

      h2 {
        color: white;
        font-weight: 700;
        font-size: 18px;
      }

      h3 {
        color: white;
        font-weight: 400;
        font-size: 16px;
      }
    }

    &--btn {
      padding: 12px 24px;
      font-weight: 400;
      font-size: 16px;
    }

    &--btn-top {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &--btn-bottom {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
    }

    &--01 {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translate(-50%, 112%);
    }

    &--02 {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-50%, -115%);
    }

    &--03 {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translate(-50%, 112%);
    }

    &--04 {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-50%, -115%);
    }
  }

  &-vertical {
    &__line {
      background-color: #858585;
      height: 1320px;
      width: 3px;
      border-radius: 4px;
      position: relative;
      margin: 0 auto;
    }

    &__dot {
      background: linear-gradient(to bottom, #0effff, #124de3);
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 2px solid #858585;

      &--01 {
        position: absolute;
        top: 10%;
        left: 0;
        transform: translate(-50%, -50%);
      }

      &--02 {
        position: absolute;
        top: 35%;
        left: 0;
        transform: translate(-50%, 0%);
      }

      &--03 {
        position: absolute;
        top: 60%;
        left: 0;
        transform: translate(-50%, 50%);
      }

      &--04 {
        position: absolute;
        top: 85%;
        left: 0;
        transform: translate(-50%, 100%);
      }
    }

    &__arrow {
      background-color: #858585;

      &--down {
        position: absolute;
        bottom: 0%;
        right: -110%;
        width: 4px;
        height: 48px;
        transform: translate(100%, 30%) rotate(270deg);

        &::after {
          content: '';
          display: block;
          width: 16px;
          height: 16px;
          background-size: cover;
          background-position: center;
          background-image: url('../../assets/images/icons/roadmap-down.svg');
          transform: translate(-44%, 250%);
        }
      }
    }

    &__phase {
      background-color: #0c6496b2;
      border-radius: 8px;
      border-width: 4px;
      border-style: solid;
      border-image: linear-gradient(to bottom, #0effff, #124de3) 1;
      max-width: 312px;
      width: 312px;
      position: relative;

      &--list {
        // list-style-type: none;
        font-size: 12px;
        padding-inline-start: 1.5em;

        li {
          font-weight: 400;
          font-size: 12px;
          color: white;
        }

        // & li::before {
        //   content: "+";
        //   display: inline-block;
        //   width: 1em;
        //   margin-left: -1em;
        // }
      }

      &--container {
        padding: 24px;
        border-radius: 8px;

        &-up {}

        &-down {
          margin-top: 32px;
        }

        h2 {
          color: white;
          font-weight: 700;
          font-size: 18px;
        }

        h3 {
          color: white;
          font-weight: 400;
          font-size: 16px;
        }
      }

      &--btn {
        padding: 12px 24px;
        font-weight: 400;
        font-size: 16px;
      }

      &--btn-top {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &--btn-bottom {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
      }

      &--01 {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translate(-50%, 112%) rotate(90deg);
      }

      &--02 {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translate(-50%, 107%) rotate(90deg);
      }

      &--03 {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translate(-50%, 107%) rotate(90deg);
      }

      &--04 {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translate(-50%, 112%) rotate(90deg);
      }
    }
  }

  &__phase--list {
    // padding: 0;
    color: #fff;
  }
}

.periodic {
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;
  margin-top: 92px;

  &__container {
    max-width: 1280px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 92px;
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 124px;
    padding: 0 96px;
  }

  &__item {
    display: grid;
    grid-template-columns: minmax(300px, max-content) 1fr;
    gap: 0 92px;
    align-items: center;

    &--image {
      position: relative;

      display: flex;
      align-items: center;

      &-1 {
        position: relative;
        z-index: 5;

        transform: translate(68%, 4%);

        &-active {
          position: relative;
          z-index: 5;
          transform: translate(56%, 0);
        }
      }

      &-2 {
        position: relative;
        z-index: 4;
        transform: translate(68%, 4%);

        &-active {
          position: relative;
          z-index: 5;
          transform: translate(64%, 0);
        }
      }

      &-3 {
        position: relative;
        z-index: 4;
        transform: translate(68%, 4%);

        &-active {
          position: relative;
          z-index: 5;
          transform: translate(80%, 0);
        }
      }

      &-4 {
        position: relative;
        z-index: 4;
        transform: translate(68%, 4%);

        &-active {
          position: relative;
          z-index: 5;
          transform: translate(73%, 0);
        }
      }
    }

    &--content {
      border-top: 1px solid rgba(0, 0, 0, 0.3);
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);

      display: flex;
      flex-direction: column;
      gap: 16px;

      min-height: 196px;

      h2 {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 0;
        margin-top: 16px;
        text-transform: uppercase;
      }

      &-active {
        color: #25e0f9;

        transition: color 0.3s;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        color: #3c3c3c;
        margin-bottom: 8px;
      }
    }
  }
}

.footer {
  position: relative;
  margin-top: 144px;
  background-image: url('../../assets/images/background/footer-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-content: center;

  &__text {
    &--primary {
      font-size: 64px;
      font-weight: 900;
      color: white;
      display: flex;
      align-items: center;
      gap: 12px;
      white-space: nowrap;
    }

    &--highlight {
      font-size: 68px;
      font-weight: 900;
      color: #25e0f9;

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 3px;
        background-color: #25e0f9;
        margin: 2px auto;
      }
    }
  }

  &__container {
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    gap: 92px;
    padding: 64px 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
  }

  &__heading {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;

    img {
      width: 128px;
      height: 128px;
    }
  }

  &__join-now {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.decoration {
  &__home {
    &--left {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-40%, 0);
    }

    &--top {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -25%);
    }

    &--right {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translate(0, 50%);
    }

    &--cube07 {
      position: absolute;
      top: 15%;
      left: 45%;
      opacity: 60%;
    }

    &--cube08 {
      position: absolute;
      top: 13%;
      left: 40%;
      opacity: 60%;
    }

    &--cube09 {
      position: absolute;
      top: 11%;
      left: 50%;
      opacity: 60%;
    }
  }

  &__form {
    &--cube11 {
      position: absolute;
      top: 0;
      left: 0;
    }

    &--cube12 {
      position: absolute;
      top: 0;
      left: 25%;
    }

    &--cube13 {
      position: absolute;
      top: 0;
      left: 15%;
    }

    &--cube14 {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  &__condition {
    &--ellipse289 {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(-20%);
    }
  }

  &__roadmap {
    &--ellipse290 {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateY(20%);
    }
  }

  &__periodic {
    &--ellipse273 {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 3;
      transform: translate(-11%, 4%);
    }

    &--ellipse272 {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      transform: translate(-2%, -3%);
    }

    &--ellipse271 {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      transform: translate(4%, -9%);
    }

    &--active {
      &-273 {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 4;
        transform: translate(-11%, 5%);
        opacity: 0;
        animation: showUp 0.5s forwards;
      }

      &-272 {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 3;
        transform: translate(-2%, -3%);
        opacity: 0;
        animation: showUp 0.5s forwards;
        animation-delay: 0.1s;
      }

      &-271 {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        transform: translate(11%, -14%);
        opacity: 0;
        animation: showUp 0.5s forwards;
        animation-delay: 0.2s;
      }

      &-274 {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        transform: translate(16%, -18%);
        opacity: 0;
        animation: showUp 0.5s forwards;
        animation-delay: 0.3s;
      }

      @keyframes showUp {
        0% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }
    }

    &--cube10 {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.custom-modal {
  border-radius: 24px;
  padding: 16px 32px;

  &__body {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__heading {
    display: flex;
    justify-content: center;
  }
}

.heading__primary--form {
  font-size: 60px;
  font-weight: 900;
  text-transform: uppercase;
}

@media (max-width: 1920px) {
  .home {
    &__container {
      max-width: 1280px;
    }

    &__images {
      &--item {
        width: 90%;
      }
    }
  }

  .condition {
    &__container {
      max-width: 1280px;
    }
  }
}

@media (max-width: 1600px) {
  .heading__primary {
    font-size: 48px;
  }

  .heading__highlight {
    font-size: 28px;
  }

  .text__primary {
    font-size: 14px;
  }

  .heading__primary--form {
    font-size: 44px;
  }

  .home {
    &__container {
      max-width: 1024px;
    }
  }

  .condition {
    &__container {
      max-width: 1024px;
    }

    &__body {
      gap: 0 16px;
    }
  }

  .map {
    &__line {
      width: 82%;
    }

    &__dot {
      width: 20px;
      height: 20px;

      &--01 {
        left: 15%;
      }

      &--02 {
        left: 38%;
      }

      &--03 {
        right: 38%;
      }

      &--04 {
        right: 15%;
      }
    }

    &__phase {
      max-width: 248px;
      width: 248px;
      height: 264px;

      &--list {
        li {
          font-size: 12px;
        }
      }

      &--container {
        padding: 16px;

        &-up {}

        &-down {
          margin-top: 32px;
        }

        h2 {
          font-size: 14px;
        }

        h3 {
          font-size: 13px;
        }
      }

      &--btn {
        padding: 12px 24px;
        font-size: 13px;
      }

      &--btn-top {
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &--btn-bottom {
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
      }

      &--01 {
        transform: translate(-50%, 112%);
      }

      &--02 {
        transform: translate(-50%, -114%);
      }

      &--03 {
        transform: translate(-50%, 112%);
      }

      &--04 {
        transform: translate(-50%, -115%);
      }
    }
  }
}

@media (max-width: 1440px) {
  .heading__primary {
    font-size: 48px;
  }

  .heading__highlight {
    font-size: 28px;
  }

  .text__primary {
    font-size: 14px;
  }

  .heading__primary--form {
    font-size: 44px;
  }

  .home {
    &__container {
      max-width: 1024px;
    }

    &__images {
      &--item {
        width: 100%;
      }
    }

    &__content {
      &--heading {
        margin: 20px 0;
      }
    }
  }

  .condition {
    &__container {
      max-width: 1024px;
    }

    &__body {
      gap: 0 16px;
    }

    &__images {}
  }

  .decoration {
    &__home {
      &--cube07 {
        top: 15%;
      }

      &--cube08 {
        top: 12%;
      }

      &--cube09 {
        top: 9%;
      }
    }
  }

  .periodic {
    &__body {
      padding: 0 100px;
    }
  }


  .footer {
    &__text {
      &--primary {
        font-size: 54px;
      }

      &--highlight {
        font-size: 56px;
      }
    }
  }
}

@media (max-width: 1280px) {
  .heading__primary {
    font-size: 42px;
  }

  .heading__highlight {
    font-size: 26px;
  }

  .text__primary {
    font-size: 14px;
  }

  .btn__primary {
    padding: 12px 24px;
  }

  .home {
    &__container {
      max-width: 1024px;
      padding: 0 32px;
    }

    &__images {
      &--item {
        width: 100%;
      }
    }
  }

  .condition {
    &__container {
      max-width: 1024px;

      padding: 0 32px;
    }

    &__body {
      gap: 0 16px;
    }
  }

  .decoration {
    &__home {
      &--cube07 {
        left: 53%;
      }

      &--cube08 {
        left: 50%;
        top: 6%;
      }

      &--cube09 {
        left: 59%;
        top: 3%;
      }
    }
  }

  .roadmap {
    &__container {
      max-width: 1024px;
      gap: 96px;
    }

    &__map {
      min-height: 800px;
      justify-content: start;

      position: relative;
      padding: 0 12px;
    }
  }

  .map {
    &__phase {
      &--btn {
        font-size: 13px;
      }

      &--01 {
        transform: translate(-50%, 112%);
      }

      &--02 {
        transform: translate(-50%, -114%);
      }

      &--03 {
        transform: translate(-50%, 112%);
      }

      &--04 {
        transform: translate(-50%, -115%);
      }
    }
  }

  .periodic {
    &__container {
      max-width: 1024px;
      gap: 92px;
      padding: 0 32px;
    }

    &__body {
      padding: 0 36px;
    }
  }
}

@media (max-width: 1024px) {
  .heading__primary {
    font-size: 36px;
  }

  .heading__highlight {
    font-size: 24px;
  }

  .text__primary {
    font-size: 13px;
  }

  .btn__primary {
    padding: 12px 36px;
  }

  .home {
    &__container {
      max-width: 768px;
      padding: 0 44px;
    }
  }

  .condition {
    &__container {
      max-width: 768px;
      padding: 0 44px;
    }

    &__body {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }

    .map {
      &__line {
        width: 94%;
      }

      &__dot {
        width: 20px;
        height: 20px;

        &--01 {
          top: 0;
          left: 10%;
          transform: translate(0, -50%);
        }

        &--02 {
          top: 0;
          left: 37%;
          transform: translate(0, -50%);
        }

        &--03 {
          top: 0;
          right: 37%;
          transform: translate(0, -50%);
        }

        &--04 {
          top: 0;
          right: 10%;
          transform: translate(0, -50%);
        }
      }

      &__arrow {
        &--down {
          bottom: 0%;
          left: 50%;
          width: 4px;
          height: 48px;
          transform: translate(-50%, 100%);

          &::after {
            transform: translate(-44%, 250%);
          }
        }

        &--up {
          top: 0;
          left: 50%;
          width: 4px;
          height: 48px;
          transform: translate(-50%, -100%);

          &::after {
            width: 16px;
            height: 16px;
            transform: translate(-40%, -100%);
          }
        }
      }

      &__phase {
        max-width: 312px;
        width: 312px;

        &--list {
          li {
            font-size: 12px;
          }
        }

        &--container {
          padding: 24px;

          &-up {}

          &-down {
            margin-top: 32px;
          }

          h2 {
            font-size: 18px;
          }

          h3 {
            font-size: 16px;
          }
        }

        &--btn {
          padding: 12px 24px;
          font-size: 16px;
        }

        &--btn-top {
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &--btn-bottom {
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 50%);
        }

        &--01 {
          bottom: 0;
          left: 0;
          transform: translate(-50%, 112%);
        }

        &--02 {
          top: 0;
          left: 0;
          transform: translate(-50%, -115%);
        }

        &--03 {
          bottom: 0;
          left: 0;
          transform: translate(-50%, 112%);
        }

        &--04 {
          top: 0;
          left: 0;
          transform: translate(-50%, -115%);
        }
      }
    }
  }

  .decoration {
    &__home {
      &--cube07 {
        left: 53%;
      }

      &--cube08 {
        left: 50%;
        top: 6%;
      }

      &--cube09 {
        left: 59%;
        top: 3%;
      }
    }

    &__periodic {
      &--ellipse273 {
        transform: translate(-20%, -3%);
      }

      &--ellipse272 {
        transform: translate(-10%, -10%);
      }

      &--ellipse271 {
        transform: translate(-2%, -14%);
      }

      &--active {
        &-273 {
          transform: translate(-20%, -4%);
        }

        &-272 {
          transform: translate(-10%, -10%);
        }

        &-271 {
          transform: translate(6%, -20%);
        }

        &-274 {
          transform: translate(11%, -23%);
        }
      }
    }
  }

  .roadmap {
    &__container {
      max-width: 768px;
      gap: 96px;
    }

    &__map {
      min-height: 800px;
      max-width: 500px;
      margin: auto;
      justify-content: start;

      position: relative;
      padding: 0 15px;
    }

    &__content {}
  }

  .periodic {
    &__container {
      padding: 0;
    }

    &__item {
      display: flex;
      flex-direction: column;
      gap: 92px;
      padding: 0 64px;

      &--image {
        &-1 {
          transform: translate(-20%, -4%);

          &-active {
            transform: translate(-14%, -9%);
          }
        }

        &-2 {
          transform: translate(-20%, -4%);

          &-active {
            transform: translate(-19%, -9%);
          }
        }

        &-3 {
          transform: translate(-20%, -3%);

          &-active {
            transform: translate(-29%, -8%);
          }
        }

        &-4 {
          transform: translate(-20%, -5%);

          &-active {
            transform: translate(-24%, -9%);
          }
        }
      }

      &--content {
        gap: 12px;

        h2 {
          font-size: 18px;
        }

        p {
          font-size: 13px;
        }
      }
    }
  }

  .footer {
    &__text {
      &--primary {
        font-size: 48px;
      }

      &--highlight {
        font-size: 50px;
      }
    }

    &__heading {
      img: {
        width: 48px;
        height: 48px;
      }
    }
  }
}

@media (max-width: 768px) {
  .heading__primary {
    font-size: 34px;
  }

  .home {
    &__container {
      max-width: 624px;
      grid-template-columns: 1fr;
      padding: 64px 0;
    }

    &__images {
      &--item {
        width: 75%;
      }
    }
  }

  .benefit {
    margin-top: 74px;
  }

  .condition {
    margin-top: 64px;

    &__container {
      max-width: 624px;
      padding: 0;
      gap: 32px 0;
    }

    &__body {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }

    &__content {
      max-width: none;
      width: 100%;
    }
  }

  .decoration {
    &__home {
      &--cube07 {
        left: 88%;
      }

      &--cube08 {
        left: 86%;
      }

      &--cube09 {
        left: 96%;
      }
    }
  }

  .periodic {
    &__container {
      max-width: 624px;
      gap: 64px;
    }

    &__item {
      padding: 0;
    }

    &__body {
      padding: 0 48px;
    }
  }

  .footer {
    &__text {
      &--primary {
        font-size: 42px;
      }

      &--highlight {
        font-size: 44px;
      }
    }

    &__heading {
      gap: 24px;

      img {
        width: 96px;
        height: 96px;
      }
    }
  }
}

@media (max-width: 624px) {
  .home {
    &__container {
      max-width: 512px;
    }
  }

  .condition {
    &__container {
      max-width: 512px;
    }
  }
}

@media (max-width: 512px) {
  .heading__primary--form {
    font-size: 36px;
  }

  .home {
    &__container {
      max-width: 375px;
    }
  }

  .condition {
    &__container {
      max-width: 375px;
    }
  }

  .roadmap {
    &__container {
      max-width: 375px;
    }

    &__map {
      min-height: 800px;
      padding: 0;
    }

    &__content {}
  }

  .periodic {
    &__item {
      padding: 0;
    }

    &__body {
      padding: 0 48px;
    }
  }

  .benefit {
    h1 {
      text-align: center;
    }

    &__content {
      &--vertical {
        gap: 28px;
      }
    }

    &__phase {
      padding: 8px 16px;
    }
  }

  .footer {
    &__text {
      &--primary {
        font-size: 24px;
      }

      &--highlight {
        font-size: 26px;
      }
    }

    &__heading {
      img {
        width: 64px;
        height: 64px;
      }
    }
  }
}

@media (max-width: 375px) {
  .heading__primary {
    font-size: 30px;
  }

  .heading__highlight {
    font-size: 20px;
  }

  .text__primary {
    font-size: 13px;
  }

  .btn__primary {
    padding: 12px 36px;
  }

  .heading__primary--form {
    font-size: 32px;
  }

  .home {
    &__container {
      max-width: 320px;
      grid-template-columns: 1fr;
      padding: 0;
    }

    &__content {
      gap: 16px 0;
    }

    &__images {
      &--item {
        width: 75%;
      }
    }
  }

  .benefit {
    &__phase {
      width: 264px;
    }
  }

  .condition {
    margin-top: 64px;

    &__container {
      max-width: 320px;
    }
  }

  .decoration {
    &__home {
      &--cube07 {
        top: 12%;
        left: 95%;
      }

      &--cube08 {
        left: 86%;
      }

      &--cube09 {
        left: 100%;
      }
    }
  }

  .roadmap {
    &__container {
      max-width: 320px;
    }

    &__map {
      min-height: 800px;
      padding: 0;
    }

    &__content {}
  }

  .map {
    &-vertical {
      &__phase {
        max-width: 264px;
        width: 264px;

        &--list {
          li {
            font-weight: 400;
            font-size: 12px;
            color: white;
          }

          & li::before {
            content: '+';
            display: inline-block;
            width: 1em;
            margin-left: -1em;
          }
        }

        &--container {
          padding: 12px;
          border-radius: 8px;

          &-down {
            margin-top: 32px;
          }

          h2 {
            color: white;
            font-weight: 700;
            font-size: 16px;
          }

          h3 {
            color: white;
            font-weight: 400;
            font-size: 14px;
          }
        }

        &--01 {
          transform: translate(-50%, 109%) rotate(90deg);
        }

        &--02 {
          transform: translate(-50%, 105%) rotate(90deg);
        }

        &--03 {
          transform: translate(-50%, 102%) rotate(90deg);
        }

        &--04 {
          transform: translate(-50%, 110%) rotate(90deg);
        }
      }
    }
  }

  .footer {
    &__text {
      &--primary {
        font-size: 18px;
      }

      &--highlight {
        font-size: 20px;
      }
    }
  }
}
</style>

<style lang="scss">
#bv-modal-form {
  .modal-dialog {
    top: 24px;

    .modal-content {
      //max-width: 712px;
      //width: 712px;
      border-radius: 20px;

      position: relative;

      .modal-body {
        background-color: #1478b0;
        border-radius: 20px;
        padding: 36px;
      }

      input {
        width: 100%;
        margin: 0;
        padding: 24px 16px;
        border-radius: 12px;
        border: 1px solid #e4e4e4;
        background-color: rgba(255, 255, 255, 0.29);
        color: white;
        font-weight: 400;
        font-size: 16px;
      }

      input::placeholder {
        text-align: start;
        font-weight: 400;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.65);
      }

      textarea {
        padding: 12px 16px;
        border-radius: 12px;
        border: 1px solid #e4e4e4;
        background-color: rgba(255, 255, 255, 0.29);
        color: white;
        font-weight: 400;
        font-size: 16px;
      }

      textarea::placeholder {
        font-weight: 400;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.65);
      }

      label {
        color: white;
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 0;
      }

      .custom-form {
        display: flex;
        flex-direction: column;
        gap: 24px;
        z-index: 10;

        &__btn-container {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 0;
}

#bv-modal-success {
  .modal-dialog {
    .modal-content {
      border-radius: 20px;

      .modal-body {
        border-radius: 20px;
        background-color: #1a3b4c;
        padding: 64px 72px;
        display: flex;
        justify-content: center;
        align-items: center;

        .modal-success {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-items: center;
          gap: 4px;

          img {
            margin-bottom: 56px;
          }

          h2 {
            color: #0087cc;
            font-weight: 500;
            font-size: 24px;
            text-transform: uppercase;
          }

          p {
            font-weight: 500;
            font-size: 20px;
            color: white;
          }
        }
      }
    }
  }
}

.accordion {
  .card {
    border: none;

    .card-header {
      border-bottom: none;

      .btn-secondary {
        background-color: #006ca3;
        padding: 16px 32px;

        &__active {
          border: 1.5px solid #00d1ff;
        }

        .btn__container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 8px;

          p {
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 0;
          }

          .text-active {
            color: #25e0f9;
          }
        }
      }
    }
  }
}

@media (max-width: 1920px) {
  #bv-modal-form {
    /*
    .modal-dialog {
      top: 50px;
    }
    */
  }
}

@media (max-width: 1600px) {
  .accordion {
    .card {
      .card-header {
        .btn-secondary {
          padding: 12px 24px;

          .btn__container {
            gap: 2px;

            p {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  #bv-modal-form {
    .modal-dialog {
      //top: 45px;

      .modal-content {
        //max-width: 632px;
        //width: 632px;

        .modal-body {
          border-radius: 20px;
        }

        input {
          padding: 12px 16px;
          font-size: 16px;
        }

        input::placeholder {
          text-align: start;
          font-size: 16px;
        }

        textarea {
          padding: 12px 16px;
          font-size: 16px;
        }

        textarea::placeholder {
          font-size: 16px;
        }

        label {
          font-size: 16px;
        }
      }
    }

    .form-group {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  .accordion {
    .card {
      .card-header {
        .btn-secondary {
          padding: 12px 24px;

          .btn__container {
            gap: 2px;

            p {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  #bv-modal-form {
    .modal-dialog {
      //max-width: 400px;

      .modal-content {
        //max-width: 512px;
        //width: 512px;
      }
    }
  }
}

@media (max-width: 768px) {
  #bv-modal-form {
    .modal-dialog {
      //max-width: 500px;
    }
  }

  #bv-modal-success {
    .modal-dialog {
      .modal-content {
        .modal-body {
          padding: 64px 72px;

          .modal-success {
            gap: 4px;

            img {
              margin-bottom: 42px;
            }

            h2 {
              font-size: 24px;
            }

            p {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 512px) {
  #bv-modal-form {
    .modal {
      //left: 40px;
    }

    .modal-dialog {
      //top: 62px;
      //max-width: 400px;

      .modal-content {
        //max-width: 400px;
        //width: 400px;
      }
    }
  }

  #bv-modal-success {
    .modal-dialog {
      .modal-content {
        .modal-body {
          padding: 64px 52px;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  #bv-modal-form {
    .modal {}

    .modal-dialog {
      //top: 62px;
      //max-width: 350px;

      .modal-content {
        //max-width: 350px;
        //width: 350px;
      }
    }
  }

  #bv-modal-success {
    .modal-dialog {
      .modal-content {
        .modal-body {
          padding: 64px 32px;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  #bv-modal-success {
    .modal-dialog {
      .modal-content {
        .modal-body {
          padding: 64px 24px;

          .modal-success {
            p {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  #bv-modal-form {
    .modal-dialog {
      .modal-content {
        //max-width: 375px;
        //width: 300px;

        .modal-body {
          padding: 24px;
        }

        input {
          padding: 8px 12px;
          font-size: 14px;
        }

        input::placeholder {
          text-align: start;
          font-size: 14px;
        }

        textarea {
          padding: 8px 12px;
          font-size: 14px;
        }

        textarea::placeholder {
          font-size: 14px;
        }

        label {
          font-size: 14px;
        }
      }
    }

    .form-group {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  .accordion {
    .card {
      .card-header {
        .btn-secondary {
          padding: 8px 16px;

          .btn__container {
            gap: 8px;

            p {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
