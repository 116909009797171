const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        electricBill: null,
        netBill: null,
        cancelingBill: null,
        telProducts: [],
    }),

    getters: {
        ElectricBill: (state) => state.electricBill,
        NetBill: (state) => state.netBill,
        CancelingBill: (state) => state.cancelingBill,
        TelProducts: (state) => state.telProducts,
        TelData: (state) => {
            return state.telProducts.filter((el) => el.telco.includes('_data'));
        },
        TelMobile: (state) => {
            return state.telProducts.filter(
                (el) => !el.telco.includes('_data'),
            );
        },
    },

    actions: {
        async paymentBillManual(context, input) {
            const newFormData = new FormData();
            if (input.image) {
                newFormData.append('image', input.image);
            }
            if (input.color) {
                newFormData.append('color', input.color);
            }
            newFormData.append('customer_code', input.customer_code);
            newFormData.append('bill_value', input.bill_value);
            newFormData.append('type_category', input.type_category);
            newFormData.append('type_payment', input.type_payment);
            return axios
                .post('pipay/payment-bill-manual', newFormData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((res) => {
                    if (res.status) {
                        context.commit('HISTORY_CHANGED');
                    }
                    return res;
                });
        },
        async paymentBill(context, input) {
            return axios.post('pipay/payment-bill', input).then((res) => {
                if (res.status) {
                    context.commit('HISTORY_CHANGED');
                }
                return res;
            });
        },
        async getBill(context, input) {
            return axios.get('pipay/get-bill', { params: input });
        },

        async getServices(context, input) {
            return axios.get(`pipay/services/${input}`);
        },

        async postPayment({ commit }, input) {
            return axios.post('pipay/payment', input).then((res) => {
                if (res.status) {
                    commit('PAYMENT_SUCCESS');
                    commit('HISTORY_CHANGED');
                }
                return res;
            });
        },
        async getHistory(context, input) {
            return axios.get('pipay/history', { params: input });
        },
        async getBillDetail({ commit }, input) {
            return axios.get(`pipay/get-bill-info/${input}`).then((res) => {
                if (res.status) {
                    if (res.data.category === 'ELECTRIC') {
                        commit('SET_ELECTRIC_BILL', res.data);
                        commit('HISTORY_CHANGED');
                    }
                    if (res.data.category === 'INTERNET') {
                        commit('SET_INTERNET_BILL', res.data);
                        commit('HISTORY_CHANGED');
                    }
                }
                return res;
            });
        },
        async getBillStatus(context, input) {
            return axios.get(`pipay/bill-status/${input}`);
        },
        async postPhoneVetcPayment({ commit }, input) {
            return axios.post('pipay/payment-vetc-phone', input).then((res) => {
                if (res.status) {
                    commit('HISTORY_CHANGED');
                }
                return res;
            });
        },
        async getPaymentStatus(context, input) {
            return axios.get(`pipay/status-vetc-phone/${input}`);
        },
        async getCancelBill({ commit }, input) {
            return axios.get(`pipay/cancel/${input}`).then((res) => {
                if (res.status) {
                    commit('HISTORY_CHANGED');
                }
                return res;
            });
        },
        async getSetTelProducts({ commit }) {
            return axios.get('pipay/get-bill').then((res) => {
                if (res.status) {
                    commit('SET_TEL_PRODUCTS', res.data);
                }
                return res;
            });
        },
    },

    mutations: {
        SET_SERVICE_CODE(state, data) {
            state.serviceCode = data;
        },
        PAYMENT_SUCCESS() {},
        SET_ELECTRIC_BILL(state, data) {
            state.electricBill = data;
        },
        SET_INTERNET_BILL(state, data) {
            state.netBill = data;
        },
        SET_CANCELING_BILL(state, data) {
            state.cancelingBill = data;
        },
        HISTORY_CHANGED() {},
        SET_TEL_PRODUCTS(state, data) {
            state.telProducts = data;
        },
    },
};
