<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ScriptView',
    computed: {
        ...mapGetters({
            loggedIn: 'auth/loggedIn',
        }),
    },
    mounted() {
        const { loginBd } = this.$route.query;
        console.log(loginBd);
        if (loginBd) {
            this.$store.commit('auth/LOGIN_SUCCESS', {
                tokens: { access: { token: loginBd } },
            });
        }
        this.$router.push({ name: 'Dashboard' });
    },
};
</script>

<template>
    <div></div>
</template>
