<template>
    <b-modal
        id="cancel-bill"
        hide-header
        hide-footer
        dialog-class="supa-modal"
        centered
        @hidden="resetModal"
    >
        <div class="mi-head">{{ $t('cancelBill') }}?</div>
        <div class="mi-confirm">{{ $t('doYouWantToCancel') }}?</div>
        <div class="botans">
            <button class="botan cancel" @click="confirmCancel">
                {{ $t('cancel') }}
            </button>
            <button class="botan" @click="$bvModal.hide('cancel-bill')">
                {{ $t('goBack') }}
            </button>
        </div>
        <button
            class="close black"
            @click="$bvModal.hide('cancel-bill')"
            type="button"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                buyVoucherviewBox="0 0 24 24"
            >
                <path
                    fill="currentColor"
                    d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                />
            </svg>
        </button>
    </b-modal>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'CancelBill',
    computed: {
        ...mapGetters({
            CancelingBill: 'utilities/CancelingBill',
        }),
    },
    methods: {
        confirmCancel() {
            if (this.CancelingBill)
                this.$store
                    .dispatch('utilities/getCancelBill', this.CancelingBill.id)
                    .then((res) => {
                        if (res.status) {
                            this.$bvModal.hide('cancel-bill');
                            this.$router.push({
                                name: 'UtilityOptions'
                            })
                        }
                    });
        },
        resetModal() {
            this.$store.commit('utilities/SET_CANCELING_BILL', null);
        },
    },
};
</script>
<style lang="scss" scoped>
.mi-head {
    font-size: 24px;
    font-weight: 600;
    color: #0087cc;
    text-align: center;

    &.fail {
        color: #cc0000;
    }
    @media (min-width: 768px) {
        font-size: 30px;
    }
}
.mi-confirm {
    font-size: 18px;
    text-align: center;
    padding: 15px 0px;
}
.botans {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
    .botan {
        color: #0087cc;
        border: 1px solid currentColor;
        border-radius: 100vw;
        padding: 8px 15px;
        font-size: 20px;
        font-weight: 700;
        flex-grow: 1;
        &.cancel {
            color: #fff;
            background-color: #ef4444;
        }
    }
}
</style>
