const axios = require('../axios.config').default;

export default {
    namespaced: true,

    state: () => ({
        categoryList: null,
        addressList: null,
    }),

    getters: {
        CategoryList: (state) => state.categoryList,
        AddressList: (state) => state.addressList,
    },

    actions: {
        async getCategoryList({ commit }, input) {
            return axios
                .get('/map-address/map-address-category')
                .then((res) => {
                    if (res && res.status) {
                        commit('SET_CATEGORY_LIST', res.data);
                        console.log('Category list: ', res.data);
                    }
                });
        },

        async getAddressList({ commit }, input) {
            return axios
                .get('/map-address/map-address', {
                    params: { category_id: input.category_id },
                })
                .then((res) => {
                    if (res && res.status) {
                        commit('SET_ADDRESS_LIST', res.data);
                        console.log('Address list: ', res.data);
                    }
                });
        },
    },
    mutations: {
        SET_CATEGORY_LIST(state, data) {
            state.categoryList = data;
        },

        SET_ADDRESS_LIST(state, data) {
            state.addressList = data;
        },
    },
};
