const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        masterNodeMemberList: null,
        statisticalMint: null,
        masterNodeList: null,
        masterNode: null
    }),

    getters: {
        MasterNodeMemberList: (state) => state.masterNodeMemberList,
        StatisticalMint: (state) => state.statisticalMint,
        MasterNodeList: (state) => state.masterNodeList,
        MasterNode: (state) => state.masterNode
    },

    actions: {
        async getMasterNodeMemberList({ commit }) {
            return axios.get('/masternode/member').then((res) => {
                if (res && res.data) {
                    commit('SET_MASTER_NODE_MEMBER_LIST', res.data);
                    console.log('Master node member list action: ', res.data);
                }
            });
        },

        async getStatistialMint({commit}) {
            return axios.get('/masternode/statistical-mint').then((res) => {
                if (res) {
                    commit('SET_STATISTICAL_MINT', res.data);
                    console.log('Get statistical mint action: ', res.data);
                }
            });
        },

        async updateMasterNode(context, input) {
            return axios.post('/masternode/member-update', input).then((res) => {
                return res
            })
        },

        async getMasterNodeList({ commit }) {
          return axios.get('/masternode/list').then((res) => {
              if (res && res.data) {
                  commit('SET_MASTER_NODE_LIST', res);
              }
          });
        },

        async getMasterNodeById({ commit }, id) {
          return axios.get(`/masternode/detail/${id}`).then((res) => {
              if (res && res.data) {
                  commit('SET_MASTER_NODE', res);
              }
          });
        },

        async updateMemberMasterNode(context, input) {
            return axios.post('/masternode/member-update', input).then((res) => {
                return res
            })
        }
    },

    mutations: {
        SET_MASTER_NODE_MEMBER_LIST(state, data) {
            // console.log('Master Node list mutation: ', data.results);
            state.masterNodeMemberList = data;
        },

        SET_STATISTICAL_MINT(state, data) {
            state.statisticalMint = data;
        },

        SET_MASTER_NODE_LIST(state, data) {
          state.masterNodeList = data;
        },

        SET_MASTER_NODE(state, data) {
          state.masterNode = data;
        },
    },
};
