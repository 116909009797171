<template>
    <b-modal
        id="update-email"
        hide-footer
        hide-header
        dialog-class="supa-modal"
        centered
    >
        <div>
            <div class="head">{{ $t('updateEmail') }}</div>
            <div class="notice">
                <div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M17 1H3a2 2 0 0 0-2 2v9h2V3h14Z"
                        />
                        <path
                            fill="currentColor"
                            d="M21 5H7a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h5.53v1.53H11V22h6v-1.48h-1.52V19H21a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2m0 12H7V7h14Z"
                        />
                    </svg>
                    {{ $t('updateEmailGetTheBest') }}
                </div>
            </div>
            <form class="myForm" @submit.prevent="updateEmail">
                <label>
                    Email
                    <div class="input-box">
                        <input v-model="email" />
                    </div>
                </label>
                <div class="text-center">
                    <button type="submit" class="botan nice-gradient">
                        {{ $t('update') }}
                    </button>
                </div>
            </form>
        </div>
        <!-- Close button x -->
        <button
            class="close"
            @click="$bvModal.hide('update-email')"
            type="button"
            style="color: #0087cc"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
            >
                <path
                    fill="currentColor"
                    d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                />
            </svg>
        </button>
    </b-modal>
</template>

<script>
export default {
    name: 'UpdateEmail',
    data() {
        return {
            email: '',
        };
    },
    methods: {
        updateEmail() {
            this.$store
                .dispatch('auth/updateEmail', {
                    email: this.email,
                })
                .then((res) => {
                    if (res) {
                        this.$store.dispatch('info/req_getInfo');
                        this.$bvModal.hide('update-email');
                    }
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.myForm {
    padding-top: 30px;
    .botan {
        color: white;
        border-radius: 100vw;
        font-weight: 600;
        padding: 10px 15px;
        box-shadow: 1px 1px 6px -2px #000;
    }
    label {
        display: block;
        font-weight: 600;
        .input-box {
            > input {
                box-shadow: 0px 0px 6px 2px #0000001c;
                border-radius: 6px;
                border: 1px solid #3f3f46;
                background-color: #d4d4d8;
                display: block;
                width: 100%;
                color: #000;
                padding: 4px 10px;
                margin: 5px 0px;
            }
        }
    }
}
.head {
    font-size: 30px;
    font-weight: 600;
    color: #0087cc;
    text-align: center;
    margin-top: 10px;
}
.notice {
    padding-top: 30px;
    > div {
        color: #0087cc;
        padding-left: 15px;
    }
}
</style>
