const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        supportList: {
            limit: 0,
            page: 0,
            totalPages: 0,
            totalResults: 0,
            results: [],
        },
    }),

    getters: {
        supportList: (state) => state.supportList,
    },

    actions: {
        async req_listTicket({ commit }, payload) {
            const data = await axios.get('/ticket/get-report', {
                ...payload,
            });

            commit('setSupportList', data.data);
            return data;
        },

        async reqTicket({ commit, state }, payload) {
            const response = await axios.post('/ticket/create', payload);
            if (response.status && response.data) {
                const { data } = response;
                const updatedResults = [data, ...state.supportList.results];

                const newState = {
                    ...state.supportList,
                    results: updatedResults,
                };

                commit('setSupportList', newState);
                return data;
            } else {
                return response;
            }
        },
    },

    mutations: {
        setSupportList(state, supportList) {
            state.supportList = supportList;
        },
    },
};
