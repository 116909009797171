<template>
    <div class="xnodeinvent-container">
        <div class="top row">
            <div class="col-lg-6 py-3">
                <div class="my-xnode">
                    <div class="header">
                        <h3>{{ $t('myXNode') }}</h3>
                        <!-- <p>
                            Receive:
                            <span
                                >{{ Countdown.days }}d {{ Countdown.hours }}h
                                {{ Countdown.minutes }}m
                                {{ Countdown.seconds }}s</span
                            >
                        </p> -->
                    </div>
                    <div class="note-text">
                        {{ $t('eachBlockTakes') }}
                    </div>
                    <div class="xnode-content">
                        <div>
                            <p>{{ $t('miningPhase') }}</p>
                            <p>{{ XNode.miningPhase }}</p>
                        </div>
                        <div>
                            <p>{{ $t('currentBlock') }}</p>
                            <p>{{ XNode.currentBlock }}</p>
                        </div>
                        <div>
                            <p>{{ $t('myMiningCapacity') }}</p>
                            <p>
                                {{
                                    TruncateToDecimals2(
                                        XNode.miningHz - XNode.bonusMiningHz,
                                    )
                                }}
                                Hz
                            </p>
                        </div>
                        <div>
                            <p>{{ $t('bonusMiningCapacity') }}</p>
                            <p>
                                {{ TruncateToDecimals2(XNode.bonusMiningHz) }}
                                Hz
                            </p>
                        </div>
                        <div>
                            <p>{{ $t('currentMining') }}</p>
                            <p>
                                {{ TruncateToDecimals2(XNode.miningHz) }} Hz
                                (+{{
                                    TruncateToDecimals2(
                                        XNode.bonusPercentHz * 100,
                                    )
                                }}%)
                            </p>
                        </div>
                        <div>
                            <p>
                                {{ $t('tokenPerHour') }}
                            </p>
                            <p>
                                {{
                                    TruncateToDecimals2(
                                        XNode.tokenPerSpeed,
                                        '',
                                        2,
                                    )
                                }}
                                XIN
                            </p>
                        </div>
                        <div>
                            <p>
                                {{ $t('totalSoM', { something: 'Mana' }) }}
                                <svg
                                    v-if="UserInfo.mana < 10"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1.2em"
                                    height="1.2em"
                                    viewBox="0 0 256 256"
                                    class="warning"
                                    v-b-tooltip.hover
                                    :title="$t('manaTooLow')"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M240.26 186.1L152.81 34.23a28.74 28.74 0 0 0-49.62 0L15.74 186.1a27.45 27.45 0 0 0 0 27.71A28.31 28.31 0 0 0 40.55 228h174.9a28.31 28.31 0 0 0 24.79-14.19a27.45 27.45 0 0 0 .02-27.71m-20.8 15.7a4.46 4.46 0 0 1-4 2.2H40.55a4.46 4.46 0 0 1-4-2.2a3.56 3.56 0 0 1 0-3.73L124 46.2a4.77 4.77 0 0 1 8 0l87.44 151.87a3.56 3.56 0 0 1 .02 3.73M116 136v-32a12 12 0 0 1 24 0v32a12 12 0 0 1-24 0m28 40a16 16 0 1 1-16-16a16 16 0 0 1 16 16"
                                    />
                                </svg>
                            </p>
                            <p>
                                <span :class="{ tooLow: UserInfo.mana < 10 }">
                                    {{
                                        UserInfo.mana === 0
                                            ? '-'
                                            : UserInfo.mana
                                    }}
                                </span>
                                /{{ UserInfo.limitMana }}
                            </p>
                        </div>
                        <div>
                            <p>{{ $t('totalXINMined') }}</p>
                            <p>
                                {{ TruncateToDecimals2(XNode.totalXinMined) }}
                                XIN
                            </p>
                        </div>
                        <div>
                            <p>{{ $t('totalXINPending') }}</p>
                            <p>
                                {{ TruncateToDecimals2(XNode.totalXinPending) }}
                                XIN
                            </p>
                        </div>
                        <div>
                            <p>{{ $t('numberOfTokens') }}</p>
                            <p>
                                {{
                                    TruncateToDecimals2(
                                        XNode.totalXinMined -
                                            XNode.totalXinPending,
                                    )
                                }}
                                XIN
                            </p>
                        </div>
                        <div>
                            <p>{{ $t('totalXNode') }}</p>
                            <p>{{ XNode.usedSlot }} slot</p>
                        </div>
                        <div class="buttons">
                            <button @click="$bvModal.show('claim-modal')">
                                {{ $t('claim') }}
                            </button>
                            <button
                                v-if="
                                    !(
                                        MerchantList && MerchantList.length > 0
                                    ) &&
                                    !(
                                        UserNode &&
                                        UserNode.results &&
                                        UserNode.results.length <= 0
                                    )
                                "
                                @click="$bvModal.show('boost-wattage')"
                            >
                                {{ $t('boostMining') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 py-3">
                <div class="mission">
                    <div class="header">
                        <h3>{{ $t('mission') }}</h3>
                        <button @click="updateMissions" class="update-missions">
                            {{ $t('updateList') }}
                        </button>
                    </div>
                    <div class="mission-content">
                        <div class="position-relative h-100">
                            <div class="content-text">
                                <div
                                    v-for="mission in RenderedMissions"
                                    :key="mission.missionId"
                                    class="mission-item"
                                    :class="{ completed: mission.status }"
                                >
                                    <div>{{ mission.comment }}</div>
                                    <div class="">
                                        <div class="rewards">
                                            <img
                                                src="@/assets/images/icons/mana.svg"
                                                width="24px"
                                                height="24px"
                                            />
                                            <div>
                                                x{{
                                                    mission.userMission.reward
                                                }}
                                            </div>
                                        </div>
                                        <div class="px-4 flex-grow-1">
                                            {{ $t('progress') }}:
                                            <span
                                                :key="
                                                    mission.progress
                                                        .accomplished
                                                "
                                            >
                                                <span>
                                                    {{
                                                        TruncateToDecimals2(
                                                            mission.progress
                                                                .accomplished,
                                                        )
                                                    }}
                                                </span>
                                                <span> / </span>
                                                <span>
                                                    {{
                                                        TruncateToDecimals2(
                                                            mission.progress
                                                                .total,
                                                        )
                                                    }}
                                                </span>
                                            </span>
                                            <b-progress
                                                v-if="mission.status === 2"
                                                :value="
                                                    mission.progress
                                                        .accomplished
                                                "
                                                :max="mission.progress.total"
                                                animated
                                            ></b-progress>
                                            <b-progress
                                                v-else
                                                :value="
                                                    mission.progress
                                                        .accomplished
                                                "
                                                :max="mission.progress.total"
                                                animated
                                                class="completed"
                                            ></b-progress>
                                        </div>
                                        <div
                                            v-if="mission.status"
                                            class="mission-status completed"
                                        >
                                            {{ $t('completed') }}
                                        </div>
                                        <div v-else class="mission-status">
                                            {{ $t('ongoing') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="swipe-icon"
                                viewBox="0 0 24 24"
                            >
                                <g fill="currentColor">
                                    <path
                                        d="m9.172 16.818l-1.415 1.414L12 22.475l4.243-4.243l-1.415-1.414L12 19.647zm5.656-9.636l1.415-1.414L12 1.525L7.757 5.768l1.415 1.414L12 4.354z"
                                    />
                                    <path
                                        fill-rule="evenodd"
                                        d="M12 9a3 3 0 1 1 0 6a3 3 0 0 1 0-6m0 2a1 1 0 1 1 0 2a1 1 0 0 1 0-2"
                                        clip-rule="evenodd"
                                    />
                                </g>
                            </svg> -->
                        </div>
                        <!-- <p class="swipe">{{ $t('swipeDownForMore') }}</p> -->
                        <!-- <div class="button">
                            <a
                                href="https://testflight.apple.com/join/42QdyTIq"
                                target="_blank"
                                >Go to app</a
                            >
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="table-container">
            <template>
                <h3>
                    {{ $t('XNodeList') }}
                    <svg
                        v-if="UserInfo.mana < 10"
                        xmlns="http://www.w3.org/2000/svg"
                        width="1.2em"
                        height="1.2em"
                        viewBox="0 0 256 256"
                        class="warning"
                        v-b-tooltip.hover
                        :title="$t('manaTooLow')"
                    >
                        <path
                            fill="currentColor"
                            d="M240.26 186.1L152.81 34.23a28.74 28.74 0 0 0-49.62 0L15.74 186.1a27.45 27.45 0 0 0 0 27.71A28.31 28.31 0 0 0 40.55 228h174.9a28.31 28.31 0 0 0 24.79-14.19a27.45 27.45 0 0 0 .02-27.71m-20.8 15.7a4.46 4.46 0 0 1-4 2.2H40.55a4.46 4.46 0 0 1-4-2.2a3.56 3.56 0 0 1 0-3.73L124 46.2a4.77 4.77 0 0 1 8 0l87.44 151.87a3.56 3.56 0 0 1 .02 3.73M116 136v-32a12 12 0 0 1 24 0v32a12 12 0 0 1-24 0m28 40a16 16 0 1 1-16-16a16 16 0 0 1 16 16"
                        />
                    </svg>
                </h3>
                <div class="d-none d-lg-block">
                    <b-table
                        class="xnode-table"
                        responsive
                        striped
                        hover
                        :fields="fields"
                        :items="UserNode.results"
                        @sort-changed="nodeSort"
                    >
                        <template #cell(name)="{ item }">
                            <div class="table-name">
                                <div>
                                    <div>
                                        {{ item.nodeInfo.name }}
                                    </div>
                                    <div>
                                        {{
                                            TruncateToDecimals2(
                                                item.nodeInfo.priceUSDT,
                                            )
                                        }}
                                        USDT
                                    </div>
                                </div>
                                <div v-if="!item.percentageOfHz">
                                    <svg
                                        @click="openModalPercent(item._id)"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="1em"
                                        height="1em"
                                        viewBox="0 0 24 24"
                                    >
                                        <g fill="none">
                                            <path
                                                d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"
                                            />
                                            <path
                                                fill="currentColor"
                                                d="M10.5 20a1.5 1.5 0 0 0 3 0v-6.5H20a1.5 1.5 0 0 0 0-3h-6.5V4a1.5 1.5 0 0 0-3 0v6.5H4a1.5 1.5 0 0 0 0 3h6.5z"
                                            />
                                        </g>
                                    </svg>
                                </div>
                                <div
                                    v-else-if="item.percentageOfHz === 5"
                                    v-b-tooltip.hover
                                    title="5%"
                                    @click="
                                        openModalReset(
                                            item._id,
                                            item.percentageOfHz,
                                        )
                                    "
                                >
                                    <img
                                        src="../../assets/images/icons/5capa.png"
                                        alt=""
                                    />
                                </div>
                                <div
                                    v-else-if="item.percentageOfHz === 8"
                                    v-b-tooltip.hover
                                    title="8%"
                                    @click="
                                        openModalReset(
                                            item._id,
                                            item.percentageOfHz,
                                        )
                                    "
                                >
                                    <img
                                        src="../../assets/images/icons/8capa.png"
                                        alt=""
                                    />
                                </div>
                                <div
                                    v-else-if="item.percentageOfHz === 15"
                                    v-b-tooltip.hover
                                    title="15%"
                                    @click="
                                        openModalReset(
                                            item._id,
                                            item.percentageOfHz,
                                        )
                                    "
                                >
                                    <img
                                        src="../../assets/images/icons/15capa.png"
                                        alt=""
                                    />
                                </div>
                                <div
                                    v-else-if="item.percentageOfHz === 25"
                                    v-b-tooltip.hover
                                    title="25%"
                                    @click="
                                        openModalReset(
                                            item._id,
                                            item.percentageOfHz,
                                        )
                                    "
                                >
                                    <img
                                        src="../../assets/images/icons/25capa.png"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </template>
                        <template #cell(performance)="{ item }">
                            {{ item.nodeInfo.performance }} Hz/h
                        </template>
                        <template #cell(expired)="{ value }">
                            {{ getDateTime3(value) }}
                        </template>
                        <template #cell(status)="{ value, item }">
                            <template v-if="item.actived || !item.promotionId">
                                <template v-if="UserInfo.mana < 10">
                                    <span
                                        class="status"
                                        v-b-tooltip.hover
                                        :title="$t('manaTooLow')"
                                    >
                                        {{ $t('inactive') }}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="1.2em"
                                            height="1.2em"
                                            viewBox="0 0 256 256"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M240.26 186.1L152.81 34.23a28.74 28.74 0 0 0-49.62 0L15.74 186.1a27.45 27.45 0 0 0 0 27.71A28.31 28.31 0 0 0 40.55 228h174.9a28.31 28.31 0 0 0 24.79-14.19a27.45 27.45 0 0 0 .02-27.71m-20.8 15.7a4.46 4.46 0 0 1-4 2.2H40.55a4.46 4.46 0 0 1-4-2.2a3.56 3.56 0 0 1 0-3.73L124 46.2a4.77 4.77 0 0 1 8 0l87.44 151.87a3.56 3.56 0 0 1 .02 3.73M116 136v-32a12 12 0 0 1 24 0v32a12 12 0 0 1-24 0m28 40a16 16 0 1 1-16-16a16 16 0 0 1 16 16"
                                            />
                                        </svg>
                                    </span>
                                </template>
                                <template v-else>
                                    <span
                                        class="status"
                                        :class="{ active: value === 0 }"
                                    >
                                        {{
                                            value === 1
                                                ? 'Expired'
                                                : value === -1
                                                ? 'Canceled'
                                                : 'Active'
                                        }}
                                    </span>
                                </template>
                            </template>
                            <template v-else-if="!item.actived">
                                <b-button
                                    v-if="!item.actived"
                                    size="sm"
                                    variant="success"
                                    @click="openSendModal(item)"
                                    class="px-2 py-0 mr-2"
                                >
                                    {{ $t('send') }}
                                </b-button>
                                <b-button
                                    v-if="!item.actived"
                                    size="sm"
                                    variant="success"
                                    @click="openActivationModal(item)"
                                    class="px-2 py-0"
                                >
                                    {{ $t('activate') }}
                                </b-button>
                            </template>
                        </template>
                        <template #cell(moreInfo)="row">
                            <img
                                v-if="
                                    row.item.promotionId === 1 ||
                                    row.item.promotionId === 2
                                "
                                width="30px"
                                src="@/assets/images/icons/package.svg"
                            />
                            <svg
                                v-if="row.item.giftId"
                                xmlns="http://www.w3.org/2000/svg"
                                width="30px"
                                height="30px"
                                viewBox="0 0 36 36"
                                class="pr-1"
                            >
                                <path
                                    fill="#0087cc"
                                    d="M33 31c0 2.2-1.8 4-4 4H7c-2.2 0-4-1.8-4-4V14c0-2.2 1.8-4 4-4h22c2.2 0 4 1.8 4 4z"
                                />
                                <path
                                    fill="#0087cc"
                                    d="M36 11c0 2.2-1.8 4-4 4H4c-2.2 0-4-1.8-4-4s1.8-4 4-4h28c2.2 0 4 1.8 4 4"
                                />
                                <path fill="#005f8f" d="M3 15h30v2H3z" />
                                <path
                                    fill="#da2f47"
                                    d="M19 3h-2a3 3 0 0 0-3 3v29h8V6a3 3 0 0 0-3-3"
                                />
                                <path
                                    fill="#da2f47"
                                    d="M16 7c1.1 0 1.263-.516.361-1.147L9.639 1.147a1.795 1.795 0 0 0-2.631.589L4.992 5.264C4.446 6.219 4.9 7 6 7zm4 0c-1.1 0-1.263-.516-.361-1.147l6.723-4.706a1.796 1.796 0 0 1 2.631.589l2.016 3.527C31.554 6.219 31.1 7 30 7z"
                                />
                            </svg>
                            <b-button
                                size="sm"
                                variant="info"
                                @click="row.toggleDetails"
                                class="px-2 py-0"
                            >
                                {{
                                    row.detailsShowing ? $t('hide') : $t('show')
                                }}
                            </b-button>
                        </template>
                        <template #row-details="{ item }">
                            <div
                                class="d-flex justify-content-between p-2 flex-wrap"
                            >
                                <div>{{ $t('nodeDuration') }}:</div>
                                <div>
                                    {{ item.nodeInfo.quantityUseMonth }}
                                    {{
                                        $tc(
                                            'month',
                                            item.nodeInfo.quantityUseMonth,
                                        )
                                    }}
                                </div>
                            </div>
                            <div
                                class="d-flex justify-content-between p-2 flex-wrap"
                            >
                                <div>{{ $t('createdDate') }}:</div>
                                <div>{{ getDateTime3(item.createdAt) }}</div>
                            </div>
                            <div
                                class="d-flex justify-content-between p-2 flex-wrap"
                                v-if="item.comment && item.comment.length > 0"
                            >
                                <div>{{ $t('comment') }}:</div>
                                <div>{{ item.comment }}</div>
                            </div>
                        </template>
                        <template #head()="data">
                            {{ $t(data.label) }}
                        </template>
                    </b-table>
                </div>
                <div class="d-lg-none">
                    <b-table
                        class="xnode-table"
                        responsive
                        striped
                        hover
                        :fields="mobileFields"
                        :items="UserNode.results"
                        @sort-changed="nodeSort"
                    >
                        <template #cell(name)="{ item }">
                            <div>
                                {{ item.nodeInfo.name }}
                            </div>
                            <div>
                                {{
                                    TruncateToDecimals2(item.nodeInfo.priceUSDT)
                                }}
                                USDT
                            </div>
                        </template>
                        <template #cell(performance)="{ item }">
                            {{ item.nodeInfo.performance }} Hz/h
                        </template>
                        <template #cell(moreInfo)="row">
                            <img
                                v-if="
                                    row.item.promotionId === 1 ||
                                    row.item.promotionId === 2
                                "
                                width="30px"
                                src="@/assets/images/icons/package.svg"
                            />
                            <svg
                                v-if="row.item.giftId"
                                xmlns="http://www.w3.org/2000/svg"
                                width="30px"
                                height="30px"
                                viewBox="0 0 36 36"
                                class="pr-1"
                            >
                                <path
                                    fill="#0087cc"
                                    d="M33 31c0 2.2-1.8 4-4 4H7c-2.2 0-4-1.8-4-4V14c0-2.2 1.8-4 4-4h22c2.2 0 4 1.8 4 4z"
                                />
                                <path
                                    fill="#0087cc"
                                    d="M36 11c0 2.2-1.8 4-4 4H4c-2.2 0-4-1.8-4-4s1.8-4 4-4h28c2.2 0 4 1.8 4 4"
                                />
                                <path fill="#005f8f" d="M3 15h30v2H3z" />
                                <path
                                    fill="#da2f47"
                                    d="M19 3h-2a3 3 0 0 0-3 3v29h8V6a3 3 0 0 0-3-3"
                                />
                                <path
                                    fill="#da2f47"
                                    d="M16 7c1.1 0 1.263-.516.361-1.147L9.639 1.147a1.795 1.795 0 0 0-2.631.589L4.992 5.264C4.446 6.219 4.9 7 6 7zm4 0c-1.1 0-1.263-.516-.361-1.147l6.723-4.706a1.796 1.796 0 0 1 2.631.589l2.016 3.527C31.554 6.219 31.1 7 30 7z"
                                />
                            </svg>
                            <b-button
                                size="sm"
                                variant="info"
                                @click="row.toggleDetails"
                                class="px-2 py-0"
                            >
                                {{
                                    row.detailsShowing ? $t('hide') : $t('show')
                                }}
                            </b-button>
                        </template>
                        <template #row-details="{ item }">
                            <div
                                class="d-flex justify-content-between p-2 flex-wrap"
                            >
                                <div>{{ $t('nodeDuration') }}:</div>
                                <div>
                                    {{ item.nodeInfo.quantityUseMonth }}
                                    {{
                                        $t(
                                            'month',
                                            item.nodeInfo.quantityUseMonth,
                                        )
                                    }}
                                </div>
                            </div>
                            <div
                                class="d-flex justify-content-between p-2 flex-wrap"
                            >
                                <div>{{ $t('createdDate') }}:</div>
                                <div>{{ getDateTime3(item.createdAt) }}</div>
                            </div>
                            <div
                                class="d-flex justify-content-between p-2 flex-wrap"
                            >
                                <div>{{ $t('expiryDate') }}:</div>
                                <div>{{ getDateTime3(item.expired) }}</div>
                            </div>
                            <div
                                class="d-flex justify-content-between p-2 flex-wrap"
                                v-if="item.comment && item.comment.length > 0"
                            >
                                <div>{{ $t('comment') }}:</div>
                                <div>{{ item.comment }}</div>
                            </div>
                            <div
                                class="d-flex justify-content-between p-2 flex-wrap"
                                v-if="item.nodeInfo.performance"
                            >
                                <div>{{ $t('performance') }}:</div>
                                <div>{{ item.nodeInfo.performance }} Hz/h</div>
                            </div>
                            <div
                                class="d-flex justify-content-between p-2 flex-wrap"
                            >
                                <div>{{ $t('status') }}:</div>
                                <div>
                                    <template
                                        v-if="item.actived || !item.promotionId"
                                    >
                                        <template v-if="UserInfo.mana < 10">
                                            <span
                                                class="status"
                                                v-b-tooltip.hover
                                                :title="$t('manaTooLow')"
                                            >
                                                {{ $t('inactive') }}
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="1.2em"
                                                    height="1.2em"
                                                    viewBox="0 0 256 256"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M240.26 186.1L152.81 34.23a28.74 28.74 0 0 0-49.62 0L15.74 186.1a27.45 27.45 0 0 0 0 27.71A28.31 28.31 0 0 0 40.55 228h174.9a28.31 28.31 0 0 0 24.79-14.19a27.45 27.45 0 0 0 .02-27.71m-20.8 15.7a4.46 4.46 0 0 1-4 2.2H40.55a4.46 4.46 0 0 1-4-2.2a3.56 3.56 0 0 1 0-3.73L124 46.2a4.77 4.77 0 0 1 8 0l87.44 151.87a3.56 3.56 0 0 1 .02 3.73M116 136v-32a12 12 0 0 1 24 0v32a12 12 0 0 1-24 0m28 40a16 16 0 1 1-16-16a16 16 0 0 1 16 16"
                                                    />
                                                </svg>
                                            </span>
                                        </template>
                                        <template v-else>
                                            <span
                                                class="status"
                                                :class="{ active: value === 0 }"
                                            >
                                                {{
                                                    value === 1
                                                        ? 'Expired'
                                                        : value === -1
                                                        ? 'Canceled'
                                                        : 'Active'
                                                }}
                                            </span>
                                        </template>
                                    </template>
                                    <template v-else-if="!item.actived">
                                        <b-button
                                            v-if="!item.actived"
                                            size="sm"
                                            variant="success"
                                            @click="openSendModal(item)"
                                            class="px-2 py-0 mr-2"
                                        >
                                            {{ $t('send') }}
                                        </b-button>
                                        <b-button
                                            v-if="!item.actived"
                                            size="sm"
                                            variant="success"
                                            @click="openActivationModal(item)"
                                            class="px-2 py-0"
                                        >
                                            {{ $t('activate') }}
                                        </b-button>
                                    </template>
                                </div>
                            </div>
                        </template>
                        <template #head()="data">
                            {{ $t(data.label) }}
                        </template>
                    </b-table>
                </div>
                <Paginate
                    @current="nodePaging"
                    :totalPages="UserNode.totalPages"
                />
            </template>
            <template
                v-if="
                    UserGift && UserGift.results && UserGift.results.length > 0
                "
            >
                <h3 class="pt-4">{{ $t('giftList') }}</h3>
                <div class="d-none d-lg-block">
                    <b-table
                        class="xnode-table"
                        responsive
                        striped
                        hover
                        :fields="fields"
                        :items="UserGift.results"
                    >
                        <template #cell(name)="{ item }">
                            <div>
                                {{ item.nodeInfo.name }}
                            </div>
                            <div>
                                {{
                                    TruncateToDecimals2(item.nodeInfo.priceUSDT)
                                }}
                                USDT
                            </div>
                        </template>
                        <template #cell(performance)="{ item }">
                            {{ item.nodeInfo.performance }} Hz/h
                        </template>
                        <template #cell(expired)="{ value }">
                            {{ getDateTime3(value) }}
                        </template>
                        <template #cell(status)="{ item, value }">
                            <b-button
                                v-if="value === 0"
                                size="sm"
                                variant="success"
                                @click="openGiftModal(item)"
                                class="px-2 py-0"
                            >
                                {{ $t('gift') }}
                            </b-button>
                            <span
                                v-else-if="value === 2"
                                class="text-success font-weight-bold"
                            >
                                {{ $t('sent') }}
                            </span>
                            <span
                                v-else-if="value === 1"
                                class="text-danger font-weight-bold"
                            >
                                {{ $t('expired') }}
                            </span>
                            <span
                                v-else-if="value === -1"
                                class="text-danger font-weight-bold"
                            >
                                {{ $t('canceled') }}
                            </span>
                        </template>
                        <template #cell(moreInfo)="row">
                            <b-button
                                size="sm"
                                variant="info"
                                @click="row.toggleDetails"
                                class="px-2 py-0"
                            >
                                {{
                                    row.detailsShowing ? $t('hide') : $t('show')
                                }}
                            </b-button>
                        </template>
                        <template #row-details="{ item }">
                            <div
                                class="d-flex justify-content-between p-2 flex-wrap"
                            >
                                <div>{{ $t('nodeDuration') }}:</div>
                                <div>
                                    {{ item.nodeInfo.quantityUseMonth }}
                                    {{
                                        $tc(
                                            'month',
                                            item.nodeInfo.quantityUseMonth,
                                        )
                                    }}
                                </div>
                            </div>
                            <div
                                class="d-flex justify-content-between p-2 flex-wrap"
                            >
                                <div>{{ $t('createdDate') }}:</div>
                                <div>{{ getDateTime3(item.createdAt) }}</div>
                            </div>
                            <div
                                class="d-flex justify-content-between p-2 flex-wrap"
                                v-if="item.comment && item.comment.length > 0"
                            >
                                <div>{{ $t('comment') }}:</div>
                                <div>{{ item.comment }}</div>
                            </div>
                        </template>
                        <template #head()="data">
                            {{ $t(data.label) }}
                        </template>
                    </b-table>
                </div>
                <div class="d-lg-none">
                    <b-table
                        class="xnode-table"
                        responsive
                        striped
                        hover
                        :fields="mobileFields"
                        :items="UserGift.results"
                    >
                        <template #cell(name)="{ item }">
                            <div>
                                {{ item.nodeInfo.name }}
                            </div>
                            <div>
                                {{
                                    TruncateToDecimals2(item.nodeInfo.priceUSDT)
                                }}
                                USDT
                            </div>
                        </template>
                        <template #cell(performance)="{ item }">
                            {{ item.nodeInfo.performance }} Hz/h
                        </template>
                        <template #cell(status)="{ item, value }">
                            <b-button
                                v-if="value === 0"
                                size="sm"
                                variant="success"
                                @click="openGiftModal(item)"
                                class="px-2 py-0"
                            >
                                {{ $t('gift') }}
                            </b-button>
                            <span
                                v-else-if="value === 2"
                                class="text-success font-weight-bold"
                            >
                                {{ $t('sent') }}
                            </span>
                            <span
                                v-else-if="value === 1"
                                class="text-danger font-weight-bold"
                            >
                                {{ $t('expired') }}
                            </span>
                            <span
                                v-else-if="value === -1"
                                class="text-danger font-weight-bold"
                            >
                                {{ $t('canceled') }}
                            </span>
                        </template>
                        <template #cell(moreInfo)="row">
                            <b-button
                                size="sm"
                                variant="info"
                                @click="row.toggleDetails"
                                class="px-2 py-0"
                            >
                                {{
                                    row.detailsShowing ? $t('hide') : $t('show')
                                }}
                            </b-button>
                        </template>
                        <template #row-details="{ item }">
                            <div
                                class="d-flex justify-content-between p-2 flex-wrap"
                            >
                                <div>{{ $t('nodeDuration') }}:</div>
                                <div>
                                    {{ item.nodeInfo.quantityUseMonth }}
                                    {{
                                        $tc(
                                            'month',
                                            item.nodeInfo.quantityUseMonth,
                                        )
                                    }}
                                </div>
                            </div>
                            <div
                                class="d-flex justify-content-between p-2 flex-wrap"
                            >
                                <div>{{ $t('createdDate') }}:</div>
                                <div>{{ getDateTime3(item.createdAt) }}</div>
                            </div>
                            <div
                                class="d-flex justify-content-between p-2 flex-wrap"
                            >
                                <div>{{ $t('expiryDate') }}:</div>
                                <div>{{ getDateTime3(item.expired) }}</div>
                            </div>
                            <div
                                class="d-flex justify-content-between p-2 flex-wrap"
                                v-if="item.comment && item.comment.length > 0"
                            >
                                <div>{{ $t('comment') }}:</div>
                                <div>{{ item.comment }}</div>
                            </div>
                            <div
                                class="d-flex justify-content-between p-2 flex-wrap"
                                v-if="item.nodeInfo.performance"
                            >
                                <div>{{ $t('performance') }}:</div>
                                <div>{{ item.nodeInfo.performance }} Hz/h</div>
                            </div>
                            <div
                                class="d-flex justify-content-between p-2 flex-wrap"
                            >
                                <div>{{ $t('status') }}:</div>
                                <div>
                                    <b-button
                                        v-if="item.status === 0"
                                        size="sm"
                                        variant="success"
                                        @click="openGiftModal(item)"
                                        class="px-2 py-0"
                                    >
                                        {{ $t('gift') }}
                                    </b-button>
                                    <span
                                        v-else-if="item.status === 2"
                                        class="text-success font-weight-bold"
                                    >
                                        {{ $t('sent') }}
                                    </span>
                                    <span
                                        v-else-if="item.status === 1"
                                        class="text-danger font-weight-bold"
                                    >
                                        {{ $t('expired') }}
                                    </span>
                                    <span
                                        v-else-if="item.status === -1"
                                        class="text-danger font-weight-bold"
                                    >
                                        {{ $t('canceled') }}
                                    </span>
                                </div>
                            </div>
                        </template>
                        <template #head()="data">
                            {{ $t(data.label) }}
                        </template>
                    </b-table>
                </div>
                <Paginate
                    @current="giftPaging"
                    :totalPages="UserGift.totalPages"
                ></Paginate>
            </template>
        </div>
        <div
            v-if="ScanData && ScanData.data && ScanData.data.length > 0"
            class="qr-table table-responsive d-lg-block"
        >
            <h3>QR Scan history</h3>
            <b-table
                responsive
                striped
                hover
                :items="ScanData.data"
                :fields="qrfield"
                thead-class="customer-header"
            >
                <template #cell(percent_up)="{ value }">
                    {{ value * 100 }}%
                </template>
            </b-table>
            <Paginate
                @current="ScanPaging"
                :totalPages="ScanData.last_page"
            ></Paginate>
        </div>
        <div class="formula-container">
            <span>{{ $t('clickHere') }}</span>
            <span class="arrow">
                <svg
                    width="41"
                    height="41"
                    viewBox="0 0 41 41"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M35.1683 20.5002L23.0504 32.6181L23.0504 26.6502L23.0504 26.1502L22.5504 26.1502L6.65039 26.1502L6.65039 14.8502L22.5504 14.8502L23.0504 14.8502L23.0504 14.3502L23.0504 8.3823L35.1683 20.5002Z"
                        fill="white"
                        stroke="url(#paint0_linear_3253_678)"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_3253_678"
                            x1="6.15039"
                            y1="20.5002"
                            x2="35.8754"
                            y2="20.5002"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stop-color="#00D1FF" />
                            <stop offset="1" stop-color="#0087C8" />
                        </linearGradient>
                    </defs>
                </svg>
            </span>
            <div class="formula-block" v-b-modal.formula-block>
                <div class="formula-gradient">
                    <i18n path="every120Blocks">
                        <strong> 0.8% </strong>
                    </i18n>
                </div>
            </div>
        </div>
        <b-modal
            id="gift"
            hide-header
            hide-footer
            centered
            dialog-class="supa-modal"
            @hidden="resetNode"
        >
            <form @submit.prevent="giftNode">
                <div class="mi-head">{{ $t('giftAnXNode') }}</div>
                <div v-if="chosenNode.nodeInfo" class="mi-sub">
                    {{ $t('giftThisNode', chosenNode.nodeInfo.name) }}
                </div>
                <label class="mi-input">
                    {{ $t('userID') }}:
                    <input type="text" required v-model="recipientID" />
                </label>
                <div class="botans">
                    <button
                        type="button"
                        class="cancel"
                        @click="$bvModal.hide('gift')"
                    >
                        {{ $t('cancel') }}
                    </button>
                    <button class="confirm" type="submit">
                        {{ $t('gift') }}
                    </button>
                </div>
                <button
                    class="close black"
                    @click="$bvModal.hide('gift')"
                    type="button"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                        />
                    </svg>
                </button>
            </form>
        </b-modal>
        <b-modal
            id="send"
            hide-header
            hide-footer
            centered
            dialog-class="supa-modal"
            @hidden="resetNode"
        >
            <form @submit.prevent="sendNode">
                <div class="mi-head">{{ $t('sendAnXNode') }}</div>
                <div v-if="chosenNode.nodeInfo" class="mi-sub">
                    {{ $t('sendThisNode', chosenNode.nodeInfo.name) }}
                </div>
                <label class="mi-input">
                    {{ $t('userID') }}:
                    <input type="text" required v-model="recipientID" />
                </label>
                <div class="botans">
                    <button
                        type="button"
                        class="cancel"
                        @click="$bvModal.hide('send')"
                    >
                        {{ $t('cancel') }}
                    </button>
                    <button class="confirm" type="submit">
                        {{ $t('send') }}
                    </button>
                </div>
                <button
                    class="close black"
                    @click="$bvModal.hide('send')"
                    type="button"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                        />
                    </svg>
                </button>
            </form>
        </b-modal>
        <b-modal
            id="activation"
            hide-header
            hide-footer
            centered
            dialog-class="supa-modal"
            @hidden="resetNode"
        >
            <div>
                <div class="mi-head">{{ $t('activateAnXNode') }}</div>
                <div v-if="chosenNode.nodeInfo" class="mi-sub">
                    {{ $t('activateThisNode', [chosenNode.nodeInfo.name]) }}
                </div>
                <div class="botans">
                    <button
                        type="button"
                        class="cancel"
                        @click="$bvModal.hide('activation')"
                    >
                        {{ $t('cancel') }}
                    </button>
                    <button class="confirm" @click="activateNode">
                        {{ $t('activate') }}
                    </button>
                </div>
                <button
                    class="close black"
                    @click="$bvModal.hide('activation')"
                    type="button"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                        />
                    </svg>
                </button>
            </div>
        </b-modal>
        <b-modal
            id="formula-block"
            hide-header
            hide-footer
            centered
            dialog-class="formula-modal"
        >
            <!-- <img src="../../assets/images/background/formula.png" alt=""> -->
            <div class="btn-close" @click="$bvModal.hide('formula-block')">
                <svg
                    data-v-b499b0c4=""
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                >
                    <path
                        data-v-b499b0c4=""
                        fill="currentColor"
                        d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                    ></path>
                </svg>
            </div>
            <div class="title">Mining index = action x subscription rate</div>
            <div class="formula">
                <span>Amount of coins received</span>
                <span>=</span>
                <div>
                    <div>Number of coins in block/h x 80%</div>
                    <div>Total mining capacity of the entire system</div>
                </div>
                <span>X</span>
                <span>Personal mining index</span>
            </div>
            <div class="bottom">
                <div class="example">
                    <h3>Example</h3>
                    <div class="example-content">
                        <div>
                            <div>20,000 (token/h) x 80%</div>
                            <div>
                                1,000,000 (10,000 users each mine 100 points)
                            </div>
                        </div>
                        <span>X</span>
                        <span>20,000</span>
                        <span>=</span>
                        <span>400 token</span>
                    </div>
                </div>
                <div class="note">
                    <h3>Note</h3>
                    <div>
                        <div>
                            Will divide the block token equally to each user
                            every hour according to the above formula, 80% to
                            pay rewards and 20% of coins to pay mining
                            commissions.
                        </div>
                        <div>
                            5% of the mined coins will be returned to the
                            <strong>Master node.</strong>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
        <b-modal id="boost-wattage" hide-header hide-footer centered>
            <h3>{{ $t('boostMining') }}</h3>
            <!-- Current password -->
            <form @submit.prevent="boostMining">
                <label class="d-block current-pass merchant">
                    <input
                        v-model="merchantCode"
                        type="text"
                        placeholder="Merchant code"
                    />
                    <QRScan />
                </label>
                <div class="note">
                    *Note: You can click the icon to enable device's camera and
                    scan QR
                </div>
                <div class="update-btn">
                    <button type="submit">Apply</button>
                </div>
            </form>

            <!-- Close button x -->
            <button
                class="close"
                @click="$bvModal.hide('boost-wattage')"
                type="button"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill="currentColor"
                        d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                    />
                </svg>
            </button>
        </b-modal>
        <b-modal id="claim-modal" hide-footer hide-header centered>
            <svg
                @click="$bvModal.hide('claim-modal')"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
            >
                <path
                    fill="currentColor"
                    d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"
                />
            </svg>
            <h2>{{ $t('claim') }}</h2>
            <h3>{{ $t('doYouWantToClaim') }}</h3>
            <!-- <p>
                <i18n path="claimConfirming">
                    <template #mana>
                        <strong>
                            {{ UserInfo.mana }}
                        </strong>
                    </template>
                </i18n>
            </p> -->
            <div>
                <span @click="claim">{{ $t('confirm') }}</span>
                <span @click="$bvModal.hide('claim-modal')">{{
                    $t('cancel')
                }}</span>
            </div>
        </b-modal>
        <b-modal id="percent-modal" hide-footer hide-header centered>
            <h3>Choose your XNode boost</h3>
            <div class="percent-container">
                <div v-for="(item, index) in PercentNode" :key="index">
                    <div>
                        <img
                            v-if="item.percentageOfHz === 5"
                            src="../../assets/images/icons/boost5.png"
                            alt=""
                        />
                        <img
                            v-else-if="item.percentageOfHz === 8"
                            src="../../assets/images/icons/boost8.png"
                            alt=""
                        />
                        <img
                            v-else-if="item.percentageOfHz === 15"
                            src="../../assets/images/icons/boost15.png"
                            alt=""
                        />
                        <img
                            v-else-if="item.percentageOfHz === 25"
                            src="../../assets/images/icons/boost25.png"
                            alt=""
                        />
                        <strong>
                            {{ item.number }}
                        </strong>
                        + {{ item.percentageOfHz }}% mining capacity
                    </div>
                    <input
                        id="percent"
                        @click="selectPercent(item._id)"
                        type="radio"
                        name="radio"
                    />
                </div>
            </div>
            <svg
                class="close"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
                @click="$bvModal.hide('percent-modal')"
            >
                <path
                    fill="currentColor"
                    d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                />
            </svg>
            <button @click="setPercent">Confirm</button>
        </b-modal>
        <b-modal id="reset-bonus-modal" hide-footer hide-header centered>
            <h3>Bonus Capacity</h3>
            <div>
                Current bonus capacity is <span>{{ this.selectedHz }}%</span>.
                Reset bonus ?
            </div>
            <button @click="resetPercent">Confirm</button>
            <svg
                class="close"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
                @click="$bvModal.hide('reset-bonus-modal')"
            >
                <path
                    fill="currentColor"
                    d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                />
            </svg>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import Paginate from '@/components/shared/Paginate.vue';
import QRScan from '../../components/shared/QRScan.vue';

export default {
    name: 'XnodeInventory',
    components: { Paginate, QRScan },
    data() {
        return {
            items: [
                {
                    merchant_id: 1,
                    email: 'duc11102000@gmail.com',
                    user_id: '456789',
                    percent_up: '0.20000000',
                    code: '1234567891563456',
                    start_date: '2024-06-14 07:27:22',
                    end_date: '2024-06-15 07:27:22',
                },
                {
                    merchant_id: 2,
                    email: 'duc11102000@gmail.com',
                    user_id: '456789',
                    percent_up: '0.20000000',
                    code: '1234567891563456',
                    start_date: '2024-06-14 07:27:22',
                    end_date: '2024-06-15 07:27:22',
                },
            ],
            fields: [
                {
                    key: 'name',
                    label: 'name',
                    sortable: true,
                },
                {
                    key: 'performance',
                    label: 'performance',
                    sortable: true,
                },
                {
                    key: 'expired',
                    label: 'expiryDate',
                    sortable: true,
                },
                {
                    key: 'status',
                    label: 'status',
                    sortable: true,
                },
                {
                    key: 'moreInfo',
                    label: 'more',
                    class: 'text-right',
                },
            ],
            mobileFields: [
                {
                    key: 'name',
                    label: 'name',
                    sortable: true,
                },
                {
                    key: 'moreInfo',
                    label: 'more',
                    class: 'text-right',
                },
            ],
            qrfield: [
                {
                    key: 'merchant_id',
                    label: 'Merchant ID',
                    sortable: true,
                },
                {
                    key: 'email',
                    label: 'Email',
                    sortable: true,
                },
                {
                    key: 'user_id',
                    label: 'User ID',
                    sortable: true,
                },
                {
                    key: 'percent_up',
                    label: 'Percent',
                    sortable: true,
                },
                {
                    key: 'code',
                    label: 'Code',
                    sortable: true,
                },
                {
                    key: 'start_date',
                    label: 'Start date',
                    sortable: true,
                },
                {
                    key: 'end_date',
                    label: 'End date',
                    sortable: true,
                },
            ],
            now: moment(),
            inter: null,
            finalDate: moment('2024-03-20'),
            chosenNode: {},
            recipientID: '',
            value: 75,
            max: 100,
            merchantCode: '',
            selectedPercent: null,
            selectedNodeId: null,
            selectedHz: null,
        };
    },
    computed: {
        ...mapGetters({
            XNode: 'info/XNode',
            UserNode: 'mining/UserNode',
            UserGift: 'mining/UserGift',
            Missions: 'missions/Missions',
            UserInfo: 'info/UserInfo',
            ScanHistory: 'merchant/ScanHistory',
            MerchantList: 'merchant/MerchantList',
            PercentNode: 'mining/PercentNode',
        }),
        Countdown() {
            const seconds = this.finalDate.diff(this.now, 'seconds') % 60;
            const minutes = this.finalDate.diff(this.now, 'minutes') % 60;
            const hours = this.finalDate.diff(this.now, 'hours') % 24;
            const days = this.finalDate.diff(this.now, 'days');

            return {
                seconds,
                minutes,
                hours,
                days,
            };
        },
        RenderedMissions() {
            let myArray = [];
            if (this.Missions && this.Missions.length > 0) {
                myArray = [...this.Missions];
                const completed = [];
                for (
                    let i = myArray.findIndex((el) => el.status);
                    i !== -1;
                    i = myArray.findIndex((el) => el.status)
                ) {
                    completed.push(myArray.splice(i, 1)[0]);
                }
                myArray = myArray.concat(completed);
            }
            return myArray;
        },
        ScanData() {
            return this.ScanHistory?.histories;
        },
        MiningRateUp() {
            return this.ScanHistory?.totalPercentUp;
        },
    },
    methods: {
        resetNode() {
            this.chosenNode = {};
            this.recipientID = '';
        },
        giftNode() {
            this.$store
                .dispatch('mining/post_gift', {
                    recipient: this.recipientID,
                    giftId: this.chosenNode.giftId,
                })
                .then((res) => {
                    if (res.status) {
                        this.$bvModal.hide('gift');
                        this.$store.dispatch('info/get_aboutXnode');
                    }
                });
        },
        openGiftModal(record) {
            this.chosenNode = record;
            this.$bvModal.show('gift');
        },

        giftPaging(payload) {
            this.$store.commit('mining/SET_GIFT_FILTER', { page: payload });
            this.$store.dispatch('mining/get_userGift');
        },

        nodePaging(payload) {
            this.$store.commit('mining/SET_NODE_FILTER', { page: payload });
            this.$store.dispatch('mining/get_userNodes');
        },

        openSendModal(payload) {
            this.chosenNode = payload;
            this.$bvModal.show('send');
        },
        sendNode() {
            this.$store
                .dispatch('mining/send_node', {
                    receiver: this.recipientID,
                    nodeId: this.chosenNode._id,
                })
                .then((res) => {
                    if (res.status) {
                        this.$bvModal.hide('send');
                        this.$store.dispatch('info/get_aboutXnode');
                    }
                });
        },

        openActivationModal(payload) {
            this.chosenNode = payload;
            this.$bvModal.show('activation');
        },
        activateNode() {
            this.$store
                .dispatch('mining/activate_node', {
                    nodeId: this.chosenNode._id,
                })
                .then((res) => {
                    if (res.status) {
                        this.$bvModal.hide('activation');
                        this.$store.dispatch('info/get_aboutXnode');
                    }
                });
        },
        nodeSort(ctx) {
            if (ctx.sortBy) {
                if (ctx.sortBy === 'performance' || ctx.sortBy === 'name') {
                    this.$store.commit('mining/SET_NODE_FILTER', {
                        sortBy: `nodeId:${ctx.sortDesc ? 'desc' : 'asc'}`,
                    });
                } else {
                    this.$store.commit('mining/SET_NODE_FILTER', {
                        sortBy: `${ctx.sortBy}:${
                            ctx.sortDesc ? 'desc' : 'asc'
                        }`,
                    });
                }
            }
            this.$store.dispatch('mining/get_userNodes');
        },

        updateMissions() {
            this.$store.dispatch('missions/get_missions').then(() => {
                this.$store.dispatch('info/req_getXnode');
                this.$store.dispatch('info/req_getInfo');
            });
        },

        claim() {
            this.$store.dispatch('mining/claim').then((res) => {
                if (res.status) {
                    this.$store.dispatch('info/req_getInfo');
                    this.$store.dispatch('info/get_aboutXnode');
                    this.$bvModal.hide('claim-modal');
                }
            });
        },

        boostMining() {
            this.$store
                .dispatch('merchant/post_merchantCode', {
                    code: this.merchantCode,
                    email: this.UserInfo.email,
                    user_id: this.UserInfo.refId,
                })
                .then((res) => {
                    if (res.status) {
                        this.$bvModal.hide('boost-wattage');
                        this.ScanPaging(1);
                    }
                });
        },
        ScanPaging(payload) {
            this.$store.dispatch('merchant/get_scanHistory', { page: payload });
        },
        openModalPercent(input) {
            this.selectedNodeId = input;
            this.$bvModal.show('percent-modal');
            console.log('selected node id: ', this.selectedNodeId);
        },
        openModalReset(nodeId, hz) {
            this.selectedNodeId = nodeId;
            this.selectedHz = hz;
            this.$bvModal.show('reset-bonus-modal');
            console.log('selected percent: ', this.selectedPercent);
        },
        selectPercent(input) {
            this.selectedPercent = input;
        },

        setPercent() {
            if (this.selectedPercent && this.selectedNodeId) {
                this.$store
                    .dispatch('mining/setPercent', {
                        nftId: this.selectedPercent,
                        userNodeId: this.selectedNodeId,
                    })
                    .then((res) => {
                        if (res && res.status) {
                            this.$bvModal.hide('percent-modal');
                            this.$store.dispatch('mining/get_userNodes');
                        }
                    });
            } else {
                this.$toastr.e('Please select bonus mining', 'Failed');
            }
        },

        resetPercent() {
            this.$store
                .dispatch('mining/setResetBonus', {
                    userNodeId: this.selectedNodeId,
                })
                .then((res) => {
                    if (res && res.status) {
                        this.$bvModal.hide('reset-bonus-modal');
                        this.$store.dispatch('mining/get_userNodes');
                    }
                });
        },
    },
    mounted() {
        this.inter = setInterval(() => {
            this.now = moment();
        }, 1000);

        this.$store.dispatch('mining/getPercent');
    },
    unmounted() {
        if (this.inter) {
            clearInterval(this.inter);
        }
    },
};
</script>

<style lang="scss" scoped>
.xnodeinvent-container {
    .top {
        .my-xnode {
            background: #1478b0;
            border: 1px solid #d8d8d8;
            padding: 1rem;
            border-radius: 20px;
            height: 100%;

            .header {
                display: flex;
                justify-content: space-between;

                h3 {
                    font-size: 20px;
                    font-weight: 600;
                    color: #fff;
                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                    color: #656565;

                    span {
                        font-size: 16px;
                        font-weight: 500;
                        // color: #ff0000;
                    }
                }
            }

            .xnode-content {
                div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 16px;
                    font-weight: 400;
                    margin-bottom: 1rem;

                    @media (min-width: 576px) {
                        font-size: 18px;
                        margin-bottom: 2rem;
                    }

                    p {
                        color: #fff;
                        margin-bottom: 0px;
                    }

                    p:nth-child(2) {
                        color: #97d7f8;
                        text-align: right;
                    }

                    button {
                        // background: linear-gradient(#00d1ff, #0087c8);
                        background: #fff;
                        width: 80%;
                        margin: 0 auto;
                        padding: 1rem;
                        font-size: 18px;
                        font-weight: 700;
                        color: #1478b0;
                        border-radius: 72px;
                        outline: none;
                        transition: 0.2s;

                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }

                .buttons {
                    gap: 15px;

                    @media (max-width: 575px) {
                        flex-direction: column;

                        button {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .mission {
            background: #1478b0;
            border: 1px solid #d8d8d8;
            padding: 1rem;
            border-radius: 20px;
            height: 100%;

            display: flex;
            flex-direction: column;

            .header {
                display: flex;
                justify-content: space-between;
                width: 100%;
                h3 {
                    font-size: 20px;
                    font-weight: 600;
                    color: #fff;
                }

                .update-missions {
                    background-color: #fff;
                    border-radius: 6px;
                    color: #1478b0;
                }
            }

            .mission-content {
                padding: 1rem 0px;
                flex-grow: 1;

                .content-text {
                    height: 100%;
                    max-height: 650px;
                    overflow-y: scroll;
                    padding-right: 6px;
                    position: relative;
                    // padding-bottom: 80px;

                    &::-webkit-scrollbar {
                        width: 6px;
                        display: none;
                    }

                    &::-webkit-scrollbar-track {
                        background: #f1f1f1;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: #0087cc;
                    }

                    /* Handle on hover */
                    &::-webkit-scrollbar-thumb:hover {
                        background: #0087c8;
                    }

                    > .mission-item {
                        background: #1478b0;
                        gap: 5px;
                        padding: 8px;
                        border-radius: 6px;
                        margin-bottom: 15px;
                        // background-color: #f1f5f9;
                        box-shadow: 1px 3px 4px -4px #020617;
                        border: 1px solid #fff;

                        font-size: 16px;
                        font-weight: 400;

                        @media (min-width: 576px) {
                            font-size: 18px;
                        }

                        &.completed {
                            filter: grayscale(0.8);
                            opacity: 0.8;
                        }

                        > div:nth-child(1) {
                            color: #fff;
                            margin-top: 10px;
                            border-bottom: 1px solid #dedede;
                        }

                        > div:nth-child(2) {
                            color: #fff;
                            white-space: nowrap;
                            display: flex;
                            flex-wrap: wrap;
                            row-gap: 1rem;
                            align-items: center;
                            justify-content: space-between;
                            padding: 5px 0px;
                        }

                        .rewards {
                            white-space: nowrap;
                            position: relative;
                            box-shadow: 0px 0px 0px 1px #64748b;
                            width: 40px;
                            height: 40px;
                            border-radius: 10px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: #e2e8f0;

                            > img {
                                width: 20px;
                                height: 20px;
                            }

                            > div {
                                position: absolute;
                                color: #000;
                                bottom: 2px;
                                right: 2px;
                                line-height: 1;
                                font-weight: 600;
                                text-shadow: 0px 0px 2px white;

                                font-size: 12px;
                            }

                            @media (min-width: 576px) {
                                width: 55px;
                                height: 55px;

                                > img {
                                    width: 24px;
                                    height: 24px;
                                }

                                > div {
                                    font-size: inherit;
                                }
                            }
                        }

                        .mission-status {
                            border-radius: 6px;
                            padding: 5px 15px;
                            background-color: #fff;
                            color: #1478b0;

                            &.completed {
                                background-color: #64748b;
                            }
                        }
                    }
                }

                .swipe-icon {
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    transform: translateX(-50%);
                    color: #fff;
                    width: 40px;
                    animation: sway 3s infinite alternate;
                    @keyframes sway {
                        0% {
                            transform: translate(-50%, -15px);
                        }

                        100% {
                            transform: translate(-50%, 15px);
                        }
                    }
                }

                .swipe {
                    font-size: 18px;
                    font-style: italic;
                    font-weight: 400;
                    color: #fff;
                    text-align: center;
                    margin-top: 41px;
                    margin-bottom: 0;
                }

                .button {
                    display: flex;
                    justify-content: center;

                    button,
                    a {
                        background: linear-gradient(#00d1ff, #0087c8);
                        width: 80%;
                        margin: 0 auto;
                        padding: 1rem;

                        font-size: 18px;
                        font-weight: 600;
                        color: #ffffff;
                        border-radius: 72px;
                        outline: none;
                        text-align: center;
                    }
                }
            }
        }

        @media (min-width: 768px) {
        }
    }

    .table-container {
        background: #1478b0;
        border: 1px solid #d8d8d8;
        border-radius: 20px;
        padding: 1.5rem 0.5rem;
        margin-top: 1rem;
        color: #fff;

        @media (min-width: 768px) {
            padding: 2rem;
        }

        h3 {
            font-size: 20px;
            font-weight: 600;
        }

        .table-name {
            display: flex;
            align-items: center;
            gap: 10px;

            div:nth-child(2) {
                cursor: pointer;
            }
        }
    }

    .formula-container {
        margin-top: 2rem;
        display: flex;
        gap: 1rem;
        justify-content: flex-end;
        align-items: center;
        position: relative;

        span {
            font-size: 14px;
            font-weight: 400;
        }

        .formula-block {
            width: 244px;
            background: linear-gradient(to bottom, #0effff, #124de3);
            padding: 2px;
            border-radius: 15px;

            font-size: 14px;
            font-weight: 400;
            cursor: pointer;

            .formula-gradient {
                background: #fff;
                padding: 15px;
                border-radius: 15px;
            }
        }
    }
}
.botans {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;

    > button {
        border-radius: 100vw;
        padding: 10px 15px;
        font-size: 18px;
    }

    .cancel {
        border: 1px solid #0087cc;
        color: #0087cc;
    }

    .confirm {
        background: linear-gradient(180deg, #00d1ff 0%, #0087c8 100%);
        color: #fff;
        transition: 0.2s;

        &:disabled {
            filter: grayscale(1);
        }
    }

    &.fail {
        grid-template-columns: 1fr;
    }
}

.mi-head {
    font-size: 30px;
    font-weight: 600;
    color: #0087cc;
    text-align: center;

    &.fail {
        color: #cc0000;
    }
}

.mi-sub {
    text-align: center;
    font-weight: 600;
    padding: 20px 0px;
}

.mi-input {
    display: flex;
    align-items: center;
    gap: 5px;

    > input {
        background-color: #cbd5e1;
        border-radius: 6px;
        color: #111827;
        flex-grow: 1;
        min-width: 0;
        padding: 0px 15px;
        margin: 5px 0px;
    }
}
.warning {
    color: #dc2626;
    cursor: pointer;
    vertical-align: text-bottom;
    animation: glowing 1s infinite alternate-reverse;
    @keyframes glowing {
        0% {
            filter: drop-shadow(0px 0px 0px #dc26267a);
        }
        100% {
            filter: drop-shadow(0px 0px 6px #dc26267a);
        }
    }
}

.tooLow {
    color: #dc2626;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>

<style lang="scss">
.xnode-table {
    th {
        white-space: nowrap;
    }
    tbody {
        tr:hover {
            color: inherit;
        }
    }
    .status {
        background: #dc2626;
        padding: 2px 8px;
        border-radius: 6px;
        font-size: 16px;
        svg {
            vertical-align: text-bottom;
        }

        &.active {
            background: #22c55e;
            color: #fff;
        }
    }

    thead,
    tbody {
        color: #fff;
    }
    tbody td {
        vertical-align: middle;
    }
}

.qr-table {
    background: #1478b0;
    border-radius: 22px;
    padding: 1rem;
    margin-top: 2rem;

    h3 {
        font-size: 20px;
        font-weight: 600;
        color: #fff;
    }

    .customer-header {
        background-color: transparent;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #fff;
    }

    .table th,
    .table td {
        border: none;
    }

    table {
        thead {
            tr {
                th {
                    white-space: nowrap;
                }
            }
        }

        tbody {
            tr {
                height: 70px;
                border-radius: 12px;
                background: transparent;

                &:nth-child(even) {
                    background: #e1e1e175;
                }

                td {
                    background: none;
                    height: 100%;
                    padding: 0.75rem;
                    vertical-align: middle;
                    color: #fff;
                    font-size: 14px;
                    font-weight: 500;

                    .mobile-data {
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        line-height: 1;
                        padding: 5px 0px;

                        > div:last-child {
                            color: #fff;
                        }
                    }

                    > span {
                        height: 70px;
                        display: flex;
                        padding: 0 0.75rem;
                        align-items: center;
                        margin-bottom: 0;
                        justify-content: center;
                        backdrop-filter: blur(15.899999618530273px);
                    }
                }
            }
        }
    }
}

#formula-block {
    .formula-modal {
        max-width: 1130px;

        .modal-content {
            max-width: 1130px;

            .modal-body {
                border-radius: 9px;
                position: relative;

                .btn-close {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    cursor: pointer;

                    svg {
                        font-size: 34px;
                    }
                }

                .title {
                    color: #0087cc;
                    font-size: 30px;
                    font-weight: 600;
                    text-align: center;
                }

                .formula {
                    margin-top: 2rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 2rem;

                    span {
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 27px;
                        color: #656565;
                    }

                    > span:nth-child(2),
                    span:nth-child(4) {
                        color: #0087cc;
                        font-weight: 600;
                    }

                    > div {
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 27px;
                        color: #656565;

                        > div:nth-child(1) {
                            padding-bottom: 1rem;
                            border-bottom: 1px solid #656565;
                        }
                    }
                }

                .bottom {
                    margin-top: 3rem;

                    h3 {
                        font-size: 18px;
                        font-weight: 600;
                        color: #0087cc;
                    }

                    .example {
                        .example-content {
                            margin-top: 1rem;
                            display: flex;
                            align-items: center;
                            gap: 1rem;
                            color: #656565;

                            > div {
                                display: flex;
                                flex-direction: column;
                                gap: 1rem;

                                div:nth-child(1) {
                                    text-align: center;
                                    padding-bottom: 1rem;
                                    border-bottom: 1px solid #656565;
                                }
                            }
                        }
                    }

                    .note {
                        div {
                            margin-top: 1rem;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 21px;
                            color: #656565;

                            strong {
                                font-weight: 700;
                                color: #0087cc;
                            }

                            span {
                                font-style: italic;
                                color: #ff0000;
                            }
                        }
                    }
                }

                @media (min-width: 1024px) {
                    .formula {
                        > div {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            gap: 1rem;

                            > div:nth-child(1) {
                                padding-bottom: 1rem;
                                border-bottom: 1px solid #656565;
                            }
                        }
                    }

                    .bottom {
                        display: flex;
                        justify-content: space-around;

                        .example {
                            flex-basis: 50%;
                        }

                        .note {
                            flex-basis: 40%;
                        }
                    }
                }

                @media (min-width: 320px) {
                    padding: 15px;

                    .bottom {
                        .note {
                            margin-top: 2rem;
                        }
                    }
                }
            }
        }
    }
}

#boost-wattage {
    .modal-body {
        border-radius: 22px;
    }

    h3 {
        font-size: 30px;
        font-weight: 600;
        color: #0087cc;
        text-align: center;
        margin-top: 1.5rem;
    }

    input {
        width: 100%;
        padding: 0 1rem;
        border: 1px solid #e4e4e4;
        border-radius: 8px;
        height: 40px;
        color: #000;
        // margin: 0px 0px 15px;
    }

    input:focus-visible {
        outline: none;
    }

    input::placeholder {
        color: #a6a6a6;
        font-size: 18px;
        font-style: italic;
        font-weight: 400;
        text-align: left;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .update-btn {
        display: flex;
        justify-content: center;

        button {
            max-width: 200px;
            width: 100%;
            color: white;
            border-radius: 72px;
            padding: 4px 10px;
            font-size: 18px;
            text-transform: uppercase;
            font-weight: 600;
            text-align: center;
            transition: 0.2s;

            &:nth-child(1) {
                background-image: linear-gradient(
                    to right,
                    #075d9b,
                    #1775b8,
                    #91c4e9,
                    #d2e9fa
                );
                background-position: center;
                background-size: 200%;

                &:hover {
                    background-position: 0%;
                }
            }

            &:nth-child(2) {
                color: #0087cc;
                border: 1px solid #0087cc;
            }
        }
    }

    .close {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .merchant {
        position: relative;
    }

    .note {
        color: #0087cc;
        margin-bottom: 10px;
    }
}

#claim-modal {
    @media (min-width: 320px) {
        .modal-content {
            position: relative;
            width: 100%;
            margin: 0 auto;

            .modal-body {
                border-radius: 12px;

                h2 {
                    color: #0087cc;
                    text-align: center;
                    font-size: 30px;
                    margin-bottom: 15px;
                }

                h3 {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                }

                p {
                    font-size: 16px;
                    font-weight: 500;
                    text-align: center;
                }

                svg {
                    position: absolute;
                    font-size: 30px;
                    top: 10px;
                    right: 10px;
                    cursor: pointer;
                }

                > div {
                    display: flex;
                    align-content: center;
                    justify-content: space-around;
                    gap: 15px;
                    margin-top: 1rem;

                    span {
                        cursor: pointer;
                        max-width: 200px;
                        width: 100%;
                        color: white;
                        border-radius: 72px;
                        padding: 4px 10px;
                        font-size: 18px;
                        text-transform: uppercase;
                        font-weight: 600;
                        text-align: center;
                        transition: 0.2s;

                        &:nth-child(1) {
                            background-image: linear-gradient(
                                to right,
                                #075d9b,
                                #1775b8,
                                #91c4e9,
                                #d2e9fa
                            );
                            background-position: center;
                            background-size: 200%;

                            &:hover {
                                background-position: 0%;
                            }
                        }

                        &:nth-child(2) {
                            color: #0087cc;
                            border: 1px solid #0087cc;
                        }
                    }
                }
            }
        }
    }
    .modal-content {
        .modal-body {
            p {
                strong {
                    color: #0087cc;
                }
            }
        }
    }
}

#percent-modal {
    .modal-body {
        border-radius: 20px;
        padding: 30px;
        position: relative;

        h3 {
            text-align: center;
            color: #0087cc;
            font-size: 30px;
            font-weight: 600;
        }

        .percent-container {
            height: 400px;
            overflow-y: auto;
            margin-top: 1rem;
            > div {
                border: 1px solid #d8d8d8;
                padding: 7px 10px;
                margin-bottom: 15px;
                border-radius: 6px;
                display: flex;
                justify-content: space-between;

                strong {
                    color: #0087cc;
                }

                input[type='radio'] {
                    width: 18px;
                    cursor: pointer;
                    border: none;
                }

                input[type='radio']:checked {
                    background: #0087cc;
                }
            }
        }

        > svg {
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;
        }

        button {
            margin-top: 2rem;
            width: 100%;
            padding: 10px 14px;
            background: #0087cc;
            color: #fff;
            border-radius: 100px;
            outline: none;
            transition: 0.2s;
            &:hover {
                opacity: 0.8;
            }
        }
    }
}

#reset-bonus-modal {
    .modal-body {
        border-radius: 20px;
        padding: 30px;
        position: relative;

        h3 {
            text-align: center;
            color: #0087cc;
            font-size: 30px;
            font-weight: 600;
        }

        > div {
            text-align: center;
            font-size: 18px;
            font-weight: 500;

            span {
                font-weight: 700;
                color: #0087cc;
            }
        }

        > svg {
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;
        }

        button {
            margin-top: 1rem;
            width: 100%;
            padding: 10px 14px;
            background: #0087cc;
            color: #fff;
            border-radius: 100px;
            outline: none;
            transition: 0.2s;
            &:hover {
                opacity: 0.8;
            }
        }
    }
}

.note-text {
    color: #e2db24;
}
</style>
