<template>
    <div>
        <div class="list">
            <div
                class="wrap"
                v-for="voucher in VoucherList"
                :key="voucher.voucherId"
            >
                <div class="voucher">
                    <div class="branding">
                        <img
                            v-if="voucher.voucherId === 1"
                            src="@/assets/images/logo/voucher-3.png"
                        />
                        <img
                            v-if="voucher.voucherId === 2"
                            src="@/assets/images/logo/logov4.png"
                        />
                        <img
                            v-if="voucher.voucherId === 3"
                            src="@/assets/images/logo/voucher-2.png"
                        />
                    </div>
                    <div class="content">
                        <div class="tit">
                            <span>{{ $t('convert') }}</span>
                            <span
                                >{{ voucher.sold }}/{{ voucher.quantity }}</span
                            >
                        </div>
                        <div
                            class="d-flex justify-content-between align-items-end"
                        >
                            <div class="cost">
                                <span>{{ voucher.price }}</span> Mana
                            </div>
                            <button class="buy" @click="openModal(voucher)">
                                {{ $t('buy') }}
                            </button>
                        </div>
                        <div class="prize">
                            <img src="@/assets/images/icons/convert.svg" />
                            <div>{{ voucher.name }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="my-voucher">
            <button @click="$bvModal.show('my-voucher')">
                {{ $t('myVoucher') }}
            </button>
        </div>
        <b-modal
            id="exchange-voucher"
            hide-header
            hide-footer
            centered
            dialog-class="supa-modal"
            @hidden="chosenVoucher = null"
        >
            <div v-if="chosenVoucher" class="exchanging">
                <h2>{{ $t('buyVoucher') }}</h2>
                <h3>{{ $t('areYouSureBuyVoucher') }}</h3>
                <p>
                    {{ $t('currentlyAccount') }}
                    <strong
                        >{{ UserInfo.mana }} / {{ UserInfo.limitMana }}</strong
                    >
                    Mana, {{ $t('doYouWantToBuy') }}
                    <span class="name"> {{ chosenVoucher.name }} </span>
                    Voucher?
                </p>
                <p
                    v-if="chosenVoucher.name === 'DISCOUNT BUY NODE'"
                    class="note"
                >
                    *{{ $t('note') }}: {{ $t('fivePercentDiscountVoucher') }}
                </p>
                <p v-if="chosenVoucher.name === 'CINEMA TICKET'" class="note">
                    *{{ $t('note') }}:
                    {{ $t('checkEmailForTicketsInformation') }}
                    <br>
                    {{ $t('timeToReceiceCode') }}
                </p>

                <div class="buttons">
                    <button
                        class="nice-gradient"
                        @click="buyVoucher(chosenVoucher.voucherId)"
                    >
                        Confirm
                    </button>
                    <button @click="$bvModal.hide('exchange-voucher')">
                        Cancel
                    </button>
                </div>
                <button
                    class="close black"
                    @click="$bvModal.hide('exchange-voucher')"
                    type="button"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        buyVoucherviewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                        />
                    </svg>
                </button>
            </div>
        </b-modal>
        <b-modal
            id="my-voucher"
            title="My Voucher"
            hide-header
            hide-footer
            centered
        >
            <svg
                @click="$bvModal.hide('my-voucher')"
                class="voucher-close"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
            >
                <path
                    fill="currentColor"
                    d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                />
            </svg>
            <h2>{{ $t('myVoucher') }}</h2>
            <div class="user-voucher-container">
                <div
                    class="user-voucher-list"
                    v-if="
                        UserVoucherList.results &&
                        UserVoucherList.results.length > 0
                    "
                >
                    <div
                        class="voucher"
                        v-for="voucher in UserVoucherList.results"
                        :key="voucher.id"
                    >
                        <div class="branding">
                            <img src="@/assets/images/logo/logov4.png" />
                        </div>
                        <div class="content">
                            <div>
                                <div class="tit">
                                    {{ voucher.voucherSetting.name }}
                                </div>
                                <div class="">
                                    <span>For every package</span>
                                </div>
                                <div class="prize">
                                    <div>
                                        {{ voucher.voucherSetting.comment }}
                                    </div>
                                </div>
                                <div
                                    v-if="voucher.voucherSettingId === 2"
                                    class="use"
                                    :class="{ used: voucher.status == 2 }"
                                >
                                    <span
                                        v-if="voucher.status !== 2"
                                        @click="useVoucherXin(voucher._id)"
                                        >Use Now</span
                                    >
                                    <span v-else>Used</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Paginate
                        @current="onChangePage"
                        :totalPages="UserVoucherList.totalPages"
                    />
                </div>
                <div class="no-voucher" v-else>
                    <h3>{{ $t('noVoucher') }}</h3>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Paginate from '@/components/shared/Paginate.vue';

export default {
    name: 'XINVoucher',
    components: { Paginate },
    data() {
        return {
            chosenVoucher: null,
            page: 1,
        };
    },
    computed: {
        ...mapGetters({
            VoucherList: 'voucher/VoucherList',
            UserInfo: 'info/UserInfo',
            UserVoucherList: 'voucher/UserVoucherList',
        }),
    },
    methods: {
        openModal(voucher) {
            this.chosenVoucher = voucher;
            this.$bvModal.show('exchange-voucher');
        },
        buyVoucher(id) {
            this.$store
                .dispatch('voucher/buyVoucher', { voucherSettingId: id })
                .then(() => {
                    this.$bvModal.hide('exchange-voucher');
                    this.$store.dispatch('voucher/userVoucher', {
                        page: this.page,
                    });
                    // filterdVoucherList()
                    this.$store.dispatch('info/req_getInfo');
                    this.$store.dispatch('voucher/voucherList');
                });
        },
        useVoucherXin(id) {
            this.$store.dispatch('voucher/useVoucherXin', id).then(() => {
                this.$store.dispatch('voucher/userVoucher', {
                    page: this.page,
                });
                // filterdVoucherList()
                this.$store.dispatch('info/req_getInfo');
            });
        },
        onChangePage(payload) {
            this.page = payload;
            this.$store.dispatch('voucher/userVoucher', { page: this.page });
            // filterdVoucherList()
        },
    },
    mounted() {
        this.$store.dispatch('voucher/voucherList');
        this.$store.dispatch('voucher/userVoucher', { page: this.page });
    },
};
</script>

<style lang="scss" scoped>
.list {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (min-width: 1280px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    gap: 10px;

    > .wrap {
        flex: 1 1 410px;
        max-width: 480px;
        min-width: 0px;

        .voucher {
            display: flex;

            .branding {
                border-radius: 8px;
                aspect-ratio: 1;
                background-color: #a0d3f8;
                // padding: 5px;
                mask-image: radial-gradient(
                        circle at top right,
                        transparent 8px,
                        black 8px
                    ),
                    radial-gradient(
                        circle at bottom right,
                        transparent 8px,
                        black 8px
                    );
                mask-type: alpha;
                mask-composite: intersect;
                display: flex;
                align-items: center;
                justify-content: center;

                > img {
                    width: 108px;

                    @media (min-width: 992px) {
                        width: 137px;
                    }
                }
            }

            .content {
                border-radius: 8px;
                background-color: #22578a;
                color: white;
                padding: 10px;
                flex: 1;
                mask-image: radial-gradient(
                        circle at top left,
                        transparent 8px,
                        black 8px
                    ),
                    radial-gradient(
                        circle at bottom left,
                        transparent 8px,
                        black 8px
                    );
                mask-type: alpha;
                mask-composite: intersect;
                display: flex;
                flex-direction: column;
                gap: 8px;

                .tit {
                    font-size: 14px;
                    text-transform: uppercase;
                    display: flex;
                    justify-content: space-between;

                    @media (min-width: 992px) {
                        font-size: 18px;
                    }
                }

                .buy {
                    color: #0087cc;
                    background-color: white;
                    font-weight: 600;
                    border-radius: 6px;
                    padding: 2px 15px;
                    outline: none;
                }

                .cost {
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 1;

                    > span {
                        font-size: 32px;
                    }

                    @media (min-width: 992px) {
                        > span {
                            font-size: 50px;
                        }
                    }
                }

                .prize {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    font-size: 16px;
                    font-weight: 600;

                    > img {
                        width: 20px;
                    }

                    @media (min-width: 992px) {
                        font-size: 18px;

                        > img {
                            width: 30px;
                        }
                    }
                }
            }
        }
    }
}
.my-voucher {
    margin-top: 15px;
    max-width: 465px;

    button {
        width: 100%;
        border-radius: 72px;
        background: linear-gradient(to right, #1775b8, #91c4e9);
        font-size: 18px;
        font-weight: 500;
        padding: 10px 15px;
        color: #fff;
        outline: none;
        transition: 0.2s;
        text-transform: uppercase;

        &:hover {
            opacity: 0.8;
        }
    }
}

.exchanging {
    h2 {
        color: #0087cc;
        font-weight: 600;
        font-size: 30px;
        text-align: center;
        margin-bottom: 15px;
    }

    h3 {
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 20px;
    }

    p {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
    }

    .note {
        color: #0087cc;
    }

    .buttons {
        display: flex;
        justify-content: center;
        gap: 10px;

        button {
            max-width: 200px;
            width: 100%;
            padding: 4px 10px;
            border-radius: 72px;
            text-transform: uppercase;
            font-size: 18px;
            font-weight: 600;
            outline: none;

            &:nth-child(1) {
                // background: linear-gradient(to right, #1775B8, #91C4E9);
                color: #fff;
            }

            &:nth-child(2) {
                color: #000;
                border: 1px solid #0087cc;
            }
        }

        .close {
            color: #0087cc;
        }
    }

    @media (max-width: 768px) {
        h2 {
            font-size: 28px;
        }

        h3 {
            font-size: 22px;
        }

        p,
        button {
            font-size: 16px;
        }

        .buttons {
            button {
                padding: 5px 10px;
            }
        }
    }
}

.name {
    color: #0087cc;
    font-weight: 600;
}
</style>

<style lang="scss">
#exchange-voucher {
    .modal-dialog {
        width: 90%;
        margin: 0 auto;
    }
}

#my-voucher {
    .modal-dialog {
        max-width: 782px;
        width: 90%;
        margin: 0 auto;

        @media (max-width: 575px) {
            width: 95%;
        }
    }

    .modal-body {
        position: relative;
        border-radius: 22px;
        position: relative;

        h2 {
            color: #0087c8;
            font-weight: 600;
            text-align: center;
        }

        .user-voucher-container {
            background: #e6e6e6;
            border-radius: 6px;
            padding: 15px 10px;

            h3 {
                font-size: 18px;
                font-weight: 600;
                color: #0087cc;
            }

            p {
                font-size: 14px;
                font-weight: 400;
                color: #3c3c3c;
            }

            .no-voucher {
                h3 {
                    font-size: 30px;
                    text-align: center;
                }
            }

            .user-voucher-list {
                max-height: 385px;
                overflow-y: scroll;
                margin-top: 15px;

                .voucher {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 15px 0px;

                    .branding {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 133px;
                        border-radius: 8px;
                        aspect-ratio: 1;
                        background-color: #006aa1;
                        padding: 1px;
                        mask-image: radial-gradient(
                                circle at top right,
                                transparent 8px,
                                black 8px
                            ),
                            radial-gradient(
                                circle at bottom right,
                                transparent 8px,
                                black 8px
                            );
                        mask-type: alpha;
                        mask-composite: intersect;

                        > img {
                            width: 80px;

                            @media (min-width: 992px) {
                                width: 130px;
                            }
                        }

                        @media (max-width: 575px) {
                            aspect-ratio: unset;
                        }
                    }

                    .content {
                        height: 133px;
                        background-color: #f1f5f9;
                        border-radius: 8px;
                        padding: 10px;
                        flex: 1;
                        mask-image: radial-gradient(
                                circle at top left,
                                transparent 8px,
                                black 8px
                            ),
                            radial-gradient(
                                circle at bottom left,
                                transparent 8px,
                                black 8px
                            );
                        mask-type: alpha;
                        mask-composite: intersect;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        gap: 8px;
                        position: relative;

                        .click-here {
                            background-color: #0087cc;
                            color: white;
                            font-weight: 600;
                            border-radius: 6px;
                            padding: 2px 15px;
                            cursor: pointer;
                            font-size: 14px;
                            transition: 0.2s;
                            position: absolute;
                            top: 0;
                            right: 0;
                            transform: translate(-10%, 40%);

                            a {
                                color: white;
                                text-decoration: none;
                                cursor: pointer;
                            }

                            &:hover {
                                opacity: 0.8;
                            }
                        }

                        input {
                            margin: 0;
                            min-width: 30px;
                            width: unset;
                            cursor: pointer;
                            height: 30px;

                            @media (max-width: 575px) {
                                min-width: 20px;
                                height: 20px;
                            }
                        }

                        div:nth-child(1) {
                            .tit {
                                font-size: 18px;
                                font-weight: 600;
                                text-transform: uppercase;

                                @media (min-width: 992px) {
                                    font-size: 24px;
                                }

                                @media (max-width: 575px) {
                                    font-size: 14px;
                                }
                            }

                            .buy {
                                color: #0087cc;
                                background-color: white;
                                font-weight: 600;
                                border-radius: 6px;
                                padding: 2px 15px;
                            }

                            .cost {
                                font-size: 18px;
                                font-weight: 600;
                                line-height: 1;

                                > span {
                                    font-size: 32px;
                                }

                                @media (min-width: 992px) {
                                    > span {
                                        font-size: 50px;
                                    }
                                }
                            }

                            div:nth-child(2) {
                                @media (max-width: 768px) {
                                    display: none;
                                }
                            }

                            .prize {
                                display: flex;
                                align-items: center;
                                gap: 10px;
                                font-size: 20px;
                                font-weight: 400;
                                color: #0087cc;

                                > img {
                                    width: 20px;
                                }

                                @media (min-width: 992px) {
                                    font-size: 20px;

                                    > img {
                                        width: 36px;
                                    }
                                }

                                @media (max-width: 575px) {
                                    font-size: 14px;
                                }
                            }

                            .use {
                                span {
                                    color: #fff;
                                    background: linear-gradient(
                                        to right,
                                        #1775b8,
                                        #91c4e9
                                    );
                                    cursor: pointer;
                                    padding: 4px 15px;
                                    border-radius: 22px;
                                    font-size: 14px;
                                    transition: 0.2s;

                                    &:hover {
                                        opacity: 0.8;
                                    }
                                }
                            }

                            .used {
                                span {
                                    color: #000;
                                    background: gray;
                                    cursor: pointer;
                                    padding: 4px 15px;
                                    border-radius: 22px;
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }

                .voucher:last-child {
                    border-bottom: none;
                }
            }
        }

        .button {
            max-width: 465px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            margin-top: 15px;

            button {
                background: linear-gradient(to right, #1775b8, #91c4e9);
                width: 90%;
                border-radius: 72px;
                padding: 10px 15px;
                font-size: 18px;
                font-weight: 600;
                color: #fff;
            }
        }

        .voucher-close {
            position: absolute;
            top: 15px;
            right: 15px;
            font-size: 35px;
            cursor: pointer;
        }
    }
}
</style>
