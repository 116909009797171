const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        missions: {},
    }),
    getters: {
        Missions: (state) => state.missions,
    },
    actions: {
        async get_missions({ commit, rootGetters }) {
            if (rootGetters['info/UserInfo']) {
                return axios
                    .post('/mission/mana-day-v2', {
                        user: rootGetters['info/UserInfo'].email,
                        // user: 'ntdchi99+5@gmail.com'
                    })
                    .then((res) => {
                        if (res?.data) {
                            commit('SET_MISSIONS_LIST', res.data);
                        }
                    });
            }
            return false;
        },
    },
    mutations: {
        SET_MISSIONS_LIST(state, data) {
            state.missions = data;
        },
    },
};
