<template>
    <div>
        <div class="content">
            <div v-if="NFTPackage" class="nft-wrap">
                <div
                    v-for="(item, index) in NFTPackage"
                    :key="index"
                    class="packages"
                >
                    <div class="position-relative">
                        <div>
                            <img :src="item.photoUrl" alt="" />
                        </div>
                        <div>
                            <ul v-if="NFTContents[item.nftNumberType]">
                                <li
                                    v-for="desc in NFTContents[
                                        item.nftNumberType
                                    ]"
                                    :key="desc.key"
                                >
                                    {{ $t(desc.key, desc.interpolator) }}
                                </li>
                            </ul>
                            <!-- <ul>
                                <li
                                    v-for="desc in item.content"
                                    :key="desc.des"
                                >
                                    {{ desc.des }}
                                </li>
                            </ul> -->
                            <button
                                class="innder-shadow"
                                @click="
                                    openModalNumber(item, item.nftNumberType)
                                "
                            >
                                {{ $t('buyNowWith') }}
                                {{ TruncateToDecimals2(item.priceUSDT) }} USDT
                            </button>
                        </div>
                        <div
                            v-if="promotionAru(item.nftNumberType)"
                            class="promotion-tag2"
                        >
                            <div>{{ $t('promotion') }}!</div>
                        </div>
                    </div>
                </div>
            </div>
            <b-modal
                id="choose-nft"
                modal-class="nft"
                @change="resetModal"
                centered
                hide-header
                hide-footer
            >
                <h3 v-if="selectedPackage">
                    {{ $t('selectNumberFor') }}
                    {{ TruncateToDecimals2(selectedPackage.priceUSDT) }} USDT
                </h3>
                <div class="search">
                    <input
                        v-model="searchValue"
                        type="number"
                        min="0"
                        inputmode="numeric"
                        pattern="[0-9]*"
                        :placeholder="$t('searchAvailable')"
                        @change="changeNumberQuery"
                    />
                </div>
                <div class="nft-select">
                    <div v-if="fetchingNumbers" class="loading">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="none"
                                stroke="currentColor"
                                stroke-dasharray="16"
                                stroke-dashoffset="16"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M12 3c4.97 0 9 4.03 9 9"
                            >
                                <animate
                                    fill="freeze"
                                    attributeName="stroke-dashoffset"
                                    dur="0.2s"
                                    values="16;0"
                                />
                                <animateTransform
                                    attributeName="transform"
                                    dur="1.5s"
                                    repeatCount="indefinite"
                                    type="rotate"
                                    values="0 12 12;360 12 12"
                                />
                            </path>
                        </svg>
                        <p>{{ $t('fetchNFT') }}</p>
                    </div>
                    <div
                        v-else-if="numberList && numberList.results.length > 0"
                    >
                        <label
                            v-for="(item, index) in numberList.results"
                            :key="index"
                            class="nft-option"
                        >
                            <div>{{ item.number }}</div>
                            <input
                                type="radio"
                                :value="item.number"
                                @change="getNFTId(item.id)"
                                v-model="selectedNum"
                            />
                            <span class="custom-check"></span>
                        </label>
                    </div>
                    <div v-else class="empty-list">
                        {{ $t('noData') }}
                    </div>
                </div>
                <div class="buttons">
                    <button @click="buyNFT(selectedId)">
                        {{ $t('buyNow') }}
                    </button>
                    <button @click="$bvModal.hide('choose-nft')">
                        {{ $t('cancel') }}
                    </button>
                </div>
                <button
                    class="close"
                    @click="$bvModal.hide('choose-nft')"
                    type="button"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                        />
                    </svg>
                </button>
            </b-modal>
            <b-modal
                id="confirm-nft"
                modal-class="confirm-nft"
                centered
                hide-header
                hide-footer
                v-if="selectedPackage"
            >
                <h3>{{ $t('confirmPurchase') }}</h3>
                <div class="content">
                    {{ $t('proceedToBuy') }}
                    {{ selectedPackage.name }}
                </div>
                <div class="nft-selectedNum">
                    <div>{{ $t('chosenNumber') }}</div>
                    <div v-if="selectedNum">
                        {{ selectedNum }}
                    </div>
                </div>
                <div class="payment">
                    <p>{{ $t('currency') }}:</p>
                    <div class="unit">
                        <div
                            :class="{
                                active: currency === 'xinCoin',
                                inactive: currency !== 'xinCoin',
                            }"
                            @click="setCurrency('xinCoin')"
                        >
                            XIN
                        </div>
                        <div
                            :class="{
                                active: currency === 'USDT',
                                inactive: currency !== 'USDT',
                            }"
                            @click="setCurrency('USDT')"
                        >
                            USDT
                        </div>
                        <div
                            :class="{
                                active: currency === '50-50',
                                inactive: currency !== '50-50',
                            }"
                            @click="setCurrency('50-50')"
                        >
                            XIN - USDT
                        </div>
                    </div>
                </div>
                <div class="note">
                    <div v-if="currency === 'USDT'">
                        {{ $t('buy') }} {{ selectedPackage.name }}
                        {{ $t('with') }}
                        {{ TruncateToDecimals2(selectedPackage.priceUSDT) }}
                        USDT
                    </div>
                    <div v-else-if="currency === 'xinCoin'">
                        {{ $t('buy') }} {{ selectedPackage.name }}
                        {{ $t('with') }}
                        {{ TruncateToDecimals2(selectedPackage.priceXin) }} XIN
                    </div>
                    <div v-else-if="currency === '50-50'">
                        <div class="notepay">
                            {{ $t('notepay') }}
                        </div>

                        {{ $t('buy') }} {{ selectedPackage.name }}
                        {{ $t('with') }}
                        {{ TruncateToDecimals2(selectedPackage.priceXin / 2) }}
                        XIN and
                        {{ TruncateToDecimals2(selectedPackage.priceUSDT / 2) }}
                        XIN
                    </div>
                </div>
                <div class="promo-notice">
                    <div v-if="SelectedPackageID">
                        {{
                            SelectedPackageID === 3
                                ? $t('oneKayPromoComment')
                                : SelectedPackageID === 4
                                ? $t('tenKayPromoComment')
                                : ''
                        }}
                    </div>
                </div>
                <div class="buttons">
                    <button @click="confirmBuy(selectedId)">
                        {{ $t('confirm') }}
                    </button>
                    <button @click="$bvModal.hide('confirm-nft')">
                        {{ $t('cancel') }}
                    </button>
                </div>
                <button
                    class="close"
                    @click="$bvModal.hide('confirm-nft')"
                    type="button"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                        />
                    </svg>
                </button>
            </b-modal>
            <NFTHistory />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NFTHistory from './NFTHistory.vue';

export default {
    components: {
        NFTHistory,
    },
    data() {
        return {
            selectedPackage: null,
            selectedNum: '',
            selectedId: null,
            searchValue: '',
            currency: 'USDT',
            page: 1,
            limit: 10,
            NFTContents: [
                [
                    {
                        key: 'freeNFTNoData',
                        interpolator: undefined,
                    },
                ],
                [
                    {
                        key: 'chooseLastNNumber',
                        interpolator: {
                            number: 2,
                        },
                    },
                    {
                        key: 'increaseMiningCapacity',
                        interpolator: {
                            percent: '5%',
                        },
                    },
                ],
                [
                    {
                        key: 'chooseLastNNumber',
                        interpolator: {
                            number: 3,
                        },
                    },
                    {
                        key: 'increaseMiningCapacity',
                        interpolator: {
                            percent: '8%',
                        },
                    },
                    {
                        key: 'freeESIMWithData',
                        interpolator: {
                            data: '5G',
                        },
                    },
                    {
                        key: 'EsimAvailableInCountries',
                    },
                ],
                [
                    {
                        key: 'chooseLastNNumber',
                        interpolator: {
                            number: 4,
                        },
                    },
                    {
                        key: 'increaseMiningCapacity',
                        interpolator: {
                            percent: '15%',
                        },
                    },
                    {
                        key: 'freeESIMWithData',
                        interpolator: {
                            data: '20G',
                        },
                    },
                    {
                        key: 'EsimAvailableInCountries',
                    },
                ],
                [
                    {
                        key: 'chooseLastNNumber',
                        interpolator: {
                            number: 6,
                        },
                    },
                    {
                        key: 'increaseMiningCapacity',
                        interpolator: {
                            percent: '25%',
                        },
                    },
                    {
                        key: 'freeESIMWithData',
                        interpolator: {
                            data: '20G',
                        },
                    },
                    {
                        key: 'EsimAvailableInCountries',
                    },
                ],
            ],
            fetchingNumbers: false,
            numberList: null,
        };
    },

    computed: {
        ...mapGetters({
            NFTPackage: 'nft/NFTPackage',
            NFTNumber: 'nft/NFTNumber',
            NFTPromo: 'promotion/NFTPromo',
        }),

        filteredNum() {
            if (this.NFTNumber) {
                if (!this.searchValue && this.NFTNumber) {
                    return this.NFTNumber.results;
                }
                return this.NFTNumber.results.filter((el) =>
                    el.number
                        .toLowerCase()
                        .includes(this.searchValue.toLowerCase()),
                );
            }
            return [];
        },
        SelectedPackageID() {
            if (this.selectedPackage) {
                return this.selectedPackage.nftNumberType;
            }
            return undefined;
        },
    },

    methods: {
        buyNFT(input) {
            if (this.selectedNum) {
                if (
                    this.selectedPackage &&
                    !(this.selectedPackage.name === 'NFT FREE')
                ) {
                    this.$bvModal.show('confirm-nft');
                } else {
                    this.$store
                        .dispatch('nft/buyNFT', {
                            currency: 'USDT',
                            paymentMethod: 'single',
                            nftNumber: input,
                        })
                        .then(() => {
                            this.$store.dispatch('nft/getNFTPackage');
                            this.$bvModal.hide('confirm-nft');
                            this.$bvModal.hide('choose-nft');
                            this.$store.commit('nft/BUY_NFT_SUCCESS');
                        });
                }
            } else {
                this.$toastr.e('Please select number first !!!', 'Warning');
            }
        },

        firstPackage() {
            this.$bvModal.show('confirm');
        },

        confirmBuy(input) {
            console.log(
                'promotion: ',
                this.promotionAru(this.SelectedPackageID),
            );
            if (this.currency !== '50-50') {
                this.$store
                    .dispatch('nft/buyNFT', {
                        currency: this.currency,
                        paymentMethod: 'single',
                        nftNumber: input,
                        promotionId: this.promotionAru(this.SelectedPackageID)
                            ?._id,
                    })
                    .then(() => {
                        this.$store.dispatch('nft/getNFTPackage');
                        this.$bvModal.hide('confirm-nft');
                        this.$bvModal.hide('choose-nft');
                        this.$store.commit('nft/BUY_NFT_SUCCESS');
                    });
            } else {
                this.$store
                    .dispatch('nft/buyNFT', {
                        currency: this.currency,
                        paymentMethod: 'half',
                        nftNumber: input,
                        promotionId: this.promotionAru(this.SelectedPackageID)
                            ?._id,
                    })
                    .then(() => {
                        this.$store.dispatch('nft/getNFTPackage');
                        this.$bvModal.hide('confirm-nft');
                        this.$bvModal.hide('choose-nft');
                        this.$store.commit('nft/BUY_NFT_SUCCESS');
                    });
            }
        },

        getNFTId(input) {
            this.selectedId = input;
        },

        openModalNumber(input, nftId) {
            this.selectedPackage = input;
            this.$bvModal.show('choose-nft');
            this.$store.dispatch('nft/getNFTNumber', {
                limit: 50,
                nftId,
            });
            this.changeNumberQuery();
        },

        searchNumber(nftId) {
            if (!this.searchValue) {
                this.$store.dispatch('nft/getNFTNumber', {
                    limit: this.limit,
                    nftId,
                });
            } else {
                this.$store.dispatch('nft/getNFTNumber', {
                    limit: this.limit,
                    nftId,
                    number: this.searchValue,
                });
            }
        },

        setCurrency(input) {
            this.currency = input;
            console.log('currency: ', this.currency);
        },

        resetModal() {
            this.selectedNum = '';
            this.searchValue = '';
        },

        refreshNFT(nftId, page) {
            this.$store.dispatch('nft/getNFTNumber', {
                page: page + 1,
                limit: 50,
                nftId,
            });
        },
        async reqList() {
            const res = await this.$store.dispatch('nft/getNFTNumber', {
                page: 1,
                limit: 50,
                nftId: this.selectedPackage.nftNumberType,
                number:
                    this.searchValue.length > 0 ? this.searchValue : undefined,
            });
            if (res.status) {
                this.numberList = res.data;
            }
        },
        async changeNumberQuery() {
            if (this.fetchingNumbers) {
                return;
            }
            this.fetchingNumbers = true;
            this.selectedNum = '';
            await this.reqList();
            this.fetchingNumbers = false;
        },
        promotionAru(nftId) {
            if (this.NFTPromo?.results) {
                return this.NFTPromo.results.find((el) => el.nftId === nftId);
            }
            return undefined;
        },
    },

    mounted() {
        this.$store.dispatch('nft/getNFTPackage');
    },
};
</script>

<style lang="scss" scoped>
.search {
    margin-bottom: 1.5rem;
    input {
        border: 2px solid #0087cc;
        outline: none;
        border-radius: 22px;
        padding: 10px 15px;
        width: 100%;
        transition: 0.2s;
        &:focus {
            box-shadow: #0087cc 0px 0px 15px;
        }
    }
}

.nft {
    h3 {
        color: #0087cc;
        text-align: center;
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 1.5rem;
    }
    .nft-select {
        height: 350px;
        overflow-y: auto;
        color: #0087cc;
        margin-bottom: 1.5rem;
        position: relative;
        .nft-option {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 6px;
            border-bottom: 1px solid #c1c1c1;
            font-weight: 500;
            font-size: 16px;
            cursor: pointer;
            transition: 0.2s;

            &:hover {
                background-color: #1249841c;
            }

            &:last-child {
                border: none;
            }

            > input[type='radio'] {
                position: absolute;
                width: 0;
                height: 0;
                opacity: 0;
            }

            .custom-check {
                display: inline-block;
                width: 24px;
                height: 24px;
                border-radius: 6px;
                border: 1px solid #afafaf;
                transition: 0.2s;
                position: relative;
                color: transparent;

                &::before {
                    content: '';
                    position: absolute;
                    border: 2px solid currentColor;
                    border-radius: 2px;
                    width: 70%;
                    height: 40%;
                    border-color: transparent transparent currentColor
                        currentColor;
                    transform: translate(-50%, -50%) rotate(-45deg);
                    top: 40%;
                    left: 50%;
                }
            }

            > input[type='radio']:checked + .custom-check {
                background-color: #124984;
                color: #fff;
            }
        }

        .loading {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            gap: 15px;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            svg {
                color: #0087cc;
                font-size: 100px;
            }

            p {
                color: #0087cc;
                font-size: 20px;
            }
        }
        .empty-list {
            text-align: center;
            padding-top: 32px;
            font-size: 20px;
        }
    }

    .buttons {
        display: flex;
        justify-content: center;
        gap: 15px;

        button {
            max-width: 200px;
            width: 100%;
            color: white;
            border-radius: 72px;
            padding: 4px 10px;
            font-size: 18px;
            text-transform: uppercase;
            font-weight: 600;
            text-align: center;
            transition: 0.2s;

            &:nth-child(1) {
                background-image: linear-gradient(
                    to right,
                    #075d9b,
                    #1775b8,
                    #91c4e9,
                    #d2e9fa
                );
                background-position: center;
                background-size: 200%;

                &:hover {
                    background-position: 0%;
                }
            }

            &:nth-child(2) {
                color: #0087cc;
                border: 1px solid #0087cc;
            }
        }
    }

    .tip {
        text-align: center;
        font-weight: 600;
        color: #0087cc;
        margin: 10px 0;
    }

    .refresh {
        gap: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0;

        > div {
            display: flex;
            align-items: center;
            gap: 5px;
            color: #0087cc;
            font-weight: 600;
            cursor: pointer;
        }
    }

    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        outline: none;
    }

    @media (max-width: 1500px) {
        .nft-select {
            height: 250px;
        }
    }

    @media (max-width: 414px) {
        h3 {
            font-size: 20px;
        }

        .search {
            input {
                font-size: 14px;
                padding: 5px 10px;
            }
        }

        .buttons {
            button {
                font-size: 14px;
            }
        }
    }
}

.confirm-nft {
    h3 {
        color: #0087cc;
        text-align: center;
        font-size: 22px;
        font-weight: 600;
    }

    .content {
        text-align: center;
        font-weight: 700;
        font-size: 20px;
    }

    .nft-selectedNum {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin: 10px 0;
        font-size: 18px;
        font-weight: 500;

        div:nth-child(2) {
            color: #0087cc;
        }
    }

    .payment {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        gap: 5px;
        margin: 7px 0;

        p {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 0;
        }

        .unit {
            display: flex;
            transition: 0.2s;

            > div {
                padding: 5px 20px;
                color: #fff;
                cursor: pointer;
                font-weight: 700;
                flex-shrink: 0;
                white-space: nowrap;
                &:nth-child(1) {
                    border-top-left-radius: 6px;
                    border-bottom-left-radius: 6px;
                }

                // &:nth-child(2) {
                //     border-top-right-radius: 6px;
                //     border-bottom-right-radius: 6px;
                // }

                &:nth-child(3) {
                    border-top-right-radius: 6px;
                    border-bottom-right-radius: 6px;
                }
            }
        }

        .unit > div.inactive {
            background: #ccc;
            color: #000;
            opacity: 0.3;
        }

        .unit > div.active {
            background: #1775b8;
            color: #fff;
        }

        @media (max-width: 991px) {
            .unit {
                > div {
                    font-size: 14px;
                }
            }
        }

        @media (max-width: 418px) {
            flex-direction: column;

            .unit {
                margin-bottom: 15px;
            }
        }
    }

    .note {
        font-weight: 600;
        text-align: center;
        margin: 10px 0;

        .notepay {
            color: #0087cc;
            text-align: center;
            font-weight: 600;
            font-size: 14px;
            @media (max-width: 376px) {
                font-size: 16px;
            }
        }
    }
    .buttons {
        display: flex;
        justify-content: space-evenly;
        gap: 15px;

        button {
            max-width: 200px;
            width: 100%;
            color: white;
            border-radius: 72px;
            padding: 4px 10px;
            font-size: 18px;
            text-transform: uppercase;
            font-weight: 600;
            text-align: center;
            transition: 0.2s;

            &:nth-child(1) {
                background-image: linear-gradient(
                    to right,
                    #075d9b,
                    #1775b8,
                    #91c4e9,
                    #d2e9fa
                );
                background-position: center;
                background-size: 200%;

                &:hover {
                    background-position: 0%;
                }
            }

            &:nth-child(2) {
                color: #0087cc;
                border: 1px solid #0087cc;
            }
        }
    }

    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        outline: none;
    }

    @media (max-width: 414px) {
        h3 {
            font-size: 20px;
        }

        .nft-selectedNum {
            div:nth-child(1) {
                font-size: 14px;
            }

            div:nth-child(2) {
                font-size: 16px;
            }
        }

        .buttons {
            button {
                font-size: 14px;
            }
        }
    }
}

.nft-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;

    .packages {
        width: 100%;
        border-radius: 22px;

        > div {
            height: 100%;
            padding: 15px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1rem;
            justify-content: space-between;
            gap: 10px;
            border-radius: 22px;
            border: 1px solid #d8d8d8;
            background: #ffffff;

            ul {
                padding-left: 30px;
                padding-top: 15px;
                li {
                    color: #3c3c3c;
                    font-size: 14px;
                }
            }

            button {
                width: 100%;
                padding: 6px 12px;
                background: #0087cc;
                border-radius: 22px;
                color: #fff;
                font-weight: 600;
                outline: none;
                transition: 0.8s;
                &:hover {
                    background-image: linear-gradient(
                        rgba(33, 111, 219, 1),
                        rgba(0, 68, 255, 1)
                    );
                }
            }

            div:nth-child(1) {
                display: flex;
                align-items: center;
                img {
                    width: 100%;
                }
            }

            div:nth-child(2) {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
            }
        }
    }

    @media (max-width: 1201px) {
        .packages {
            > div {
                display: flex;
                flex-direction: column;
                height: 450px;

                div:nth-child(2) {
                    width: 100%;
                }
            }
        }
    }

    @media (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);

        .packages {
            > div {
                height: unset;
            }
        }
    }
}

.innder-shadow {
    width: 100%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px 0px inset,
        rgba(17, 17, 26, 0.05) 0px 8px 32px 0px;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #0087cc;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>

<style lang="scss">
.nft {
    .modal-dialog {
        max-width: 700px;
    }
    .modal-content {
        border-radius: 22px;
        .modal-body {
            padding: 3rem;
            border-radius: 19px;
            background-image: url('~@/assets/images/mockup/pattern.png');

            @media (max-width: 414px) {
                padding: 1rem;

                h3 {
                    margin-top: 1rem;
                }
            }
        }
    }
}

.confirm-nft {
    .modal-content {
        border-radius: 22px;
        .modal-body {
            padding: 2rem 1rem;
            border-radius: 19px;

            @media (max-width: 414px) {
                padding: 1rem;
            }
        }
    }
}
.promotion-tag {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 60px;
    height: 60px;
    color: rgb(233, 50, 50);
    transform: translate(-50%, -50%) rotate(-30deg);
    // mask-image: url('~@/assets/images/icons/badge.svg');
    background-color: #ffe53b;
    background-image: linear-gradient(147deg, #ffe53b 0%, #ff2525 74%);
    animation: zoomin 3s infinite alternate;
    @keyframes zoomin {
        0% {
            transform: translate(-50%, -50%) rotate(-30deg) scale(1);
        }
        100% {
            transform: translate(-50%, -50%) rotate(-30deg) scale(1.15);
        }
    }
    > div {
        position: absolute;
        font-size: 10px;
        line-height: 1.1;
        color: rgb(255, 255, 255);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 700;
        max-width: 100%;
        max-height: 100%;
        padding: 10px;
        text-align: center;
    }
}
.promotion-tag2 {
    background-color: #ff2525;
    position: absolute;

    top: 4px;
    right: -0.8em;
    // transform: translate(-50%, -50%) rotate(-30deg);
    line-height: 1.6;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    padding: 0px 8px;

    filter: drop-shadow(1px 1px 2px #000000aa);
    &::before {
        content: '';
        position: absolute;
        border: 0.8em solid #ff2525;
        border-left-color: transparent;
        right: 100%;
        top: 0;
        bottom: 0;
    }
    &::after {
        content: '';
        position: absolute;
        border: 0.4em solid transparent;
        border-left-color: #ce1f1f;
        border-top-color: #ce1f1f;
        right: 0;
        top: 100%;
        z-index: -1;
    }
}
.promo-notice {
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-color: #f76b1c;
    background-image: linear-gradient(to right, #f76b1c, #ff2525, #f76b1c);
    background-size: 200% 100%;
    animation: panning 2s infinite linear;

    text-align: center;
    font-weight: 600;
    // font-size: 1.125em;

    max-width: 300px;
    margin: 15px auto;
    // filter: drop-shadow(0px 0px 1px #f76b1cbb);
    @keyframes panning {
        0% {
            background-position: 0% 0%;
        }
        100% {
            background-position: 200% 0%;
        }
    }
}
</style>
