var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.FilteredTypes)?_c('b-container',{staticClass:"activity-tab"},[_c('div',{staticClass:"activity-list"},[_c('div',{staticClass:"filter-history"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('activityType')))]),_c('div',{staticClass:"group-hist"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.search.type),expression:"search.type"}],key:_vm.FilteredTypes.length,staticClass:"form-control select-type",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.search, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.FilteredTypes),function(item){return _c('option',{key:item.value,domProps:{"value":item.value}},[_vm._v(" "+_vm._s(_vm.$t(("" + (item.text))))+" ")])}),0)])]),_c('div',{staticClass:"d-none d-lg-block table-responsive mt-4"},[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.History.list,"fields":_vm.myFields[_vm.search.type],"show-empty":"","thead-class":"customer-header","no-local-sorting":""},on:{"sort-changed":_vm.detectSort},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}},{key:"cell()",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(value))])]}},{key:"empty",fn:function(){return [_c('h4',{staticClass:"text-center my-3"},[_vm._v(_vm._s(_vm.$t('noData')))])]},proxy:true},{key:"cell(transaction)",fn:function(data){return [_c('div',[(data.item.transaction)?_c('a',{attrs:{"target":"_blank","href":("" + _vm.url + (data.item.transaction))}},[_vm._v(" "+_vm._s(data.item.transaction.slice(0, 5) + '...' + data.item.transaction.slice( data.item.transaction.length - 10, data.item.transaction.length ))+" ")]):_vm._e()])]}},{key:"cell(createdAt)",fn:function(data){return [_c('div',[_vm._v(" "+_vm._s(Number.isInteger(data.item.createdAt) ? _vm.getDateTime( data.item.createdAt * 1000) : _vm.getDateTime3(data.item.createdAt))+" ")])]}},{key:"cell(status)",fn:function(data){return [(_vm.search.type === 'reversecommission')?_c('div',[(data.item.status === -1)?_c('div',{staticClass:"text-danger"},[_c('strong',[_vm._v(_vm._s(_vm.$t('canceled')))])]):_vm._e(),(data.item.status === 0)?_c('div',{staticClass:"text-warning"},[_c('strong',[_vm._v(_vm._s(_vm.$t('pending')))])]):_vm._e(),(data.item.status === 1)?_c('div',{staticClass:"received"},[_c('strong',[_vm._v(_vm._s(_vm.$t('received')))])]):_vm._e(),(data.item.status === 2)?_c('div',{staticClass:"text-warning"},[_c('strong',[_vm._v(_vm._s(_vm.$t('expired')))])]):_vm._e()]):_c('div',[_c('span',{class:data.item.status ? 'success' : 'text-danger'},[_vm._v(" "+_vm._s(data.item.status ? 'Success' : 'Failed')+" ")])])]}},{key:"cell(action)",fn:function(data){return [_c('div',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(data.item.action)+" ")])]}},{key:"cell(currency)",fn:function(ref){
var value = ref.value;
return [_c('div',[(
                                value === 'xinCoin' || value === 'bxinCoin'
                            )?_c('img',{attrs:{"src":require("@/assets/images/logo/xin.png"),"width":"30px"}}):_c('img',{attrs:{"src":require("@/assets/images/logo/usdt.png"),"width":"30px"}})])]}},{key:"cell(amount)",fn:function(ref){
                            var value = ref.value;
return [_c('div',[_vm._v(" "+_vm._s(_vm.TruncateToDecimals2(value, '', 4))+" ")])]}},{key:"cell(expired)",fn:function(data){return [_c('div',[_vm._v(" "+_vm._s(Number.isInteger(data.item.createdAt) ? _vm.getDateTime( data.item.createdAt * 1000) : _vm.getDateTime3(data.item.createdAt))+" ")])]}},{key:"cell(fee)",fn:function(ref){
                            var item = ref.item;
                            var value = ref.value;
return [_c('div',[_vm._v(" "+_vm._s(value)+" "),(item.to)?[(
                                    item.to === 'xinCoin' ||
                                    item.to === 'bxinCoin'
                                )?_c('img',{attrs:{"src":require("@/assets/images/logo/xin.png"),"width":"30px"}}):_c('img',{attrs:{"src":require("@/assets/images/logo/usdt.png"),"width":"30px"}})]:_vm._e()],2)]}},{key:"cell(from)",fn:function(ref){
                                var value = ref.value;
return [_c('div',[(_vm.search.type !== 'commission')?[(
                                    value === 'xinCoin' ||
                                    value === 'bxinCoin'
                                )?_c('img',{attrs:{"src":require("@/assets/images/logo/xin.png"),"width":"30px"}}):_c('img',{attrs:{"src":require("@/assets/images/logo/usdt.png"),"width":"30px"}})]:[_vm._v(" "+_vm._s(value)+" ")]],2)]}},{key:"cell(to)",fn:function(ref){
                                var value = ref.value;
return [_c('div',[(
                                value === 'xinCoin' || value === 'bxinCoin'
                            )?_c('img',{attrs:{"src":require("@/assets/images/logo/xin.png"),"width":"30px"}}):_c('img',{attrs:{"src":require("@/assets/images/logo/usdt.png"),"width":"30px"}})])]}},{key:"cell(comment)",fn:function(ref){
                            var value = ref.value;
return [_c('div',{staticClass:"comment"},[_vm._v(" "+_vm._s(_vm.ManipulateString(value))+" ")])]}},{key:"cell(claimed)",fn:function(ref){
                            var value = ref.value;
return [_c('div',[_c('span',{class:value === 1 ? 'success' : 'text-danger'},[_vm._v(" "+_vm._s(value === 1 ? 'Claimed' : value === -1 ? 'Canceled' : 'Mined')+" ")])])]}},{key:"cell(reservedTime)",fn:function(ref){
                            var value = ref.value;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getDateTime(value))+" ")])]}},{key:"cell(#)",fn:function(row){return [_c('div',[_c('b-button',{staticClass:"px-2 py-0",attrs:{"size":"sm","variant":"info"},on:{"click":row.toggleDetails}},[_vm._v(" "+_vm._s(row.detailsShowing ? _vm.$t('hide') : _vm.$t('show'))+" ")])],1)]}},{key:"row-details",fn:function(ref){
                            var item = ref.item;
return [(_vm.search.type === 'reversecommission')?_c('div',{staticClass:"details"},[(item.createdAt)?_c('div',{staticClass:"mobile-data"},[_c('div',[_vm._v(_vm._s(_vm.$t('createdDate'))+":")]),_c('div',[_vm._v(" "+_vm._s(Number.isInteger(item.createdAt) ? _vm.getDateTime(item.createdAt * 1000) : _vm.getDateTime3(item.createdAt))+" ")])]):_vm._e(),_c('div',{staticClass:"mobile-data"},[_c('span',[_vm._v(_vm._s(_vm.$t('remaining'))+":")]),_c('span',[_c('span',[_vm._v(" "+_vm._s(Number(item.paid).toLocaleString( 'en-US' ))+" "),_c('i',{staticClass:"subtext"},[_vm._v(" ("+_vm._s(_vm.$t('settled'))+") ")])]),_vm._v(" + "),_c('span',[_vm._v(" "+_vm._s(Number(item.amount).toLocaleString( 'en-US' ))+" "),_c('i',{staticClass:"subtext"},[_vm._v(" ("+_vm._s(_vm.$t('remaining'))+") ")])]),_vm._v(" = "),_c('span',[_vm._v(" "+_vm._s(Number( item.amount + item.paid ).toLocaleString('en-US'))+" ")])])]),(item.comment)?_c('div',{staticClass:"mobile-data"},[_c('span',[_vm._v(_vm._s(_vm.$t('comment'))+": ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.comment)+" ")])]):_vm._e()]):_c('div',{staticClass:"details"},[(item.transaction)?_c('div',{staticClass:"mobile-data"},[_c('div',[_vm._v("Transaction:")]),_c('a',{attrs:{"target":"_blank","href":("" + _vm.url + (item.transaction))}},[(item.transaction)?_c('span',[_vm._v(" "+_vm._s(item.transaction.slice(0, 5) + '...' + item.transaction.slice( item.transaction.length - 10, item.transaction.length ))+" ")]):_vm._e()])]):_vm._e(),(typeof item.fee === 'number')?_c('div',{staticClass:"mobile-data"},[_c('span',[_vm._v("Fee: ")]),_c('span',[_vm._v(" "+_vm._s(item.fee)+" "),(item.currency)?[(
                                            item.currency === 'xinCoin' ||
                                            item.currency === 'bxinCoin'
                                        )?_c('img',{attrs:{"src":require("@/assets/images/logo/xin.png"),"width":"30px"}}):_c('img',{attrs:{"src":require("@/assets/images/logo/usdt.png"),"width":"30px"}})]:[(
                                            item.to === 'xinCoin' ||
                                            item.to === 'bxinCoin'
                                        )?_c('img',{attrs:{"src":require("@/assets/images/logo/xin.png"),"width":"30px"}}):_c('img',{attrs:{"src":require("@/assets/images/logo/usdt.png"),"width":"30px"}})]],2)]):_vm._e(),(item.createdAt)?_c('div',{staticClass:"mobile-data"},[_c('div',[_vm._v("Time of creation:")]),_c('div',[_vm._v(" "+_vm._s(Number.isInteger(item.createdAt) ? _vm.getDateTime(item.createdAt * 1000) : _vm.getDateTime3(item.createdAt))+" ")])]):_vm._e(),(item.status)?_c('div',{staticClass:"mobile-data"},[_c('span',[_vm._v("Status: ")]),_c('span',{class:item.status ? 'success' : 'text-danger'},[_vm._v(" "+_vm._s(item.status ? 'Success' : 'Failed')+" ")])]):_vm._e(),(item.comment)?_c('div',{staticClass:"mobile-data"},[_c('span',[_vm._v("Comment: ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.comment)+" ")])]):_vm._e(),(item.currency)?_c('div',{staticClass:"mobile-data"},[_c('span',[_vm._v("Currency: ")]),_c('span',[(
                                        item.currency === 'xinCoin' ||
                                        item.currency === 'bxinCoin'
                                    )?_c('img',{attrs:{"src":require("@/assets/images/logo/xin.png"),"width":"18px"}}):_c('img',{attrs:{"src":require("@/assets/images/logo/usdt.png"),"width":"18px"}})])]):_vm._e()])]}},{key:"cell(settled)",fn:function(ref){
                                    var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.paid) - Number(item.amount))+" ")]}},{key:"cell(totalCom)",fn:function(ref){
                                    var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.paid) + Number(item.amount))+" ")]}},{key:"cell(manaType)",fn:function(ref){
                                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.userId === item.receiverId ? _vm.$t('limitBreak') : _vm.$t('transferMana'))+" ")]}},{key:"cell(manaAmount)",fn:function(ref){
                                    var item = ref.item;
return [(item.userId === item.receiverId)?[_vm._v(" "+_vm._s(item.amount)+" USDT ")]:[_vm._v(" "+_vm._s(item.amount)+" Mana ")]]}}],null,false,2426790086)})],1),_c('div',{staticClass:"d-lg-none mobile table-responsive mt-4"},[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.History.list,"fields":_vm.myMobileFields[_vm.search.type],"show-empty":"","thead-class":"customer-header","no-local-sorting":""},on:{"sort-changed":_vm.detectSort},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
                                    var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}},{key:"cell()",fn:function(ref){
                                    var value = ref.value;
return [_c('div',[_vm._v(_vm._s(value))])]}},{key:"empty",fn:function(){return [_c('h4',{staticClass:"text-center my-3"},[_vm._v("No Data")])]},proxy:true},{key:"row-details",fn:function(ref){
                                    var item = ref.item;
return [(_vm.search.type === 'reversecommission')?_c('div',{staticClass:"details"},[(item.createdAt)?_c('div',{staticClass:"mobile-data"},[_c('div',[_vm._v(_vm._s(_vm.$t('createdDate'))+":")]),_c('div',[_vm._v(" "+_vm._s(Number.isInteger(item.createdAt) ? _vm.getDateTime(item.createdAt * 1000) : _vm.getDateTime3(item.createdAt))+" ")])]):_vm._e(),_c('div',{staticClass:"mobile-data"},[_c('span',[_vm._v(_vm._s(_vm.$t('commission'))+":")]),_c('span',[_c('span',[_vm._v(" "+_vm._s(Number(item.paid).toLocaleString( 'en-US' ))+" "),_c('i',{staticClass:"subtext"},[_vm._v(" ("+_vm._s(_vm.$t('settled'))+") ")])]),_vm._v(" + "),_c('span',[_vm._v(" "+_vm._s(Number(item.amount).toLocaleString( 'en-US' ))+" "),_c('i',{staticClass:"subtext"},[_vm._v(" ("+_vm._s(_vm.$t('remaining'))+") ")])]),_vm._v(" = "),_c('span',[_vm._v(" "+_vm._s(Number( item.amount + item.paid ).toLocaleString('en-US'))+" ")])])]),(item.comment)?_c('div',{staticClass:"mobile-data"},[_c('span',[_vm._v(_vm._s(_vm.$t('comment'))+": ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.comment)+" ")])]):_vm._e()]):_c('div',{staticClass:"details"},[(item.transaction)?_c('div',{staticClass:"mobile-data"},[_c('div',[_vm._v("Transaction:")]),_c('a',{attrs:{"target":"_blank","href":("" + _vm.url + (item.transaction))}},[(item.transaction)?_c('span',[_vm._v(" "+_vm._s(item.transaction.slice(0, 5) + '...' + item.transaction.slice( item.transaction.length - 10, item.transaction.length ))+" ")]):_vm._e()])]):_vm._e(),(typeof item.fee === 'number')?_c('div',{staticClass:"mobile-data"},[_c('span',[_vm._v("Fee: ")]),_c('span',[_vm._v(" "+_vm._s(item.fee)+" "),(item.currency)?[(
                                            item.currency === 'xinCoin' ||
                                            item.currency === 'bxinCoin'
                                        )?_c('img',{attrs:{"src":require("@/assets/images/logo/xin.png"),"width":"30px"}}):_c('img',{attrs:{"src":require("@/assets/images/logo/usdt.png"),"width":"30px"}})]:[(
                                            item.to === 'xinCoin' ||
                                            item.to === 'bxinCoin'
                                        )?_c('img',{attrs:{"src":require("@/assets/images/logo/xin.png"),"width":"30px"}}):_c('img',{attrs:{"src":require("@/assets/images/logo/usdt.png"),"width":"30px"}})]],2)]):_vm._e(),(item.createdAt)?_c('div',{staticClass:"mobile-data"},[_c('div',[_vm._v("Time of creation:")]),_c('div',[_vm._v(" "+_vm._s(Number.isInteger(item.createdAt) ? _vm.getDateTime(item.createdAt * 1000) : _vm.getDateTime3(item.createdAt))+" ")])]):_vm._e(),(item.status)?_c('div',{staticClass:"mobile-data"},[_c('span',[_vm._v("Status: ")]),_c('span',{class:item.status ? 'success' : 'text-danger'},[_vm._v(" "+_vm._s(item.status ? 'Success' : 'Failed')+" ")])]):_vm._e(),(item.comment)?_c('div',{staticClass:"mobile-data"},[_c('span',[_vm._v("Comment: ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.comment)+" ")])]):_vm._e(),(item.currency)?_c('div',{staticClass:"mobile-data"},[_c('span',[_vm._v("Currency: ")]),_c('span',[(
                                        item.currency === 'xinCoin' ||
                                        item.currency === 'bxinCoin'
                                    )?_c('img',{attrs:{"src":require("@/assets/images/logo/xin.png"),"width":"18px"}}):_c('img',{attrs:{"src":require("@/assets/images/logo/usdt.png"),"width":"18px"}})])]):_vm._e()])]}},{key:"cell(action)",fn:function(data){return [_c('div',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(data.item.action)+" ")])]}},{key:"cell(status)",fn:function(data){return [_c('div',[_c('span',{class:data.item.status ? 'success' : 'text-danger'},[_vm._v(" "+_vm._s(data.item.status ? 'Success' : 'Failed')+" ")])])]}},{key:"cell(#)",fn:function(row){return [_c('div',[_c('b-button',{staticClass:"px-2 py-0",attrs:{"size":"sm","variant":"info"},on:{"click":row.toggleDetails}},[_vm._v(" "+_vm._s(row.detailsShowing ? _vm.$t('hide') : _vm.$t('show'))+" ")])],1)]}},{key:"cell(currency)",fn:function(ref){
                                    var value = ref.value;
return [_c('div',[(
                                value === 'xinCoin' || value === 'bxinCoin'
                            )?_c('img',{attrs:{"src":require("@/assets/images/logo/xin.png"),"width":"30px"}}):_c('img',{attrs:{"src":require("@/assets/images/logo/usdt.png"),"width":"30px"}})])]}},{key:"cell(amount)",fn:function(ref){
                            var value = ref.value;
return [_c('div',[_vm._v(" "+_vm._s(_vm.TruncateToDecimals2(value, '', 4))+" ")])]}},{key:"cell(from)",fn:function(ref){
                            var value = ref.value;
return [_c('div',[(_vm.search.type !== 'commission')?[(
                                    value === 'xinCoin' ||
                                    value === 'bxinCoin'
                                )?_c('img',{attrs:{"src":require("@/assets/images/logo/xin.png"),"width":"30px"}}):_c('img',{attrs:{"src":require("@/assets/images/logo/usdt.png"),"width":"30px"}})]:[_vm._v(" "+_vm._s(value)+" ")]],2)]}},{key:"cell(to)",fn:function(ref){
                                var value = ref.value;
return [_c('div',[(
                                value === 'xinCoin' || value === 'bxinCoin'
                            )?_c('img',{attrs:{"src":require("@/assets/images/logo/xin.png"),"width":"30px"}}):_c('img',{attrs:{"src":require("@/assets/images/logo/usdt.png"),"width":"30px"}})])]}},{key:"cell(comment)",fn:function(ref){
                            var value = ref.value;
return [_c('div',{staticClass:"comment"},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"cell(claimed)",fn:function(ref){
                            var value = ref.value;
return [_c('div',[_c('span',{class:value === 1 ? 'success' : 'text-danger'},[_vm._v(" "+_vm._s(value === 1 ? 'Claimed' : value === -1 ? 'Canceled' : 'Mined')+" ")])])]}},{key:"cell(reservedTime)",fn:function(ref){
                            var value = ref.value;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getDateTime(value))+" ")])]}},{key:"cell(remainingReserve)",fn:function(ref){
                            var item = ref.item;
return [_c('div',[_c('div',[_c('div',[_vm._v(" "+_vm._s(Number(item.amount).toLocaleString( 'en-US' ))+" "),(
                                        item.currency === 'xinCoin' ||
                                        item.currency === 'bxinCoin'
                                    )?_c('img',{attrs:{"src":require("@/assets/images/logo/xin.png"),"width":"24px"}}):_c('img',{attrs:{"src":require("@/assets/images/logo/usdt.png"),"width":"24px"}})]),_c('div',[(item.status === -1)?_c('div',{staticClass:"text-danger"},[_c('strong',[_vm._v(_vm._s(_vm.$t('canceled')))])]):_vm._e(),(item.status === 0)?_c('div',{staticClass:"text-warning"},[_c('strong',[_vm._v(_vm._s(_vm.$t('pending')))])]):_vm._e(),(item.status === 1)?_c('div',{staticClass:"received"},[_c('strong',[_vm._v(_vm._s(_vm.$t('received')))])]):_vm._e(),(item.status === 2)?_c('div',{staticClass:"text-warning"},[_c('strong',[_vm._v(_vm._s(_vm.$t('expired')))])]):_vm._e()])])])]}},{key:"cell(manaType)",fn:function(ref){
                                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.userId === item.receiverId ? 'Limit break' : 'Tranfer')+" ")]}},{key:"cell(manaAmount)",fn:function(ref){
                                    var item = ref.item;
return [(item.userId === item.receiverId)?[_vm._v(" "+_vm._s(item.amount)+" USDT ")]:[_vm._v(" "+_vm._s(item.amount)+" Mana ")]]}}],null,false,3996847780)})],1)]),_c('div',{staticClass:"table-paginate"},[_c('Paginate',{attrs:{"totalPages":_vm.History.total},on:{"current":_vm.onChangePage}})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }