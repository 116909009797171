<template>
    <div class="container py-2">
        <div class="banners">
            <template v-if="$route.name === 'XINVoucher'">
                <img
                    style="max-width: 100%"
                    class="d-none d-md-inline"
                    src="@/assets/images/mockup/banner.png"
                />
                <img
                    style="max-width: 100%"
                    class="d-md-none"
                    src="@/assets/images/mockup/longBanner.png"
                />
            </template>
            <template v-else-if="RouteMatching">
                <img
                    style="max-width: 100%"
                    class="d-none d-md-inline"
                    src="@/assets/images/mockup/gotitBannerLong.png"
                />
                <img
                    style="max-width: 100%"
                    class="d-md-none"
                    src="@/assets/images/mockup/gotitBanner.png"
                />
            </template>
        </div>
        <div class="listing">
            <div class="heading-container">
                <router-link :to="{ name: 'GotIt' }" class="head">
                    {{ $t('gotitVoucher') }}
                </router-link>
                <router-link :to="{ name: 'XINVoucher' }" class="head"
                    >{{ $t('voucher') }}
                </router-link>
            </div>
            <router-view />
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'VoucherPage',
    data() {
        return {};
    },

    computed: {
        ...mapGetters({
            VoucherList: 'voucher/VoucherList',
        }),
        RouteMatching() {
            return this.$route?.matched?.find((route) => {
                return route?.name === 'GotItWrap';
            });
        },
    },

    methods: {
        filterdVoucherList() {
            this.$store
                .dispatch('voucher/userVoucher', { page: this.page })
                .then((res) => {
                    if (res && res.data) {
                        console.log(res.data);
                    }
                });
        },
    },

    mounted() {
        // this.$store.dispatch('voucher/voucherType')
    },
};
</script>

<style lang="scss" scoped>
.banners {
    > img {
        border-radius: 16px;
    }
}

.listing {
    padding: 10px 0px;
    margin-top: 15px;

    .heading-container {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .head {
        color: #0087cc;
        font-weight: 600;
        margin-bottom: 15px;
        border: 1px solid #0087cc;
        padding: 8px 16px;
        border-radius: 8px;
        cursor: pointer;
    }

    .head.active {
        background-color: #0087cc;
        color: #ffffff;
    }
}

.voucher-gotit {
    .content {
        .cost {
            span {
                font-size: 30px !important;
            }
        }
    }
}
</style>

<style lang="scss">
::-webkit-scrollbar {
    width: 6px;
    display: block;
    padding-right: 5px;
}

::-webkit-scrollbar-track {
    border-radius: 12px;
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #0087cc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #0087c8;
}
</style>
