<template>
    <div class="px-4 py-4 mb-8 d-flex flex-column gap-3 text-dark">
        <div
            class="d-flex flex-column flex-lg-row justify-content-between align-items-center mb-3"
        >
            <h3 class="font-weight-bold">{{ $t('supportCenter') }}</h3>
            <div
                class="d-flex flex-column flex-lg-row align-items-center w-lg-auto justify-content-between"
            >
                <form
                    @submit.prevent
                    inline
                    class="d-flex flex-column flex-lg-row align-items-center justify-content-center w-100 w-lg-auto"
                >
                    <div class="mb-2 mb-lg-0 mr-lg-2 w-100 w-lg-auto">
                        <select v-model="selectedStatus" class="p-2 w-100">
                            <option
                                v-for="(item, index) in StatusTicket"
                                :key="index"
                                :value="item.value"
                            >
                                {{ item.label }}
                            </option>
                        </select>
                    </div>

                    <div
                        class="mx-2 w-100 w-lg-auto mb-3 mb-lg-0 order-3 order-lg-1"
                    >
                        <b-form-datepicker
                            v-model="params.dateReportFrom"
                            placeholder="Start Time"
                            class="w-100 bg-white"
                            @input="onDateReportFromChange"
                        ></b-form-datepicker>
                    </div>
                    <div
                        class="mx-2 w-100 w-lg-auto mb-3 mb-lg-0 order-4 order-lg-2"
                    >
                        <b-form-datepicker
                            v-model="params.dateReportTo"
                            placeholder="End Time"
                            class="w-100 bg-white"
                            @input="onDateReportToChange"
                        ></b-form-datepicker>
                    </div>
                    <div
                        class="mx-2 w-100 w-lg-auto mb-3 mb-lg-0 order-2 order-lg-3"
                    >
                        <b-form-input
                            v-model="params.query"
                            placeholder="Search by title"
                            class="w-100 bg-white"
                            @change="handleSearch"
                        ></b-form-input>
                    </div>
                </form>

                <b-button
                    variant="primary"
                    class="mt-3 mt-lg-0"
                    @click="$bvModal.show('openFormTicket')"
                >
                    {{ $t('requestSupport') }}
                </b-button>
            </div>
        </div>
        <div>
            <b-table
                v-if="supportList && supportList.results"
                striped
                hover
                :items="supportList.results"
                show-empty
                :fields="fields"
                thead-class="custom-header"
                responsive
            >
                <template #empty>
                    <h4 class="text-center my-3">
                        {{ $t('noData') }}
                    </h4>
                </template>
                <template #head()="{ label }">
                    {{ $t(label) }}
                </template>
                <template #cell()="{ value }">
                    <span>{{ value }}</span>
                </template>
                <template #cell(status)="{ value }">
                    <span v-if="value === 'in_progress'">
                        {{ $t('inProgress') }}
                    </span>
                    <span v-else-if="value === 'approved'">
                        {{ $t('approve') }}
                    </span>
                    <span v-else-if="value === 'rejected'">
                        {{ $t('reject') }}
                    </span>
                </template>

                <template #cell(dateReport)="{ value }">
                    <span>{{ formatDate(value) }}</span>
                </template>

                <template #cell(dateResponse)="{ value }">
                    <span v-if="value">{{ formatDate(value) }}</span>
                    <span v-else>{{ $t('noResponse') }}</span>
                </template>
            </b-table>
        </div>

        <b-pagination
            v-if="supportList && supportList.results"
            v-model="supportList.page"
            :total-rows="supportList.totalResults"
            :per-page="supportList.limit"
            @change="handlePageChange"
        ></b-pagination>

        <b-modal id="openFormTicket" hide-footer hide-header-close centered>
            <template #modal-title>
                <div
                    class="text-primary"
                    style="font-size: 30px; font-weight: 600"
                >
                    {{ $t('createTicket') }}
                </div>
            </template>

            <div class="d-flex flex-column">
                <b-form-input
                    :placeholder="$t('title')"
                    v-model="title"
                    class="w-100 font-weight-medium mb-3"
                ></b-form-input>
                <div>
                    <b-form-textarea
                        v-model="description"
                        :placeholder="$t('description')"
                        :maxlength="MAX_LENGTH"
                        rows="8"
                        style="resize: none; font-weight: 500"
                        class="w-100"
                        @input="handleInput"
                    ></b-form-textarea>
                    <span class="text-sm"
                        >{{ description.length }} / {{ MAX_LENGTH }}</span
                    >
                </div>
                <div class="w-100 mt-2">
                    <label for="uploadImg" class="font-weight-bold">
                        {{ $t('uploadImage') }}
                    </label>
                    <input
                        id="uploadImg"
                        type="file"
                        accept="image/*"
                        :multiple="true"
                        @change="handleFileChange"
                        ref="fileInput"
                    />
                    <!-- <b-button
                        variant="primary"
                        class="w-100 mb-2"
                        @click="triggerFileInput"
                    >
                        {{ $t('upload') }}
                    </b-button> -->
                    <div v-if="fileList.length">
                        <ul class="row w-100 list-unstyled">
                            <li
                                v-for="(file, index) in limitedFileList"
                                :key="index"
                                class="col"
                            >
                                <div class="position-relative">
                                    <img
                                        :src="file.preview"
                                        alt="Image preview"
                                        class="w-100 h-100 object-cover"
                                        style="
                                            max-width: 100px;
                                            max-height: 100px;
                                        "
                                    />
                                    <button
                                        @click="removeFile(index)"
                                        class="position-absolute bg-danger text-white rounded-circle p-1"
                                        style="
                                            top: -0.5rem;
                                            right: -0.5rem;
                                            width: 1.5rem;
                                            height: 1.5rem;
                                        "
                                    >
                                        &times;
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <p v-if="errorMessage != ''" class="text-danger">
                        {{ errorMessage }}
                    </p>
                </div>
            </div>
            <div class="d-flex gap-2 justify-content-around pt-4">
                <b-button
                    variant="primary"
                    @click="$bvModal.show('openConfirm')"
                >
                    {{ $t('requestSupport') }}
                </b-button>
                <b-button
                    variant="secondary"
                    @click="$bvModal.hide('openFormTicket')"
                >
                    {{ $t('cancel') }}
                </b-button>
            </div>
        </b-modal>

        <b-modal id="openConfirm" hide-footer hide-header-close centered>
            <template #modal-title>
                <div
                    class="text-primary"
                    style="font-size: 30px; font-weight: 600"
                >
                    {{ $t('confirm') }}
                </div>
            </template>
            <div class="d-flex flex-column text-center">
                <p>{{ $t('confirmSendTicket') }}</p>
            </div>
            <div class="d-flex gap-2 justify-content-around pt-4">
                <b-button
                    variant="primary"
                    @click="handleAddTicket"
                    :disabled="pendingPayment"
                >
                    {{ $t('confirm') }}
                </b-button>
                <b-button
                    variant="secondary"
                    @click="$bvModal.hide('openConfirm')"
                >
                    {{ $t('cancel') }}
                </b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { debounce } from 'lodash';

export default {
    data() {
        return {
            pendingPayment: false,
            MAX_LENGTH: 500,
            description: '',
            title: '',
            selectedStatus: '',
            fileList: [],
            fileInput: null,
            fields: [
                {
                    title: this.$t('title'),
                    dataIndex: 'title',
                    key: 'title',
                    align: 'center',
                },
                {
                    title: this.$t('dateReport'),
                    dataIndex: 'dateReport',
                    key: 'dateReport',
                    align: 'center',
                },
                {
                    title: this.$t('amount'),
                    dataIndex: 'amount',
                    key: 'amount',
                    align: 'center',
                },
                {
                    title: this.$t('dateResponse'),
                    dataIndex: 'dateResponse',
                    key: 'dateResponse',
                    align: 'center',
                },
                {
                    title: this.$t('status'),
                    dataIndex: 'status',
                    key: 'status',
                    align: 'center',
                },
            ],
            params: {
                limit: 10,
                page: 1,
                dateReportFrom: '',
                dateReportTo: '',
                status: '',
                query: '',
            },
            tickets: null,
            errorMessage: '',
        };
    },
    computed: {
        ...mapGetters({
            supportList: 'support/supportList',
        }),
        limitedFileList() {
            return this.fileList.slice(0, 5);
        },
        StatusTicket() {
            return [
                { label: this.$t('all'), value: '' },
                { label: this.$t('inProgress'), value: 'in_progress' },
                { label: this.$t('approve'), value: 'approved' },
                { label: this.$t('reject'), value: 'rejected' },
            ];
        },
    },
    methods: {
        ...mapActions('support', ['req_listTicket', 'reqTicket']),
        handlePageChange(page) {
            console.log(page);
            this.params.page = page;
            this.reqTableData();
        },
        handleFileChange(event) {
            const target = event.target;

            if (target.files) {
                if (target.files.length > 5) {
                    this.errorMessage =
                        'You can only upload 5 images at a time';
                    return;
                }
                const files = Array.from(target.files).map((file) => ({
                    file,
                    preview: URL.createObjectURL(file),
                }));
                this.fileList = files;
                this.errorMessage = '';
            }
        },
        triggerFileInput() {
            if (this.$refs.fileInput) {
                this.$refs.fileInput.click();
            }
        },
        handleSearch: debounce(function () {
            this.reqTableData();
        }, 500),
        removeFile(index) {
            URL.revokeObjectURL(this.fileList[index].preview);
            this.fileList.splice(index, 1);
        },
        onDateReportFromChange(newVal) {
            this.params.dateReportFrom = newVal;
            this.reqTableData();
        },
        onDateReportToChange(newVal) {
            this.params.dateReportTo = newVal;
            this.reqTableData();
        },
        formatDate(input) {
            const options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false,
                weekday: 'short',
            };
            const date = new Date(input);

            return date.toLocaleDateString('en-US', options);
        },
        handleInput(event) {
            const target = event.target;
            this.description = target.value;
        },
        async handleAddTicket() {
            if (this.pendingPayment) {
                return;
            }

            if (!this.title) {
                console.error('Title is missing');
                return;
            }

            this.pendingPayment = true;
            const formData = new FormData();
            formData.append('title', this.title);
            formData.append('description', this.description);
            this.fileList.forEach(({ file }) => {
                formData.append('images', file);
            });
            const response = await this.$store.dispatch(
                'support/reqTicket',
                formData,
            );

            if (response && !response.status && response.code == 400) {
                this.pendingPayment = false;
                this.errorMessage = this.$t('requiredImage');
                this.$bvModal.hide('openConfirm');

                return;
            } else {
                //reset form
                this.$bvModal.hide('openFormTicket');
                this.$bvModal.hide('openConfirm');

                this.description = '';
                this.title = '';
                this.fileList = [];
                this.pendingPayment = false;
            }
        },
        async reqTableData() {
            Object.keys(this.params).forEach((key) => {
                if (!this.params[key]) {
                    delete this.params[key];
                }
            });
            await this.$store.dispatch('support/req_listTicket', {
                params: this.params,
            });
        },
    },
    watch: {
        selectedStatus() {
            this.params.status = this.selectedStatus;
            this.reqTableData();
        },
    },
    mounted() {
        this.reqTableData();
    },
};
</script>

<style scoped lang="scss"></style>
