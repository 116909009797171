<script>
import LineChart from '@/components/trade/Chart.vue';
import History from '@/components/trade/History.vue';
import TradingWidget from '@/components/trade/Trade.vue';

import {
    listCoin,
    tradingList,
    dataChart,
    detailCrypto,
} from '@/store/modules/exchange';

export default {
    components: { LineChart, History, TradingWidget },
    name: 'ExchangeEnergy',
    data() {
        return {
            type: '',
            ListType: [],
            fields: [
                {
                    key: '_id',
                    sortable: false,
                    label: 'Price',
                    class: 'text-left',
                },
                {
                    key: 'totalAmount',
                    label: 'Amount',
                    sortable: false,
                    class: 'text-left',
                },
                {
                    key: 'totalPrice',
                    label: 'Total (USDT)',
                    sortable: false,
                    class: 'text-left',
                },
            ],

            width: 1920,
            listActive: 'isBuy',
            tabActive: '',
            tabPopup: 'trading',
            // exchange variables
            chart: [],
            listTrading: {
                buy: [],
                sell: [],
            },
            resizeChart: true,
            dataTrade: {},
            refreshRender: true,
            objectPick: {},
        };
    },
    watch: {
        tabActive: {
            handler() {
                this.tabPopup = 'trading';
            },
        },
        type: {
            handler() {
                this.refreshRender = false;
                this.$nextTick(() => {
                    this.refreshRender = true;
                });
                this.listOfTrading(this.coinActive.slug);
                this.getDetail(this.coinActive.id);
                this.getDataChart(this.coinActive.id);
            },
        },
    },
    computed: {
        coinActive() {
            const items = this.ListType.find((item) => item.slug === this.type);
            return items;
        },
    },
    methods: {
        handleResize() {
            this.width = window.innerWidth;
            if (window.innerWidth <= 991) {
                this.showBuySell = false;
            } else {
                this.showBuySell = true;
            }
            this.resizeChart = false;
            this.$nextTick(() => {
                this.resizeChart = true;
            });
        },
        async getInfoExchange() {
            const data = await listCoin();
            if (!data) {
                console.log(data);
                return;
            }
            if (data?.length >= 1) {
                this.ListType = data;
                this.type = data[0].slug;
                await this.listOfTrading(data[0].slug);
                await this.getDetail(data[0].id);
                await this.getDataChart(data[0].id);
            } else {
                // this.ListType = [];
                this.$toastr.e('Error', 'Cannot get list coin');
            }
        },
        async listOfTrading(slug) {
            this.refreshRender = false;
            this.$nextTick(() => {
                this.refreshRender = true;
            });
            const [listOfTrading] = await Promise.all([
                tradingList(slug),
                this.getDetail(this.coinActive.id),
                this.$store.dispatch('info/req_getInfo'),
            ]);
            this.listTrading = listOfTrading;
        },
        async getDetail(id) {
            const data = await detailCrypto(id);
            this.dataTrade = data;
        },
        async getDataChart(id) {
            const data = await dataChart({
                query: null,
                id,
            });
            this.chart = data;
        },
        onPickBuyOrSell(payload) {
            console.log(payload);
            // this.dataForm.type = type;
            // this.dataForm = item;
        },
        onShow(popup) {
            if (this.width <= 480) {
                this.tabActive = popup;
            }
        },
        onPickSell(payload) {
            const { _id, totalAmount, totalPrice } = payload;
            this.objectPick = {
                type: 'sell',
                price: _id,
                total: totalAmount,
                totalPrice,
            };
            this.onShow('isSell');
        },
        onPickBuy(payload) {
            const { _id, totalAmount, totalPrice } = payload;
            this.objectPick = {
                type: 'buy',
                price: _id,
                total: totalAmount,
                totalPrice,
            };
            this.onShow('isBuy');
        },
    },
    mounted() {
        this.handleResize();
        // Public
        this.getInfoExchange();
        // Auth
    },
    async created() {
        window.addEventListener('resize', this.handleResize);
        if (window.innerWidth <= 991) {
            this.showBuySell = false;
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
};
</script>
<template>
    <b-container class="page-trade">
        <div class="box-1 mb-3">
            <div class="stat-tr">
                <div class="value">XIN/USDT</div>
                <div class="text">Type</div>
            </div>
            <div class="stat-tr">
                <div class="value">
                    {{
                        TruncateToDecimals2(dataTrade.currentPrice || 0, '', 5)
                    }}
                    USDT
                </div>
                <div class="text">Price</div>
            </div>

            <div class="stat-tr">
                <div class="value sell">
                    {{
                        TruncateToDecimals2(dataTrade.minSellPrice || 0, '', 5)
                    }}
                    USDT
                </div>
                <div class="text">Min Sell Price</div>
            </div>

            <div class="stat-tr">
                <div class="value buy">
                    {{ TruncateToDecimals2(dataTrade.maxBuyPrice || 0, '', 5) }}
                    USDT
                </div>
                <div class="text">Max Buy Price</div>
            </div>

            <div class="stat-tr">
                <div class="value">
                    {{ TruncateToDecimals2(dataTrade.volume || 0, '', 5) }}
                    USDT
                </div>
                <div class="text">Volume</div>
            </div>
            <!-- <div class="stat-tr">
                <div class="value">
                    {{ TruncateToDecimals2(dataTrade.poolFee || 0, '', 5) }}
                    USDT
                </div>
                <div class="text">Pool Fee</div>
            </div> -->
        </div>
        <div class="box-chart">
            <div class="left-tr">
                <div class="chart-cn">
                    <LineChart v-if="resizeChart && chart" :chartData="chart" />
                </div>
                <!--  trade -->
                <TradingWidget
                    v-if="width >= 768 && coinActive && refreshRender"
                    :coin="coinActive"
                    :showHeader="width <= 1400 ? true : false"
                    @getListTrading="listOfTrading(coinActive.slug)"
                    :itemPick="objectPick"
                />
            </div>
            <div class="right-tr">
                <div class="list-buy-sel">
                    <div class="list-header" v-if="width <= 575">
                        <b-button
                            class="list-header-item"
                            variant="none"
                            :class="{ active: listActive == 'isBuy' }"
                            @click="listActive = 'isBuy'"
                        >
                            List Buy
                        </b-button>

                        <b-button
                            class="list-header-item"
                            variant="none"
                            :class="{ active: listActive == 'isSell' }"
                            @click="listActive = 'isSell'"
                        >
                            List Sell
                        </b-button>
                    </div>
                    <div
                        class="list-selling base-table"
                        v-if="width >= 576 || listActive == 'isSell'"
                    >
                        <b-table
                            striped
                            hover
                            :sticky-header="true"
                            :items="listTrading.sell"
                            :fields="fields"
                            show-empty
                            thead-class="custom-header"
                            @row-clicked="onPickBuy"
                        >
                            <template #empty>
                                <h4 class="text-center my-3">No Data</h4>
                            </template>

                            <template #cell(_id)="data">
                                {{ TruncateToDecimals2(data.item._id, '', 10) }}
                            </template>
                            <template #cell(totalAmount)="data">
                                {{
                                    TruncateToDecimals2(
                                        data.item.totalAmount,
                                        '',
                                        5,
                                    )
                                }}
                            </template>

                            <template #cell(totalPrice)="data">
                                {{
                                    TruncateToDecimals2(
                                        data.item.totalPrice,
                                        '',
                                        5,
                                    )
                                }}
                            </template>
                        </b-table>
                    </div>
                    <div
                        class="list-buying base-table"
                        v-if="width >= 576 || listActive == 'isBuy'"
                    >
                        <b-table
                            :sticky-header="true"
                            striped
                            hover
                            :items="listTrading.buy"
                            :fields="fields"
                            show-empty
                            thead-class="custom-header"
                            @row-clicked="onPickSell"
                        >
                            <template #empty>
                                <h4 class="text-center my-3">No Data</h4>
                            </template>
                            <template #cell(_id)="data">
                                {{ TruncateToDecimals2(data.item._id, '', 10) }}
                            </template>
                            <template #cell(totalAmount)="data">
                                {{
                                    TruncateToDecimals2(
                                        data.item.totalAmount,
                                        '',
                                        5,
                                    )
                                }}
                            </template>

                            <template #cell(totalPrice)="data">
                                {{
                                    TruncateToDecimals2(
                                        data.item.totalPrice,
                                        '',
                                        5,
                                    )
                                }}
                            </template>
                        </b-table>
                    </div>
                </div>

                <div v-if="width <= 767 && width >= 481" class="trading">
                    <TradingWidget
                        v-if="coinActive && refreshRender"
                        :coin="coinActive"
                        :showHeader="true"
                        @getListTrading="listOfTrading(coinActive.slug)"
                        :itemPick="objectPick"
                    />
                </div>
            </div>
        </div>
        <History
            v-if="width >= 481 && coinActive && refreshRender"
            :coin="coinActive"
            :isMobile="width <= 767 ? true : false"
            :listCurrency="ListType"
            @canceled="listOfTrading(coinActive.slug)"
        />
        <div class="gr-show" v-if="width <= 480">
            <div class="button-trade buy" @click="tabActive = 'isBuy'">Buy</div>
            <div class="button-trade sell" @click="tabActive = 'isSell'">
                Sell
            </div>
        </div>
        <transition
            name="slide"
            enter-active-class="animate__animated animate__fadeInUp"
            leave-active-class="animate__animated animate__fadeOutDown"
        >
            <div class="popup-trade" v-if="width <= 480 && tabActive">
                <div class="popup-trade-header">
                    <div class="popup-header-menu">
                        <div
                            class="popup-header-menu-item"
                            :class="{ active: tabPopup == 'trading' }"
                            @click="tabPopup = 'trading'"
                        >
                            {{ tabActive == 'isBuy' ? 'Buying' : 'Selling' }}
                        </div>
                        <div
                            class="popup-header-menu-item"
                            :class="{ active: tabPopup == 'history' }"
                            @click="tabPopup = 'history'"
                        >
                            History
                        </div>
                    </div>
                    <div class="popup-header-close" @click="tabActive = null">
                        X
                    </div>
                </div>
                <TradingWidget
                    v-if="coinActive && tabPopup == 'trading' && refreshRender"
                    :coin="coinActive"
                    :showHeader="true"
                    :hiddenHeader="true"
                    :tabActive="tabActive"
                    @getListTrading="listOfTrading(coinActive.slug)"
                    :itemPick="objectPick"
                />
                <History
                    :listCurrency="ListType"
                    v-if="tabPopup == 'history' && coinActive && refreshRender"
                    :coin="coinActive"
                    :isMobile="true"
                    @canceled="listOfTrading(coinActive.slug)"
                />
            </div>
        </transition>
    </b-container>
</template>
<style lang="scss">
.page-trade {
    @media (max-width: 991px) {
        width: 100%;
        max-width: calc(100% - 20px);
        padding: 0;
        padding-bottom: 50px;
    }
}
.page-trade {
    .box-1 {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .stat-tr {
            padding: 5px;
            // height: 120px;
            flex: 1;
            margin: 5px;
            background: #0f172a;
            border-radius: calc(50px * 2 / 3);
            &.select-1 {
                border-radius: 0;
                background: none;
                border: 0;
            }
            select {
                width: 100%;
                background: #0f172a;
                border-radius: calc(50px * 2 / 3);
                color: #fff;

                font-size: 1.2em;
                max-height: 45px;
                text-align: center;
                border: 1px solid #fff;
                option {
                    background: #0f172a;
                    text-align: left;
                    &:checked {
                        display: none;
                        text-align: center;
                    }
                }
            }
            .value {
                text-align: center;
                color: #fcd34d;
                font-weight: 500;
                font-size: 1em;
                line-height: 120%;
                &.sell {
                    color: #ef4444;
                }
                &.buy {
                    color: #4ade80;
                }
            }
            .text {
                text-align: center;
                color: #fff;
                font-family: 'Helvetica';
                font-weight: 600;
                font-size: 0.75em;
                letter-spacing: 1px;
            }
            @media (max-width: 991px) {
                flex: 1 1 auto;
            }
            @media (max-width: 767px) {
                flex: 0 0 calc(50% - 20px);
            }
            @media (max-width: 767px) {
                flex: 0 0 calc(50% - 20px);
            }
            @media (max-width: 480px) {
                flex: 0 0 45%;
                margin: 1%;
                justify-content: center;
                border: none;
                border-radius: 0;
                .value {
                    text-align: center;
                    color: #00ffff;

                    font-size: 13px;
                    line-height: 120%;
                }
                .text {
                    text-align: center;
                    color: #fff;
                    font-family: 'Helvetica';
                    font-weight: 600;
                    font-size: 8px;
                    letter-spacing: 1px;
                }
                &.select-1 {
                    flex: 0 0 100%;
                    max-width: 300px;
                    text-align-last: center;
                    label {
                        width: 100%;
                    }
                }
                select {
                    font-size: 1em;
                    max-height: 30px;
                    padding: 0;
                    margin-bottom: 5px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
    .box-chart {
        min-height: 500px;
        display: flex;
        justify-content: center;
        gap: 15px;
        flex-wrap: wrap;

        .left-tr {
            flex-basis: 100%;
            background-color: #0f172a;
            border-radius: 24px;
            .chart-cn {
                width: 100%;
                height: calc(370px - 15px);
                margin-bottom: 15px;
                // background: linear-gradient(
                //     180deg,
                //     #00030f2e 100%,
                //     #00172a75 0%
                // );
                display: flex;
                align-items: center;
                .chart {
                    height: calc(370px - 15px - 50px);
                }
                @media (max-width: 991px) {
                    height: calc(350px - 15px);
                    .chart {
                        height: calc(350px - 15px - 50px);
                    }
                }
                @media (max-width: 480px) {
                    height: calc(40vh - 15px);
                    .chart {
                        height: calc(40vh - 15px - 50px);
                    }
                }
            }
        }
        .right-tr {
            width: 100%;
            display: flex;
            flex-direction: column-reverse;

            .list-header {
                //
                width: 100%;
                // max-width: 300px;
                height: 35px;
                margin: 0 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 10px;
                &-item {
                    padding: 0 10px;
                    color: #fff;
                    background-color: #0f172a;
                    height: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 600;
                    box-shadow: none;
                    border: 1px solid #fff;
                    margin: 0 5px;
                    &.active {
                        color: #00ffff;
                    }
                }
            }
            .list-buying,
            .list-selling {
                padding: 15px;
                background-color: #0f172a;
                border-radius: 24px;
                min-height: 325px;
                flex: 1;
                overflow: hidden;
                .b-table-sticky-header {
                    margin-bottom: 0;
                    max-height: 325px;
                    thead > tr > th {
                        background-color: #0f172a;
                        box-shadow: inset 0px -2px 0px 0px rgba(0, 0, 0, 0.3);
                    }
                    &::-webkit-scrollbar-track {
                        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                        border-radius: 10px;
                        // background-color: #f5f5f5;
                    }

                    &::-webkit-scrollbar {
                        width: 5px;
                        background-color: rgb(201, 199, 199);
                        height: 5px;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 1px;
                        background-color: rgba(0, 0, 0, 0.4);
                    }
                }
            }
            .list-buying {
                table {
                    tr,
                    td,
                    th {
                        color: #4ade80;
                    }
                }
            }
            .list-selling {
                table {
                    tr,
                    td,
                    th {
                        color: #ef4444;
                    }
                }
            }
            .list-buy-sel {
                display: flex;
                flex-direction: column;
                gap: 15px;
            }
            .trading {
                padding: 15px;
                background-color: #0f172a;
                border-radius: 24px;
                margin-bottom: 15px;
            }
        }
        // @media (max-width: 480px) {
        //     .right-tr {
        //         width: 100%;
        //         display: flex;
        //         flex-direction: column-reverse;
        //         .list-buy-sel {
        //             display: flex;
        //             justify-content: space-between;
        //             flex-direction: column;
        //             overflow: auto;
        //             .list-buying,
        //             .list-selling {
        //                 flex: 1;
        //                 height: 250px;
        //                 .b-table-sticky-header {
        //                     max-height: 250px;
        //                 }
        //             }
        //         }
        //     }
        // }
        @media (min-width: 768px) {
            flex-wrap: nowrap;
            .left-tr {
            }
            .right-tr {
                max-width: 260px;
                .list-buy-sel {
                    height: 100%;
                }
            }
        }
        @media (min-width: 992px) {
            .right-tr {
                max-width: 325px;
            }
        }
    }
    .gr-show {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 80px;
        width: 100%;
        z-index: 20;
        .button-trade {
            margin: 10px auto;
            width: 110px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            outline: none !important;
            font-style: italic;
            letter-spacing: 1px;
            font-size: 1.3em;
            font-weight: 600;

            box-shadow: none;
            filter: drop-shadow(2px 2px 1px rgba($color: #000000, $alpha: 0.7));
            transition: 500ms;
            &:focus,
            &:hover {
                transform: scale(0.9);
            }
            &.sell {
                background: url('~@/assets/images/mockup/account/bgBtn-red.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-position: center center;
            }
            &.buy {
                background: url('~@/assets/images/mockup/account/bgBtn-green.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
    }
    .popup-trade {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 60px;
        width: 100%;
        height: auto;
        min-height: calc(100% - 60px);
        max-height: 70vh;
        background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.33) 0%,
                rgba(0, 0, 0, 0.33) 100%
            ),
            radial-gradient(
                99.03% 99.03% at 50% 0.97%,
                #005bae 0%,
                rgba(12, 109, 255, 0.82) 47.4%,
                #033059 100%
            );
        backdrop-filter: blur(8px);
        z-index: 900;
        overflow-y: auto;
        overflow-x: hidden;
        box-shadow: 0px -10px 10px 15px rgba(0, 0, 0, 0.2);
        .popup-trade-header {
            display: flex;
            justify-content: space-between;
            position: relative;
            // &::after {
            //     content: '';
            //     width: calc(100% - 200px);
            //     height: 1px;
            //     background: #fff;
            //     position: absolute;
            //     bottom: 0;
            //     right: 0;
            // }
            .popup-header-menu {
                display: flex;
                justify-content: flex-start;

                &-item {
                    // border-bottom: 1px solid #fff;
                    flex: 1;
                    height: 45px;
                    width: 100px;
                    display: flex;
                    color: #fff;
                    justify-content: center;
                    align-items: center;
                    padding: 0 10px;
                    border-radius: 0 0 5px 5px;
                    &.active {
                        background: rgba(0, 0, 0, 0.5);

                        // border: 1px solid #fff;
                        border-bottom: 0;
                    }
                }
            }
            .popup-header-close {
                width: 45px;
                height: 45px;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;

                color: red;
                background: rgba(255, 255, 255, 0.1);
            }
        }
        .history-tr {
            background: transparent;
        }
    }
}
</style>
