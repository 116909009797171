<template>
    <div class="update-pass-modal">
        <h3>{{ $t('updatePassword') }}</h3>

        <!-- Current password -->
        <form @submit.prevent="updatePassword">
            <div class="current-pass">
                <input
                    :type="showCurrentPass ? 'text' : 'password'"
                    :placeholder="$t('currentPassword')"
                    v-model="currentPassword"
                    autocomplete="current-password"
                    required
                />
                <img
                    v-if="!showCurrentPass"
                    @click="toggleShowCurrent"
                    class="eye-open"
                    src="@/assets/images/icons/eye.svg"
                    alt=""
                />
                <img
                    v-else
                    @click="toggleShowCurrent"
                    class="eye-close"
                    src="@/assets/images/icons/eye-close.svg"
                    alt=""
                />
            </div>

            <!-- New password -->
            <div class="new-pass">
                <input
                    :type="showNewPass ? 'text' : 'password'"
                    :placeholder="$t('newPassword')"
                    v-model="newPassword"
                    autocomplete="new-password"
                    required
                />
                <img
                    v-if="!showNewPass"
                    @click="toggleShowNew"
                    class="eye-open"
                    src="@/assets/images/icons/eye.svg"
                    alt=""
                />
                <img
                    v-else
                    @click="toggleShowNew"
                    class="eye-close"
                    src="@/assets/images/icons/eye-close.svg"
                    alt=""
                />
            </div>

            <div class="new-pass">
                <input
                    :type="showConfirmPassword ? 'text' : 'password'"
                    :placeholder="$t('confirmNewPassword')"
                    v-model="confirmPassword"
                    autocomplete="new-password"
                    required
                />
                <img
                    v-if="!showConfirmPassword"
                    @click="showConfirmPassword = !showConfirmPassword"
                    class="eye-open"
                    src="@/assets/images/icons/eye.svg"
                    alt=""
                />
                <img
                    v-else
                    @click="showConfirmPassword = !showConfirmPassword"
                    class="eye-close"
                    src="@/assets/images/icons/eye-close.svg"
                    alt=""
                />
            </div>
            <div class="update-btn">
                <button type="submit">
                    {{ $t('update') }}
                </button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            currentPassword: null,
            newPassword: null,
            showCurrentPass: false,
            showNewPass: false,
            confirmPassword: null,
            showConfirmPassword: null,
        };
    },

    methods: {
        toggleShowNew() {
            this.showNewPass = !this.showNewPass;
        },
        toggleShowCurrent() {
            this.showCurrentPass = !this.showCurrentPass;
        },
        updatePassword() {
            if (this.confirmPassword === this.newPassword) {
                this.$store
                    .dispatch('auth/updatePassword', {
                        currentPassword: this.currentPassword,
                        newPassword: this.newPassword,
                    })
                    .then((res) => {
                        if (res.status) {
                            this.$store.commit('auth/LOGOUT_SUCCESS');
                            this.$router.push({ name: 'Login' });
                        }
                    });
            } else {
                this.$toastr.e(this.$i18n.t('passwordNotMatch'));
            }
        },
    },
};
</script>

<style lang="scss">
.update-pass-modal {
    position: relative;

    h3 {
        font-size: 30px;
        font-weight: 600;
        color: #0087cc;
        text-align: center;
        margin-top: 1.5rem;
    }

    .current-pass,
    .new-pass {
        position: relative;
        margin-top: 15px;
        input {
            width: 100%;
            padding: 0 1rem;
            border: 1px solid #e4e4e4;
            border-radius: 8px;
            height: 40px;
            color: #000;
        }

        input:focus-visible {
            outline: none;
        }

        input::placeholder {
            color: #a6a6a6;
            font-size: 18px;
            font-style: italic;
            font-weight: 400;
            text-align: left;
        }

        img {
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);
            width: 16px;
            cursor: pointer;
        }
    }

    .update-btn {
        display: flex;
        justify-content: center;
        margin-top: 15px;
        button {
            background: linear-gradient(180deg, #00d1ff 0%, #0087c8 100%);
            border-radius: 72px;
            color: white;
            font-size: 16px;
            padding: 0.5rem 2rem;
        }
    }

    .modal-content {
        .modal-body {
            display: flex;
            border-radius: 22px;
            flex-direction: column;
        }
    }

    .close {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}
</style>
