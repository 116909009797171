const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        promotion: [],
        singlePromo: undefined,
        myPromotion: null,
        nftPromo: undefined,
        nodePromo: null,
    }),
    getters: {
        MyPromotion: (state) => state.myPromotion,
        Promotion: (state) => state.promotion,
        SinglePromo: (state) => state.singlePromo,
        NFTPromo: (state) => state.nftPromo,
        NodePromo: (state) => state.nodePromo,
    },
    mutations: {
        SET_PROMOTION(state, data) {
            state.promotion = data;
        },
        action5kPromo() {},
        SET_SINGLE_PROMO(state, data) {
            state.singlePromo = data;
        },
        SET_MY_PROMOTION(state, data) {
            state.myPromotion = data;
        },
        SET_NFT_PROMOTION(state, data) {
            state.nftPromo = data;
        },
        SET_NODE_PROMOTION(state, data) {
            state.nodePromo = data;
        },
    },
    actions: {
        async claim_promotion7(context, input) {
            return axios.post('/promotion/promotion-7', input);
        },
        async getPromotionInfo({ commit }) {
            return axios.get('promotion/list').then((res) => {
                if (res && res.status) {
                    commit('SET_PROMOTION', res.data);
                }
            });
        },
        async claim_airdrop() {
            return axios.get('/promotion/claim-airdrop2');
        },
        async get5kPromo({ commit }) {
            const res = await axios.get('promotion/promotion-with-id', {
                params: {
                    promotion_id: 8,
                },
            });
            if (res.status) {
                commit('SET_SINGLE_PROMO', res);
            }
            return res;
        },

        async getPromotion({ commit }, input) {
            return axios
                .get('/promotion/list-promotion', { params: input })
                .then((res) => {
                    if (res.status) {
                        if (input?.type === 'nft') {
                            commit('SET_NFT_PROMOTION', res.data);
                        } else if (input?.type === 'node') {
                            commit('SET_NODE_PROMOTION', res.data);
                        } else {
                            commit('SET_MY_PROMOTION', res.data);
                        }

                        return res;
                    }
                    return res;
                });
        },
    },
};
