var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.Master)?_c('div',{staticClass:"dashboard-container container py-2"},[_c('div',{staticClass:"statistical"},[_c('div',{staticClass:"box-statistical"},[_c('div',{staticClass:"text"},[_c('div',{staticClass:"text-title"},[_vm._v("3.5% "+_vm._s(_vm.$t('totalClaim')))]),_c('div',{staticClass:"text-content"},[(_vm.StatisticalMint)?_c('span',[_vm._v(" "+_vm._s(_vm.StatisticalMint.claimComm)+" ")]):_vm._e(),_vm._v(" XIN ")])])])]),_c('div',{staticClass:"masternode-list"},[_c('div',{staticClass:"d-flex justify-content-between flex-wrap align-items-start mb-3"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"title p-0"},[_vm._v(_vm._s(_vm.$t('masterNodeMemberList')))])])]),_c('div',{staticClass:"d-none d-lg-block table-responsive"},[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.MasterNodeMemberList,"fields":_vm.fields,"show-empty":"","thead-class":"customer-header"},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('h4',{staticClass:"text-center my-4"},[_vm._v(_vm._s(_vm.$t('noInfo')))])]},proxy:true},{key:"head(refId)",fn:function(data){return [_c('div',[_vm._v(_vm._s(_vm.$t('userId')))])]}},{key:"head(email)",fn:function(data){return [_c('div',[_vm._v(_vm._s(_vm.$t('email')))])]}},{key:"head(createdAt)",fn:function(data){return [_c('div',[_vm._v(_vm._s(_vm.$t('createdDate')))])]}},{key:"cell(refId)",fn:function(ref){
var item = ref.item;
return [(item.nodeInfo)?_c('span',[_vm._v(" "+_vm._s(item.nodeInfo.refId)+" ")]):_vm._e()]}},{key:"cell(email)",fn:function(ref){
var item = ref.item;
return [(item.nodeInfo)?_c('span',[_vm._v(" "+_vm._s(item.nodeInfo.email)+" ")]):_vm._e()]}},{key:"cell(createdAt)",fn:function(ref){
var item = ref.item;
return [(item.nodeInfo)?_c('span',[_vm._v(" "+_vm._s(_vm.getDateTime(item.createdAt))+" ")]):_vm._e()]}}],null,false,4217597965)})],1),_c('div',{staticClass:"d-lg-none table-responsive mobile"},[_c('b-table',{attrs:{"items":_vm.MasterNodeMemberList,"fields":_vm.mobileFields,"show-empty":"","striped":"","thead-class":"customer-header"},scopedSlots:_vm._u([{key:"cell(#)",fn:function(row){return [_c('b-button',{staticClass:"px-2 py-0",attrs:{"size":"sm","variant":"info"},on:{"click":row.toggleDetails}},[_vm._v(" "+_vm._s(row.detailsShowing ? _vm.$t('hide') : _vm.$t('show'))+" ")])]}},{key:"cell(refId)",fn:function(ref){
var item = ref.item;
return [(item.nodeInfo)?_c('span',[_vm._v(" "+_vm._s(item.nodeInfo.refId)+" ")]):_vm._e()]}},{key:"cell(email)",fn:function(ref){
var item = ref.item;
return [(item.nodeInfo)?_c('span',[_vm._v(" "+_vm._s(item.nodeInfo.email)+" ")]):_vm._e()]}},{key:"cell(createdAt)",fn:function(ref){
var item = ref.item;
return [(item.nodeInfo)?_c('span',[_vm._v(" "+_vm._s(_vm.getDateTime(item.createdAt))+" ")]):_vm._e()]}}],null,false,1089659400)})],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }