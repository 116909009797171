<template>
    <div class="container">
        <!-- <div class="count-wrap">
            <h2>Time until next Block</h2>
            <CountDown :deadline="loopingDate"></CountDown>
        </div> -->
        <div class="mining-header">
            <div class="mining-text">
                <div>
                    {{ $t('totalHzGlobal') }}:
                    <span
                        >{{
                            TruncateToDecimals2(NodeStat.totalHZGlobal)
                        }}
                        Hz</span
                    >
                </div>
                <div>
                    {{ $t('totalXINMined') }}:
                    <span
                        >{{
                            TruncateToDecimals2(NodeStat.totalXinMined)
                        }}
                        XIN</span
                    >
                </div>
                <div>
                    {{ $t('total10KNode') }}:
                    <span
                        >{{
                            TruncateToDecimals2(NodeStat.totalNodeTenThousand)
                        }}
                        / 200
                    </span>
                </div>
                <div>
                    {{ $t('total20kNode') }}:
                    <span
                        >{{
                            TruncateToDecimals2(
                                NodeStat.totalNodeTwentyThousand,
                            )
                        }}
                        / 100
                    </span>
                </div>
                <div>
                    {{ $t('onePercentTotalSales') }}:
                    <span>
                        <span
                            >{{ TruncateToDecimals2(NodeStat.OnePercentSales) }}
                            USDT
                        </span>
                        <span v-if="Rates[0]"
                            >~
                            {{
                                TruncateToDecimals2(
                                    NodeStat.OnePercentSales * Rates[0].rate,
                                )
                            }}
                            XIN</span
                        >
                    </span>
                </div>
                <div>
                    {{ $t('onePercentTotalSalesXin') }}:
                    <span>
                        <span
                            >{{
                                TruncateToDecimals2(NodeStat.OnePercentSalesXin)
                            }}
                            XIN
                        </span>
                    </span>
                </div>
                <div>
                    {{ $t('twoPercentTotalSales') }}:
                    <span>
                        <span
                            >{{ TruncateToDecimals2(NodeStat.twoPercentSales) }}
                            USDT
                        </span>
                        <span v-if="Rates[0]"
                            >~
                            {{
                                TruncateToDecimals2(
                                    NodeStat.twoPercentSales * Rates[0].rate,
                                )
                            }}
                            XIN</span
                        >
                    </span>
                </div>
                <div>
                    {{ $t('twoPercentTotalSalesXin') }}:
                    <span>
                        <span
                            >{{
                                TruncateToDecimals2(NodeStat.twoPercentSalesXin)
                            }}
                            XIN
                        </span>
                    </span>
                </div>
            </div>
            <div class="cta">
                <img class="node" src="@/assets/images/mockup/node.png" />
                <RouterLink
                    class="changepage"
                    v-if="$route.name === 'BuyXnode'"
                    :to="{ name: 'XnodeInventory' }"
                >
                    {{ $t('myXNode') }}
                    <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle
                            cx="15"
                            cy="15"
                            r="14.5"
                            stroke="currentColor"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.40127 15.0001C7.40127 15.144 7.45846 15.2821 7.56024 15.3838C7.66203 15.4856 7.80009 15.5428 7.94404 15.5428L20.7456 15.5428L17.3295 18.9579C17.2276 19.0598 17.1703 19.198 17.1703 19.3422C17.1703 19.4863 17.2276 19.6245 17.3295 19.7264C17.4314 19.8284 17.5696 19.8856 17.7138 19.8856C17.8579 19.8856 17.9961 19.8284 18.098 19.7264L22.4402 15.3843C22.4907 15.3339 22.5308 15.274 22.5582 15.2081C22.5855 15.1421 22.5996 15.0714 22.5996 15.0001C22.5996 14.9287 22.5855 14.858 22.5582 14.792C22.5308 14.7261 22.4907 14.6662 22.4402 14.6158L18.098 10.2737C18.0476 10.2232 17.9877 10.1832 17.9217 10.1559C17.8558 10.1286 17.7851 10.1145 17.7138 10.1145C17.5696 10.1145 17.4314 10.1718 17.3295 10.2737C17.2276 10.3756 17.1703 10.5138 17.1703 10.658C17.1703 10.8021 17.2276 10.9403 17.3295 11.0422L20.7456 14.4573L7.94404 14.4573C7.80009 14.4573 7.66203 14.5145 7.56024 14.6163C7.45846 14.7181 7.40127 14.8561 7.40127 15.0001Z"
                            fill="currentColor"
                        />
                    </svg>
                </RouterLink>
                <RouterLink
                    class="changepage"
                    v-else
                    :to="{ name: 'BuyXnode' }"
                >
                    {{ $t('buyXNode') }}
                    <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle
                            cx="15"
                            cy="15"
                            r="14.5"
                            stroke="currentColor"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.40127 15.0001C7.40127 15.144 7.45846 15.2821 7.56024 15.3838C7.66203 15.4856 7.80009 15.5428 7.94404 15.5428L20.7456 15.5428L17.3295 18.9579C17.2276 19.0598 17.1703 19.198 17.1703 19.3422C17.1703 19.4863 17.2276 19.6245 17.3295 19.7264C17.4314 19.8284 17.5696 19.8856 17.7138 19.8856C17.8579 19.8856 17.9961 19.8284 18.098 19.7264L22.4402 15.3843C22.4907 15.3339 22.5308 15.274 22.5582 15.2081C22.5855 15.1421 22.5996 15.0714 22.5996 15.0001C22.5996 14.9287 22.5855 14.858 22.5582 14.792C22.5308 14.7261 22.4907 14.6662 22.4402 14.6158L18.098 10.2737C18.0476 10.2232 17.9877 10.1832 17.9217 10.1559C17.8558 10.1286 17.7851 10.1145 17.7138 10.1145C17.5696 10.1145 17.4314 10.1718 17.3295 10.2737C17.2276 10.3756 17.1703 10.5138 17.1703 10.658C17.1703 10.8021 17.2276 10.9403 17.3295 11.0422L20.7456 14.4573L7.94404 14.4573C7.80009 14.4573 7.66203 14.5145 7.56024 14.6163C7.45846 14.7181 7.40127 14.8561 7.40127 15.0001Z"
                            fill="currentColor"
                        />
                    </svg>
                </RouterLink>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-6 col-xl-4 py-3">
                <div class="box-statistical">
                    <div class="text">
                        <div class="text-title">
                            <p>
                                {{ $t('totalSoM', { something: $t('token') }) }}
                            </p>
                            <p>
                                <router-link
                                    class="router-link"
                                    :to="{ name: 'Wallet' }"
                                >
                                    {{ $t('wallet') }}
                                    <img
                                        src="../../assets/images/icons/arrow_right.svg"
                                        alt=""
                                    />
                                </router-link>
                            </p>
                        </div>
                        <div class="text-content">
                            {{ TruncateToDecimals2(UserInfo.xinCoin) }}
                            <p>XIN80</p>
                        </div>
                        <div class="text-content bound">
                            {{ TruncateToDecimals2(UserInfo.bxinCoin) }}
                            <p>XIN20</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xl-4 py-3">
                <div class="box-statistical">
                    <div class="text">
                        <div class="text-title">
                            <p>{{ $t('totalSoM', { something: 'USDT' }) }}</p>
                            <p>
                                <router-link
                                    class="router-link"
                                    :to="{ name: 'Wallet' }"
                                >
                                    {{ $t('wallet') }}
                                    <img
                                        src="../../assets/images/icons/arrow_right.svg"
                                        alt=""
                                    />
                                </router-link>
                            </p>
                        </div>
                        <div class="text-content">
                            {{ TruncateToDecimals2(UserInfo.USDT) }}

                            <p>USDT</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
import { RouterLink } from 'vue-router';
import { mapGetters } from 'vuex';
// import moment from 'moment';

export default {
    name: 'MiningPage',
    components: { RouterLink },
    data() {
        return {
            finalDate: '2024-04-01T10+07:00',
            visible: false,
        };
    },
    methods: {
        showModal() {
            this.visible = true;
        },
    },
    computed: {
        ...mapGetters({
            NodeStat: 'mining/NodeStat',
            UserInfo: 'info/UserInfo',
            Rates: 'wallet/Rates',
        }),
    },
    mounted() {
        this.$store.dispatch('mining/get_nodeInfo');
        this.$store.dispatch('mining/max_out');
        this.$store.dispatch('wallet/get_rates');
    },
};
</script>

<style scoped lang="scss">
.container {
    .mining-header {
        background: #1478b0;
        margin-top: 2rem;
        margin-bottom: 1rem;
        align-items: center;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        border: 1px solid #d8d8d8;
        border-radius: 12px;
        padding: 15px;

        @media (min-width: 768px) {
            padding: 15px 45px;
        }

        .mining-text {
            div {
                font-size: 16px;
                font-weight: 400;
                color: #fff;
                margin: 10px 0px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 5px;

                @media (min-width: 576px) {
                    display: block;
                }

                span {
                    font-size: 14px;
                    font-weight: 600;
                    color: #97d7f8;
                    text-align: right;
                }

                @media (min-width: 576px) {
                    font-size: 20px;

                    span {
                        font-size: 18px;
                    }
                }
            }

            div:nth-child(3),
            div:last-child {
                > span {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                }

                @media (min-width: 575px) {
                    display: flex;
                    justify-content: unset;

                    > span {
                        display: flex;
                        gap: 5px;
                    }
                }
            }
        }

        .changepage {
            font-size: 20px;
            font-style: italic;
            font-weight: 400;
            color: #fff;
            // background: linear-gradient(180deg, #00d1ff 0%, #0087c8 100%);
            background: #fff;
            padding: 10px 15px;
            border-radius: 100vw;
            color: #1478b0;
            font-weight: 700;
            transition: 0.2s;

            svg {
                padding-left: 5px;
            }

            &:hover {
                opacity: 0.8;
            }
        }

        .cta {
            text-align: center;

            .node {
                max-width: 180px;
                display: block;
                margin: auto;
                padding-bottom: 20px;
            }
        }

        > div {
            flex-basis: 100%;

            @media (min-width: 576px) {
                flex-basis: auto;
            }
        }
    }
}

.box-statistical {
    background: #1478b0;
    height: 100%;
    outline: none;
    padding: 15px;
    position: relative;
    border-radius: 22px;
    border: 1px solid #d8d8d8;

    .text {
        color: #fff;
        z-index: 1;
        flex-grow: 1;
        font-size: 14px;

        .text-title {
            font-size: 1em;
            font-style: italic;
            font-weight: 500;
            margin-bottom: 15px;
            text-transform: capitalize;
            display: flex;
            justify-content: space-between;

            .router-link {
                background: #fff;
                padding: 5px 10px;
                color: #1478b0;
                font-weight: 700;
                border-radius: 6px;
                transition: 0.2s;

                img {
                    width: 15px;
                    height: 13px;
                    padding-bottom: 1px;
                }

                &:hover {
                    opacity: 0.8;
                }
            }
        }

        .text-content {
            font-size: 1.125em;
            font-weight: 700;
            display: flex;
            justify-content: space-between;
            gap: 5px;

            &.bound {
                color: #97d7f8;
            }

            > span {
                min-width: 0;
                word-wrap: break-word;
            }

            > div:last-child {
                flex-shrink: 0;
            }
        }

        &.bound {
            .text-content {
                color: #0f766e;
            }

            .text-title {
                color: #022c22;
            }
        }

        @media (min-width: 992px) {
            font-size: 16px;
        }
    }
}

.count-wrap {
    text-align: center;
    margin-top: 20px;

    h2 {
        color: #0087c8;
        font-weight: 600;
        font-size: 1.5rem;

        @media (min-width: 768px) {
            font-size: 2rem;
        }
    }
}
</style>
