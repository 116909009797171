<template>
    <div class="regiser-wallet-container">
        <form @submit.prevent="registerWallet">
            <div class="img">
                <img src="../../../assets/images/logo/logoV3.png" alt="" />
            </div>
            <div class="inputs">
                <!-- <label for="address">Address:</label> -->
                <!-- <input type="text" placeholder="Address" v-model="walletAddress" id="address" readonly/> -->
                <div class="wallet-address">
                    <p>{{ this.walletAddress }}</p>
                </div>
                <div class="input-pw">
                    <input type="text" placeholder="Sponsor" v-model="sponsor" id="sponsor" required />
                </div>
                <span>
                    <router-link class="router-link" to="/login">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="1.5" d="M20 12H4m0 0l6-6m-6 6l6 6" />
                        </svg>
                        <strong>
                            {{ $t('login') }}
                        </strong>
                    </router-link>
                </span>
            </div>
            <div class="buttons">
                <button>{{ $t('activate') }}</button>
            </div>
        </form>
    </div>
</template>


<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            walletAddress: [],
            sponsor: null
        }
    },
    computed: {
        ...mapGetters({
            isNetWork: 'contract/isNetWork',
            Address: 'info/Address',
        }),
        ChainID() {
            if (this.isNetWork === 'testnet') {
                return 97;
            }
            return 56;
        },
        netWorkConfig() {
            const testNet = {
                chainId: '0x61',
                chainName: 'Smart Chain - Testnet',
                nativeCurrency: {
                    name: 'Binance Coin',
                    symbol: 'BNB',
                    decimals: 18,
                },
                rpcUrls: [
                    'https://data-seed-prebsc-2-s2.binance.org:8545/',
                    'https://data-seed-prebsc-1-s1.binance.org:8545/',
                    'https://data-seed-prebsc-5-s5.binance.org:8545/',
                    'https://data-seed-prebsc-3-s3.binance.org:8545/',
                    'https://data-seed-prebsc-4-s4.binance.org:8545/',
                ],
                blockExplorerUrls: ['https://testnet.bscscan.com'],
            };
            const mainNet = {
                chainId: '0x38',
                chainName: 'Smart Chain',
                nativeCurrency: {
                    name: 'Binance Coin',
                    symbol: 'BNB',
                    decimals: 18,
                },
                rpcUrls: [
                    'https://bsc-dataseed.binance.org/',
                    'https://bsc-dataseed1.defibit.io/',
                    'https://bsc-dataseed1.ninicoin.io/',
                ],
                blockExplorerUrls: ['https://bscscan.com'],
            };
            if (this.isNetWork === 'testnet') {
                return testNet;
            }
            return mainNet;
        },
    },

    method: {
        async onConnectMetaMask() {
            try {
                this.walletAddress = await window.ethereum.request({
                    method: 'eth_requestAccounts',
                });
            } catch (error) {
                this.outLoad();
                if (
                    error.message ===
                    'Already processing eth_requestAccounts. Please wait.'
                ) {
                    this.$toastr.e('Please Connect Metamask To Join', 'Oops!');
                    return false;
                }
                this.$toastr.e(error.message, 'Oops!');
                return false;
            }
            if (window.ethereum.chainId !== this.ChainID) {
                const params = this.netWorkConfig;
                window.ethereum
                    .request({
                        method: 'wallet_addEthereumChain',
                        params: [params],
                    })
                    .then(() => console.log('add network success'))
                    .catch(() => {
                        this.showNotification = true;
                        this.message = {
                            content:
                                'Please switch To The  Binance Smartchain Network!',
                            failed: true,
                            title: 'Oops...',
                        };
                        window.ethereum.request({
                            method: 'wallet_switchEthereumChain',
                            params: [{ chainId: this.ChainID }],
                        });
                    });
            }
            if (this.walletAddress.length < 0) {
                return false;
            }
            this.$store.commit('info/SET_ADDRESS', {
                address: this.walletAddress[0],
            })
            this.$store.dispatch('auth/registerWallet', {
                address: this.walletAddress[0],
            });
            console.log('Address: ', this.walletAddress)
            return true;
        },
        async registerWallet() {
            const txData = await window.ethereum.request({
                method: 'personal_sign',
                params: [this.Address, this.$store.state.auth.sign],
            });
            console.log(this.$store.state.auth.sign)
            this.$store.dispatch('auth/register-wallet', {
                address: this.Address,
                sign: txData,
                sponsir: this.sponsor
            });
        }
    },

    // mounted() {
    //     this.onConnectMetaMask
    // }
}
</script>


<style lang="scss">
.regiser-wallet-container {
    background: linear-gradient(#3E84C6, #1A446B);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    form {
        flex-basis: 400px;

        .img {
            display: flex;
            justify-content: center;

            >img {
                max-width: 100%;
                width: 200px;
            }
        }

        .inputs {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            margin: 0 auto;
            margin-top: 1.5rem;

            .input-pw {
                position: relative;

                input {
                    width: 100%;
                }

                img {
                    position: absolute;
                    top: 50%;
                    right: 1rem;
                    transform: translateY(-50%);
                    width: 24px;
                    cursor: pointer;
                }

                .get-otp {
                    position: absolute;
                    right: 2px;
                    top: 2px;
                    bottom: 2px;
                    background-color: #0087cc;
                    border-radius: 6px;
                    color: white;
                    transition: 0.5s;

                    &:hover {
                        opacity: 0.5;
                    }
                }
            }

            input {
                padding: 0 1rem;
                border: 1px solid #e4e4e4;
                border-radius: 8px;
                height: 40px;
                background-color: #6a93b9;
                color: #fff;
            }

            input:focus-visible {
                outline: none;
            }

            input::placeholder {
                color: #fff;
                font-size: 18px;
                font-style: italic;
                font-weight: 400;
            }

            span {
                text-align: right;
            }
        }

        .buttons {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 1.5rem;
            gap: 1rem;

            button {
                padding-top: 1rem;
                padding-bottom: 1rem;
                width: 70%;
                border-radius: 72px;
                text-transform: uppercase;
                font-size: 18px;
                transition: 0.5s;

                &:hover {
                    opacity: 0.5;
                }

                &.pending {
                    filter: grayscale(1);
                }
            }

            button:nth-child(1) {
                background: linear-gradient(to right, #1775B8, #91C4E9);
                color: #fff;
                font-weight: 600;
            }

            button:nth-child(2) {
                border: 1px solid #00d1ff;
                color: #00d1ff;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 7px;

                font-weight: 600;
            }
        }

        span {
            color: #fff;

            .router-link {
                transition: 0.5s;

                &:hover {
                    color: #fff;
                    opacity: 0.5;
                }
            }
        }

        .forgot {
            display: flex;
            justify-content: center;
            margin-top: 1rem;
            font-size: 18px;
        }
    }

    @media (max-width: 1201px) {
        form {
            .inputs {
                width: 100%;
            }
        }
    }

    @media (max-width: 990px) {
        form {
            .inputs {
                input::placeholder {
                    font-size: 16px;
                }

                .input-pw {
                    img {
                        width: 25px;
                    }
                }
            }

            .buttons {
                button {
                    width: 100%;
                    font-size: 16px;
                }
            }

            p {
                font-size: 16px;
            }
        }
    }

    @media (max-width: 376px) {
        padding: 5rem 1rem 3rem 1rem;
    }
}
</style>