const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        info: {
            ClaimDate: '2022-05-17T00:00:00.000Z',
            MaxPresale: 0,
            price: 0,
            Requirement: ' Whitelisted Wallet',
            TimeEnd: '2022-04-17T00:00:00.000Z',
            TimeStart: '2022-03-17T00:00:00.000Z',
            Vesting: 'Released at the time of TGE',
            totalBuy: 0,
        },
        list: [],
        claimed: {
            current: 1,
            total: 1,
            list: [],
        },
        statistical: {
            totalBought: 0,
            totalLocking: 0,
            totalRemaining: 0,
            totalUnlocked: 0,
            downlineUCON: 0,
            downLineUSDT: 0,
        },
    }),
    getters: {
        Info: (state) => state.info,
        List: (state) => state.list,
        Statistical: (state) => state.statistical,
        Claimed: (state) => state.claimed,
    },
    actions: {
        req_getInfoPreSales: ({ commit }) => {
            axios.get('/presale/info').then((response) => {
                commit('SET_PRE_SALE_INFO', response.data);
            });
        },
        req_getBuyHistory: ({ commit }) => {
            axios.post('/presale/unlock-schedule').then((response) => {
                if (response) {
                    commit('SET_PRE_SALE_USER', response.data);
                }
            });
        },
        req_getListClaimed: ({ commit }, input) => {
            axios
                .get('/presale/history-claim', {
                    params: input,
                })
                .then((response) => {
                    commit('SET_LIST_CLAIMED', response.data);
                });
        },
        req_postBuyToken: ({ dispatch }, input) => {
            axios.post('/presale/buy-token', input).then(() => {
                dispatch('req_getBuyHistory');
                dispatch('req_getInfoPreSales');
            });
        },
        req_postClaimToken: ({ dispatch }, input) => {
            axios.post('/presale/claim', input).then(() => {
                dispatch('req_getBuyHistory');
                dispatch('req_getInfoPreSales');
            });
        },
    },
    mutations: {
        SET_PRE_SALE_INFO(state, data) {
            state.info = data;
        },
        SET_PRE_SALE_USER(state, data) {
            const {
                totalBought,
                totalLocking,
                totalRemaining,
                totalUnlocked,
                unlockSchedule,
                totalUnderSale,
            } = data;
            state.list = unlockSchedule;
            state.statistical = {
                totalBought,
                totalLocking,
                totalRemaining,
                totalUnlocked,
                downLineUSDT: totalUnderSale.totalUSDT,
                downlineUCON: totalUnderSale.amountUCON
            };
        },
        SET_LIST_CLAIMED(state, data) {
            const { results, page, totalPages } = data;
            state.claimed = {
                current: page,
                total: totalPages,
                list: results,
            };
        },
    },
};
