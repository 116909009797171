<template>
    <div>
        <div class="back">
            <router-link class="goback" :to="{ name: 'GotIt' }">
                {{ $t('goBack') }}
            </router-link>
            <a
                class="howtouse"
                href="https://www.gotit.vn/how-to-use"
                target="_blank"
                >{{ $t('howToUse') }}</a
            >
        </div>
        <div v-if="GotItHistory" class="d-none d-xl-block gotit-history">
            <b-table
                striped
                hover
                :items="ComputedHistory"
                :fields="fields"
                show-empty
                responsive
                thead-class="customer-header"
            >
                <template #head()="{ label }">
                    {{ $t(label) }}
                </template>
                <template #cell(price)="{ value }">
                    {{ TruncateToDecimals2(value) }} VNĐ
                </template>
                <template #cell(transactionRefId)="{ item }">
                    <template v-if="item && item.statusObject">
                        <GotItStatus :stateObject="item.statusObject" />
                    </template>
                </template>
                <template #cell(voucherLink)="row">
                    <a :href="row.value" target="_blank" class="botan">
                        {{ $t('useGiftcard') }}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            viewBox="0 0 24 24"
                        >
                            <g fill="none">
                                <path
                                    d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"
                                />
                                <path
                                    fill="currentColor"
                                    d="m14.707 5.636l5.657 5.657a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 0 1-1.414-1.414l3.95-3.95H4a1 1 0 1 1 0-2h13.243l-3.95-3.95a1 1 0 1 1 1.414-1.414"
                                />
                            </g>
                        </svg>
                    </a>
                </template>
                <template #cell(voucherImageLink)="{ value }">
                    <a :href="value" target="_blank" class="botan">
                        {{ $t('giftcardImage') }}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            viewBox="0 0 24 24"
                        >
                            <g fill="none">
                                <path
                                    d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"
                                />
                                <path
                                    fill="currentColor"
                                    d="m14.707 5.636l5.657 5.657a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 0 1-1.414-1.414l3.95-3.95H4a1 1 0 1 1 0-2h13.243l-3.95-3.95a1 1 0 1 1 1.414-1.414"
                                />
                            </g>
                        </svg>
                    </a>
                </template>
                <template #cell(#)="row">
                    <b-form-checkbox
                        v-model="row.detailsShowing"
                        @change="row.toggleDetails"
                    >
                        {{ $t('showDetails') }}
                    </b-form-checkbox>
                </template>
                <template #row-details="{ item }">
                    <div class="details">
                        <div v-if="item.mana || item.xin" class="mobile-data">
                            <div>
                                {{ $t('totalSoM', { something: $t('cost') }) }}:
                            </div>
                            <div>
                                <!-- <span v-if="item.mana">
                                    {{ TruncateToDecimals2(item.mana) }}
                                    {{ $t('mana') }}
                                </span>
                                <span v-if="item.mana && item.xin"> + </span> -->
                                <span v-if="item.xin">
                                    {{ TruncateToDecimals2(item.xin) }}
                                    <img
                                        src="@/assets/images/logo/xin.png"
                                        width="18px"
                                    />
                                </span>
                            </div>
                        </div>
                        <div v-if="item.voucherCode" class="voucher-code">
                            <div class="flex">
                                <div>{{ $t('giftcardCode') }}:</div>
                                <div>
                                    {{ item.voucherCode }}
                                </div>
                            </div>
                            <div class="barcode-wrapper">
                                <barcode :value="item.voucherCode" :width="4">
                                    Failed to render barcode
                                </barcode>
                            </div>
                        </div>
                        <div v-if="item.createdAt" class="mobile-data">
                            <div>{{ $t('boughtDate') }}:</div>
                            <div>
                                {{ getDateTime3(item.createdAt) }}
                            </div>
                        </div>
                        <div v-if="item.expiryDate" class="mobile-data">
                            <div>{{ $t('expiryDate') }}:</div>
                            <div>
                                {{ getDateTime3(item.expiryDate) }}
                            </div>
                        </div>
                        <GotItDetail :stateObject="item.statusObject" />
                    </div>
                </template>
            </b-table>
        </div>
        <div v-if="GotItHistory" class="d-xl-none gotit-history">
            <b-table
                striped
                hover
                :items="ComputedHistory"
                :fields="mobileFields"
                show-empty
                responsive
                thead-class="customer-header"
            >
                <template #head()="{ label }">
                    {{ $t(label) }}
                </template>
                <template #cell(price)="{ item, value }">
                    <div>{{ TruncateToDecimals2(value) }} VNĐ</div>
                    <div class="serial">
                        {{ item.voucherSerial }}
                    </div>
                </template>
                <template #cell(status)="{ item, value }">
                    <div>{{ TruncateToDecimals2(value) }} VNĐ</div>
                    <div class="serial">
                        {{ item.voucherSerial }}
                    </div>
                </template>
                <template #cell(voucherLink)="row">
                    <a :href="row.value" target="_blank" class="botan">
                        {{ $t('useGiftcard') }}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            viewBox="0 0 24 24"
                        >
                            <g fill="none">
                                <path
                                    d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"
                                />
                                <path
                                    fill="currentColor"
                                    d="m14.707 5.636l5.657 5.657a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 0 1-1.414-1.414l3.95-3.95H4a1 1 0 1 1 0-2h13.243l-3.95-3.95a1 1 0 1 1 1.414-1.414"
                                />
                            </g>
                        </svg>
                    </a>
                </template>
                <template #cell(#)="row">
                    <b-form-checkbox
                        v-model="row.detailsShowing"
                        @change="row.toggleDetails"
                    >
                    </b-form-checkbox>
                    <GotItStatus :stateObject="row.item.statusObject" />
                </template>
                <template #row-details="{ item }">
                    <div class="details">
                        <div v-if="item.orderName" class="mobile-data">
                            <div>{{ $t('name') }}:</div>
                            <div>
                                {{ item.orderName }}
                            </div>
                        </div>
                        <div v-if="item.mana || item.xin" class="mobile-data">
                            <div>
                                {{ $t('totalSoM', { something: $t('cost') }) }}:
                            </div>
                            <div>
                                <span v-if="item.mana">
                                    {{ TruncateToDecimals2(item.mana) }}
                                    {{ $t('mana') }}
                                </span>
                                <span v-if="item.mana && item.xin"> + </span>
                                <span v-if="item.xin">
                                    {{ TruncateToDecimals2(item.xin) }} XIN
                                </span>
                            </div>
                        </div>
                        <div v-if="item.voucherCode" class="voucher-code">
                            <div class="flex">
                                <div>{{ $t('giftcardCode') }}:</div>
                                <div>
                                    {{ item.voucherCode }}
                                </div>
                            </div>
                            <div class="barcode-wrapper">
                                <barcode :value="item.voucherCode" :width="4">
                                    Failed to render barcode
                                </barcode>
                            </div>
                        </div>
                        <div v-if="item.createdAt" class="mobile-data">
                            <div>{{ $t('boughtDate') }}:</div>
                            <div>
                                {{ getDateTime3(item.createdAt) }}
                            </div>
                        </div>
                        <div v-if="item.expiryDate" class="mobile-data">
                            <div>{{ $t('expiryDate') }}:</div>
                            <div>
                                {{ getDateTime3(item.expiryDate) }}
                            </div>
                        </div>
                        <GotItDetail :stateObject="item.statusObject" />
                    </div>
                </template>
                <template #cell(transactionRefId)="{ item }">
                    <template v-if="item && item.statusObject">
                        <GotItStatus :stateObject="item.statusObject" />
                    </template>
                </template>
            </b-table>
        </div>
        <b-pagination
            v-if="GotItHistory"
            v-model="page"
            :total-rows="GotItHistory.totalResults"
            :per-page="limit"
        >
        </b-pagination>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GotItStatus from '../../../components/voucher/GotItStatus.vue';
import GotItDetail from '../../../components/voucher/GotItDetail.vue';
// import GotItDetailAPI from './GotItDetailAPI.vue';

export default {
    name: 'GotItHistory',
    data() {
        return {
            page: 1,
            limit: 5,
            fields: [
                {
                    class: 'text-center',
                    label: 'serial',
                    key: 'voucherSerial',
                },
                {
                    class: 'text-center',
                    label: 'value',
                    key: 'price',
                },
                {
                    class: 'text-center',
                    label: 'name',
                    key: 'orderName',
                },
                {
                    class: 'text-center',
                    label: 'giftcardLink',
                    key: 'voucherLink',
                },
                {
                    class: 'text-center',
                    label: 'Status',
                    key: 'transactionRefId',
                },
                {
                    class: 'text-center',
                    label: 'more',
                    key: '#',
                },
            ],
            mobileFields: [
                {
                    class: 'text-center',
                    label: 'value',
                    key: 'price',
                },
                {
                    class: 'text-center',
                    label: 'giftcardLink',
                    key: 'voucherLink',
                },
                {
                    class: 'text-center',
                    label: 'more',
                    key: '#',
                },
            ],
            lookupStatus: {},
        };
    },
    components: {
        GotItStatus,
        GotItDetail,
    },
    computed: {
        ...mapGetters({
            GotItHistory: 'voucher/GotItHistory',
        }),
        ComputedHistory() {
            if (this.GotItHistory && this.GotItHistory.results.length > 0) {
                const copy = [...this.GotItHistory.results];
                const newCopy = copy.map((el) => {
                    if (this.lookupStatus[el.transactionRefId] === undefined) {
                        this.addToLookup(el.transactionRefId);
                    }
                    return {
                        ...el,
                        statusObject: this.lookupStatus[el.transactionRefId],
                    };
                });
                return newCopy;
            }
            return [];
        },
    },
    methods: {
        req_list() {
            this.$store.dispatch('voucher/gotItVoucherHistory', {
                page: this.page,
                limit: this.limit,
            });
        },
        async addToLookup(transactionRefId) {
            this.$set(this.lookupStatus, transactionRefId, 'fetching');
            const response = await this.$store.dispatch(
                'voucher/gotItDetail',
                transactionRefId,
            );
            if (response?.success) {
                // eslint-disable-next-line prefer-destructuring

                this.$set(
                    this.lookupStatus,
                    transactionRefId,
                    response.data[0].vouchers[0] ?? null,
                );
            }
        },
    },
    watch: {
        page() {
            this.req_list();
        },
    },
    mounted() {
        this.req_list();
    },
};
</script>

<style lang="scss" scoped>
.back {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-bottom: 15px;

    .goback {
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        padding: 0px 10px;
        position: relative;
        line-height: 1.6;
        display: inline-block;
        background-color: #0087cc;
        filter: drop-shadow(1px 1px 2px #a1a1a1);
        &:hover {
            text-decoration: underline;
        }
        &::before {
            content: '';
            position: absolute;
            border: 0.8em solid transparent;
            border-right-color: transparent;
            border-color: transparent #0087cc transparent transparent;
            right: 100%;
            top: 0;
            bottom: 0;
        }
        &::after {
            content: '';
            position: absolute;
            border: 0.8em solid #0087cc;
            border-right-color: transparent;
            left: 100%;
            top: 0;
            bottom: 0;
        }
    }

    .howtouse {
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        padding: 4px 15px;
        position: relative;
        line-height: 1.6;
        display: inline-block;
        background-color: #0087cc;
        filter: drop-shadow(1px 1px 2px #a1a1a1);
        border-radius: 8px;

        &:hover {
            text-decoration: underline;
        }
    }
}
.botan {
    transition: 0.2s;
    padding: 5px 10px;
    border-radius: 6px;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    margin: 5px 0px;
    text-decoration: underline;
    &:hover {
        background-color: #0087cc;
        color: inherit;
    }
}
</style>
<style lang="scss">
.gotit-history {
    background: #1478b0;
    border-radius: 22px;
    .customer-header {
        background-color: transparent;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        color: #fff;
    }

    .table th,
    .table td {
        border: none;
        font-size: 14px;
        @media (min-width: 768px) {
            font-size: 16px;
        }
    }

    table {
        thead {
            tr {
                th {
                    white-space: nowrap;
                }
            }
        }

        tbody {
            tr {
                height: 70px;
                border-radius: 12px;
                background: transparent;

                &:nth-child(odd) {
                    background: #e1e1e175;
                }

                td {
                    background: none;
                    padding: 0.75rem;
                    color: #fff;
                    font-weight: 500;
                    vertical-align: middle;

                    .mobile-data {
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        line-height: 1;
                        padding: 5px 0px;

                        > div:first-child {
                            color: #f5f5f5;
                        }

                        border-bottom: 0.5px dashed #fff;
                        &:last-child {
                            border: none;
                        }
                    }
                    .serial {
                        color: #f3f4f6;
                        font-size: 0.875em;
                        line-height: 1;
                    }
                    .voucher-code {
                        border-bottom: 0.5px dashed #fff;
                        .flex {
                            display: flex;
                            justify-content: space-between;
                            flex-wrap: wrap;
                            padding-bottom: 20px;
                            > div:first-child {
                                color: #f5f5f5;
                            }
                        }
                        .barcode-wrapper {
                            padding-bottom: 20px;
                            > div {
                                text-align: center;
                            }
                            svg {
                                max-width: 100%;
                                min-width: 0px;
                            }
                        }
                    }
                    > span {
                        height: 70px;
                        display: flex;
                        padding: 0 0.75rem;
                        align-items: center;
                        margin-bottom: 0;
                        justify-content: center;
                        backdrop-filter: blur(15.899999618530273px);
                    }
                }
            }
        }
    }
}
</style>
