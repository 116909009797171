<template>
    <div class="container py-2">
        <div class="row mx-n1">
            <div class="col-lg-3 col-md-4 col-sm-6 py-2 px-1">
                <div class="box-statistical">
                    <div class="text">
                        <div class="text-title">
                            {{ $t('totalSoM', { something: 'USDT' }) }}
                        </div>
                        <div class="text-content">
                            <span>{{
                                TruncateToDecimals2(UserInfo.USDT)
                            }}</span>
                            <div>USDT</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 py-2 px-1">
                <div class="box-statistical">
                    <div class="text">
                        <div class="text-title">
                            {{ $t('totalSoM', { something: $t('token') }) }}
                        </div>
                        <div class="text-content">
                            <span>{{
                                TruncateToDecimals2(UserInfo.xinCoin)
                            }}</span>
                            <div>XIN80</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 py-2 px-1">
                <div class="box-statistical">
                    <div class="text bound">
                        <div class="text-title">
                            {{ $t('totalSoM', { something: $t('boundXIN') }) }}
                        </div>
                        <div class="text-content">
                            <span>{{
                                TruncateToDecimals2(UserInfo.bxinCoin)
                            }}</span>
                            <div>XIN20</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 py-2 px-1">
                <div class="box-statistical">
                    <div class="text">
                        <div class="text-title">
                            {{
                                $t('totalSoM', { something: $t('commissions') })
                            }}
                        </div>
                        <div class="text-content">
                            <span>
                                {{ TruncateToDecimals2(USDTCommission) }}
                            </span>
                            <div>USDT</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-n1">
            <div class="col-lg-6 py-2 px-1">
                <div class="wallet-box">
                    <div class="balance">
                        <div>{{ $t('SoMBalance', { something: 'USDT' }) }}</div>
                        <div>
                            <button
                                @click="
                                    $store.dispatch(
                                        'info/get_refreshBalance',
                                        'USDT',
                                    )
                                "
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M12 20q-3.35 0-5.675-2.325T4 12q0-3.35 2.325-5.675T12 4q1.725 0 3.3.712T18 6.75V4h2v7h-7V9h4.2q-.8-1.4-2.187-2.2T12 6Q9.5 6 7.75 7.75T6 12q0 2.5 1.75 4.25T12 18q1.925 0 3.475-1.1T17.65 14h2.1q-.7 2.65-2.85 4.325T12 20"
                                    />
                                </svg>
                            </button>
                            <span>{{
                                TruncateToDecimals2(UserInfo.USDT)
                            }}</span>
                            USDT
                        </div>
                    </div>
                    <div class="rate">
                        <div>
                            {{ $t('rate') }}
                            <img
                                class="p-2"
                                src="@/assets/images/logo/usdt.png"
                            />
                        </div>
                        <div>
                            <div v-if="Rates[0]">{{ Rates[0].rate }} XIN</div>
                            =
                            <div>1 USDT</div>
                        </div>
                    </div>
                    <div class="actions">
                        <button class="botan" @click="openDeposit('USDT')">
                            {{ $t('deposit') }}
                        </button>
                        <button class="botan" @click="openTransfer('USDT')">
                            {{ $t('transfer') }}
                        </button>
                        <button class="botan" @click="openSwap('USDT')">
                            {{ $t('swap') }}
                        </button>
                        <button class="botan" @click="openWithdraw('USDT')">
                            {{ $t('withdraw') }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 py-2 px-1">
                <div class="wallet-box">
                    <div class="balance">
                        <div>{{ $t('SoMBalance', { something: 'XIN' }) }}</div>
                        <div>
                            <button
                                @click="
                                    $store.dispatch(
                                        'info/get_refreshBalance',
                                        'xinCoin',
                                    )
                                "
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M12 20q-3.35 0-5.675-2.325T4 12q0-3.35 2.325-5.675T12 4q1.725 0 3.3.712T18 6.75V4h2v7h-7V9h4.2q-.8-1.4-2.187-2.2T12 6Q9.5 6 7.75 7.75T6 12q0 2.5 1.75 4.25T12 18q1.925 0 3.475-1.1T17.65 14h2.1q-.7 2.65-2.85 4.325T12 20"
                                    />
                                </svg>
                            </button>
                            <span>
                                {{ TruncateToDecimals2(Balance) }}
                            </span>
                            XIN
                        </div>
                    </div>
                    <div class="rate">
                        <div>
                            <img src="@/assets/images/logo/logoV3.png" />
                            {{ $t('rate') }}
                        </div>
                        <div>
                            <div v-if="Rates[1]">{{ Rates[1].rate }} USDT</div>
                            =
                            <div>1 XIN</div>
                        </div>
                    </div>
                    <div class="actions">
                        <button class="botan" @click="openDeposit('xinCoin')">
                            {{ $t('deposit') }}
                        </button>
                        <button class="botan" @click="openTransfer('xinCoin')">
                            {{ $t('transfer') }}
                        </button>
                        <button class="botan" @click="openSwap('xinCoin')">
                            {{ $t('swap') }}
                        </button>
                        <button class="botan" @click="openWithdraw('xinCoin')">
                            {{ $t('withdraw') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <b-modal
            id="transfer"
            hide-footer
            title="Transfer"
            dialog-class="my-modal wallet"
            centered
            @hidden="resetTransfer"
        >
            <div>
                {{ $t('transferToOther') }}
                <form class="my-form" @submit.prevent="postTransfer">
                    <label>
                        {{ $t('recipientID') }}:
                        <input v-model="transferForm.to" required />
                    </label>
                    <div>{{ $t('currency') }}:</div>
                    <div class="radio">
                        <label>
                            <input
                                type="radio"
                                v-model="transferForm.currency"
                                value="xinCoin"
                            />
                            <span>
                                <img src="@/assets/images/logo/xin.png" />
                                XIN
                            </span>
                        </label>
                        <label>
                            <input
                                type="radio"
                                v-model="transferForm.currency"
                                value="USDT"
                            />
                            <span>
                                <img src="@/assets/images/logo/usdt.png" />
                                USDT
                            </span>
                        </label>
                    </div>
                    <label>
                        <div class="labeling">
                            {{ $t('amount') }}:
                            <span class="minimum">
                                ( {{ $t('minimum') }}:
                                <template
                                    v-if="transferForm.currency === 'xinCoin'"
                                >
                                    {{ Fees.minTransferXIN }}
                                    <img
                                        src="@/assets/images/logo/xin.png"
                                        width="16px"
                                        height="16px"
                                        alt="xin"
                                    />
                                </template>
                                <template v-else>
                                    {{ Fees.minTransferUSDT }}
                                    <img
                                        src="@/assets/images/logo/usdt.png"
                                        width="16px"
                                        height="16px"
                                        alt="xin"
                                    />
                                </template>
                                )
                            </span>
                            <span class="fee">
                                {{ $t('fee') }}:
                                <span
                                    v-if="transferForm.currency === 'xinCoin'"
                                >
                                    {{ Fees.transferXIN * 100 }}%
                                </span>
                                <span v-else>
                                    {{ Fees.transferUSDT * 100 }}%
                                </span>
                            </span>
                        </div>
                        <input
                            v-model="transferForm.amount"
                            type="number"
                            min="0"
                            step="any"
                            required
                        />
                    </label>
                    <label class="wrap">
                        OTP:
                        <div class="position-relative">
                            <input
                                v-model="transferForm.otp"
                                type="text"
                                required
                            />
                            <button
                                type="button"
                                class="get-otp"
                                @click="$store.dispatch('wallet/get_OTP')"
                            >
                                {{ $t('getOTP') }}
                            </button>
                        </div>
                    </label>

                    <div class="text-center">
                        <button
                            type="submit"
                            class="submit"
                            :disabled="pendingTransfer"
                        >
                            {{ $t('transfer') }}
                        </button>
                    </div>
                </form>
            </div>
        </b-modal>
        <b-modal
            id="swap"
            hide-footer
            title="Swap"
            dialog-class="my-modal wallet"
            centered
            @hidden="resetSwap"
        >
            <div>
                {{ $t('swapCurrency') }}
                <form class="my-form" @submit.prevent="postSwap">
                    <div>
                        {{
                            $t('swapFromTo', [
                                swapForm.from === 'xinCoin' ? 'XIN' : 'USDT',
                                swapForm.to === 'xinCoin' ? 'XIN' : 'USDT',
                            ])
                        }}
                    </div>
                    <div class="swap-confirm">
                        <img
                            v-if="swapForm.from === 'xinCoin'"
                            src="@/assets/images/logo/xin.png"
                        />
                        <img v-else src="@/assets/images/logo/usdt.png" />
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            viewBox="0 0 24 24"
                        >
                            <g transform="translate(24 0) scale(-1 1)">
                                <g
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                >
                                    <path
                                        stroke-dasharray="20"
                                        stroke-dashoffset="20"
                                        d="M21 12H3.5"
                                    >
                                        <animate
                                            fill="freeze"
                                            attributeName="stroke-dashoffset"
                                            dur="0.3s"
                                            values="20;0"
                                        />
                                    </path>
                                    <path
                                        stroke-dasharray="12"
                                        stroke-dashoffset="12"
                                        d="M3 12L10 19M3 12L10 5"
                                    >
                                        <animate
                                            fill="freeze"
                                            attributeName="stroke-dashoffset"
                                            begin="0.3s"
                                            dur="0.2s"
                                            values="12;0"
                                        />
                                    </path>
                                </g>
                            </g>
                        </svg>
                        <img
                            v-if="swapForm.to === 'xinCoin'"
                            src="@/assets/images/logo/xin.png"
                        />
                        <img v-else src="@/assets/images/logo/usdt.png" />
                    </div>
                    <label>
                        <div class="labeling">
                            {{ $t('amount') }}:
                            <span class="minimum">
                                ( {{ $t('minimum') }}:
                                <template v-if="swapForm.from === 'xinCoin'">
                                    {{ Fees.minSwapXIN }}
                                    <img
                                        src="@/assets/images/logo/xin.png"
                                        width="16px"
                                        height="16px"
                                        alt="xin"
                                    />
                                </template>
                                <template v-else>
                                    {{ Fees.minSwapUSDT }}
                                    <img
                                        src="@/assets/images/logo/usdt.png"
                                        width="16px"
                                        height="16px"
                                        alt="xin"
                                    />
                                </template>
                                )
                            </span>
                            <span class="fee"
                                >{{ $t('fee') }}:
                                <template v-if="swapForm.from === 'xinCoin'">
                                    {{ Fees.swapXIN * 100 }}%
                                </template>
                                <template v-else>
                                    {{ Fees.swapUSDT * 100 }}%
                                </template>
                            </span>
                        </div>
                        <input
                            v-model="swapForm.amount"
                            type="number"
                            min="0"
                            step="any"
                            required
                        />
                    </label>
                    <label class="wrap">
                        {{ $t('result') }}:
                        <div class="position-relative">
                            <input
                                v-if="swapForm.from === 'xinCoin'"
                                :value="swapForm.amount * Rates[1].rate"
                                readonly
                            />
                            <input
                                v-else
                                :value="swapForm.amount * Rates[0].rate"
                                readonly
                            />
                            <img
                                v-if="swapForm.to === 'xinCoin'"
                                src="@/assets/images/logo/xin.png"
                            />
                            <img v-else src="@/assets/images/logo/usdt.png" />
                        </div>
                    </label>
                    <div class="text-center">
                        <button
                            type="submit"
                            class="submit"
                            :disabled="pendingSwap"
                        >
                            {{ $t('swap') }}
                        </button>
                    </div>
                </form>
            </div>
        </b-modal>
        <b-modal
            id="withdraw"
            hide-footer
            title="Withdraw"
            dialog-class="my-modal wallet"
            centered
            @hidden="resetWithdraw"
        >
            <div>
                {{ $t('withdrawFromSystem') }}
                <form class="my-form" @submit.prevent="postWithdraw">
                    <div class="swap-confirm">
                        <img
                            v-if="withdrawForm.currency === 'xinCoin'"
                            src="@/assets/images/logo/xin.png"
                        />
                        <img v-else src="@/assets/images/logo/usdt.png" />
                    </div>
                    <label>
                        <div class="labeling">
                            {{ $t('amount') }}:
                            <span class="minimum">
                                ( {{ $t('minimum') }}:
                                <template
                                    v-if="withdrawForm.currency === 'xinCoin'"
                                >
                                    {{ Fees.minWithdrawXIN }}
                                    <img
                                        src="@/assets/images/logo/xin.png"
                                        width="16px"
                                        height="16px"
                                        alt="xin"
                                    />
                                </template>
                                <template v-else>
                                    {{ Fees.minWithdrawUSDT }}
                                    <img
                                        src="@/assets/images/logo/usdt.png"
                                        width="16px"
                                        height="16px"
                                        alt="xin"
                                    />
                                </template>
                                )
                            </span>
                            <span class="fee"
                                >{{ $t('fee') }}:
                                <template
                                    v-if="withdrawForm.currency === 'xinCoin'"
                                >
                                    {{ Fees.withdrawXIN * 100 }} %
                                </template>
                                <template v-else>
                                    {{ Fees.withdrawUSDT * 100 }} %
                                </template>
                            </span>
                        </div>
                        <input
                            v-model="withdrawForm.amount"
                            type="number"
                            min="0"
                            step="any"
                            required
                        />
                    </label>
                    <label>
                        {{ $t('address') }}:
                        <input
                            v-model="withdrawForm.address"
                            type="text"
                            required
                        />
                        <div class="withdraw-notice">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                viewBox="0 0 256 256"
                            >
                                <path
                                    fill="currentColor"
                                    d="M236.8 188.09L149.35 36.22a24.76 24.76 0 0 0-42.7 0L19.2 188.09a23.51 23.51 0 0 0 0 23.72A24.35 24.35 0 0 0 40.55 224h174.9a24.35 24.35 0 0 0 21.33-12.19a23.51 23.51 0 0 0 .02-23.72m-13.87 15.71a8.5 8.5 0 0 1-7.48 4.2H40.55a8.5 8.5 0 0 1-7.48-4.2a7.59 7.59 0 0 1 0-7.72l87.45-151.87a8.75 8.75 0 0 1 15 0l87.45 151.87a7.59 7.59 0 0 1-.04 7.72M120 144v-40a8 8 0 0 1 16 0v40a8 8 0 0 1-16 0m20 36a12 12 0 1 1-12-12a12 12 0 0 1 12 12"
                                />
                            </svg>
                            {{ $t('withdrawToBEP20WalletOnly') }}
                        </div>
                    </label>
                    <label class="wrap">
                        OTP:
                        <div class="position-relative">
                            <input
                                v-model="withdrawForm.otp"
                                type="text"
                                required
                            />
                            <button
                                type="button"
                                class="get-otp"
                                @click="$store.dispatch('wallet/get_OTP')"
                            >
                                {{ $t('getOTP') }}
                            </button>
                        </div>
                    </label>
                    <div class="text-center">
                        <button
                            type="submit"
                            class="submit"
                            :disabled="pendingWithdraw"
                        >
                            {{ $t('withdraw') }}
                        </button>
                    </div>
                </form>
            </div>
        </b-modal>
        <b-modal
            id="deposit"
            hide-footer
            title="Deposit BEP20 Token"
            dialog-class="my-modal wallet"
            centered
            @hidden="resetDeposit"
        >
            <div
                class="d-flex justify-content-center align-items-center flex-column"
                v-if="UserInfo && UserInfo.bep20Wallet"
            >
                <VueQr :text="UserInfo.bep20Wallet" />
                <div class="copy-address">
                    <span>
                        {{ UserInfo.bep20Wallet }}
                    </span>
                    <b-button class="copy">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                        >
                            <g clip-path="url(#clip0_57_1540)">
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M6.5 3.5C6.5 2.70435 6.81607 1.94129 7.37868 1.37868C7.94129 0.816071 8.70435 0.5 9.5 0.5L21.5 0.5C22.2956 0.5 23.0587 0.816071 23.6213 1.37868C24.1839 1.94129 24.5 2.70435 24.5 3.5V15.5C24.5 16.2956 24.1839 17.0587 23.6213 17.6213C23.0587 18.1839 22.2956 18.5 21.5 18.5H9.5C8.70435 18.5 7.94129 18.1839 7.37868 17.6213C6.81607 17.0587 6.5 16.2956 6.5 15.5V3.5ZM9.5 2C9.10218 2 8.72064 2.15804 8.43934 2.43934C8.15804 2.72064 8 3.10218 8 3.5V15.5C8 15.8978 8.15804 16.2794 8.43934 16.5607C8.72064 16.842 9.10218 17 9.5 17H21.5C21.8978 17 22.2794 16.842 22.5607 16.5607C22.842 16.2794 23 15.8978 23 15.5V3.5C23 3.10218 22.842 2.72064 22.5607 2.43934C22.2794 2.15804 21.8978 2 21.5 2H9.5ZM3.5 8C3.10218 8 2.72064 8.15804 2.43934 8.43934C2.15804 8.72064 2 9.10218 2 9.5V21.5C2 21.8978 2.15804 22.2794 2.43934 22.5607C2.72064 22.842 3.10218 23 3.5 23H15.5C15.8978 23 16.2794 22.842 16.5607 22.5607C16.842 22.2794 17 21.8978 17 21.5V20H18.5V21.5C18.5 22.2956 18.1839 23.0587 17.6213 23.6213C17.0587 24.1839 16.2956 24.5 15.5 24.5H3.5C2.70435 24.5 1.94129 24.1839 1.37868 23.6213C0.816071 23.0587 0.5 22.2956 0.5 21.5V9.5C0.5 8.70435 0.816071 7.94129 1.37868 7.37868C1.94129 6.81607 2.70435 6.5 3.5 6.5H5V8H3.5Z"
                                    fill="white"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_57_1540">
                                    <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                        transform="translate(0.5 0.5)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                        <div
                            class="text"
                            v-clipboard:copy="UserInfo.bep20Wallet"
                            v-clipboard:success="onCopy"
                            v-clipboard:error="onError"
                        >
                            {{ $t('copy') }}
                        </div>
                    </b-button>
                </div>
            </div>
            <div class="deposit-labeling">
                <span class="minimum">
                    ( {{ $t('minimum') }}:
                    <template v-if="deposit === 'xinCoin'">
                        {{ Fees.minDepositXIN }}
                        <img
                            src="@/assets/images/logo/xin.png"
                            width="16px"
                            height="16px"
                            alt="xin"
                        />
                    </template>
                    <template v-else>
                        {{ Fees.minDepositUSDT }}
                        <img
                            src="@/assets/images/logo/usdt.png"
                            width="16px"
                            height="16px"
                            alt="xin"
                        />
                    </template>
                    )
                </span>
                <span class="fee">
                    {{ $t('fee') }}:
                    <span v-if="deposit === 'xinCoin'">
                        {{ Fees.depositXIN * 100 }}%
                    </span>
                    <span v-else> {{ Fees.depositUSDT * 100 }}% </span>
                </span>
            </div>
            <div class="content mt-4">
                <div class="deposit-notice">
                    <i18n path="depositNotice">
                        <button @click="refreshBalance">
                            {{ $t('speedUp') }}
                        </button>
                    </i18n>
                </div>
                <p class="mb-1 pl-3 important" style="letter-spacing: 1px">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 100 100"
                        class="arrow"
                    >
                        <path
                            fill="currentColor"
                            d="m50.868 78.016l36.418-26.055a2.52 2.52 0 0 0 1.051-2.043v-.006a2.52 2.52 0 0 0-1.059-2.048L50.86 21.977a2.51 2.51 0 0 0-2.612-.183a2.51 2.51 0 0 0-1.361 2.236v12.183l-32.709-.001a2.514 2.514 0 0 0-2.515 2.516l.001 22.541a2.515 2.515 0 0 0 2.516 2.516h32.706v12.187c0 .94.53 1.803 1.366 2.237a2.51 2.51 0 0 0 2.616-.193"
                        />
                    </svg>
                    <span class="headline">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            viewBox="0 0 256 256"
                        >
                            <path
                                fill="currentColor"
                                d="M236.8 188.09L149.35 36.22a24.76 24.76 0 0 0-42.7 0L19.2 188.09a23.51 23.51 0 0 0 0 23.72A24.35 24.35 0 0 0 40.55 224h174.9a24.35 24.35 0 0 0 21.33-12.19a23.51 23.51 0 0 0 .02-23.72m-13.87 15.71a8.5 8.5 0 0 1-7.48 4.2H40.55a8.5 8.5 0 0 1-7.48-4.2a7.59 7.59 0 0 1 0-7.72l87.45-151.87a8.75 8.75 0 0 1 15 0l87.45 151.87a7.59 7.59 0 0 1-.04 7.72M120 144v-40a8 8 0 0 1 16 0v40a8 8 0 0 1-16 0m20 36a12 12 0 1 1-12-12a12 12 0 0 1 12 12"
                            />
                        </svg>
                        {{ $t('important') }}:
                    </span>
                    {{ $t('useAddressHere') }}
                </p>
                <p
                    class="text-left font-size-14 mb-1 pl-3 font-weight-bold"
                    style="letter-spacing: 1px"
                >
                    - {{ $t('onlyBEP20Wallet') }}
                    <span class="text-warning">
                        {{ $t('onlyBEP20Token') }}
                    </span>
                </p>
                <p
                    class="text-left font-size-14 mb-1 pl-3 font-weight-bold"
                    style="letter-spacing: 1px"
                >
                    - {{ $t('waitForABit') }}
                    <span class="text-warning">
                        {{ $t('maxWaitTime') }}
                    </span>
                </p>
                <p class="text-left font-size-14 pl-3 font-weight-bold">
                    - {{ $t('questionOrSupport') }}
                    <span class="text-warning">support@xintel.co</span>
                </p>
            </div>
        </b-modal>
    </div>
</template>

<script>
import VueQr from 'vue-qr';
import { mapGetters } from 'vuex';

export default {
    name: 'WalletPage',
    components: { VueQr },
    data() {
        return {
            options2: {
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
                prefix: '',
                decimalPlaces: 2,
            },

            pendingTransfer: false,
            transferForm: {
                to: '',
                amount: '',
                currency: '',
                otp: '',
            },

            pendingSwap: false,
            swapForm: {
                from: '',
                to: '',
                amount: '',
            },

            pendingWithdraw: false,
            withdrawForm: {
                amount: '',
                currency: '',
                otp: '',
                address: '',
            },

            deposit: '',
        };
    },
    computed: {
        ...mapGetters({
            Balance: 'info/Balance',
            UserInfo: 'info/UserInfo',
            Rates: 'wallet/Rates',
            XNode: 'info/XNode',
            Fees: 'wallet/Fees',
        }),
        USDTCommission() {
            if (
                this.XNode.totalCommission &&
                this.XNode.totalCommission.length > 0
            ) {
                const com = this.XNode.totalCommission.find((el) => {
                    return el._id === 'USDT';
                });
                if (com) {
                    return com.total;
                }
            }
            return 0;
        },
    },
    methods: {
        resetTransfer() {
            this.transferForm.to = '';
            this.transferForm.amount = '';
            this.transferForm.currency = '';
            this.transferForm.otp = '';
        },
        openTransfer(currency) {
            this.transferForm.currency = currency;
            this.$bvModal.show('transfer');
        },
        postTransfer() {
            if (this.pendingTransfer) {
                return;
            }
            this.pendingTransfer = true;

            this.$store
                .dispatch('wallet/post_transferSystem', this.transferForm)
                .then((res) => {
                    if (res) {
                        this.$bvModal.hide('transfer');
                        this.$store.dispatch('info/req_getInfo');
                    }
                })
                .finally(() => {
                    this.pendingTransfer = false;
                });
        },

        resetSwap() {
            this.swapForm.to = '';
            this.swapForm.from = '';
            this.swapForm.amount = '';
        },
        openSwap(currency) {
            if (currency === 'xinCoin') {
                this.swapForm.from = 'xinCoin';
                this.swapForm.to = 'USDT';
            } else {
                this.swapForm.from = 'USDT';
                this.swapForm.to = 'xinCoin';
            }
            this.$bvModal.show('swap');
        },
        postSwap() {
            if (this.pendingSwap) {
                return;
            }
            this.pendingSwap = true;

            this.$store
                .dispatch('wallet/post_swapSystem', this.swapForm)
                .then((res) => {
                    if (res) {
                        this.$bvModal.hide('swap');
                        this.$store.dispatch('info/req_getInfo');
                    }
                })
                .finally(() => {
                    this.pendingSwap = false;
                });
        },

        resetWithdraw() {
            this.withdrawForm.amount = '';
            this.withdrawForm.currency = '';
            this.withdrawForm.otp = '';
            this.withdrawForm.address = '';
        },
        openWithdraw(currency) {
            this.withdrawForm.currency = currency;
            this.$bvModal.show('withdraw');
        },
        postWithdraw() {
            if (this.pendingWithdraw) {
                return;
            }
            this.pendingWithdraw = true;

            this.$store
                .dispatch('wallet/post_withdrawSystem', this.withdrawForm)
                .then((res) => {
                    if (res) {
                        this.$bvModal.hide('withdraw');
                        this.$store.dispatch('info/req_getInfo');
                    }
                })
                .finally(() => {
                    this.pendingWithdraw = false;
                });
        },

        resetDeposit() {
            this.deposit = '';
        },
        openDeposit(currency) {
            this.deposit = currency;
            this.$bvModal.show('deposit');
        },
        refreshBalance() {
            if (this.deposit === 'xinCoin') {
                this.$store.dispatch('info/get_refreshBalance', 'xinCoin');
            } else {
                this.$store.dispatch('info/get_refreshBalance', 'USDT');
            }
        },

        onCopy() {
            this.$toastr.s('Copy Address Wallet Success', 'Successfully');
        },
        onError() {
            this.$toastr.e(
                'Copy Address Wallet Fail,Please Try Again',
                'Failed',
            );
        },
    },
    mounted() {
        this.$store.dispatch('wallet/get_rates');
        this.$store.dispatch('wallet/get_fees');
    },
};
</script>

<style lang="scss" scoped>
.box-statistical {
    // background: linear-gradient(to right, #18d4e2, #0087C8);
    background: #1478b0;
    height: 100%;
    outline: none;
    padding: 15px;
    position: relative;
    border-radius: 22px;
    border: 1px solid #d8d8d8;

    .text {
        color: #fff;
        z-index: 1;
        flex-grow: 1;
        font-size: 14px;

        .text-title {
            font-size: 1em;
            font-style: italic;
            font-weight: 500;
            margin-bottom: 15px;
            text-transform: capitalize;
            display: flex;
            justify-content: space-between;
        }

        .text-content {
            font-size: 1.125em;
            color: #fff;
            font-weight: 700;
            display: flex;
            justify-content: space-between;
            gap: 5px;

            &.bound {
                color: #0f766e;
            }

            > span {
                min-width: 0;
                word-wrap: break-word;
            }

            > div:last-child {
                flex-shrink: 0;
            }
        }

        &.bound {
            .text-content {
                color: #fff;
            }

            .text-title {
                color: #fff;
            }
        }

        @media (min-width: 992px) {
            font-size: 16px;
        }
    }
}

.wallet-box {
    border: 1px solid #d8d8d8;
    border-radius: 12px;
    padding: 15px;
    background: #1478b0;

    .balance {
        display: flex;
        justify-content: space-between;
        gap: 5px;
        padding-bottom: 15px;
        border-bottom: 1px solid #c8c8c8;
        color: #fff;

        > div:last-child {
            font-weight: 600;

            button {
                color: inherit;
            }
        }
    }

    .rate {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0px;
        color: #fff;
        img {
            width: 60px;
        }

        > div:last-child {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 5px;
            font-weight: 600;

            @media (min-width: 576px) {
                flex-basis: 35%;
                flex-shrink: 0;
            }

            > div:first-child {
                color: #6dceff;
            }

            > div:last-child {
                white-space: nowrap;
            }
        }
    }

    .actions {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 5px;
        padding: 10px 0px;

        > .botan {
            background: #fff;
            color: #1478b0;
            font-weight: 700;
            border: 1px solid #0087cc;
            border-radius: 100vw;
            padding: 10px 15px;
            transition: 0.2s;

            &:hover {
                opacity: 0.8;
            }
        }
    }
}

.my-form {
    > label {
        display: block;
        margin: 10px 0px;

        > .labeling {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        > input {
            box-shadow: 0px 0px 6px 2px #0000001c;
            border-radius: 6px;
            display: block;
            width: 100%;
            color: #000;
            padding: 4px 10px;
            margin: 5px 0px;
        }
    }

    .radio {
        display: flex;
        justify-content: space-around;

        > label {
            input[type='radio'] {
                opacity: 0;
                width: 0;
                height: 0;
                position: absolute;
            }

            span {
                border-radius: 8px;
                border: 1px solid #656565;
                display: flex;
                align-items: center;
                gap: 5px;
                padding: 4px;
                transition: 0.2s;

                > img {
                    width: 32px;
                }

                &:hover {
                    border-color: #0087cc;
                }
            }

            input[type='radio']:checked + span {
                color: white;
                border-color: #0087cc;
                background-color: #0087cc;
            }
        }
    }

    .swap-confirm {
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 5px;
        padding: 10px;

        svg {
            color: #0087cc;
            font-size: 40px;
        }

        > img {
            width: 40px;
        }
    }

    .submit {
        background: linear-gradient(180deg, #00d1ff 0%, #0087c8 100%);
        padding: 5px 10px;
        border-radius: 100vw;
        color: #fff;
        transition: 0.2s;

        &:disabled {
            filter: grayscale(1);
        }
    }

    .wrap {
        > div {
            position: relative;

            > input {
                box-shadow: 0px 0px 6px 2px #0000001c;
                border-radius: 6px;
                display: block;
                width: 100%;
                color: #000;
                padding: 4px 10px;
                margin: 5px 0px;
            }

            img {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                width: 20px;
            }

            .get-otp {
                position: absolute;
                right: 2px;
                top: 2px;
                bottom: 2px;
                background-color: #0087cc;
                border-radius: 6px;
                color: white;
            }
        }
    }
}

.deposit-labeling {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0px 30px;
}

.fee {
    color: #0087c8;
    font-weight: 600;
    flex-grow: 1;
    text-align: right;
    white-space: nowrap;
}

.minimum {
    color: #0087c8;
    font-weight: 600;
    white-space: nowrap;
}

.deposit-notice {
    color: #d97706;
    padding-left: 16px;
    padding-bottom: 10px;
    font-size: 20px;

    button {
        background: linear-gradient(180deg, #00d1ff 0%, #0087c8 100%);
        color: white;
        border-radius: 6px;
        padding: 4px 10px;
    }
}
.important {
    color: #0087c8;
    font-weight: bold;
    font-size: 18px;
    // text-decoration: underline #d97706;
    .arrow {
        animation: pointing 2s infinite;
        margin-right: 20px;
        color: #d97706;
        @keyframes pointing {
            50% {
                transform: translateX(20px);
                transition-timing-function: ease-in;
            }
            100% {
                transform: translateX(0px);
                transition-timing-function: ease-out;
            }
        }
    }
    .headline {
        color: #d97706;
    }
}
.withdraw-notice {
    font-size: 14px;
    font-weight: 600;
    color: #d97706;
}
</style>

<style lang="scss">
.my-modal {
    .modal-body {
        border-radius: 0px 0px 4px 4px;
    }
}

.copy-address {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #00d1ff;
    padding-left: 5px;
    border-radius: 5px;
    margin-top: 10px;
    max-width: 100%;

    span {
        font-size: 14px;
        word-wrap: break-word;
        min-width: 0;
    }

    button {
        padding: 5px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        width: 75px;
        margin-left: 5px;
        background: linear-gradient(180deg, #00d1ff 0%, #0087c8 100%);
        border-color: #00d1ff;
        line-height: 1;

        svg {
            width: 15px;
            margin-right: 3px;
            height: 15px;
        }
    }
}
</style>
