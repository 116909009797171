<template>
    <div>
        <b-modal id="mana-transfer" hide-footer hide-header dialog-class="supa-modal" centered>
            <div>
                <div class="head">{{ $t('transferMana') }}</div>
                <input v-model="amount" class="input" type="number" min="0" oninput="validity.valid||(value='');"
                    :placeholder="$t('amount')">
                <input v-model="recipient" class="input" type="text" :placeholder="$t('recipientID')">
                <div class="button">
                    <button @click="confirmPop">{{ $t('transfer') }}</button>
                </div>
            </div>
            <button class="close" @click="$bvModal.hide('mana-transfer')" type="button" style="color: #0087cc">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor"
                        d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z" />
                </svg>
            </button>
            <button class="breadcrumb" @click="goBack">
                <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                    <path fill="currentColor"
                        d="M17 11H9.414l2.293-2.293a.999.999 0 1 0-1.414-1.414L5.586 12l4.707 4.707a.997.997 0 0 0 1.414 0a.999.999 0 0 0 0-1.414L9.414 13H17a1 1 0 0 0 0-2" />
                </svg>
                {{ $t('manaCenter') }}
            </button>
        </b-modal>
        <b-modal id="mana-transfer-confirm" dialog-class="mana-transfer-confirm" hide-header hide-footer centered>
            <svg @click="$bvModal.hide('mana-transfer-confirm')" xmlns="http://www.w3.org/2000/svg" width="1em"
                height="1em" viewBox="0 0 24 24">
                <path fill="currentColor"
                    d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z" />
            </svg>
            <h2>{{ $t('transferMana') }}</h2>
            <h3>{{ $t('transferManaConfirm') }}</h3>
            <p>
                <i18n path="transferManaConfirming">
                    <template v-slot:mana>
                        <strong>{{ UserInfo.mana }}</strong>
                    </template>
                    <template v-slot:amount>
                        <strong>{{ amount }}</strong>
                    </template>
                    <template v-slot:user>
                        <strong>
                            {{ $t('user') }}
                            {{ recipient }}
                        </strong>
                    </template>
                </i18n>
            </p>
            <p>
            </p>
            <div>
                <span @click="transferMana">{{ $t('confirm') }}</span>
                <span @click="cancelPop">{{ $t('cancel') }}</span>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ManaTopup',
    data() {
        return {
            amount: '',
            recipient: ''
        }
    },
    computed: {
        ...mapGetters({
            UserInfo: 'info/UserInfo',
        }),
    },
    methods: {
        goBack() {
            this.$bvModal.show('mana-center');
            this.$bvModal.hide('mana-transfer');
        },
        confirmPop() {
            if (this.amount && this.recipient) {
                this.$bvModal.hide('mana-transfer');
                this.$bvModal.show('mana-transfer-confirm');
            } else {
                this.$toastr.e('Please fill the inputs!!', 'Oops!');
            }
        },
        cancelPop() {
            this.$bvModal.hide('mana-transfer-confirm')
            this.$bvModal.show('mana-transfer')
        },
        transferMana() {
            this.$store.dispatch('info/transferMana', { amount: this.amount, recipient: this.recipient }).then(() => {
                this.$store.dispatch('info/req_getInfo')
                this.$bvModal.hide('mana-transfer-confirm')
                this.amount = ''
                this.recipient = ''
            })
        },
    },

};
</script>

<style lang="scss" scoped>
.head {
    font-size: 30px;
    font-weight: 600;
    color: #0087cc;
    text-align: center;
    margin-top: 1rem;
}

.input {
    width: 100%;
    padding: 0 1rem;
    border: 1px solid #e4e4e4;
    border-radius: 8px;
    height: 40px;
    color: #000;

    &::placeholder {
        color: #a6a6a6;
        font-size: 18px;
        font-style: italic;
        font-weight: 400;
        text-align: left;
    }
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.button {
    display: flex;
    justify-content: center;

    button {
        max-width: 200px;
        width: 100%;
        padding: 4px 10px;
        border-radius: 72px;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 600;
        background: linear-gradient(to right, #1775B8, #91C4E9);
        color: #fff;
        transition: 0.2s;
        outline: none;

        &:hover {
            opacity: 0.8;
        }
    }
}
</style>


<style lang="scss">
.mana-transfer-confirm {
    @media (min-width: 320px) {
        .modal-content {
            position: relative;
            width: 100%;
            margin: 0 auto;

            .modal-body {
                border-radius: 12px;

                h2 {
                    color: #0087CC;
                    text-align: center;
                    font-size: 30px;
                    margin-bottom: 15px;
                }

                h3 {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                }

                p {
                    font-size: 16px;
                    font-weight: 500;
                    text-align: center;
                }

                svg {
                    position: absolute;
                    font-size: 30px;
                    top: 10px;
                    right: 10px;
                    cursor: pointer;
                }

                >div {
                    display: flex;
                    align-content: center;
                    justify-content: space-around;
                    gap: 15px;
                    margin-top: 1rem;

                    span {
                        cursor: pointer;
                        max-width: 200px;
                        width: 100%;
                        color: white;
                        border-radius: 72px;
                        padding: 4px 10px;
                        font-size: 18px;
                        text-transform: uppercase;
                        font-weight: 600;
                        text-align: center;
                        transition: 0.2s;

                        &:nth-child(1) {
                            background-image: linear-gradient(to right, #075d9b, #1775b8, #91c4e9, #d2e9fa);
                            background-position: center;
                            background-size: 200%;

                            &:hover {
                                background-position: 0%;

                            }
                        }

                        &:nth-child(2) {
                            color: #0087CC;
                            border: 1px solid #0087CC;
                        }
                    }
                }
            }
        }
    }
}
</style>
