<template>
    <div v-if="this.Master" class="dashboard-container container py-2">
        <div class="statistical">
            <div class="box-statistical">
                <div class="text">
                    <div class="text-title">3.5% {{ $t('totalClaim') }}</div>
                    <div class="text-content">
                        <span v-if="StatisticalMint">
                            {{ StatisticalMint.claimComm }}
                        </span>
                        XIN
                    </div>
                </div>
            </div>
        </div>
        <div class="masternode-list">
            <div class="d-flex justify-content-between flex-wrap align-items-start mb-3">
                <div class="d-flex align-items-center">
                    <div class="title p-0">{{ $t('masterNodeMemberList') }}</div>
                </div>
            </div>
            <div class="d-none d-lg-block table-responsive">
                <b-table striped hover :items="MasterNodeMemberList" :fields="fields" show-empty
                    thead-class="customer-header">
                    <template #empty>
                        <h4 class="text-center my-4">{{ $t('noInfo') }}</h4>
                    </template>

                    <template #head(refId)="data">
                        <div>{{ $t('userId') }}</div>
                    </template>

                    <template #head(email)="data">
                        <div>{{ $t('email') }}</div>
                    </template>

                    <template #head(createdAt)="data">
                        <div>{{ $t('createdDate') }}</div>
                    </template>

                    <template #cell(refId)="{ item }">
                        <span v-if="item.nodeInfo">
                            {{ item.nodeInfo.refId }}
                        </span>
                    </template>
                    <template #cell(email)="{ item }">
                        <span v-if="item.nodeInfo">
                            {{ item.nodeInfo.email }}
                        </span>
                    </template>
                    <template #cell(createdAt)="{ item }">
                        <span v-if="item.nodeInfo">
                            {{ getDateTime(item.createdAt) }}
                        </span>
                    </template>
                </b-table>
            </div>
            <div class="d-lg-none table-responsive mobile">
                <b-table :items="MasterNodeMemberList" :fields="mobileFields" show-empty striped
                    thead-class="customer-header">
                    <template #cell(#)="row">
                        <b-button size="sm" variant="info" @click="row.toggleDetails" class="px-2 py-0">
                            {{ row.detailsShowing ? $t('hide') : $t('show') }}
                        </b-button>
                    </template>
                    <template #cell(refId)="{ item }">
                        <span v-if="item.nodeInfo">
                            {{ item.nodeInfo.refId }}
                        </span>
                    </template>
                    <template #cell(email)="{ item }">
                        <span v-if="item.nodeInfo">
                            {{ item.nodeInfo.email }}
                        </span>
                    </template>
                    <template #cell(createdAt)="{ item }">
                        <span v-if="item.nodeInfo">
                            {{ getDateTime(item.createdAt) }}
                        </span>
                    </template>
                </b-table>
            </div>
            <!-- <div class="table-paginate">
                <Paginate @current="onChangePage" :totalPages="Member.total" v-if="renderComponent" />
            </div> -->
        </div>
    </div>
</template>

<style lang="scss">
.dashboard-container {
    .statistical {
        margin-top: 1rem;

        .box-statistical {
            max-width: 450px;
            margin: 0 auto;
            height: 100%;
            outline: none;
            padding: 15px;
            position: relative;
            border-radius: 22px;
            border: 1px solid #d8d8d8;

            .icons {
                flex: 0 0 70px;
                width: 70px;
                height: 100%;

                img {
                    width: 50px;
                    height: auto;
                }
            }

            .text {
                color: #000;
                z-index: 1;
                flex-grow: 1;
                font-size: 14px;

                .text-title {
                    font-size: 1em;
                    font-style: italic;
                    font-weight: 500;
                    margin-bottom: 15px;
                    text-transform: capitalize;
                    display: flex;
                    justify-content: space-between;
                }

                .text-content {
                    font-size: 1.125em;
                    color: #0087cc;
                    font-weight: 700;
                    display: flex;
                    justify-content: space-between;
                    gap: 5px;

                    &.bound {
                        color: #0f766e;
                    }

                    >span {
                        min-width: 0;
                        word-wrap: break-word;
                    }

                    >div:last-child {
                        flex-shrink: 0;
                    }
                }

                &.bound {
                    .text-content {
                        color: #0f766e;
                    }

                    .text-title {
                        color: #022c22;
                    }
                }

                @media (min-width: 992px) {
                    font-size: 16px;
                }
            }
        }
    }

    .masternode-list {
        margin-top: 1rem;

        .title {
            font-size: clamp(1.2em, 4vw, 1.8em);
            padding-bottom: 10px;
            position: relative;
            letter-spacing: 0.6px;
            width: max-content;
            font-weight: 600;
            background: #0087cc;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .table-responsive {
            .customer-header {
                background-color: transparent;
                text-align: center;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #a6a6a6;
            }

            .table th,
            .table td {
                border: none;
            }

            table {
                thead {
                    tr {
                        th {
                            white-space: nowrap;
                        }
                    }
                }

                tbody {
                    tr {
                        height: 70px;
                        border-radius: 12px;
                        background: transparent;

                        &:nth-child(even) {
                            background: #e1e1e175;
                        }

                        td {
                            background: none;
                            height: 100%;
                            padding: 0.75rem;
                            vertical-align: middle;
                            color: #000;
                            font-weight: 500;

                            .mobile-data {
                                display: flex;
                                justify-content: space-between;
                                flex-wrap: wrap;
                                line-height: 1;
                                padding: 5px 0px;

                                >div:last-child {
                                    color: #0087cc;
                                }
                            }

                            >span {
                                height: 70px;
                                display: flex;
                                padding: 0 0.75rem;
                                align-items: center;
                                margin-bottom: 0;
                                justify-content: center;
                                backdrop-filter: blur(15.899999618530273px);
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>

<script>
import { mapGetters } from 'vuex';

export default {
    data: () => {
        return {
            fields: [
                {
                    key: 'refId',
                    sortable: false,
                    label: 'User ID',
                    class: 'text-center',
                },
                {
                    key: 'email',
                    sortable: false,
                    label: 'Email',
                    class: 'text-center',
                },
                {
                    key: 'createdAt',
                    sortable: false,
                    label: 'Create Date',
                    class: 'text-center',
                },
            ],
            mobileFields: [
                {
                    key: 'userId',
                    sortable: false,
                    label: 'User ID',
                    class: 'text-center',
                },
                {
                    key: 'email',
                    sortable: false,
                    label: 'Email',
                    class: 'text-center',
                },
                {
                    key: 'createdAt',
                    sortable: false,
                    label: 'Create Date',
                    class: 'text-center',
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            MasterNodeMemberList: 'masternode/MasterNodeMemberList',
            Master: 'info/Master',
            StatisticalMint: 'masternode/StatisticalMint',
        }),
    },
    beforeMount() {
        if (this.Master !== null) {
            if (!this.Master) {
                this.$router.push({ name: 'AboutMasterXNode' });
            } else {
                this.$store.dispatch('masternode/getMasterNodeMemberList');
                this.$store.dispatch('masternode/getStatistialMint');
            }
        } else {
            this.$store.dispatch('info/get_masterXNode').then((res) => {
                if (!res) {
                    this.$router.push({ name: 'AboutMasterXNode' });
                } else {
                    this.$store.dispatch('masternode/getMasterNodeMemberList');
                    this.$store.dispatch('masternode/getStatistialMint');
                }
            });
        }
    },
};
</script>
