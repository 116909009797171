/* eslint-disable camelcase */
import { TOKEN_ABI } from '@/store/abi/token';
import { REGISTER_ABI } from '@/store/abi/register';
import { PRESALE_ABI } from '@/store/abi/presale';
import { PANCAKE_ABI } from '@/store/abi/pancake';
import { USDT_ABI } from '@/store/abi/usdtToken';
import { VESTING } from '@/store/abi/vesting';

const network = process.env.VUE_APP_CONTRACT;
function getNetwork() {
    const mainNet = {
        provider: {
            chainId: 0x38,
            link: 'https://bsc-dataseed1.defibit.io',
            url: 'https://bscscan.com',
        },
        pool: {
            deposit: '',
        },
        usdt: {
            addr: '0x55d398326f99059ff775485246999027b3197955',
            dec: 10 ** 18,
            cont: {},
        },
        bnb: {
            addr: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
            dec: 10 ** 18,
            cont: {},
        },
        ucon: {
            addr: '0x1a8ae40f498aed05e3647933fbdad54ecfd2134b',
            dec: 10 ** 18,
            cont: {},
        },
        preSale: {
            addr: '0x88FE4e91087Dd23e3017eB86391d51c492f1ECAE',
            abi: PRESALE_ABI,
            cont: {},
        },

        register: {
            addr: '0xa41fb1ccc155558c763fb4d932eda82b36cf808c',
            abi: REGISTER_ABI,
            cont: {},
        },
        pancake: {
            addr: '0x10ed43c718714eb63d5aa57b78b54704e256024e',
            abi: PANCAKE_ABI,
            cont: {},
        },
        vesting: {
            addr: '0x9bb347379ed36f5B37675C7639E5F878070Bb119',
            abi: VESTING,
            cont: {},
        },
    };
    const testNet = {
        provider: {
            chainId: 0x61,
            link: 'https://bsc-testnet.publicnode.com',
            url: 'https://testnet.bscscan.com',
        },
        pool: {
            deposit: '0x9AABBC35cd3025eab5D59d7206de6058F3A2B1F4',
        },
        usdt: {
            addr: '0x89dFf5a79B4B9546A36a9F5c6D2F092574135a5F',
            dec: 10 ** 18,
            cont: {},
            abi: USDT_ABI,
        },
        bnb: {
            addr: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
            dec: 10 ** 18,
            cont: {},
        },
        ucon: {
            addr: '0x9f4425c2555893d6a5714cb688161af8676265FE',
            dec: 10 ** 18,
            cont: {},
        },
        preSale: {
            addr: '0xa8763aC69DaA7c091D47b8C9581A3e06aE491f58',
            abi: PRESALE_ABI,
            cont: {},
        },

        register: {
            addr: '0x28467609f0a8e19d13a9a751370f56429f28bc8d',
            abi: REGISTER_ABI,
            cont: {},
        },
        pancake: {
            addr: '0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3',
            abi: PANCAKE_ABI,
            cont: {},
        },
        vesting: {
            addr: '0x3C2ccC81935e2Fc745EBd3D2f0C6b36Adb29bD65',
            abi: VESTING,
            cont: {},
        },
    };

    if (network === 'mainnet') {
        return mainNet;
    }
    return testNet;
}

export default {
    namespaced: true,
    state: () => ({
        provider: getNetwork().provider,
        web3: {},

        // wallet
        wallet: {
            addr: '',
        },

        // token
        bep_20: {
            ucon: getNetwork().ucon,
            usdt: getNetwork().usdt,
            bnb: getNetwork().bnb,
        },
        pool: getNetwork().pool,
        bep_721: {},
        // manager
        marketplace: {
            pancake: getNetwork().pancake,
            preSale: getNetwork().preSale,
            vesting: getNetwork().vesting,
        },
        managers: {
            register: getNetwork().register,
            // airdrop: getNetwork().airdrop,
        },
        isNetwork: network,
    }),

    getters: {
        isNetWork: (state) => state.isNetwork,
    },

    actions: {},
    mutations: {
        async setupTokens(state) {
            const bep_20_token = state.bep_20;

            Object.keys(bep_20_token).forEach((key) => {
                if (state.bep_20[key].addr !== '') {
                    if (key === 'usdt' && state.isNetwork === 'testnet') {
                        state.bep_20[key].cont =
                            new state.web3.provider.eth.Contract(
                                state.bep_20[key].abi,
                                state.bep_20[key].addr,
                            );
                    } else {
                        state.bep_20[key].cont =
                            new state.web3.provider.eth.Contract(
                                TOKEN_ABI,
                                state.bep_20[key].addr,
                            );
                    }
                }
            });
        },
        async setupManagers(state) {
            // Managers
            Object.keys(state.managers).forEach((key) => {
                if (state.managers[key].addr !== '') {
                    state.managers[key].cont =
                        new state.web3.provider.eth.Contract(
                            state.managers[key].abi,
                            state.managers[key].addr,
                        );
                }
            });
            Object.keys(state.marketplace).forEach((key) => {
                if (state.marketplace[key].addr !== '') {
                    state.marketplace[key].cont =
                        new state.web3.provider.eth.Contract(
                            state.marketplace[key].abi,
                            state.marketplace[key].addr,
                        );
                }
            });
        },
    },
};
